import { Box, Skeleton } from "@mui/material";
import React from "react";

function InvoicePDFLoading() {
  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
        position: "relative",
        bgcolor: "white",
        borderRadius: "15px",
        margin: "auto",
      }}
    >
      <Box
        sx={{
          p: "1rem",
          position: "absolute",
          top: "0",
          width: "99%",
          height: "99%",
          background: "white",
          margin: "0.5%",
        }}
      >
        <Box
          sx={{ display: "flex", justifyContent: "space-between", mb: "2rem" }}
        >
          <Box>
            <Skeleton
              sx={{ mb: "0.5rem" }}
              variant="rectangular"
              width={210}
              height={30}
            />
            <Skeleton
              sx={{ mb: "0.5rem" }}
              variant="rectangular"
              width={250}
              height={15}
            />
            <Skeleton
              sx={{ mb: "0.5rem" }}
              variant="rectangular"
              width={210}
              height={15}
            />
          </Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ marginRight: "0.5rem" }}>
              <Skeleton
                sx={{ mb: "0.7rem" }}
                variant="rectangular"
                width={125}
                height={15}
              />
              <Skeleton
                sx={{ mb: "0.7rem" }}
                variant="rectangular"
                width={80}
                height={15}
              />
              <Skeleton
                sx={{ mb: "0.7rem" }}
                variant="rectangular"
                width={130}
                height={15}
              />
              <Skeleton
                sx={{ mb: "0.7rem" }}
                variant="rectangular"
                width={105}
                height={15}
              />
              <Skeleton
                sx={{ mb: "0.7rem" }}
                variant="rectangular"
                width={150}
                height={15}
              />
            </Box>
            <Box>
              <Skeleton
                sx={{ mb: "0.5rem" }}
                variant="rectangular"
                width={120}
                height={120}
              />
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" marginBottom="3rem">
          <Box>
            <Skeleton
              sx={{ mb: "0.5rem" }}
              variant="rectangular"
              width={310}
              height={20}
            />
            <Skeleton
              sx={{ mb: "0.5rem" }}
              variant="rectangular"
              width={310}
              height={20}
            />
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: "0.5rem" }}>
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={140}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={110}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={130}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={120}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={150}
                  height={10}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={130}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={110}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={150}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={120}
                  height={10}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={140}
                  height={10}
                />
              </Box>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Box sx={{ mr: "0.5rem" }}>
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={130}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={110}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={150}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={120}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={140}
                  height={12}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={120}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={150}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={130}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={140}
                  height={12}
                />
                <Skeleton
                  sx={{ mb: "0.5rem" }}
                  variant="rectangular"
                  width={110}
                  height={12}
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mb: "3rem" }}>
          <Skeleton
            sx={{ mb: "0.5rem" }}
            variant="rectangular"
            width={150}
            height={30}
          />
          <Skeleton sx={{ mb: "0.1rem", fontSize: "1.5rem" }} variant="text" />
          <Skeleton sx={{ mb: "0.1rem", fontSize: "1.5rem" }} variant="text" />
          <Skeleton sx={{ mb: "0.1rem", fontSize: "1.5rem" }} variant="text" />
          <Skeleton sx={{ mb: "0.1rem", fontSize: "1.5rem" }} variant="text" />
        </Box>
      </Box>
    </Box>
  );
}

export default InvoicePDFLoading;
