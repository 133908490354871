import React from 'react';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box, Typography } from '@mui/material';

const MyTableComponent = (props) => {
  return (
    <TableContainer component={Box} sx={{ marginTop: '5px', border: '1px solid black' }}>
      <Table sx={{ borderCollapse: 'collapse' }}>
        {/* Table Header */}
        <TableHead sx={{borderBottom:'0px solid red'}}>
          <TableRow sx={{ backgroundColor: '#002647' ,borderBottom:'2px solid #002647'}}>
            {(props?.data?.settings?.IsSlNo || props?.printsettings?.IsSlNo) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', }}>SI </TableCell>
            }
            {(props?.data?.settings?.IsProductCode || props?.printsettings?.IsProductCode) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', }}>ItemCode 
            {/* <Typography sx={{ color: 'white'}}>رمز العنصر</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsProductName || props?.printsettings?.IsProductName) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', }}>ProductName
            {/* <Typography sx={{ color: 'white'}}>اسم المنتج</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsBatchCode || props?.printsettings?.IsBatchCode) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', }}>BatchCode
            {/* <Typography sx={{ color: 'white'}}>رمز الدفعة</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsExpiryDate || props?.printsettings?.IsExpiryDate) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', }}>Exp
            {/* <Typography sx={{ color: 'white'}}>الخبرة</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsBaseQty || props?.printsettings?.IsBaseQty) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', textAlign:'center' }}>BaseQty
            {/* <Typography sx={{ color: 'white'}}>الكمية الأساسية</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsQuantity || props?.printsettings?.IsQuantity) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px', textAlign:'center' }}>Qty
            {/* <Typography sx={{ color: 'white'}}>الكمية</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsFreeQuantity || props?.printsettings?.IsFreeQuantity) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>FreeQty
            {/* <Typography sx={{ color: 'white'}}>الكمية المجانية</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsUnit || props?.printsettings?.IsUnit) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>Unit
            {/* <Typography sx={{ color: 'white'}}>وحدة</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsRate || props?.printsettings?.IsRate) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>Rate
            {/* <Typography sx={{ color: 'white'}}>معدل</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsGrossAmount || props?.printsettings?.IsGrossAmount) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>Gross Amt
            {/* <Typography sx={{ color: 'white'}}>المبلغ الإجمالي</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsTax || props?.printsettings?.IsTax) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>VAT
            {/* <Typography sx={{ color: 'white'}}>ضريبة القيمة المضافة</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsDiscount || props?.printsettings?.IsDiscount) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>Discount
            {/* <Typography sx={{ color: 'white'}}>تخفيض</Typography> */}
            </TableCell>
            }
            {(props?.data?.settings?.IsNetTotal || props?.printsettings?.IsNetTotal) &&
            <TableCell sx={{ color: 'white', fontFamily: 'sans-serif', fontSize: '13px', padding: '5px',textAlign:'center' }}>Total
            {/* <Typography sx={{ color: 'white'}}>المجموع</Typography> */}
            </TableCell>
            }
          </TableRow>
        </TableHead>

        {/* Table Body */}
        {props.data?.type === 'pdf' ? (
            <TableBody>
                {props?.data?.details?.map((e, i) => (
                <TableRow key={i}>
                    {(props?.data?.settings?.IsSlNo || props?.printsettings?.IsSlNo) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>{i + 1}</TableCell>
                    }
                    {(props?.data?.settings?.IsProductCode || props?.printsettings?.IsProductCode) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>{e.ProductCode}</TableCell>
                    }
                    {(props?.data?.settings?.IsProductName || props?.printsettings?.IsProductName) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' , maxWidth:'300px', wordBreak:'break-all'}}>{e.ProductName}</TableCell>
                    }
                    {(props?.data?.settings?.IsBatchCode || props?.printsettings?.IsBatchCode) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>{e.BatchCode}</TableCell>
                    }
                    {(props?.data?.settings?.IsExpiryDate || props?.printsettings?.IsExpiryDate) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>{e.ExpiryDatePrint}</TableCell>
                    }
                    {(props?.data?.settings?.IsBaseQty || props?.printsettings?.IsBaseQty) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' , textAlign:'end'}}>{e.BaseQty}</TableCell>
                    }
                    {(props?.data?.settings?.IsQuantity || props?.printsettings?.IsQuantity) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' , textAlign:'end',textAlign:'center'}}>{Number(e.Qty).toFixed(props.PriceRounding)}</TableCell>
                    }
                    {(props?.data?.settings?.IsFreeQuantity || props?.printsettings?.IsFreeQuantity) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' , textAlign:'end',textAlign:'center'}}>{Number(e.FreeQty).toFixed(props.PriceRounding)}</TableCell>
                    }
                    {(props?.data?.settings?.IsUnit || props?.printsettings?.IsUnit) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>{e.UnitName}</TableCell>
                    }
                    {(props?.data?.settings?.IsRate || props?.printsettings?.IsRate) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black', textAlign:'end' }}>{Number(e.UnitPrice).toFixed(props.PriceRounding)}</TableCell>
                    }
                    {(props?.data?.settings?.IsGrossAmount || props?.printsettings?.IsGrossAmount) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black', textAlign:'end' }}>{Number(e.GrossAmount).toFixed(props.PriceRounding)}</TableCell>
                    }
                    {(props?.data?.settings?.IsTax || props?.printsettings?.IsTax) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black', textAlign:'end' }}>{Number(e.TotalTax).toFixed(props.PriceRounding)}</TableCell>
                    }
                    {(props?.data?.settings?.IsDiscount || props?.printsettings?.IsDiscount) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black', textAlign:'end' }}>{Number(e.DiscountAmount_print).toFixed(props.PriceRounding)}</TableCell>
                    }
                    {(props?.data?.settings?.IsNetTotal || props?.printsettings?.IsNetTotal) &&
                    <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black', textAlign:'end' }}>{parseFloat(e.NetAmount).toFixed(2)}</TableCell>
                    }
                </TableRow>
                ))}
            </TableBody>
            ) : (
            <TableBody>
                <TableRow>
                {(props?.data?.settings?.IsSlNo || props?.printsettings?.IsSlNo) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>1</TableCell>
                }
                {(props?.data?.settings?.IsProductCode || props?.printsettings?.IsProductCode) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>Product Code</TableCell>
                }
                {(props?.data?.settings?.IsProductName || props?.printsettings?.IsProductName) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>Product Name</TableCell>
                }
                {(props?.data?.settings?.IsBatchCode || props?.printsettings?.IsBatchCode) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>Batch Code</TableCell>
                }
                {(props?.data?.settings?.IsExpiryDate || props?.printsettings?.IsExpiryDate) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>01/01/2025</TableCell>
                }
                {(props?.data?.settings?.IsBaseQty || props?.printsettings?.IsBaseQty) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0</TableCell>
                }
                {(props?.data?.settings?.IsQuantity || props?.printsettings?.IsQuantity) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0</TableCell>
                }
                {(props?.data?.settings?.IsFreeQuantity || props?.printsettings?.IsFreeQuantity) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0</TableCell>
                }
                {(props?.data?.settings?.IsUnit || props?.printsettings?.IsUnit) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>Unit</TableCell>
                }
                {(props?.data?.settings?.IsRate || props?.printsettings?.IsRate) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0.00</TableCell>
                }
                {(props?.data?.settings?.IsGrossAmount || props?.printsettings?.IsGrossAmount) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0.00</TableCell>
                }
                {(props?.data?.settings?.IsTax || props?.printsettings?.IsTax) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0.00</TableCell>
                }
                {(props?.data?.settings?.IsDiscount || props?.printsettings?.IsDiscount) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0.00</TableCell>
                }
                {(props?.data?.settings?.IsNetTotal || props?.printsettings?.IsNetTotal) &&
                <TableCell sx={{ fontFamily: 'sans-serif', fontSize: '12px', padding: '5px', border: '1px solid black' }}>0.00</TableCell>
                }
                </TableRow>
            </TableBody>
            )}

      </Table>
    </TableContainer>
  );
};

export default MyTableComponent;
