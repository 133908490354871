import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CallParties } from "../../../Api/Reports/CommonReportApi";
import {
  Box,
  Checkbox,
  Paper,
  styled,
  TableBody,
  TableRow,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagenation from "../../../Components/Utils/Pagenation";
import {
  CreateNewButton,
  DeleteButton,
} from "../../../Components/Utils/StyledButtons";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../Components/ReportTable/ReportTable";
import { blue } from "@mui/material/colors";
import { formatNumber } from "../../../generalFunction";
import { MenuOptions } from "../../../Components/CommonComponents";
import { useNavigate } from "react-router-dom";
import { openSnackbar } from "../../../features/SnackBarslice";
import ConfirmBox from "../../../Components/Utils/ConfirmBox";
import { delete_contacts_api } from "../../../Api/Sales/SalesApis";
import BottomNavigationMenu from "../../../Components/Utils/BottomNavigationMenu";
import { domain } from "../../../generalVeriable";
import SearchTextField from "../../../Components/Utils/SearchTextField";

const ListSupplier = () => {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const [loading, setLoading] = useState(false);
  const { PriceDecimalPoint } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { supplierForAllBranches } = useSelector(
    (state) => state.branchSettings
  );
  const appSetting = useSelector((state) => state.appSettingsSclice);

  const { user_id } = useSelector((state) => state.user);
  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Invoice"
  );
  let sales_delete = sales_permission[0].delete_permission;
  let sales_edit = sales_permission[0].edit_permission;
  let sales_view = sales_permission[0].view_permission;
  let sales_save = sales_permission[0].save_permission;

  const OptionList = [
    { name: "Edit", permission: sales_edit },
    { name: "Delete", permission: sales_delete },
  ];

  const [IsConfirmBox, setConfirmBox] = useState(false);

  const [page_no, setPage_no] = useState(1);
  const [state, setState] = useState({
    data: [],
    brand: "",
    description: "",
    BranchID: BranchID,
    PriceRounding: 4,
    submitLoading: false,
    unq_id: null,
    total_count: 0,
    page_no: 1,
    selecte_ids: [],
    is_checked_all: false,
    Search: "",
    selectedItems:[],
    isSelectedAll:false
  });
  const [searchText, setSearchText] = useState("");

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit") {
        if (permission) {
          navigate("/create-supplier", {
            state: { is_edit: true, uniq_id: uniq_id },
          });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for edit",
              severity: "warning",
            })
          );
        }
      } else if (name === "Delete") {
        if (permission) {
          setState({ ...state, selectedItems: [uniq_id] });
          setConfirmBox(true);
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for delete",
              severity: "warning",
            })
          );
        }
      }
    }
  };

  let MenuBottomData = [
    {
      name: "Customer", 
      image: "../images/icons/Customer.svg",
      link: "/customer-list",
    },
    {
      name: "Supplier",
      image: "../images/icons/Supplier.svg",
      link: "/supplier-list",

    },
    {
      name: "Employee",
      image: "../images/icons/Employee.svg",
      link: "dashboard/employee-list",
      domain:"books"

    },
  ]
  const deleteData = async () => {
    if (sales_delete) {
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        selecte_ids: state.selectedItems,
        
      };
      console.log("sup------------------------");
      
      let responseData = await delete_contacts_api({ ...payload });

      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Deleted Successfully",
            severity: "success",
          })
        );

        callSupplierList();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: "You don't have permission for delete",
          severity: "warning",
        })
      );
    }
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = state.data.valuelist?.map((item) => item.id) || [];

      } else {
        data = state.data.map(i => i.id)|| [];

      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const callSupplierList = async function () {
    setLoading(true)
    const res = await CallParties({
      PriceRounding: PriceDecimalPoint,
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      PartyType: 2,
      page_no: page_no,
      items_per_page: 15,
      supplierForAllBranches: supplierForAllBranches,
      List: "All",
      search: searchText,
    });

    if (res.StatusCode === 6000) {
      console.log(res.data);
      setState({
        ...state,
        loading: false,
        page_no: state.page_no + 1,
        total_count: res.count,
        data: res.data,
      });
    } else {
      setState({
        ...state,
        loading: false,
        page_no: 1,
        data: [],
      });
    }
    setLoading(false)
  };

  useEffect(() => {
    callSupplierList();
  }, [state.search, page_no, searchText]);  
  
  useEffect(() => {
    page_no === 1 && callSupplierList();
  }, [searchText])
  
  return (
    <>
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <FlexBox className="left">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Supplier List")}
          </Typography>
          <SearchTextField
            value={searchText}
            escClearState={setSearchText}
            onChange={(e) => {
              setSearchText(e.target.value);
              page_no !== 1 && setPage_no(1)
            }}
          />
        </FlexBox>

        <FlexBox className="right">
          <DeleteButton
            onClick={confirmDelete}
            t={t}
          />

          <CreateNewButton
            onClick={() => {
              if (sales_save) {
                navigate("/create-supplier");
              } else {
                dispatch(
                  openSnackbar({
                    open: true,
                    message: "You don't have permission for create",
                    severity: "warning",
                  })
                );
              }
            }}
            t={t}
          />

          <Pagenation
            totalItem={state.total_count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
        </FlexBox>
      </Box>

      {/* ==============TABLE=============== */}

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll?state.isSelectedAll:false} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("Supplier Name")}</VBTableCellHeader>
          {/* <VBTableCellHeader>{t("First Name	")} </VBTableCellHeader> */}
          {/* <VBTableCellHeader>{t("Last Name")}</VBTableCellHeader> */}
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {t("Supplier Code")}{" "}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {t("Opening Balance")}{" "}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {t("Created ")}{" "}
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {"  "}
          </VBTableCellHeader>
        </VBTableHeader>

        {loading? (
          <VBSkeletonLoader />
        ) : (
          <TableBody>
            {state.data.map((data, i) => (
              <TableRow key={i}
              // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
              //   onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody
                  sx={{
                    // cursor: "pointer",
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <Checkbox
                    checked={state.selectedItems.includes(data.id) ? true : false} 
                    //   state.selectedItems.includes(data.id) ? true : false
                    // } // The checked prop determines whether this checkbox is checked.
                    onChange={() => {
                      selectItems("not", data.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody
                  //   onClick={() => {
                  //     SingleView(data.id);
                  //   }}
                  sx={{ textAlign: "left", cursor: "pointer" }}
                >
                  {(page_no - 1) * appSetting.itemPerPage + i + 1}
                </VBTableCellBody>
                <VBTableCellBody
                  //   onClick={() => {
                  //     SingleView(data.id);
                  //   }}
                  sx={{ cursor: "pointer" }}
                >
                  {data.PartyName}
                </VBTableCellBody>
                <VBTableCellBody
                  //   onClick={() => {
                  //     SingleView(data.id);
                  //   }}
                  sx={{ textAlign: "right", cursor: "pointer" }}
                >
                  {data.PartyCode}
                </VBTableCellBody>
                <VBTableCellBody
                  //   onClick={() => {
                  //     SingleView(data.id);
                  //   }}
                  sx={{ textAlign: "right", cursor: "pointer" }}
                >
                  {data.OpeningBalance}
                </VBTableCellBody>
                <VBTableCellBody
                  //   onClick={() => {
                  //     SingleView(data.id);
                  //   }}
                  sx={{ textAlign: "right", cursor: "pointer" }}
                >
                  {data.CreatedDate}
                </VBTableCellBody>
                <VBTableCellBody
                  //   onClick={() => {
                  //     SingleView(data.id);
                  //   }}
                  sx={{ textAlign: "right", cursor: "pointer" }}
                >
                  <MenuOptions
                    OptionList={OptionList}
                    OptionOnClick={OptionOnClick}
                    uniq_id={data.id}
                    disable={
                      data.is_billwised || data.IsNewSale === false
                        ? true
                        : false
                    }
                  />
                </VBTableCellBody>
              </TableRow>
            ))}
            <VBTableRowNull length = {state.data?.length}/>
          </TableBody>
        )}
      </VBTableContainer>

      <ConfirmBox
            heading={"Confirm to delete?"}
            message={"Once you delete, you will not get it back!"}
            open={IsConfirmBox}
            setOpen={setConfirmBox}
            confirmFunction={deleteData}
          />
    </Paper>
      <BottomNavigationMenu data={MenuBottomData}/>
</>
  );
};

export default ListSupplier;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  // width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: 2,
  },
}));
