import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagenation from "../../Components/Utils/Pagenation";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../generalFunction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_stock_management,
  list_stock_management,
} from "../../Api/CommonApi/InvoiceApis/StockManagementApi";
import { json, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CreateNewButton,
  DeleteButton,
} from "../../Components/Utils/StyledButtons";
import ConfirmBox from "../../Components/Utils/ConfirmBox";
import { delete_sales_api } from "../../Api/Sales/SalesApis";
import { MenuOptions } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import {
  delete_sales_return_api,
  list_sales_return_api,
} from "../../Api/Sales/SalesReturnApis";
import { domain, VIKNBOOKS_FRONT_URL } from "../../generalVeriable";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";

const SalesReturnList = () => {
  let MenuBottomData = [
    {
      name: "Sales Estimate",
      image: "../images/icons/Estimate.svg",
      link: "/list-sales-estimate",
    },
    {
      name: "Sales Order",
      image: "../images/icons/Order.svg",
      link: "dashboard/sales-order-list",
      domain: "books",
    },
    {
      name: "Sales Invoice",
      image: "../images/icons/Invoice.svg",
      link: "/list-sales",
    },
    {
      name: "Sales Return",
      image: "../images/icons/Return.svg",
      link: "/list-sales-return",
    },
  ];

  const { EnableEinvoiceKSA } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    search: null,
    selectedItems: [],
    isSelectedAll: false,
  });
  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Return"
  );
  let sales_delete = sales_permission[0].delete_permission;
  let sales_edit = sales_permission[0].edit_permission;
  let sales_view = sales_permission[0].view_permission;
  let sales_save = sales_permission[0].save_permission;

  const OptionList = [
    { name: "Edit", permission: sales_edit },
    { name: "Delete", permission: sales_delete },
  ];
  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit" && permission) {
        navigate("/create-sales-return", {
          state: { is_edit: true, uniq_id: uniq_id },
        });
      } else if (name === "Delete" && permission) {
        setState({ ...state, selectedItems: [uniq_id] });
        setConfirmBox(true);
      }
    }
  };

  const SingleView = (id) => {
    if (sales_view) {
      const url = `${VIKNBOOKS_FRONT_URL}dashboard/sales-return-preview?id=${id}&unq_id=${id}`;
      const newTab = window.open(url, "_blank");
    }
  };

  const [IsConfirmBox, setConfirmBox] = useState(false);

  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  const [loading, setLoading] = useState(false);

  const [t, i18n] = useTranslation("common");

  const fetchData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      search: state.search,
      page: page_no,
      NoOfItems: noOfItems,
    };
    let responseData = await list_sales_return_api({ ...payload });
    console.log(responseData, "responseData==========================");
    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message:
            responseData?.message + " : " + responseData?.error ??
            "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      IdList: state.selectedItems,
    };

    let responseData = await delete_sales_return_api({ ...payload });
    console.log(responseData, "responseeeeeeeeeDataaaaaaaaaa");
    if (responseData.StatusCode === 6000) {
      fetchData();

      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  useEffect(() => {
    fetchData();
  }, [state.search, page_no]);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {t("Sales Return List")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            {!EnableEinvoiceKSA && (
              <DeleteButton onClick={confirmDelete} t={t} />
            )}

            <CreateNewButton
              onClick={() => {
                if (sales_save) {
                  navigate("/create-sales-return");
                } else {
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: "You don't have permission for create",
                      severity: "warning",
                    })
                  );
                }
              }}
              t={t}
            />

            <Pagenation
              totalItem={state.data_count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </FlexBox>
        </Box>

        {/* ==============TABLE=============== */}

        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader
              sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
            >
              <Checkbox
                checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
                onChange={() => {
                  selectItems("all");
                }}
                sx={{
                  padding: "2px",
                  margin: 0,
                  color: blue[400],
                  "&.Mui-checked": {
                    color: blue[400],
                  },
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                }}
              />
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Serial No")}
            </VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
            <VBTableCellHeader>{t("Ledger Name")}</VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Gross Amount")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Total Tax")}{" "}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "right" }}>
              {t("Grand Total")}{" "}
            </VBTableCellHeader>
            {/* <VBTableCellHeader>{t("Status")} </VBTableCellHeader> */}
            <VBTableCellHeader sx={{ textAlign: "right", color: "white" }}>
              .
            </VBTableCellHeader>
          </VBTableHeader>

          {loading ? (
            <VBSkeletonLoader />
          ) : (
            <TableBody>
              {state.dataList.map((data, i) => (
                <TableRow sx={{height:EnableEinvoiceKSA && "48px"}}
                // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
                //   onClick={()=>{handleTableRowClick(data)}}
                >
                  <VBTableCellBody
                    sx={{
                      // cursor: "pointer",
                      textAlign: "left",
                      padding: "0px",
                      paddingLeft: "5px",
                    }}
                  >
                    <Checkbox
                      checked={
                        state.selectedItems.includes(data.id) ? true : false
                      } // The checked prop determines whether this checkbox is checked.
                      onChange={() => {
                        selectItems("not", data.id);
                      }}
                      sx={{
                        padding: "2px",
                        margin: 0,
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                        "& .MuiSvgIcon-root": { fontSize: "20px" },
                      }}
                    />
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ textAlign: "left", cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {(page_no - 1) * appSetting.itemPerPage + i + 1}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {data.VoucherNo}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {data.VoucherDate}
                  </VBTableCellBody>
                  <VBTableCellBody
                    className="truncated-text "
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {data.LedgerName}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ textAlign: "right", cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {formatNumber(data.TotalGrossAmt)}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ textAlign: "right", cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {formatNumber(data.TotalTax)}
                  </VBTableCellBody>
                  <VBTableCellBody
                    sx={{ textAlign: "right", cursor: "pointer" }}
                    onClick={() => {
                      SingleView(data.id);
                    }}
                  >
                    {formatNumber(data.GrandTotal)}
                  </VBTableCellBody>
                  {!EnableEinvoiceKSA && (
                    <VBTableCellBody
                      sx={{ cursor: "pointer", textAlign: "right" }}
                    >
                      <MenuOptions
                        OptionList={OptionList}
                        OptionOnClick={OptionOnClick}
                        uniq_id={data.id}
                        disable={data.IsNewSalesReturn === false}
                      />
                    </VBTableCellBody>
                  )}
                </TableRow>
              ))}
              <VBTableRowNull
                length={
                  state.data_count ? state.data_count : state.dataList.length
                }
              />
            </TableBody>
          )}
        </VBTableContainer>
        <ConfirmBox
          heading={"Confirm to delete?"}
          message={"Once you delete, you will not get it back!"}
          open={IsConfirmBox}
          setOpen={setConfirmBox}
          confirmFunction={deleteData}
        />
      </Paper>
      <BottomNavigationMenu data={MenuBottomData} />
    </>
  );
};

export default SalesReturnList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
