import moment from "moment";
import {
  ACCOUNTS_API_URL,
  ACCOUNTS_FRONT_URL,
  ADMIN_FRONT_URL,
  MyUrl,
  PAYROLL_FRONT_URL,
  TASK_FRONT_URL,
  VIKNBOOKS_FRONT_URL,
  domain,
} from "../generalVeriable";
import { Decrypt, Encrypt, formatNumber, getCookie } from "../generalFunction";
import axios from "axios";
import { store } from "../app/store";
import {
  executeAlertFunction,
  openAlertPopUp,
} from "../features/SnackBarslice";
const _ = require("lodash");

export const date_range = function (range) {
  let date = {
    fromdate: "",
    todate: "",
  };
  switch (range) {
    case "Last 90 Days":
      date.fromdate = moment().subtract(90, "days");
      date.todate = moment();
      break;
    case "Last Year":
      date.fromdate = moment().subtract(1, "year").startOf("year");
      date.todate = moment().subtract(1, "year").endOf("year");
      break;
    case "Last Month":
      date.fromdate = moment().subtract(1, "month").startOf("month");
      date.todate = moment().subtract(1, "month").endOf("month");
      break;
    case "This Week":
      date.fromdate = moment().startOf("week");
      date.todate = moment().endOf("week");
      break;
    case "This Month":
    case "Monthly":
      date.fromdate = moment().startOf("month");
      date.todate = moment().endOf("month");
      break;
    case "This Year":
      date.fromdate = moment().startOf("year");
      date.todate = moment().endOf("year");
      break;
    case "This Quarter":
      date.fromdate = moment().subtract(3, "month").startOf("month").date(1); // First day of the month 3 months ago
      date.todate = moment(date.fromdate)
        .add(2, "months")
        .date(31)
        .endOf("day"); // Last day of the current month
      break;
    default:
      date.fromdate = moment();
      date.todate = moment();
      break;
  }
  return date;
};

// Go to the invoice by passing the voucher type
export const goToInvoice = function (id, VoucherType, VoucherNo) {
  const voucherTypeUrls = {
    "Sales Invoice": `${MyUrl}/dashboard/sale-invoice-single?id=${id}`,
    SI: `${MyUrl}/dashboard/sale-invoice-single?id=${id}`,
    "Sales Return": `${MyUrl}/dashboard/sales-return-preview?id=${id}`,
    SR: `${MyUrl}/dashboard/sales-return-preview?id=${id}`,
    "Purchase Invoice": `${MyUrl}/dashboard/purchase-invoice-single?id=${id}`,
    PI: `${MyUrl}/dashboard/purchase-invoice-single?id=${id}`,
    "Purchase Return": `${MyUrl}/dashboard/purchase-return-single?id=${id}`,
    PR: `${MyUrl}/dashboard/purchase-return-single?id=${id}`,
    "Opening Stock": `${MyUrl}/dashboard/create-opening-stock?id=${id}`,
    OS: `${MyUrl}/dashboard/create-opening-stock?id=${id}`,
    "Work Order": `${MyUrl}/dashboard/create-work-order?id=${id}`,
    WO: `${MyUrl}/dashboard/create-work-order?id=${id}`,
    "Stock Transfer": `${MyUrl}/dashboard/stock-transfer-single?id=${id}&invoice_type=stock_transfer`,
    ST: `${MyUrl}/dashboard/stock-transfer-single?id=${id}`,
    Expense: `${MyUrl}/dashboard/expense-single?id=${id}`,
    EX: `${MyUrl}/dashboard/expense-single?id=${id}`,
    Payment: `${MyUrl}/dashboard/payment-single?boo=true&is_print=true&id=${id}`,
    CP: `${MyUrl}/dashboard/payment-single?boo=true&is_print=true&id=${id}`,
    BP: `${MyUrl}/dashboard/payment-single?boo=true&is_print=true&id=${id}`,
    Receipt: `${MyUrl}/dashboard/receipt-single?boo=true&is_print=true&id=${id}`,
    BR: `${MyUrl}/dashboard/receipt-single?boo=true&is_print=true&id=${id}`,
    CR: `${MyUrl}/dashboard/receipt-single?boo=true&is_print=true&id=${id}`,
    Journal: `${MyUrl}/dashboard/journal-single?id=${id}`,
    JL: `${MyUrl}/dashboard/journal-single?id=${id}`,
    "Excess Stock": `${MyUrl}/create-stock-management?id=${id}`,
    ES: `${MyUrl}/create-stock-management?id=${id}`,
    "Shortage Stock": `${MyUrl}/create-stock-management?id=${id}`,
    SS: `${MyUrl}/create-stock-management?id=${id}`,
    "Damage Stock": `${MyUrl}/create-stock-management?id=${id}`,
    DS: `${MyUrl}/create-stock-management?id=${id}`,
    "Used Stock": `${MyUrl}/create-stock-management?id=${id}`,
    US: `${MyUrl}/create-stock-management?id=${id}`,
    "Create Product": `${MyUrl}/dashboard/create-products?id=${id}`,
    SBT: `${MyUrl}/dashboard/sale-invoice-single-bt?id=${id}&invoice_type=purchase_invoice_bt&VoucherNo=${VoucherNo}`,
    PBT: `${MyUrl}/dashboard/purchase-invoice-single-bt?id=${id}&invoice_type=purchase_invoice_bt&VoucherNo=${VoucherNo}`,
    "Goods Received Note": `${MyUrl}/dashboard/preview-goods-received-note?id=${id}&invoice_type=goods_received_note&VoucherNo=${VoucherNo}`,
  };

  // Check if VoucherType exists in voucherTypeUrls, and open the corresponding URL
  if (voucherTypeUrls.hasOwnProperty(VoucherType)) {
    window.open(voucherTypeUrls[VoucherType], "_blank");
  } else {
    console.log("Voucher type not found in voucherTypeUrls");
  }
};

export const handleCashLedgers = async (filterOptions, notStartZero) => {
  let list = [0];
  if (notStartZero) {
    list = [];
  }
  let ledgerValue = filterOptions.ledgerValue;
  const ledgerIDs = ledgerValue.map((item) => item.LedgerID);
  list.push(...ledgerIDs);
  return list;
};
// to get avatar images just pass a value you will get a random image
export const getAvatar = (value = "1", imageType = "identicon", res = 46) => {
  const hashCode = (s) =>
    s.split("").reduce((a, b) => {
      a = (a << 5) - a + b.charCodeAt(0);
      return Math.abs(a & a);
    }, 0);

  // Create an hash of the string
  const hash = hashCode(value);
  // Grab the actual image URL

  // return `https://avatars.dicebear.com/api/${imageType}/${hash}.svg`;
  return `https://www.gravatar.com/avatar/${hash}?s=${res}&d=${imageType}&r=PG&f=1`;
};
export function checkWordLength(word) {
  let str = new String(word);
  let length = str.length;
  if (length > 13) {
    return true;
  }
  return false;
}
export const get_unq_browserID = async (data) => {
  // const biri = require("biri");
  // const uniqueId = await biri();
  var navigator_info = window.navigator;
  var screen_info = window.screen;
  var uid = navigator_info.mimeTypes.length;
  uid += navigator_info.userAgent.replace(/\D+/g, "");
  uid += navigator_info.plugins.length;
  uid += screen_info.height || "";
  uid += screen_info.width || "";
  uid += screen_info.pixelDepth || "";
  console.log(uid);
  return uid;
};
export function getServiceUrl(service) {
  if (service === "viknbooks") {
    console.log("inside viknbooks");
    window.open(`${VIKNBOOKS_FRONT_URL}dashboard/home`, "_self");
  } else if (service === "payroll") {
    console.log("inside payroll", PAYROLL_FRONT_URL);
    window.open(`${PAYROLL_FRONT_URL}dashboard`, "_self");
  } else if (service === "task_manager") {
    console.log("inside task manager");
    window.open(`${TASK_FRONT_URL}`, "_self");
  } else if (service === "admin_panel") {
    window.open(`${ADMIN_FRONT_URL}`, "_self");
  } else {
    window.open(`${ACCOUNTS_FRONT_URL}`, "_self");
  }
}
export const set_RecentLoginUsersCookies = async (data) => {
  let ciphertext = Encrypt(data);
  console.log(ciphertext, "(((((((((((************))))))))))))))))");
  // =======END=============
  let return_value = false;
  await axios
    .get(
      `${ACCOUNTS_API_URL}api/v1/accounts/recent-login-users?data=${ciphertext}`,
      {
        withCredentials: true,
      }
    )
    .then(() => {
      const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
      });
      let value = params.service;
      // window.location.reload();
      return_value = true;
    });
  return return_value;
};
export const set_handleLogout = async (dic) => {
  document.cookie = `VBID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;

  // ==================RECENT USERS==================
  let recent_login_users = getCookie("ACCOUNT_CHOOSER");

  // let dic = {
  //   username: username,
  //   email: email,
  //   image_url:
  //     "https://www.api.viknbooks.com/media/profiles/Screenshot_from_2022-02-02_11-45-23.png",
  // };
  let data = [];
  let data_exists = {};
  if (recent_login_users) {
    console.log(data, "###datadata============");
    try {
      data = Decrypt(JSON.parse(recent_login_users));
    } catch (err) {
      data = Decrypt(recent_login_users);
    }
    data_exists = _.find(data, function (o) {
      return o.username === dic["username"];
    });
  }

  if (_.isEmpty(data_exists)) {
    data.push(dic);
    console.log("^^^^^IFFF^^^^^^^^^^^&*");
  } else {
    console.log("^^^^^ELSE^^^^^^^^^^^&*");
  }

  data = data.slice(-2);
  let return_value = await set_RecentLoginUsersCookies(data);

  // if (recent_login_users) {
  //   data = JSON.parse(data);
  // }
  return return_value;
};

// Export report PDF and EXEL
const ExportExcel = function (data, filename) {
  // Convert a binary string to an array buffer
  // let newData = data.map((item) =>
  //   item.data.map((i) => ({
  //     Date: item.date,
  //     Particular: returnVoucherType(i.voucher_type),
  //     Amount: formatNumber(i.amount),
  //     Notes: i.description,
  //   }))
  // );
  // newData = newData.flat();
  // // Calculate the total amount
  // const totalAmount = newData.reduce((total, item) => total + parseFloat(item.Amount), 0);
  // // Add the total amount to the data
  // newData.push({
  //   Date: "Total",
  //   Particular: "",
  //   Amount: AmountFormater(totalAmount),
  //   Notes: "",
  // });
  // const s2ab = (s) => {
  //   const buf = new ArrayBuffer(s.length);
  //   const view = new Uint8Array(buf);
  //   for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
  //   return buf;
  // };
  // // Convert the data to a worksheet
  // const worksheet = XLSX.utils.json_to_sheet(newData);
  // // Create a new workbook and append the worksheet to it
  // const workbook = XLSX.utils.book_new();
  // XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
  // // Write the workbook to a binary string
  // const wbout = XLSX.write(workbook, { bookType: "xlsx", type: "binary" });
  // // Create a blob and download the file
  // const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" });
  // const url = window.URL.createObjectURL(blob);
  // const a = document.createElement("a");
  // a.href = url;
  // a.download = filename + ".xlsx";
  // a.click();
};

const customRound = (num) => {
  const integerPart = Math.floor(num);
  const decimalPart = num - integerPart;

  if (decimalPart >= 0.5) {
    return Math.ceil(num * 10) / 10;
  } else {
    return Math.floor(num * 10) / 10;
  }
};

export function debounce(func, delay) {
  let timeout;
  return function (...args) {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}

export function debounce1(func, wait) {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

export function showAlertBox() {}

export const downloadFile = (
  data,
  filename,
  mime = "application/octet-stream",
  bom
) => {
  const blobData = typeof bom !== "undefined" ? [bom, data] : [data];
  const blob = new Blob(blobData, { type: mime });

  if (typeof window.navigator.msSaveBlob !== "undefined") {
    // IE workaround
    window.navigator.msSaveBlob(blob);
  } else {
    const blobURL = window.URL.createObjectURL(blob);
    const tempLink = document.createElement("a");
    tempLink.style.display = "none";
    tempLink.href = blobURL;
    tempLink.setAttribute("download");

    // Handle Safari and its popup blocking
    if (typeof tempLink.download === "undefined") {
      tempLink.setAttribute("target", "_blank");
    }

    document.body.appendChild(tempLink);
    tempLink.click();

    // Clean up
    setTimeout(() => {
      document.body.removeChild(tempLink);
      window.URL.revokeObjectURL(blobURL);
    }, 200);
  }
};

export const serializeParams = (obj) => {
  const str = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]));
    }
  }
  return str.join("&");
};

export const MasterCalculations = async (
  Details,
  state,
  PriceRounding,
  RoundOffFigure,
  EnableForeignCurrency
) => {
  // console.log("master calculations??????????");
  let TotalNetAmount = 0;
  let TotalGrossAmt = 0;
  let TotalTaxableAmount = 0;
  let GrandTotalTax = 0;
  let TotalDiscountAmount = 0;
  let TotalAddlDiscAmt = 0;
  let TotalVATAmount = 0;
  let TotalSGSTAmount = 0;
  let TotalCGSTAmount = 0;
  let TotalIGSTAmount = 0;
  let TotalTAX1Amount = 0;
  let TotalTAX2Amount = 0;
  let TotalTAX3Amount = 0;
  let TotalKFCAmount = 0;
  let TotalQty = 0;
  let TotalFreeQty = 0;

  let IGST_perc_list = [];
  let IGST_final_list = [];
  let SGST_perc_list = [];
  let SGST_final_list = [];
  let shipping_tax_amount = state.shipping_tax_amount;
  if (!shipping_tax_amount) {
    shipping_tax_amount = 0;
  }
  let half_shipping_tax_amt = Number(shipping_tax_amount) / 2;
  if (!half_shipping_tax_amt) {
    half_shipping_tax_amt = 0;
  }
  let ShippingCharge = state.ShippingCharge;
  if (!ShippingCharge) {
    ShippingCharge = 0;
  }
  let SalesTax = state.SalesTax;
  if (!SalesTax) {
    SalesTax = 0;
  }

  let TaxTaxableAmount = 0;
  let NonTaxTaxableAmount = 0;

  let TotalNetAmountFc = 0;
  let TotalGrossAmtFc = 0;
  let DetailTotalDiscAmount = 0;

  let ExciseTaxAmount = 0;

  Details.map((i) => {
    TotalNetAmount += i.NetAmount ? Number(i.NetAmount) : 0;
    TotalGrossAmt += i.GrossAmount ? Number(i.GrossAmount) : 0;
    TotalTaxableAmount += i.TaxableAmount ? Number(i.TaxableAmount) : 0;
    GrandTotalTax += i.TotalTax ? Number(i.TotalTax) : 0;
    TotalDiscountAmount += i.DiscountAmount ? Number(i.DiscountAmount) : 0;
    TotalAddlDiscAmt += i.AddlDiscAmt ? Number(i.AddlDiscAmt) : 0;
    TotalVATAmount += i.VATAmount ? Number(i.VATAmount) : 0;
    TotalSGSTAmount += i.SGSTAmount ? Number(i.SGSTAmount) : 0;
    TotalCGSTAmount += i.CGSTAmount ? Number(i.CGSTAmount) : 0;
    TotalIGSTAmount += i.IGSTAmount ? Number(i.IGSTAmount) : 0;
    TotalTAX1Amount += i.TAX1Amount ? Number(i.TAX1Amount) : 0;
    TotalTAX2Amount += i.TAX2Amount ? Number(i.TAX2Amount) : 0;
    TotalTAX3Amount += i.TAX3Amount ? Number(i.TAX3Amount) : 0;
    TotalKFCAmount += i.KFCAmount ? Number(i.KFCAmount) : 0;

    TotalNetAmountFc += i.NetAmountFc ? Number(i.NetAmountFc) : 0;
    TotalGrossAmtFc += i.GrossAmountFc ? Number(i.GrossAmountFc) : 0;
    DetailTotalDiscAmount += i.DiscountAmount
      ? Number(i.DiscountAmount) / i.ForeignCurrency
      : 0;

    ExciseTaxAmount += i.ExciseTax ? Number(i.ExciseTax) : 0;

    let qty = i.Qty ? Number(i.Qty) : 0;
    let free_qty = i.FreeQty ? Number(i.FreeQty) : 0;
    let MultiFactor = i.MultiFactor ? Number(i.MultiFactor) : 1;
    let BaseQty = Number(qty) * Number(MultiFactor);
    let BaseFreeQty = Number(free_qty) * Number(MultiFactor);

    TotalQty += Number(BaseQty);
    TotalFreeQty += Number(BaseFreeQty);

    if (i.VATPerc > 0 || i.IGSTPerc > 0) {
      TaxTaxableAmount += Number(i.TaxableAmount);
    } else {
      NonTaxTaxableAmount += Number(i.TaxableAmount);
    }

    let is_exist_IGST = IGST_perc_list.includes(i.IGSTPerc);
    if (is_exist_IGST === false && i.IGSTPerc > 0) {
      IGST_perc_list.push(i.IGSTPerc);
      let IGSTAmount = Number(i.IGSTAmount) + Number(shipping_tax_amount);
      IGST_final_list.push({
        key: Number(i.IGSTPerc).toFixed(PriceRounding),
        val: Number(IGSTAmount),
      });
    } else {
      IGST_final_list.map((b) => {
        if (Number(b.key).toFixed(2) === Number(i.IGSTPerc).toFixed(2)) {
          let val_amt = b.val;
          b.val = Number(val_amt) + Number(i.IGSTAmount);
        }
      });
    }

    let is_exist_SGST = SGST_perc_list.includes(i.SGSTPerc);
    if (is_exist_SGST === false && Number(i.SGSTPerc) > 0) {
      SGST_perc_list.push(i.SGSTPerc);
      let SGSTAmount = Number(i.SGSTAmount) + Number(half_shipping_tax_amt);
      SGST_final_list.push({
        key: Number(i.SGSTPerc).toFixed(PriceRounding),
        val: Number(SGSTAmount),
      });
    } else {
      SGST_final_list.map((b) => {
        if (Number(b.key).toFixed(2) === Number(i.SGSTPerc).toFixed(2)) {
          let val_amt = b.val;
          b.val = Number(val_amt) + Number(i.SGSTAmount);
        }
      });
    }
  });

  if (Number(TotalVATAmount) > 0) {
    TotalVATAmount = Number(TotalVATAmount) + Number(shipping_tax_amount);
  }
  if (Number(TotalSGSTAmount) > 0) {
    TotalSGSTAmount = Number(TotalSGSTAmount) + Number(half_shipping_tax_amt);
  }
  if (Number(TotalCGSTAmount) > 0) {
    TotalCGSTAmount = Number(TotalCGSTAmount) + Number(half_shipping_tax_amt);
  }
  if (Number(TotalIGSTAmount) > 0) {
    TotalIGSTAmount = Number(TotalIGSTAmount) + Number(shipping_tax_amount);
  }
  if (Number(SalesTax) > 5) {
    TotalKFCAmount = Number(TotalKFCAmount) + Number(shipping_tax_amount);
  }

  TotalTaxableAmount =
    Number(TotalTaxableAmount) +
    Number(shipping_tax_amount) +
    Number(ShippingCharge);

  let BillDiscPercent = state.BillDiscPercent;
  let BillDiscAmt = Number(
    (TotalTaxableAmount * Number(BillDiscPercent)) / 100
  );
  let TotalDiscount =
    Number(TotalDiscountAmount) +
    Number(TotalAddlDiscAmt) +
    Number(BillDiscAmt);
  let GrandTotal = 0;
  let GrandTotalFc = 0;

  TotalNetAmount =
    Number(TotalNetAmount) +
    Number(shipping_tax_amount) +
    Number(ShippingCharge);

  if (EnableForeignCurrency) {
    TotalNetAmountFc =
      Number(TotalNetAmountFc) +
      Number(shipping_tax_amount) +
      Number(ShippingCharge);
  }

  // if saudi vat then Calculation will change if bill discount  start function
  let tx_ok = true;
  if (state.is_purchase || state.is_purchaseOrder) {
    if (state.VAT_Treatment === "1") {
      tx_ok = false;
    } else if (state.VAT_Treatment === "7") {
      tx_ok = false;
    }
  }

  if (state.CountryCode === "SAR" && state.VAT && BillDiscAmt > 0 && tx_ok) {
    GrandTotalTax = await get_vat_changed_details(
      TotalGrossAmt,
      Details,
      BillDiscAmt,
      TaxTaxableAmount,
      NonTaxTaxableAmount
    );

    TotalVATAmount = Number(GrandTotalTax) + Number(shipping_tax_amount);
  }
  // end function
  GrandTotal = Number(
    TotalGrossAmt +
      GrandTotalTax -
      TotalDiscount +
      Number(shipping_tax_amount) +
      Number(ShippingCharge)
  );

  if (EnableForeignCurrency) {
    let BillDiscPercent = state.BillDiscPercent;
    let BillDiscAmt = Number(
      (TotalTaxableAmount * Number(BillDiscPercent)) / 100
    );

    let TotalDiscountFc = BillDiscAmt / state.ForeignCurrency;
    TotalDiscountFc += DetailTotalDiscAmount;

    GrandTotalFc = Number(
      TotalGrossAmtFc +
        GrandTotalTax -
        TotalDiscountFc +
        Number(shipping_tax_amount) +
        Number(ShippingCharge)
    );
  }

  let roundoff = state.RoundOff;
  if (state.edit_did_mount === false) {
    if (state.is_manual_roundoff === false) {
      let grand_int = Math.floor(GrandTotal);
      let grand_dec = Number(GrandTotal % 1);
      let round = 0;
      if (RoundOffFigure > 0) {
        round = Number(RoundOffFigure) / 10;
      }
      if (round > 0) {
        if (grand_dec <= round) {
          GrandTotal = grand_int;
          roundoff = Number(grand_dec) * -1;
        } else {
          GrandTotal = Number(grand_int + 1);
          roundoff = 1 - Number(grand_dec);
        }
      }
    } else {
      GrandTotal = Number(GrandTotal) + Number(state.RoundOff);
    }
  } else {
    GrandTotal = Number(GrandTotal) + Number(state.RoundOff);
  }

  if (!roundoff) {
    roundoff = 0;
  }

  let LoyaltyValue = state.LoyaltyValue;
  if (!LoyaltyValue) {
    LoyaltyValue = 0;
  }
  if (GrandTotal > LoyaltyValue) {
    GrandTotal = Number(GrandTotal) - Number(LoyaltyValue);
  }

  let CashReceived = Number(state.CashReceived);
  let BankAmount = state.BankAmount;
  let cash = Number(CashReceived) + Number(BankAmount);
  // let Balance = Number(GrandTotal) - Number(cash);
  let Balance = Number(cash) - Number(GrandTotal);
  TotalQty = Number(TotalQty) + Number(TotalFreeQty);
  GrandTotalTax = Number(GrandTotalTax) + Number(shipping_tax_amount);
  let master_values = {
    TotalNetTotal: Number(TotalNetAmount),
    TotalGrossAmt: Number(TotalGrossAmt),
    TotalTaxableAmount: Number(TotalTaxableAmount),
    GrandTotalTax: Number(GrandTotalTax),
    TotalDiscount: Number(TotalDiscount),
    GrandTotal: Number(GrandTotal),
    TotalVATAmount: Number(TotalVATAmount),
    TotalSGSTAmount: Number(TotalSGSTAmount),
    TotalCGSTAmount: Number(TotalCGSTAmount),
    TotalIGSTAmount: Number(TotalIGSTAmount),
    TotalTAX1Amount: Number(TotalTAX1Amount),
    TotalTAX2Amount: Number(TotalTAX2Amount),
    TotalTAX3Amount: Number(TotalTAX3Amount),
    TotalKFCAmount: Number(TotalKFCAmount),
    BillDiscPercent: Number(BillDiscPercent),
    BillDiscAmount: Number(BillDiscAmt),
    Balance: Number(Balance),
    Balance_print: Number(Balance).toFixed(PriceRounding),
    RoundOff: Number(roundoff).toFixed(PriceRounding),
    RoundOffSaveVal: Number(roundoff),
    IGST_final_list: IGST_final_list,
    SGST_final_list: SGST_final_list,
    TaxTaxableAmount: TaxTaxableAmount,
    NonTaxTaxableAmount: NonTaxTaxableAmount,
    TotalQty: Number(TotalQty),
    GrandTotalFc: Number(GrandTotalFc),
    TotalNetAmountFc: Number(TotalNetAmountFc),
    TotalGrossAmtFc: Number(TotalGrossAmtFc),
    ExciseTaxAmount: Number(ExciseTaxAmount),
  };
  return master_values;
};

const get_vat_changed_details = async (
  TotalGrossAmt,
  Details,
  BillDiscAmt,
  TaxTaxableAmount,
  NonTaxTaxableAmount
) => {
  let tax_sum = 0;
  if (Number(TaxTaxableAmount) >= Number(BillDiscAmt)) {
    let vat_tax = (Number(BillDiscAmt) / Number(TaxTaxableAmount)) * 100;
    Details.map((i, index) => {
      if (i.VATPerc > 0) {
        let tx_amt = (Number(i.TaxableAmount) * Number(vat_tax)) / 100;
        let new_taxble = Number(i.TaxableAmount) - Number(tx_amt);
        tax_sum += (Number(new_taxble) * Number(i.VATPerc)) / 100;
      }
    });
  }
  return tax_sum;
};

/**
 * Dispatches an alert popup with customizable content.
 * @param {Object} options - Options for configuring the alert popup.
 * @param {boolean} options.open - Whether the alert is open.
 * @param {string} [options.title="Alert Title"] - Title of the alert.
 * @param {string} [options.description="Alert Description"] - Description of the alert.
 * @param {string} [options.cancelButtonText="Cancel"] - Text for the cancel button.
 * @param {string} [options.confirmButtonText="Confirm"] - Text for the confirm button.
 * @param {Function} [options.cnfFunction] - Callback function for confirm action.
 */
export const viknAlert = function ({
  open = false,
  title = "Alert Title",
  description = "Alert Description",
  cancelButtonText = "Cancel",
  confirmButtonText = "Confirm",
  cnfFunction = null,
}) {
  store.dispatch(
    openAlertPopUp({
      open,
      title,
      description,
      cancelButtonText,
      confirmButtonText,
      cnfFunction,
    })
  );
};

// To confirm and execute the function, call `executeAlertFunction`
store.dispatch(executeAlertFunction());
