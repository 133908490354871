import axios from "axios";
import { openSnackbar } from "../../features/SnackBarslice";

const bankListAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/banks/banks/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in Bank List API");
  }
};

const bankDeleteAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/banks/delete/bank/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const bankCreateAPI = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v10/banks/create-bank/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Bank Create API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const BankViewAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/banks/view/bank/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const BankUpdateAPI = async function (body, id) {
  try {
    const { data } = await axios.post("api/v10/banks/edit/bank/" + id + "/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const taxCatogeryListAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/taxCategories/list-taxCategory/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in Tax Catogery List API");
  }
};

const taxTypeListAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/taxCategories/listTaxType/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in Tax Type List API");
  }
};

const taxCatogeryCreateAPI = async function (body, dispatch) {
  try {
    const data = axios({
      method: "post",
      url: "api/v11/taxCategories/create-taxCategory/",
      data: body,
      headers: { "Content-Type": "application/json" },
    });

    return data;
  } catch (error) {
    console.log("error on Tax Create API", error);
    dispatch(
      openSnackbar({
        open: true,
        message: error.response?.data?.message || "Error Occurred In API",
        severity: "error",
      })
    );
  }
};

const taxCatogeryViewAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/taxCategories/view-taxCategory/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const taxCatogeryUpdateAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/taxCategories/edit-taxCategory/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const taxCatogeryDeleteAPI = async function (body, id) {
  try {
    const { data } = await axios.post(
      "api/v11/taxCategories/delete/taxCategory/" + id + "/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error on  API", error);
    return error;
  }
};

const setDefultTaxCatogeryAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v10/taxCategories/setDefault-taxCategory/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in CallProductCategories");
  }
};

const taxConversionAPI = async function (body) {
  try {
    const { data } = await axios.post("api/v10/taxCategories/tax-conversion/", {
      ...body,
    });
    return data;
  } catch (error) {
    console.log("error in Tax Conversion API");
  }
};

const taxSearchQureyAPI = async function (body) {
  try {
    const { data } = await axios.post(
      "api/v11/taxCategories/taxCategory-search-query/",
      {
        ...body,
      }
    );
    return data;
  } catch (error) {
    console.log("error in Tax Catogery Query API");
  }
};

export {
  bankListAPI,
  bankDeleteAPI,
  bankCreateAPI,
  BankViewAPI,
  BankUpdateAPI,
  taxCatogeryListAPI,
  taxTypeListAPI,
  taxCatogeryCreateAPI,
  taxCatogeryViewAPI,
  taxCatogeryUpdateAPI,
  taxCatogeryDeleteAPI,
  setDefultTaxCatogeryAPI,
  taxConversionAPI,
  taxSearchQureyAPI,

};
