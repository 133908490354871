import styled from "@emotion/styled";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Paper,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import VBSwitch from "../../../Components/Utils/VBSwitch";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBSelect from "../../../Components/Utils/VBSelect";
import VBDatePiker from "../../../Components/Utils/VBDatePiker";
import Transactions from "./Components/Transactions";
import Address from "./Components/Address";
import BankDetails from "./Components/BankDetails";
import { callCreateSupplier,Get_code,create_customer_edit_api } from "../../../Api/Contacts/contacts";
// import { callCreateCustomer ,create_customer_edit,create_customer_edit_api,Get_code} from "../../../Api/Contacts/contacts";

import { openSnackbar } from "../../../features/SnackBarslice";
import { dataURLtoFile, fetchFileFromUrl, jsObjtoFormData, urlToFile } from "../../../generalFunction";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { price_category } from "../../../Api/CommonApi/CommonApis";
import { CallRoutes } from "../../../Api/Reports/CommonReportApi";
import * as base from "../../../generalVeriable";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {/* {value === index && <Box sx={{ p: 3 }}>{children}</Box>} */}
      <Box sx={{ p: 3 }}>{children}</Box>
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function CreateSupplier() {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const {VAT ,GST } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { EnableEinvoiceKSA } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { Country } = useSelector((state) => state.branchSettings);
  
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const params = new URLSearchParams(window.location.search);
  const uniq_id = location.state?.uniq_id ?? params.get("unq_id");
  const [is_edit, setIs_edit] = useState(uniq_id?true:false)

  // const is_edit = location.state?.is_edit ?? params.get('is_edit');
  // const uniq_id = location.state?.uniq_id ?? params.get('unq_id');
  const [dataLists, setDataLists] = useState({
    routList: [],
    categoryList: [],
    treatment: null,
    partyIdentificationCodeList: [
      { id: 1, code: "NAT", description: "National ID" },
      { id: 2, code: "TIN", description: "Tax Identification Number" },
      { id: 3, code: "IQA", description: "Iqama Number" },
      { id: 4, code: "PAS", description: "Passport ID" },
      { id: 5, code: "CRN", description: "Commercial registration number" },
      { id: 6, code: "MOM", description: "Momra license" },
      { id: 7, code: "MLS", description: "MLSD license" },
      { id: 8, code: "SAG", description: "Sagia license" },
      { id: 9, code: "GCC", description: "GCC ID" },
      { id: 10, code: "OTH", description: "Other ID" }
    ]
  });

  const [open, setOpen] = useState(false);
  const [validationerrors, seterrors] = useState({})
  const [editAddress, setEditAddress] = useState({
    position: "",
    address: "",
  });

  const handleOpen = () => setOpen(true);
  const handleClose = () =>{
    setOpen(false);
    setEditAddress({
      position: "",
      address: "",
    })
  }
  let gstTreatmentList = [
    { value: "0", name: "Registered Business - Regular" },
    { value: "1", name: "Registered Business - Composition" },
    { value: "2", name: "Unregistered Business" },
    { value: "4", name: "Overseas" },
    { value: "5", name: "Special Economic Zone" },
    { value: "6", name: "Deemed Export" },
  ];
  
  let vatTreatmentList = [
    { value: "0", name: "B2B Registered" },
    { value: "1", name: "B2B Unregistered" },
    { value: "7", name: "Import" },
  ];
  
  const [uploadDetail, setUploadDetail] = useState({
    IsActive: true,
    CustomerName: "",
    CustomerCode: "",
    DisplayName: "",
    OpeningBalance:0,
    PartyImage: null,
    PartyType: 2,
    CrOrDr: "CR",
    WorkPhone: "",
    PartyCode: "",
    WebURL: "",
    Email: "",
    as_on_date: moment(),
    creditPeriod: 0,
    PanNumber: "",
    creditLimit: 0,
    CRNo: "",
    priceCategory: null,
    routes: null,
    VATNumber: "",
    GSTNumber: "",
    GST_Treatment: "",
    VAT_Treatment: "",
    treatment: null,
    AddresList: [],
    bankList: [],
    CustomerSecName: "",
    partyIdentificationCode: 5,
  });
  const [value, setValue] = React.useState(0);
  const [showImage, setShowImage] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleAddImage = function () {
    let btn = document.getElementById("imgInput");
    btn.click();
  };

  const handleImage = function (e) {
    let ing = e.target?.files[0];
    if (ing) {
     setShowImage(URL.createObjectURL(ing));
      setUploadDetail({
        ...uploadDetail,
        PartyImage: ing,
      }); 
    }
  };

  const handleClearData =  (PartyCode) =>{
    setShowImage(null)
    setUploadDetail({
      IsActive: true,
      CustomerName: "",
      CustomerCode: "",
      DisplayName: "",
      OpeningBalance:0,
      PartyImage: null,
      PartyType: 2,
      CrOrDr: "CR",
      WorkPhone: "",
      PartyCode: PartyCode,
      WebURL: "",
      Email: "",
      as_on_date: moment(),
      creditPeriod: 0,
      PanNumber: "",
      creditLimit: 0,
      CRNo: "",
      priceCategory: null,
      routes: null,
      VATNumber: "",
      GSTNumber: "",
      GST_Treatment: "",
      VAT_Treatment: "",
      treatment: null,
      AddresList: [],
      bankList: [],
      CustomerSecName :'',
      partyIdentificationCode : 5,
    });
    setIs_edit(false)
    initialApis()
  }
  // const handleSubmitSupplier = function () {
  //   const formOne = document.getElementById("FormOne");
  //   const formTwo = document.getElementById("FormTwo");
  
  //   const isFormValid = (form) => {
  //     return form.checkValidity(); // You can add custom validation logic if needed
  //   };
  
  //   const handleFormOneSubmit = (e) => {
  //     e.preventDefault(); // Prevent the default submit behavior
  
  //     if (isFormValid(formOne)) {
  //       handleFormTwoSubmit();
  //     } else {
  //       formOne.reportValidity();
  //     }
  //   };
  
  //   const handleFormTwoSubmit = (e) => {
  //     if (e) e.preventDefault(); // Prevent the default submit behavior if triggered by formTwo
  
  //     if (isFormValid(formTwo)) {
  //       createCustomer(); // Call createCustomer() if formTwo is valid
  //     } else {
  //       formTwo.reportValidity();
  //     }
  //   };
  
  //   if (isFormValid(formOne)) {
  //     formOne.addEventListener("submit", handleFormOneSubmit, { once: true });
  //     formOne.requestSubmit(); // Submit formOne programmatically
  //   } else {
  //     formOne.reportValidity();
  //   }
  // };

  const handleValidation = () => {
    let errors = {};
    let IsValid = true;

    var reggst =
    /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (uploadDetail.AddresList.length > 0 && !uploadDetail.AddresList.some(address => address.IsBilling)){
      IsValid = false;
      errors["error"] = "Please choose someone to handle billing."
    }
    if (EnableEinvoiceKSA && uploadDetail.VAT_Treatment === "0"){
      if(!uploadDetail.AddresList[0]){
        errors["error"] = "Please provide an address"
        IsValid = false;
      }
    }
    if (VAT) {
      if (!uploadDetail.VAT_Treatment){
        errors['error'] = 'VAT Treatments Cannot be empty';
        IsValid = false;
      }
      if (uploadDetail.VAT_Treatment === '0'){
        if (uploadDetail.VATNumber.charAt(14) !== '3'){
          IsValid = false;
          errors['error'] = "VAT Number Should End with 3 ";
        }
        if (uploadDetail.VATNumber.length < 15 ) {
          IsValid = false;
          errors['error'] = "VAT Number Should have 15 number";
        } 
        if (uploadDetail.VATNumber.charAt(0) !== '3') {
          IsValid = false;
          errors['error'] = "VAT Number Should start with 3";
        }
        if (uploadDetail.VATNumber === '') {
          IsValid = false;
          errors['error'] = 'VAT Number Cannot be empty';
        }
      }
    }
    if (GST) {
      if (!uploadDetail.GST_Treatment){
        errors['error'] = 'TAX Treatments Cannot be empty';
        IsValid = false;
      }
      if (uploadDetail.GST_Treatment === '0' || uploadDetail.GST_Treatment === '1'|| uploadDetail.GST_Treatment === '5'|| uploadDetail.GST_Treatment === '6' ){
        if (!uploadDetail.GSTNumber.match(reggst)){
          errors['error'] = 'Invalid GST Number'
          IsValid = false;
        }
        if (uploadDetail.GSTNumber.length < 15 ) {
          IsValid = false;
          errors['error'] = "GST Number Should have 15 number";
        } 
        if (uploadDetail.GSTNumber === '') {
          IsValid = false;
          errors['error'] = 'GST Number Cannot be empty';
        }
      }
    }
    if (Country === '94e4ce66-26cc-4851-af1e-ecc068e80224' && EnableEinvoiceKSA){
      if (!uploadDetail.partyIdentificationCode){
        IsValid = false;
        errors['error'] = "Select Party Identification Code";
      }
    }
    
    if (uploadDetail.Email && !emailRegex.test(uploadDetail.Email)){
      IsValid = false;
      errors['error'] = "Email Is Not valid"
    }
    if (!uploadDetail.PartyCode) {
      errors['error'] = 'PartyCode Cannot be empty';
      IsValid = false;
    }
    if(EnableEinvoiceKSA){
      if (!uploadDetail.CustomerSecName || uploadDetail.CustomerSecName?.trim() === '') {
        errors['error'] = 'SupplierSecName Cannot be empty';
        IsValid = false;
      }
    }
    if (!uploadDetail.CustomerName || uploadDetail.CustomerName?.trim() === '') {
      errors['error'] = 'SupplierName Cannot be empty';
      IsValid = false;
    }
    seterrors(errors)
    return { IsValid, errors };
  }

  
  const handleSubmitSupplier =  (e) => {
    e.preventDefault();
    const { IsValid, errors } = handleValidation();
    if (IsValid) {
      createCustomer();
    } else {
      // seterrors(errors)
      dispatch(
        openSnackbar({
          open: true,
          message: errors?.error,
          severity: "error",
        })
      );
      console.log(errors,'eeeeeeerrrrrrrrrrrrrrrrrrorrrrrrrrsssssssssssssss');
      
    }

  };
  const createCustomer = async function () {
    let PartyImagefile = null;
    if (uploadDetail.PartyImage instanceof File) {
        PartyImagefile = uploadDetail.PartyImage;
    }  else if (uploadDetail.PartyImage !== null) {
      PartyImagefile = await dataURLtoFile(base.MEDIA_URL + uploadDetail.PartyImage);
    }
    try{
      const payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,

        CustomerName: uploadDetail.CustomerName,
        DisplayName: uploadDetail.DisplayName,
        OpeningBalance: uploadDetail.OpeningBalance || 0,
        PartyType: uploadDetail.PartyType,
        CrOrDr: uploadDetail.CrOrDr==="DR"?0:1,
        WorkPhone: uploadDetail.WorkPhone,
        PartyCode: uploadDetail.PartyCode,
        CreditLimit: uploadDetail.creditLimit,
        CreditPeriod: uploadDetail.creditPeriod,
        priceCategory: uploadDetail.priceCategory.PriceCategoryID,
        Routes: uploadDetail.routes.RouteID,
        WebURL: uploadDetail.WebURL,
        Email: uploadDetail.Email,
        CRNo: uploadDetail.CRNo,
        PanNumber: uploadDetail.PanNumber,
        as_on_date: uploadDetail.as_on_date.format("YYYY-MM-DD"),
        GST_Treatment: uploadDetail.GST_Treatment,
        VAT_Treatment: uploadDetail.VAT_Treatment,
        Address: uploadDetail.AddresList,
        Bank: uploadDetail.bankList,
        uniq_id:uniq_id,
        VATNumber:uploadDetail.VATNumber,
        IsActive:uploadDetail.IsActive,
        PartyImage:PartyImagefile,
        CustomerSecName:uploadDetail.CustomerSecName,
        PartyIdenificationCode:uploadDetail.partyIdentificationCode?.id || 5,
        GSTNumber :uploadDetail.GSTNumber
      };
      
      const formData = jsObjtoFormData(payload);

      let res = null
      if (is_edit && uniq_id){
        res = await create_customer_edit_api(formData, dispatch); 
      }else{
        res = await callCreateSupplier(formData, dispatch);
      }
      if (res.data.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: res.data?.message || "Supplier Created Successfully",
            severity: "success",
          })
        );
        handleClearData(res.data.PartyCode)
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: res.data?.message || "Error Occurred In API",
            severity: "error",
          })
        );
      }
    }catch(error){
      console.log(error,"error");
    }
    
  };

  // useEffect(() => {
  //   const formOne = document.getElementById("FormOne");
  //   const formTwo = document.getElementById("FormTwo");
  //   formOne.addEventListener('submit', function(e) {
  //     e.preventDefault();
  //   });

  //   formTwo.addEventListener('submit', function(e) {
  //     e.preventDefault();
  //   });
  //   return () => {
  //     formOne.removeEventListener('submit');
  //     formTwo.removeEventListener('submit');
  //   };
  // }, [])



  const initialApis = async () => {
    let response = null;
    let PriceCategoryID=null
    let RouteID=null
    let partyIdentificationCode = 5

    const routRes = await CallRoutes({
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      PartyType: "customer",
    });

    const resCategory = await price_category({
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
    });

    let routlist,categorylist = [],routes={},priceCategory={},sortedData=[];

    if (routRes.StatusCode === 6000) {
      routlist = routRes.data;
      sortedData = routlist.sort((a, b) => a.RouteID - b.RouteID);
    }
    if (resCategory.StatusCode === 6000) {
      categorylist = resCategory.data;
    }
    routes = sortedData[0];
    priceCategory = categorylist.find((i) => i.PriceCategoryID === 1);

    partyIdentificationCode = dataLists.partyIdentificationCodeList.find((i) => i.id === 5);

    setDataLists((prev) => ({
      ...prev,
      routList: routlist,
      categoryList: categorylist,
    }));
    setUploadDetail((prev) => ({
      ...prev, 
      PriceCategoryID,
      priceCategory,
      RouteID,
      routes,
      partyIdentificationCode,
  }));
  }


  const fetchData = async ()=>{
    let PartyCode = ""
    let CustomerName= ""
    let DisplayName= ""
    let PartyImage= null
    let PartyType= 2
    let OpeningBalance= uploadDetail.OpeningBalance
    let CrOrDr= uploadDetail.CrOrDr
    let creditPeriod= uploadDetail.creditPeriod
    let creditLimit= uploadDetail.creditLimit
    let WorkPhone= ""
    let WebURL= ""
    let Email= ""
    let PanNumber= ""
    let CRNo= ""
    let VATNumber = ""
    let PriceCategoryID=null
    let RouteID=null
    let treatment= null
    let AddresList= []
    let bankList= []
    let IsActive = true
    let as_on_date = moment()
    let VAT_Treatment = ''
    let GST_Treatment = ''
    let CustomerSecName = ''
    let partyIdentificationCode = 5
    let GSTNumber = ''

    let response = null;
    const routRes = await CallRoutes({
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      PartyType: "customer",
    });

    const resCategory = await price_category({
      BranchID: BranchID,
      CompanyID: CompanyID,
      CreatedUserID: user_id,
    });

    let routlist,categorylist = [],routes={},priceCategory={},sortedData=[];

    if (routRes?.StatusCode === 6000) {
      routlist = routRes.data;
      sortedData = routlist.sort((a, b) => a.RouteID - b.RouteID);
    }
    if (resCategory?.StatusCode === 6000) {
      categorylist = resCategory.data;
    }

    if (is_edit && uniq_id){

      const Data = await Get_code({
        CompanyID: CompanyID,
        BranchID: BranchID,
        PartyType: 2,
        uniq_id:uniq_id ,
        PriceRounding:2
      })

      response  = Data.data.data;
      if (response.StatusCode === 6000){
        let VAT_Treatment_id = response.data?.VAT_Treatment
        let GST_Treatment_id = response.data?.GST_Treatment
        if (VAT_Treatment_id.length ){
          treatment = vatTreatmentList.find((i)=> i.value === VAT_Treatment_id)||[];     
        } else {
          treatment = gstTreatmentList.find((i) => i.value === GST_Treatment_id) || [];
        }
        setDataLists((prev) => ({
          ...prev, 
          treatment
          }
        ));
        PartyCode = response.data.PartyCode;
        CustomerName = response.data.PartyName;
        OpeningBalance = response.data.OpeningBalance;
        PartyType = response.data.PartyType;
        CrOrDr = response.data.CrOrDr==="0"?"DR":"CR";
        WorkPhone = response.data.WorkPhone;
        WebURL = response.data.WebURL;
        Email = response.data.Email;
        PanNumber = response.data.PanNumber;
        creditPeriod = response.data.CreditPeriod;
        creditLimit = response.data.CreditLimit;
        CRNo = response.data.CRNo;
        DisplayName = response.data.DisplayName;
        VATNumber = response.data.VATNumber;
        bankList = response.data.BankDetails;
        IsActive = response.data.IsActive

        // treatment = treatment;

        AddresList = response.data.AddressDetails;
        bankList = response.data.BankDetails;
        PriceCategoryID=response.data.PriceCategoryID
        RouteID=response.data.RouteID
        routes = sortedData.find((i) => i.RouteID === RouteID);     
        priceCategory = categorylist.find((i) => i.PriceCategoryID === PriceCategoryID);
        as_on_date =  response.data?.as_on_date 
        as_on_date = moment(as_on_date)
        VAT_Treatment = response.data.VAT_Treatment
        GST_Treatment = response.data.GST_Treatment
        GSTNumber = response.data.GSTNumber
        PartyImage = response.data.PartyImage
        if (PartyImage !== '/media/null' && PartyImage !== null && PartyImage !== undefined){
          setShowImage(base.MEDIA_URL + PartyImage);
        } else {
          setShowImage(null);
        }
        CustomerSecName = response.data.FirstName
        partyIdentificationCode = response.data.IdentificationCode
        partyIdentificationCode = dataLists.partyIdentificationCodeList.find((i) => i.id === partyIdentificationCode)
        
      }
    } else{
      const Data = await Get_code({
        CompanyID: CompanyID,
        BranchID: BranchID,
        PartyType: 2
      })
      let response = Data.data
      if (response.StatusCode === 6000){
        PartyCode = response.PartyCode
      }
      routes = sortedData[0];
      priceCategory = categorylist.find((i) => i.PriceCategoryID === 1);
      partyIdentificationCode = dataLists.partyIdentificationCodeList.find((i) => i.id === partyIdentificationCode)
    }
    setDataLists((prev) => ({
      ...prev, 
      routList: routlist,
      categoryList: categorylist,
      }
    ));
    setUploadDetail((prev) => ({
      ...prev, 
      PartyCode,
      CustomerName,
      OpeningBalance,
      CrOrDr,
      WorkPhone,
      WebURL,
      Email,
      PanNumber,
      creditPeriod,
      creditLimit,
      CRNo,
      DisplayName,
      bankList,
      VATNumber,
      treatment,
      AddresList,
      PriceCategoryID,
      priceCategory,
      RouteID,
      routes,
      PartyImage,
      IsActive,
      as_on_date,
      VAT_Treatment,
      GST_Treatment,
      GSTNumber,
      CustomerSecName,
      partyIdentificationCode,
  }));
  
  } 

  console.log(uploadDetail,"uploaaaaaaadddddddddddddetaaaaaaaaaaaa");
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper sx={{ width: "100%" }}>
      <Box
        sx={{
          borderBottom: "1px solid",
          p: 3,
          borderColor: "#EBEBEB",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography
          sx={{ fontSize: "24px", fontWeight: "600", lineHeight: "36px" }}
        >
             {is_edit ? t("Update Supplier") : t("Create Supplier")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: 1,
          }}
        >
          <Button
            sx={{ fontSize: "16px", textTransform: "none", px: 2 }}
            variant="text"
            onClick={() => {navigate("/supplier-list");}}
          >
            {t("Cancel")}
          </Button>
          <BlueButton sx={{ px: 4 }} onClick={(e) => handleSubmitSupplier(e)}>
            {t("Save")}
          </BlueButton>
        </Box>
      </Box>

      <form id="FormOne">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: 8,
            py: 2,
            mb: 4,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "flex-end", gap: 6 }}>
            <Box>
              <Typography
                sx={{
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#5C5C5C",
                  mb: 4,
                }}
              >
                {t("Profile image")}
              </Typography>
              <Badge sx={{
                vertical: 'top',
                horizontal: 'right',
              }} badgeContent={showImage && <IconButton onClick={() => setShowImage(null)} ><DeleteIcon /></IconButton>}>
                <ImageButton
                  onClick={() => {
                    handleAddImage();
                  }}
                >
                  {showImage ? (
                    <Avatar
                      alt="Remy Sharp"
                      src={showImage}
                      sx={{ height: "100%", width: "100%" }}
                    />
                  ) : (
                    <AddPhotoAlternateIcon />
                  )}
                </ImageButton>
              </Badge>

              <input
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                id="imgInput"
                onChange={(e) => handleImage(e)}
              />
            </Box>
          <Box sx={{}}>
            <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  marginBottom: "15px",
                  // flexDirection: "",
                  // justifyContent: "flex-end",
                  // alignItems: "flex-end",
                }}
              >
                <VBInputField
                  label="Supplier Name"
                  type="text"
                  placeholder="Type here"
                  value={uploadDetail.CustomerName}
                  onChange={(e) => {
                    setUploadDetail({
                      ...uploadDetail,
                      CustomerName: e.target.value,
                    });
                  }}
                />
                <VBInputField
                  label="Supplier SecName"
                  type="text"
                  placeholder="Type here"
                  value={uploadDetail.CustomerSecName}
                  onChange={(e) => {
                    setUploadDetail({
                      ...uploadDetail,
                      CustomerSecName: e.target.value,
                    });
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  gap: 2,
                  // flexDirection: "",
                  // justifyContent: "flex-end",
                  // alignItems: "flex-end",
                }}
              >
                {/* <Box sx={{ mb: 2, minWidth: 350 }} /> */}
                <VBInputField
                  label="Display Name"
                  placeholder="Type here"
                  value={uploadDetail.DisplayName || uploadDetail.CustomerName}
                  onChange={(e) => {
                    setUploadDetail({
                      ...uploadDetail,
                      DisplayName: e.target.value,
                    });
                  }}
                />
                <VBInputField
                  label="Supplier Code"
                  placeholder="Type here"
                  type="text"
                  value={uploadDetail.PartyCode}
                  disabled={is_edit}
                  onChange={(e) => {
                    setUploadDetail({
                      ...uploadDetail,
                      PartyCode: e.target.value,
                    });
                  }}
                />
              </Box>
            </Box>
          </Box>
            

          <VBSwitch
            label={"Active"}
            labelPlacement="start"
            labelColor={"black"}
            checked={uploadDetail.IsActive}
            onChange={() => {
              setUploadDetail({
                ...uploadDetail,
                IsActive: !uploadDetail.IsActive,
              });
            }}
          />
        </Box>

        <Box sx={{ flexGrow: 1, minWidth: 1000, px: 8, mb: 2 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 4, md: 3 }}
          >
            <Grid item xs={2}>
              <VBInputField
                label="Opening Balance"
                placeholder="Type here"
                type="number"
                value={uploadDetail.OpeningBalance}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    OpeningBalance: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={2}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  pt: "18px",
                }}
              >
                <div style={{ width: "35%" }}>
                  <VBSelect
                    options={["DR", "CR"]}
                    value={uploadDetail.CrOrDr}
                    handleChange={(e) => {
                      setUploadDetail({
                        ...uploadDetail,
                        CrOrDr: e.target.value,
                      });
                    }}
                  />
                </div>
                <VBDatePiker
                  notLabel
                  fromDate={uploadDetail.as_on_date}
                  fromOnChange={(e) =>
                    setUploadDetail({
                      ...uploadDetail,
                      as_on_date: e,
                    })
                  }
                />
              </Box>
            </Grid>
            <Grid item xs={2}>
              <VBInputField
                label="Work Phone"
                placeholder="Type here"
                type="number"
                value={uploadDetail.WorkPhone}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    WorkPhone: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ px: 8, mb: 5 }}>
          <Grid
            container
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 4, md: 3 }}
          >
            <Grid item xs={3}>
              <VBInputField
                label="Email ID"
                placeholder="Type here"
                value={uploadDetail.Email}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    Email: e.target.value,
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <VBInputField
                label="Website URL"
                // type="url"
                value={uploadDetail.WebURL}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    WebURL: e.target.value,
                  });
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </form>

      <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4 }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Transactions"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(0)}
          />
          <Tab
            label="Address"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(1)}
          />
          <Tab
            label="Bank Details"
            sx={{ width: "288px", textTransform: "none" }}
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>
      <Box sx={{ px: 8 }}>
        <CustomTabPanel value={value} index={0}>
          <Transactions
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
            gstTreatmentList={gstTreatmentList}
            vatTreatmentList={vatTreatmentList}
            dataLists={dataLists}
            setDataLists={setDataLists}
            is_edit={is_edit}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={1}>
          <Address
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
            handleOpen = {handleOpen}
            open = {open}
            handleClose = {handleClose}
            setEditAddress= {setEditAddress}
            setOpen = {setOpen}
            editAddress ={editAddress}
          />
        </CustomTabPanel>

        <CustomTabPanel value={value} index={2}>
          <BankDetails
            uploadDetail={uploadDetail}
            setUploadDetail={setUploadDetail}
          />
        </CustomTabPanel>
      </Box>
    </Paper>
  );
}

export default CreateSupplier;

const ImageButton = styled(IconButton)(() => ({
  backgroundColor: "#E8E8E8",
  fontSize: "35px",
  padding: 0,
  height: "112px",
  width: "112px",
}));

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));
