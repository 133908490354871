import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagenation from "../../../Components/Utils/Pagenation";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../../generalFunction";
import moment from "moment";
import { useSelector } from "react-redux";
import {
  delete_stock_management,
  list_stock_management,
} from "../../../Api/CommonApi/InvoiceApis/StockManagementApi";
import { json, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CreateNewButton,
  DeleteButton,
} from "../../../Components/Utils/StyledButtons";
import ConfirmBox from "../../../Components/Utils/ConfirmBox";
import SearchTextField from "../../../Components/Utils/SearchTextField";

const StockManagementList = () => {
  const navigate = useNavigate();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    selectedItems: [],
    isSelectedAll: false,
  });

  const [IsConfirmBox, setConfirmBox] = useState(false);

  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  const [loading, setLoading] = useState(false);

  const [t, i18n] = useTranslation("common");
  const [searchText, setSearchText] = useState("");
  const fetchData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      search: searchText,
      page: page_no,
      noOfItems: noOfItems,
    };
    let responseData = await list_stock_management({ ...payload });

    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    }
  };

  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      id_list: state.selectedItems,
    };

    let responseData = await delete_stock_management({ ...payload });

    if (responseData.StatusCode === 6000) {
      fetchData();
    } else {
      console.log(responseData.message);
    }
  };

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const navigateEditPage = (id) => {
    navigate("/create-stock-management", {
      state: { is_edit: true, uniq_id: id },
    });
  };

  useEffect(() => {
    fetchData();
  }, [searchText, page_no]);

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <FlexBox className="left">
          <Typography
            sx={{ fontSize: "25px", fontWeight: "500", mr: 2 ,color:"#001746"}}
            variant="h2"
          >
            {t("Stock Management list")}
          </Typography>
          <SearchTextField
            value={searchText}
            escClearState={setSearchText}
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
              page_no !== 1 && setPage_no(1)
            }}
            />
        </FlexBox>

        <FlexBox className="right">
          <DeleteButton onClick={confirmDelete} t={t} />

          <CreateNewButton
            onClick={() => {
              navigate("/create-stock-management");
            }}
            t={t}
          />

          <Pagenation
            totalItem={state.data_count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
        </FlexBox>
      </Box>

      {/* ==============TABLE=============== */}

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
          <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Warehouse")}</VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>{t("Total Amount")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Notes")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Type")} </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            <BorderColorIcon
              color="#0A9EF3"
              sx={{ color: "#0A9EF3", width: "20px" }}
            />
          </VBTableCellHeader>
        </VBTableHeader>

        {loading ? (
          <VBSkeletonLoader />
        ) : (
          <TableBody>
            {state.dataList.map((data, i) => (
              <TableRow
              // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
              //   onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody
                  sx={{
                    cursor: "pointer",
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <Checkbox
                    checked={
                      state.selectedItems.includes(data.id) ? true : false
                    } // The checked prop determines whether this checkbox is checked.
                    onChange={() => {
                      selectItems("not", data.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer", textAlign: "left" }}>
                  {(page_no - 1) * appSetting.itemPerPage + i + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data.VoucherNo}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {moment(data.Date).format("DD-MM-YYYY")}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data.WarehouseName}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer",textAlign:"right" }}>
                  {formatNumber(data.TotalAmount)}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      whiteSpace: "nowrap",
                      width: "150px",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {formatNumber(data.Notes)}
                  </p>
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{
                    cursor: "pointer",
                    color:
                      data.Type === 0
                        ? "green"
                        : data.Type === 1
                        ? "#d01dd9"
                        : data.Type === 2
                        ? "red"
                        : null,
                  }}
                >
                  {data.Type === 0
                    ? "Adjustment"
                    : data.Type === 1
                    ? "Used"
                    : data.Type === 2
                    ? "Damage"
                    : ""}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer", textAlign: "right" }}>
                  <EditIcon
                    color="#0A9EF3"
                    sx={{ color: "#0A9EF3", cursor: "pointer", width: "20px" }}
                    onClick={() => {
                      navigateEditPage(data.id);
                    }}
                  />
                </VBTableCellBody>
              </TableRow>
            ))}
            <VBTableRowNull
              length={
                state.data_count ? state.data_count : state.dataList.length
              }
            />
          </TableBody>
        )}
      </VBTableContainer>
      <ConfirmBox
        heading={"Confirm to delete?"}
        message={"Once you delete, you will not get it back!"}
        open={IsConfirmBox}
        setOpen={setConfirmBox}
        confirmFunction={deleteData}
      />
    </Paper>
  );
};

export default StockManagementList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
