import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Paper, Typography, styled } from "@mui/material";
import {
  ClearButton,
  ListButton,
  SaveButton,
  LoadingButton,
} from "../../../Components/Utils/StyledButtons";
import {
  InvoiceAutoComplete,
  InvoiceVoucherNoTextBox,
  InvoiceDateBox,
  InvoiceBarCodeTextBox,
  InvoiceTextBox,
} from "../../../Components/CommonComponents";
import MyGridRaw from "./DetailsRaw";
import MyGridFinshed from "./DetailsFinshed";
import { useSelector } from "react-redux";
import { debounce } from "../../../Function/comonFunction";
import NotesTextArea from "../../../Components/Utils/TextArea";
import SummaryDetails from "./SummaryDetails";
import moment from "moment";

const Manufacturing = () => {
  const [t, i18n] = useTranslation("common");
  // let today = new Date();
  // today.setDate(today.getDate());
  // let DefaultDate = today.toISOString().substr(0, 10);
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableBillwise,
    EnableWarehouse,
    EnableSalesManInSales,
    PriceCategory,
    EnableDeliveryDateInSales,
    EnableShippingCharge,

    PurchasePriceInSales,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  let GeneralSettingsData = {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableBillwise,
    EnableWarehouse,
    EnableSalesManInSales,
    PriceCategory,
    EnableDeliveryDateInSales,
    EnableShippingCharge,

    PurchasePriceInSales,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    //userorrll
    //  sales_discount_limit,
    //  sales_discount_perm,
    //  sales_save,
    //  sales_purchase_price_perm
  };

  let is_edit = false;
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    WarehouseList: [],
    filteredWarehouseList: [],
    ProductList: [],
    ProductCodeList: [],
    filteredFromWarehouseList: [],
    filteredToWarehouseList: [],
  });
  const [state, setState] = useState({
    Date: DefaultDate,
    FromWarehouse: null,
    ToWarehouse: null,
    Warehouse: null,
    TotalQty: 0,
    GrandTotal: 0,
    TotalProducts: 0,
    StockTransferDetails: [],
    Notes: "",
    TransferredByID: null,
    IsActive: true,
    BatchID: 1,
    MaxGrandTotal: 0,
  });

  const [DataListraw, setDataListraw] = useState([
    {
      ProductCode: null,
      BarCode: "",
      Product: null,
      Unit: null,
      Qty: "",
      SalesPrice:"",
      TotalCost:"",
      TotalSalesPrice:"",
      UnitList: [],
      unq_id: null,
    },
  ]);
  const [DataListfinshed, setDataListfinshed] = useState([
    {
      ProductCode: null,
      BarCode: "",
      Product: null,
      Unit: null,
      Qty: "",
      SalesPrice:"",
      TotalCost:"",
      TotalSalesPrice:"",
      UnitList: [],
      unq_id: null,
    },
  ]);
  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Unit: true,
    SalesPrice:true,
    TotalCost:true,
    TotalSalesPrice:true,
    date:true,
    Activity: true,
  });

  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    BarCode: true,
    Product: true,
    Unit: true,
    SalesPrice:true,
    TotalCost:true,
    TotalSalesPrice:true,
    Activity: true,
  });

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  const onDetailsInputChange = async (e, name) => {};
  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  const onDetailsAutoChange = async (v, index, name) => {};

  const onDetailsChange = async (e, index) => {};

  const ChangeTableCustomization = (type, name) => {
    let value = null;

    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({ ...tableSettings, [name]: value });
    } else {
      value = !focusSettings[name];
      setFocusSettings({ ...focusSettings, [name]: value });
    }
  };

  const AddLineRaw = () => {
    let Data = DataListraw;
    let newData = {
      ProductCode: "",
      Product: null,
      Unit: null,
      Cost: "",
      Stock: "",
      Excess: "",
      Shortage: "",
      Used: "",
      Damage: "",
      Adjustment: "",
      UnitList: [],
    };
    Data.push(newData);
    setDataListraw([...Data]);
  };
  const AddLineFinshed = () => {
    let Data = DataListfinshed;
    let newData = {
      ProductCode: "",
      Product: null,
      Unit: null,
      Cost: "",
      Stock: "",
      Excess: "",
      Shortage: "",
      Used: "",
      Damage: "",
      Adjustment: "",
      UnitList: [],
    };
    Data.push(newData);
    setDataListfinshed([...Data]);
  };
  const RemoveLinerow = (index) => {
    let Data = DataListraw;
    let deletedData = [];

    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
      }
    }
    setState({ ...state, deletedData: deletedData });
    setDataListraw([...Data]);
  };

  const RemoveLinefinshed = (index) => {
    let Data = DataListfinshed;
    let deletedData = [];

    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }

        Data.splice(index, 1);
      }
    }
    setState({ ...state, deletedData: deletedData });
    setDataListfinshed([...Data]);
  };


  return (
    <>
      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                cmr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit ? t("Update Manufacturing") : t("Manufacturing")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                //   clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                //   navigate("/list-stock-order");
              }}
              t={t}
            />

            {IsButtonClick ? <LoadingButton t={t} /> : <SaveButton t={t} />}
          </FlexBox>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            borderBottom: "2px solid #F5F5F5",
            height: "150px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "17px",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  PlaceHolder={"Invoivce Number"}
                />
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "3.3rem" }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name={"Date"} label={"Select a Date"} />
              </Typography>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "17px",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category :
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name="FromWarehouse"
                  label="Select Warehouse From"
                  optionLabel="WarehouseName" // Ensure this matches the property name in your warehouse objects
                />
              </Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", gap: "2.5rem" }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name="Warehouse"
                  label="Select Warehouse "
                  optionLabel="WarehouseName" // Ensure this matches the property name in your warehouse objects
                />
              </Typography>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "17px",
              justifyContent: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "2rem" }}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Mft. Date* :
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name={"Date"} label={"Select a Date"} />
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Expiry Date* :
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name={"Date"} label={"Select a Date"} />
              </Typography>
            </div>
          </div>
        </Box>

        <Box
          sx={{
            px: "10px",
            py: "5px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              cmr: 2,
              color: "#001746",
              marginBottom: "10px",
            }}
          >
            Raw Material
          </Typography>
          <MyGridRaw
            dataState={dataState}
            state={state}
            DataList={DataListraw}
            setDataLists={setDataListraw}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLineRaw={AddLineRaw}
            RemoveLine={RemoveLinerow}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
          />
          <Box
            sx={{
              px: "26px",
              py: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ cursor: "pointer" }} onClick={AddLineRaw}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  color: "#0A9EF3",
                  margin: 0,
                  fontSize: "13px",
                }}
              >
                + Add Line{" "}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px", // Adjust the gap between the span and BarcodeTextBox
                height: "40px",
              }}
            >
              <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
                Add By Barcode :{" "}
              </span>
              <InvoiceBarCodeTextBox placeholder={"Add By Barcode"} />
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            px: "10px",
            py: "5px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "column",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Typography
            id="modal-modal-description"
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              cmr: 2,
              color: "#001746",
              marginBottom: "10px",
            }}
          >
            Finished Product
          </Typography>
          <MyGridFinshed
            dataState={dataState}
            state={state}
            DataList={DataListfinshed}
            setDataLists={setDataListfinshed}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLineFinshed={AddLineFinshed}
            RemoveLine={RemoveLinefinshed}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
          />
          <Box
            sx={{
              px: "26px",
              py: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ cursor: "pointer" }} onClick={AddLineFinshed}>
              <span
                style={{
                  whiteSpace: "nowrap",
                  color: "#0A9EF3",
                  margin: 0,
                  fontSize: "13px",
                }}
              >
                + Add Line{" "}
              </span>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px", // Adjust the gap between the span and BarcodeTextBox
                height: "40px",
              }}
            >
              <span style={{ whiteSpace: "nowrap", fontSize: "13px" }}>
                Add By Barcode :{" "}
              </span>
              <InvoiceBarCodeTextBox placeholder={"Add By Barcode"} />
            </div>
          </Box>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
            />
          </div>

          <div style={{display:"flex",gap:"20px"}}>
            <div style={{display:"flex",gap:"1rem",paddingTop:"2rem"}}>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Expenses
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"RefNo"}
                  label={"Type a RefNo"}
                  placeholder={"Expenses"}
                />
              </Typography>
            </div>

            <SummaryDetails state={state} />
          </div>
        </Box>
      </Paper>
    </>
  );
};

export default Manufacturing;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
