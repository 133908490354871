import React, { useState } from 'react'
import LoaderLite from '../../Components/Utils/LoaderLite';
import {Box,Paper,Typography,styled,} from "@mui/material";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from 'react-router-dom';
import { ClearButton, ListButton, LoadingButton, SaveButton } from '../../Components/Utils/StyledButtons';
import { get_VoucherNo } from '../../Api/CommonApi/CommonApis';
import { useSelector } from 'react-redux';
import moment from "moment";
import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceLedgerSearch,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
  SelectShippingAddress,
} from "../../Components/CommonComponents";
const PurchaseInvoice = () => {
  const [t] = useTranslation("common");
  
  const params = new URLSearchParams(window.location.search);
  // Determine is_edit and uniq_id from URL params or location state
  
  const location = useLocation();
  const navigate = useNavigate();
  
  const is_edit = location.state?.is_edit ?? params.get('is_edit');
  const uniq_id = location.state?.uniq_id ?? params.get('unq_id');

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const { 
    PriceDecimalPoint,
     EnableBranch,
     EnableProductBatchWise,
     GST,
     VAT,
     CurrencySymbol,
     EnableBillwise,
     EnableWarehouse,
     EnableSalesManInSales,
     PriceCategory,
     EnableDeliveryDateInSales,
     EnableShippingCharge,
     PurchasePriceInSales,
     show_brand_InsearchSale,
     show_stock_InsearchSale,
     show_purchasePrice_InsearchSale,
     show_productcode_InsearchSale,
     } = useSelector((state) => state.generalSettingsSclice.generalSettings);
     
  // let today = new Date();
  // today.setDate(today.getDate());
  // let DefaultDate = today.toISOString().substr(0, 10);
  let today = moment();
  let DefaultDate = today.format("YYYY-MM-DD");
  
  
  const [openCustomVoucher, setopenCustomVoucher] = useState(false)
  const [progress, setProgress] = useState(0);
  const [IsButtonClick, setIsButtonClick] = useState(false);
  const [dataState,setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData : [],
    CashList : [],
    BankList : [],
    CountryData : [],
    TaxCategoryData : [],
    UserTableData : [],
    WarehouseData : [],
    ProductList: [],
    ProductCodeList: [],
    SalesHistory:[],
    PurchaseHistory:[]
  })
  const [defaultValue,setDefaultValue] = useState({
    AccountLedger:null,
    CashAccount:null,
    BankAccount:null,
    Warehouse:null,
    Treatment:null,
    PlaceOfSupply:null,
    ShippingTax:null
})
  //when you adding any field here add in clearData function tooo if needed
  const [state, setState] = useState({
    AccountLedger:null,
    CashAccount:null,
    BankAccount:null,
    Employee :null,
    Warehouse:null,
    Treatment:null,
    PlaceOfSupply:null,
    Date:DefaultDate,
    RefNo:"",    
    ShippingCharge:0,
    ShippingTax:null,
    shipping_tax_amount:0,
    BillDiscPercent:0,
    BillDiscAmt:0,
    CashAmount:0,
    BankAmount:0,
    Notes:"",
    BillingAddress:null,
    RoundOff:"0",
    DueDate:DefaultDate,
    DeliveryDate:DefaultDate,
    //Total
    TotalQty:0,
    TotalGrossAmt:0,
    TotalDiscount:0,
    TotalTax:0,
    NetTotal:0,
    TotalTaxableAmount:0,
    TaxTaxableAmount:0,
    NonTaxTaxableAmount:0,
    GrandTotal:0
  });
    //when you adding any field here add in AddLine and clearData function tooo
    let DataListItem = {
      ProductCode: null,
      BarCode: "",
      Product: null,
      Stock: "",
      Description: "",
      Unit: null,
      UnitList: [],
      BatchCode:null,
      ManufactureDate:"",
      ExpiryDate:"",
      Qty: "",
      FQty: "",
      RateFC: "",
      Rate: "",
      ConversionRate: "",
      MinimumSalesPrice: "",
      SalesPrice: "",
      MRP:"",
      Tax:null,
      InclusivePrice: "",
      GrossAmount: "",
      ExpenseFC: "",
      Expense: "",
      CostWithExpense: "",
      DiscountPerc: "",
      DiscountAmount: "",
      TaxAmount: "",
      AmountFC: "",
      Amount: "",
      PurchasePrice:"",
      AverageCost:"",
      TaxableAmount:0,
      NonTaxableAmount:0,
      is_inclusive:false,  
    }
  const [DataList, setDataLists] = useState([DataListItem]);
  const is_supplier = state.AccountLedger?.AccountGroupUnder !== 9 && state.AccountLedger?.AccountGroupUnder !== 8;

  const clearData = async () => {
    
    if (is_edit){
      navigate("/create-sales")
    }
    setIsButtonClick(false)
    
    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "PI",
    });
    
    setDataState({
      ...dataState,
      ...voucher_datas
    })
  
    setState(({
      ...state,
      AccountLedger:defaultValue.AccountLedger,
      CashAccount:defaultValue.CashAccount,
      BankAccount:defaultValue.BankAccount,
      Warehouse:defaultValue.Warehouse,
      Treatment:defaultValue.Treatment,
      PlaceOfSupply:defaultValue.PlaceOfSupply,
      Date:DefaultDate,
      RefNo:"",    
      ShippingCharge:0,
      ShippingTax:defaultValue.ShippingTax,
      shipping_tax_amount:0,
      BillDiscPercent:0,
      BillDiscAmt:0,
      CashAmount:0,
      BankAmount:0,
      Notes:"",
      DueDate:DefaultDate,
      DeliveryDate:DefaultDate,
      //Total
      TotalQty:0,
      TotalGrossAmt:0,
      TotalDiscount:0,
      TotalTax:0,
      NetTotal:0,
      TotalTaxableAmount:0,
      TaxTaxableAmount:0,
      NonTaxTaxableAmount:0,
      GrandTotal:0
    }))
    
    setDataLists([DataListItem,]);
    
  };
  const handleSubmit = async() =>{}
  return (
  <div>
    <Paper sx={{ maxHeight: "800px", width: "100%", overflow: "scroll" }}>
     {/*=================================== PART ONE==================================== */}

     <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit ? t("Update Purchase Invoice") : t("Create Purchase Invoice")}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            <ClearButton onClick={() => { clearData()}} t={t} />

            <ListButton
              onClick={() => {
                navigate("/list-sales");
              }}
              t={t}
            />

            {IsButtonClick ? <LoadingButton t={t} /> : <SaveButton onClick={handleSubmit} t={t} />}
          </FlexBox>
        </Box>
    </Paper>
     {/*=================================== PART TWO==================================== */}

     <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          {/* SEC - 1 */}
          {/* <div className="sales-grid-item  give-10px-border-radious "> */}
            {/* customer search */}
            {/* <InvoiceLedgerSearch name = {"AccountLedger"} label = {"Select Customer"} optionLabel = {"LedgerName"} optionLabel2 = {"LedgerCode"} OptionList = {dataState.AccountLedgerData} Value = {state.AccountLedger}  OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} openBillingAddressModal={openBillingAddressModal} BillingAddress={state.BillingAddress} is_customer={is_customer} />
          </div> */}

          {/* SEC - 2 */}

            {/* shipping Address */}
          {/* <div className="sales-grid-item no-border-and-shadow">
            {is_supplier && EnableShippingCharge === true?(
              <>
            <SelectShippingAddress state={state} setState={setState} setShippingAddress={setShippingAddress} ShippingAddress={ShippingAddress} Create_ShippingAddress={Create_ShippingAddress}  Value={state.ShippingAddress} open={IsShipping} setOpen={setIsShipping} />
             
             <div
              style={{
                display: EnableBillwise?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "150px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                DueDate:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"DueDate"} label = {"Select a Date"} Value={state.DueDate} OnChange={onMasterChange} width={155} />
              </Typography>
            </div>
              </>
            ):null}
          </div> */}

          {/* SEC - 3 */}
          {/* <div className="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: EnableWarehouse?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Warehouse:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Warehouse"} label = {"Select a Warehouse"} optionLabel = {"WarehouseName"} List = {dataState.WarehouseData} Value = {state.Warehouse} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                VAT Treatment:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Treatment"} label = {"Select a Treatment"} optionLabel = {"name"} List = {VAT?VAT_TreatmentData: GST?GST_TreatmentData:[]} Value = {state.Treatment} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PlaceOfSupply"} label = {"Select a Place Of Supply"} optionLabel = {"Name"} List = {dataState.PlaceOfSupplyData} Value = {state.PlaceOfSupply} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange}  />
              </Typography>
            </div>

            <div
              style={{
                display: EnableSalesManInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Sales Man:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"Employee"} label = {"Select a Employee"} optionLabel = {"FirstName"} List = {dataState.EmployeeData} Value = {state.Employee} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>

            <div
              style={{
                display: PriceCategory?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Price Category:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"PriceCategory"} label = {"Select a Price Category"} optionLabel = {"PriceCategoryName"} List = {dataState.PriceCategoryData} Value = {state.PriceCategory} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>
            </div>
          </div> */}

          {/* SEC - 4 */}
          {/* <div className="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox name = {"VoucherNo"} label = {"Type a VoucherNo"} Value={dataState.VoucherNo} openCustomVoucher={openCustomVoucher} setCustomVoucher = {setopenCustomVoucher}  />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <CustomVoucher 
              open = {openCustomVoucher}
              setCustomVoucher = {setopenCustomVoucher}
              state={dataState}
              handleChange={handleChange}
              CustomVoucherSet = {CustomVoucherSet}
            />
              
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"Date"} label = {"Select a Date"} Value={state.Date} OnChange={onMasterChange} />
              </Typography>
            </div>
            
            <div
              style={{
                display: EnableDeliveryDateInSales?"flex":"none",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Delivery Date:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox name = {"DeliveryDate"} label = {"Select a DeliveryDate"} Value={state.DeliveryDate} OnChange={onMasterChange} />
              </Typography>
            </div>
   
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No:
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox name = {"RefNo"} label = {"Type a RefNo"} Value={state.RefNo} OnChange={onMasterChange} placeholder={" Ref Bill No"} />
              </Typography>
            </div>
          </div> */}
        </Box>
    {progress > 0 ? (
        <LoaderLite message={"Please wait......." } fullscreen={true} />
      ) : null}
  </div>
  )
}

export default PurchaseInvoice
const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));