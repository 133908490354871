import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import VBInputField from "../../../Components/Utils/VBInputField";
import VBDatePiker from "../../../Components/Utils/VBDatePiker";
import moment from "moment";
import ViknAutoComplete from "../../../Components/Utils/ViknAutoComplete";
import Grid from "@mui/material/Unstable_Grid2";
import MyGrid from "./Details2";
import { StockManagementCalc } from "./functions";
import { useDispatch, useSelector } from "react-redux";
import {
  CallBarcodeSearch,
  CallProductGroups,
  CallProductSearch,
  CallWarehouses,
} from "../../../Api/Reports/CommonReportApi";
import { callProductCodeSearch } from "../../../Api/Reports/PurchaseApi";
import {
  getNewPriceList,
  getSinglePriceList,
} from "../../../Api/Reports/CommonApi";
import { BarcodeTextBox } from "../../../Components/Utils/BarcodeTextBox";
import { get_VoucherNo } from "../../../Api/CommonApi/CommonApis";
import VBTextField from "../../../Components/Utils/VBTextField";
import { openSnackbar } from "../../../features/SnackBarslice";
import {
  create_stock_management,
  import_products,
  list_stock_management,
  update_stock_management,
} from "../../../Api/CommonApi/InvoiceApis/StockManagementApi";

import ClearIcon from "@mui/icons-material/Clear";
import ListIcon from "@mui/icons-material/List";
import {
  ClearButton,
  CreateButton,
  ImportButton,
  ListButton,
  UpdateButton,
  SaveButton,
  LoadingButton,
} from "../../../Components/Utils/StyledButtons";
import ViknDrawerImport from "../../../Components/ViknDrawer/ViknDrawerImport";
import ViknSearchAutoComplete from "../../../Components/Utils/ViknSearchAutoComplete";
import ViknRadioGroup from "../../../Components/Utils/ViknRadioGroup";
import LoaderLite from "../../../Components/Utils/LoaderLite";
import NotesTextArea from "../../../Components/Utils/TextArea";
import { Notes } from "@mui/icons-material";
import ViknCheckboxGroup from "../../../Components/Utils/ViknCheckboxGroup";
const StockManagement = () => {
  const location = useLocation();
  let uniq_id = null
  let is_edit = null
   is_edit = location.state?.is_edit ?? null;
   uniq_id = location.state?.uniq_id ?? null;

   if (!uniq_id){    
     const queryParams = new URLSearchParams(location.search);
     uniq_id = queryParams.get('id');
     if (uniq_id){
      is_edit=true
     }
   }
  
  
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [t, i18n] = useTranslation("common");
  const [state, setState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    Date: moment(),
    WarehouseList: [],
    TypeList: [
      { name: "Adjustment", value: 0 },
      { name: "Used", value: 1 },
      { name: "Damage", value: 2 },
    ],
    Type: { name: "Adjustment", value: 0 },
    warehouse: null,
    ProductList: [],
    ProductCodeList: [],
    TotalAdjustedQty: "0",
    TotalExcessAmount: "0",
    TotalShortageAmount: "0",
    TotalDamageAmount: "0",
    TotalUsedAmount: "0",
    TotalAmount: "0",
    errors: {
      field: null,
      message: null,
      error: false,
      indexList: [],
    },
    deletedData: [],
    DefaultWarehouse: null,
    ProductGroupsList: [{ name: "first group" }, { name: "second group" }],
    Barcode: "",
    Notes:""
  });
  const [progress, setProgress] = useState(0);
  const [IsButtonClick ,setIsButtonClick] = useState(false)
  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    Product: true,
    Unit: true,
    Cost: true,
    Stock: true,
    Adjustment: true,
    Used: true,
    Damage: true,
    AdjustedStock: true,
    Activity: true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    Product: true,
    Unit: true,
    Cost: true,
    Stock: true,
    Adjustment: true,
    Used: true,
    Damage: true,
    AdjustedStock: true,
    Activity: true,
  });
  const [filterData, setFilterData] = useState({
    stockLevelList: [
      "All Stock",
      "Positive Stock",
      "Negative Stock",
      "Zero Stock",
      "Below Recorder Level",
      "Above Max Level",
      "Below Min Stock",
    ],
  })
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableBranch,EnableProductBatchWise } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState({
    isLoadingProduct: false,
  });

  const [DataList, setDataLists] = useState([
    {
      ProductCode: "",
      Product: null,
      Unit: null,
      Cost: "",
      Stock: "",
      Adjustment: "",
      Used: "",
      Damage: "",
      AdjustedStock: "",
      UnitList: [],
    },
  ]);

  const [ProductList, setProductList] = useState([
    { ProductName: "Sahil" },
    { ProductName: "Anusree" },
  ]);

  const [filterOptions, setFilterOptions] = useState({
    ImportTypesList: [
      { name: "all_product", label: "All Product" },
      { name: "product_group", label: "Product Group" },
    ],
    ImportType: "all_product",
    stockLevel: "All Stock",
    SelectedProductGroups: [],
    stockLevelActive: false,

  });
  const [openFilter, setOpenFilter] = useState(false);
  const closeFilter = () => setOpenFilter(false);
  const searchReport = function () {
    fetchData();
  };

  const handleDefaultFilter = () => {
    //pass
  };


  
  const ImportProducts = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      uniq_id: uniq_id,
      type_data: filterOptions.ImportType,
      group_list: filterOptions.SelectedProductGroups,
      page: 0,
      WarehouseID: filterOptions.stockLevelActive === true ? [0,state.warehouse.WarehouseID] : state.warehouse.WarehouseID,
      StockFilter : filterData.stockLevelList.indexOf(filterOptions.stockLevel) + 1 ,
      ToDate : moment().format("YYYY-MM-DD"),
      stockLevel : filterOptions.stockLevelActive
    };
    let page = 1;
    let length = 0;

    let responseData = await import_products({ ...payload,test:true });

    if (responseData.StatusCode === 6000) {
      let data = responseData.data;
      length = responseData.length;
      if (length > 100) {
        for (let i = length; i >= 0; i = i - 100) {
          setProgress(length);

          payload.page = payload.page + 1;
          // length = length-500
          payload.data_count = length;

          responseData = await import_products({ ...payload });
          
          let new_data = []

    
          data = [...data, ...responseData.data];
        }
        setProgress(0);
      }
      else{
        payload.page =1
        responseData = await import_products({ ...payload });
        data = [...data, ...responseData.data];
      }
    
      data.map((i,index)=>{
        let Stock = i.Stock
        let TypeName = state.Type.name
        let num_value =  createSpecialNumber(Number(Stock))
        if (TypeName === "Adjustment"){
          i.Adjustment = num_value
          i.AdjustedStock = Number(Stock) + num_value
        }
        else if (TypeName === "Damage"){
          i.Damage = num_value
          i.AdjustedStock = Number(Stock) + num_value
        }
        else if (TypeName === "Used"){
          i.Used = num_value
          i.AdjustedStock = Number(Stock) + num_value
        }
      })
      
      
      setDataLists([...data]);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.name === "Barcode") {
      getProductFromBarcode(state.Barcode);
    }
  };

  const getProductFromBarcode = async (value) => {
    let Data = DataList;

    let responseBarcode = await CallBarcodeSearch({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      BarCode: value,
      WarehouseID: state.warehouse.WarehouseID,
      type: "Purchase",
    });

    let NewData = null;
    if (responseBarcode.StatusCode === 6000) {
      let ProdcutData = responseBarcode.data;
      NewData = {};
      let UnitList = await getProductPriceList(ProdcutData.id);
      let Unit = UnitList.filter((i) => i.DefaultUnit === true)[0];

      let UnitDetails = await getProductSinglePriceList(Unit.PriceListID);

      NewData.Unit = UnitDetails.data;
      NewData.Cost = UnitDetails.data.PurchasePrice;
      NewData.Stock = UnitDetails.Stock;
      NewData.UnitList = UnitList;
      NewData.ProductCode = ProdcutData;
      NewData.Product = ProdcutData;
      NewData.AdjustedStock = UnitDetails.Stock;
      
      let Stock = NewData.Stock
      let TypeName = state.Type.name
      let num_value =  createSpecialNumber(Number(Stock))
      if (TypeName === "Adjustment"){
        NewData.Adjustment= num_value
        NewData.AdjustedStock= Number(Stock) + num_value
      }
      else if (TypeName === "Damage"){
        NewData.Damage= num_value
        NewData.AdjustedStock= Number(Stock) + num_value
      }
      else if (TypeName === "Used"){
        NewData.Used= num_value
        NewData.AdjustedStock= Number(Stock) + num_value
      }
      
      

      Data.push(NewData);

      setDataLists([...Data]);
      setState({ ...state, Barcode: "" });
    }
  };

  const handleChange = (e, v, name) => {
    let { value } = e.target;
    console.log(value, "value--------------");
    setFilterOptions({ ...filterOptions, ImportType: value });
  };

  const handleAutoComplete = (v, name) => {
    if (name === "SelectedProductGroups") {
      setFilterOptions({ ...filterOptions, [name]: v });
    } else if (name === "Type") {
      if (DataList) {
        // if (DataList.length===1 && !DataList[0].Product ){
        //   setState({ ...state, [name]: v });
        // }
        // else if(DataList.length===0){
        //   setState({ ...state, [name]: v });

        // }

        let type_name = v.name;
        let Data = DataList;
        DataList.map((i, index) => {
          if (type_name === "Adjustment") {
            Data[index].Used = "0";
            Data[index].Damage = "0";
            Data[index].AdjustedStock = Data[index].Stock;
          } else if (type_name === "Damage") {
            Data[index].Used = "0";
            Data[index].Adjustment = "0";
            Data[index].AdjustedStock = Data[index].Stock;
          } else if (type_name === "Used") {
            Data[index].Damage = "0";
            Data[index].Adjustment = "0";
            Data[index].AdjustedStock = Data[index].Stock;
          }

          
          //adjusting AdjustedStock to 0 automatically
          let Stock = Data[index]["Stock"]
          let num_value =  createSpecialNumber(Number(Stock))
          
          if (type_name === "Adjustment"){
            Data[index]["Adjustment"] = num_value
            Data[index]["AdjustedStock"] = Number(Stock) + num_value
          }
          else if (type_name === "Damage"){
            Data[index]["Damage"] = num_value
            Data[index]["AdjustedStock"] = Number(Stock) + num_value
          }
          else if (type_name === "Used"){
            Data[index]["Used"] = num_value
            Data[index]["AdjustedStock"] = Number(Stock) + num_value
          }
          
          setState({ ...state, [name]: v });
          setDataLists([...Data]);
        });
      }
    } else {
      setState({ ...state, [name]: v });
    }
  };

  const warehouseChangePermission = () => {
    let data = false;
    if (DataList) {
      if (DataList.length === 1 && !DataList[0].Product) {
        data = true;
      } else if (DataList.length === 0) {
        data = true;
      }
    }

    return data;
  };

  const RemoveLine = (index) => {
    let Data = DataList;
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          let deletedData = state.deletedData;
          deletedData.push(Data[index]);
          setState({ ...state, deletedData: deletedData });
        }
        Data.splice(index, 1);
      }
    }
    setDataLists([...Data]);
  };

  const AddLine = () => {
    let Data = DataList;
    let newData = {
      ProductCode: "",
      Product: null,
      Unit: null,
      Cost: "",
      Stock: "",
      Excess: "",
      Shortage: "",
      Used: "",
      Damage: "",
      Adjustment: "",
      UnitList: [],
    };
    Data.push(newData);
    setDataLists([...Data]);
  };

  const handleDetailOnChanges = (e, index) => {
    console.log(e, "<~~~e", index, "<~~~~index");
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      let Stock = 0;
      Stock = Data[index]?.Stock ?? 0;
      Stock = Number(Stock);
      if (name === "Adjustment") {
        Data[index]["AdjustedStock"] = Stock + Number(value);
        Data[index][name] = value;
      } else if (name === "Used") {
        if (Number(value) >= 0) {
          Data[index]["AdjustedStock"] = Stock - Number(value);
          Data[index][name] = value;
        }
      } else if (name === "Damage") {
        if (Number(value) >= 0) {
          Data[index]["AdjustedStock"] = Stock - Number(value);
          Data[index][name] = value;
        }
      } else if (name === "AdjustedStock" && state.Type.name === "Adjustment") {
        Data[index]["Adjustment"] = Number(value) - Data[index]["Stock"];
        Data[index][name] = value;
      } else if (name === "AdjustedStock" && state.Type.name === "Used") {
        if (Stock >= Number(value)) {
          Data[index]["Used"] = Math.abs(Number(value) - Data[index]["Stock"]);

          Data[index][name] = value;
        }
      } else if (name === "AdjustedStock" && state.Type.name === "Damage") {
        if (Stock >= Number(value)) {
          Data[index]["Damage"] = Math.abs(
            Number(value) - Data[index]["Stock"]
          );
          Data[index][name] = value;
        }
      } else {
        Data[index][name] = value;
      }

      setDataLists([...Data]);
    }
  };
  function createSpecialNumber(num) {
    if (num > 0){
      return -num
    }
    else if (num < 0){
      return num * -1
    }
    else if (num === 0){
      return 0
    }
  }
  const handleDetailAutoChanges = async (v, index, name) => {
    console.log(v, index, name, "-------------------");
    if (v) {
      let UnitList = [];
      let Unit = null;
      let UnitDetails = null;

      let Data = DataList;
      let value = v;
      Data[index][name] = value;

      if (name === "Product") {
        UnitList = await getProductPriceList(v.id);
        Unit = UnitList.filter((i) => i.DefaultUnit === true)[0];

        UnitDetails = await getProductSinglePriceList(Unit.PriceListID);

        Data[index]["Unit"] = UnitDetails.data;
        Data[index]["Cost"] = UnitDetails.data.PurchasePrice;
        Data[index]["Stock"] = UnitDetails.Stock;
        Data[index]["UnitList"] = UnitList;
        Data[index]["ProductCode"] = v;
        Data[index]["Adjustment"] = "0";
        Data[index]["Used"] = "0";
        Data[index]["Damage"] = "0";

        Data[index]["AdjustedStock"] = UnitDetails.Stock;
      } else if (name === "ProductCode") {
        UnitList = await getProductPriceList(v.id);
        Unit = UnitList.filter((i) => i.DefaultUnit === true)[0];

        UnitDetails = await getProductSinglePriceList(Unit.PriceListID);

        Data[index]["Unit"] = UnitDetails.data;
        Data[index]["Cost"] = UnitDetails.data.PurchasePrice;
        Data[index]["Stock"] = UnitDetails.Stock;
        Data[index]["UnitList"] = UnitList;
        Data[index]["Product"] = v;
        Data[index]["Adjustment"] = "0";
        Data[index]["Used"] = "0";
        Data[index]["Damage"] = "0";
        
        Data[index]["AdjustedStock"] = UnitDetails.Stock;
      } else if (name === "Unit") {
        UnitDetails = await getProductSinglePriceList(v.PriceListID);

        Data[index]["Cost"] = UnitDetails.data.PurchasePrice;
        Data[index]["Stock"] = UnitDetails.Stock;
        Data[index]['MultiFactor'] = UnitDetails.data.MultiFactor
        if (Data[index].Stock_b && Data[index].PriceListID_b && Data[index].id){
          let Stock_b = Data[index].Stock_b
          let MultiFactor = Data[index].MultiFactor
          Stock_b = Stock_b/MultiFactor
          Data[index]["Stock"] = Stock_b
        }
        
        // Data[index]["Adjustment"] = "0";
        // Data[index]["Used"] = "0";
        // Data[index]["Damage"] = "0";
        Data[index]["AdjustedStock"] = UnitDetails.Stock;
      }
      
      
      //adjusting AdjustedStock to 0 automatically
      if (name === "Product" || name === "ProductCode" || name === "Unit"){
        let Stock = Data[index]["Stock"]
        let TypeName = state.Type.name
        let num_value =  createSpecialNumber(Number(Stock))
        if (TypeName === "Adjustment"){
          Data[index]["Adjustment"] = num_value
          Data[index]["AdjustedStock"] = Number(Stock) + num_value
        }
        else if (TypeName === "Damage"){
          Data[index]["Damage"] = num_value
          Data[index]["AdjustedStock"] = Number(Stock) + num_value
        }
        else if (TypeName === "Used"){
          Data[index]["Used"] = num_value
          Data[index]["AdjustedStock"] = Number(Stock) + num_value
        }
        
        
      }
      
      

      setDataLists([...Data]);
    }
  };

  const searchProduct = async function (search) {
    setIsLoading({ ...isLoading, isLoadingProduct: true });
    const callProduct = await CallProductSearch({
      product_name: search,
      length: 1,
      type: "StockManagement",
      is_product_image: false,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });
    if (callProduct.StatusCode === 6000) {
      setState((prev) => ({ ...prev, ProductList: callProduct.data }));
      setIsLoading({ ...isLoading, isLoadingProduct: false });
    }
  };

  const searchProductCode = async function (search) {
    setIsLoading({ ...isLoading, isLoadingProduct: true });

    let callProductCode = await callProductCodeSearch({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      length: 1,
      product_name: search,
    });

    if (callProductCode.StatusCode === 6000) {
      setState((prev) => ({ ...prev, ProductCodeList: callProductCode.data }));
      setIsLoading({ ...isLoading, isLoadingProduct: false });
    }
  };

  const getProductPriceList = async (id) => {
    setIsLoading({ ...isLoading, isLoadingProduct: true });
    let payload = {
      product_id: id,
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_data: "SM",
    };
    let response = await getNewPriceList(payload);

    if (response.StatusCode === 6000) {
      return response.data;
    } else {
      return [];
    }
  };

  const productCheckChange = (name) => {
    setFilterOptions({
      ...filterOptions,
      [name]: !filterOptions[name],
    });
  };


  const stockRadioChange = (e) => {
    setFilterOptions({
      ...filterOptions,
      stockLevel: e.target.value,
    });
  };
  const getProductSinglePriceList = async (PriceListID) => {
    setIsLoading({ ...isLoading, isLoadingProduct: true });

    let WarehouseID = state.warehouse ? state.warehouse.WarehouseID : null;
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceListID: PriceListID,
      CreatedUserID: user_id,
      PriceRounding: Number(PriceDecimalPoint),
      WarehouseID: WarehouseID,
    };
    let response = await getSinglePriceList(payload);

    if (response.StatusCode === 6000) {
      return response;
    } else {
      return null;
    }
  };

  const handleChanges = (e) => {
    console.log(e.target.value,"eeeeeeeeeeeeeeeeee",e.target.name);
    if (e) {
      let name = e.target.name;
      let value = e.target.value;
      setState({ ...state, [name]: value });
    }
  };

  const handleInputChange = (e, name) => {
    if (e) {
      let value = e.target.value;
      if (name === "Product") {
        searchProduct(value);
      } else if (name == "ProductCode") {
        searchProductCode(value);
      }
    }
  };

  const ChangeTableCustomization = (type, name) => {
    let value = null;

    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({ ...tableSettings, [name]: value });
    } else {
      value = !focusSettings[name];
      setFocusSettings({ ...focusSettings, [name]: value });
    }
  };

  const clearData = async () => {
    setIsButtonClick(false)
    
    if (is_edit){
      navigate("/create-stock-management")
    }
    is_edit = false;
    uniq_id = null;

    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "SM",
    });
    let DefaultWarehouse = state.DefaultWarehouse;
    let warehouse = DefaultWarehouse;

    setState({
      ...state,
      ...voucher_datas,
      Type: { name: "Adjustment", value: 0 },
      warehouse: warehouse,
      ProductList: [],
      ProductCodeList: [],
      TotalAdjustedQty: "0",
      TotalExcessAmount: "0",
      TotalShortageAmount: "0",
      TotalDamageAmount: "0",
      TotalUsedAmount: "0",
      TotalAmount: "0",
      errors: {
        field: null,
        message: null,
        error: false,
        indexList: [],
      },
      deletedData: [],
      is_edit: null,
      uniq_id: null,
      Notes:"",
      Barcode:"",
      Date: moment(),
    });

    setDataLists([
      {
        ProductCode: "",
        Product: null,
        Unit: null,
        Cost: "",
        Stock: "",
        Adjustment: "",
        Used: "",
        Damage: "",
        AdjustedStock: "",
        UnitList: [],
      },
    ]);
  };

  const Validation = () => {
    let field = null;
    let message = null;
    let error = null;
    let indexList = [];

    let Data = DataList;
    let length = Data.length;

    // Check Details
    let TotalAdjustedQty = 0;
    let TotalExcessAmount = 0;
    let TotalShortageAmount = 0;
    let TotalDamageAmount = 0;
    let TotalUsedAmount = 0;
    let TotalAmount = 0;

    //check is there atlease one valid row
    if (Data.length === 0) {
      error = true;
      message = "At least need one valid row";
    } else if (Data.length === 1 && !Data[0].Product) {
      error = true;
      message = "At least need one valid row";
    }

    Data.map((obj, i) => {
      if (!obj.Product) {
        if (
          i + 1 === length &&
          !obj.Unit &&
          !obj.ProductCode &&
          !obj.Cost &&
          !obj.Adjustment &&
          !obj.Used &&
          !obj.Damage &&
          !obj.AdjustedStock
        ) {
          //ignoring last line if product is not selected and changed any other data in that line
          console.log("IGNORE THE PART");
        } else {
          indexList.push(i);
        }
      } else if (!obj.ProductCode) {
        indexList.push(i);
      } else if (!obj.Unit) {
        indexList.push(i);
      } else if (!obj.Cost) {
        indexList.push(i);
      }
    });
    
    let newIndexList = indexList.map(i => i + 1);
    
    if (indexList.length > 0) {
      message = "Data missing in Lines " + newIndexList;
      error = true;
    }

    //master data
    if (!state.VoucherNo && !error) {
      error = true;
      message = "Provide valid VoucherNo";
    } else if (!state.Date && !error) {
      error = true;
      message = "Provide valid Date";
    } else if (!state.warehouse && !error) {
      error = true;
      message = "Provide valid warehouse";
    } else if (!state.Type && !error) {
      error = true;
      message = "Provide valid Type";
    }

    //chacke some calculations

    return { error, message, field, indexList };
  };

  const Submit = async () => {
   
    setState({
      ...state,
      errors: {
        field: null,
        message: null,
        error: false,
        indexList: [],
      },
    });
    let { error, message, field, indexList } = await Validation();
    if (error) {
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "error",
        })
      );
      console.log(errors, "errors");
      setState({ ...state, errors });
    } else {
      setIsButtonClick(true)
      let payload = {
        uniq_id: state.uniq_id,
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        VoucherNo: state.VoucherNo,
        PreFix: state.PreFix,
        Seperator: state.Seperator,
        InvoiceNo: state.InvoiceNo,
        Suffix: state.Suffix,
        SuffixSeparator: state.SuffixSeparator,
        Date: state.Date.format("YYYY-MM-DD"),
        Notes: state.Notes,
        warehouse: state.warehouse,
        TotalAdjustedQty: state.TotalAdjustedQty,
        TotalExcessAmount: state.TotalExcessAmount,
        TotalShortageAmount: state.TotalShortageAmount,
        TotalDamageAmount: state.TotalDamageAmount,
        TotalUsedAmount: state.TotalUsedAmount,
        TotalAmount: state.TotalAmount,
        Type: state.Type.value,
        Details: DataList,
        deletedData: state.deletedData,
      };

      let responseData = null;
      if (state.is_edit) {
        responseData = await update_stock_management({
          ...payload,
          dispatch,
        });
      } else {
        responseData = await create_stock_management({
          ...payload,
          dispatch,
        });
      }
      console.log(responseData,"responseData");
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        clearData();
      } else if (responseData.StatusCode === 6001) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
      } 
      
      else if (responseData.StatusCode === 6002) {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      } 
      
      else {
        setIsButtonClick(false)
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    }
  };

  const getSingleData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      uniq_id: uniq_id,
    };
    setProgress(10);
    let responseData = await list_stock_management({ ...payload });
    setProgress(0);
    if (responseData.StatusCode === 6000) {
      return responseData.data;
    }
  };

  const fetchData = async () => {
    // declaring values
    let TotalAdjustedQty = "0";
    let TotalExcessAmount = "0";
    let TotalShortageAmount = "0";
    let TotalDamageAmount = "0";
    let TotalUsedAmount = "0";
    let TotalAmount = "0";
    let VoucherNo = "";
    let voucher_datas = null;
    let DefaultWarehouse = null;
    let Date = moment()
    let Notes = ""

    let warehouse = null;
    let Type = { name: "Adjustment", value: 0 };
    let Details = [
      {
        ProductCode: "",
        Product: null,
        Unit: null,
        Cost: "",
        Stock: "",
        Adjustment: "",
        Used: "",
        Damage: "",
        AdjustedStock: "",
        UnitList: [],
      },
    ];

    let ProductGroupsList = [];

    const productGroupRes = await CallProductGroups({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
    });

    if (productGroupRes.StatusCode === 6000) {
      ProductGroupsList = productGroupRes.data;
    }

    const warehouseResponse = await CallWarehouses({
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      show_all: true,
    });

    let WarehouseList = [];
    if (warehouseResponse.StatusCode === 6000) {
      WarehouseList = warehouseResponse.data;
      DefaultWarehouse = WarehouseList.filter(
        (i) => i.WarehouseID === warehouseResponse.DefaultWarehouse
      )[0];
    } else {
      console.log("Warehouse api errror!!!");
    }

    let singleData = null;
    if (is_edit) {
      singleData = await getSingleData();
      if (singleData) {
        TotalAdjustedQty = singleData.TotalAdjustedQty;
        TotalExcessAmount = singleData.TotalExcessAmount;
        TotalShortageAmount = singleData.TotalShortageAmount;
        TotalDamageAmount = singleData.TotalDamageAmount;
        TotalUsedAmount = singleData.TotalUsedAmount;
        TotalAmount = singleData.TotalAmount;
        warehouse = singleData.warehouse;
        Type = singleData.Type;
        Details = singleData.Details;
        VoucherNo = singleData.VoucherNo;
        let momentDate = moment(singleData.Date, "YYYY-MM-DD");
        Date =momentDate
        Notes = singleData.Notes
        
      }
    } else {
      voucher_datas = await get_VoucherNo({
        CompanyID: CompanyID,
        UserID: user_id,
        BranchID: BranchID,
        VoucherType: "SM",
      });
      VoucherNo = voucher_datas.VoucherNo;

      warehouse = DefaultWarehouse;
    }

    setState({
      ...state,
      ...voucher_datas,
      WarehouseList: WarehouseList,
      TotalAdjustedQty: TotalAdjustedQty,
      TotalExcessAmount: TotalExcessAmount,
      TotalShortageAmount: TotalShortageAmount,
      TotalDamageAmount: TotalDamageAmount,
      TotalUsedAmount: TotalUsedAmount,
      TotalAmount: TotalAmount,
      warehouse: warehouse,
      Type: Type,
      VoucherNo: VoucherNo,
      is_edit: is_edit,
      uniq_id: uniq_id,
      DefaultWarehouse: DefaultWarehouse,
      ProductGroupsList: ProductGroupsList,
      Date:Date,
      Notes:Notes
    });

    setDataLists([...Details]);
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const CalculateData = () => {
      let Data = StockManagementCalc(state, DataList);
      setState({ ...Data });
    };
    CalculateData();
  }, [DataList]);

  console.log(DataList, "DataList");
  console.log(state, "state");
  return (
    <>
      <Paper sx={{ height: "800px", width: "100%", overflow: "scroll" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ fontSize: "25px", fontWeight: "500", mr: 2,color:"#001746" }}
              variant="h2"
            >
              
              {is_edit?(
              t("Update Manage Stock") 
                
              ):(
                
              t("Manage Stock")
              )}
            </Typography>
          </FlexBox>

          <FlexBox className="right">
            {/* <Button
            variant="contained"
            sx={{
              backgroundColor: "#00639D",
              borderRadius: "6px",
              mx: 1,
              textTransform: "none",
              "&:hover": { backgroundColor: "#00639D" },
            }}
            onClick={() => {
              clearData();
            }}
          >
            Clear
          </Button>
           */}

            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              onClick={() => {
                navigate("/list-stock-management");
              }}
              t={t}
            />

            <ImportButton
              onClick={() => {
                setOpenFilter(true);
              }}
              t={t}
            />
            {/* 
          {state.is_edit ? (
            <UpdateButton onClick={Submit} t={t} />
          ) : (
            <CreateButton onClick={Submit} t={t} />
          )} */}
          
          {IsButtonClick?
          (
            <LoadingButton  t={t} />
            
          )
          :(
            
            <SaveButton onClick={Submit} t={t} />
          )}
          
          </FlexBox>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={3}>
              {/* <VBInputField
              label=""
              freeSolo
              placeholder="Voucher No"
              filterName="VoucherNo"
              name="VoucherNo"
              type="number"
              value={state.VoucherNo}
              onWheel={(e) => e.target.blur()}
              onKeyDown={(e) =>
                ["e", "E", "-"].includes(e.key) && e.preventDefault()
              }
              sx={{ width: "100%", height: "40px", padding: "3px" }}
            /> */}

              <VBTextField value={state.VoucherNo} disabled={true} />
            </Grid>
            <Grid item xs={3}>
              <VBDatePiker
                notLabel={true}
                fromDate={state.Date}
                fromOnChange={(value) =>
                  setState({
                    ...state,
                    Date: value,
                  })
                }
                sx={{ width: "100%", height: "40px", padding: "3px" }}
              />
            </Grid>
            <Grid item xs={3}>
              {warehouseChangePermission() === true ? (
                <ViknAutoComplete
                  label={""}
                  placeholder={"Select a Warehouse"}
                  value={state.warehouse}
                  onChange={(e, v) => handleAutoComplete(v, "warehouse")}
                  name="warehouse"
                  options={state.WarehouseList}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: "100%", height: "40px", padding: "3px" }}
                />
              ) : (
                <VBTextField value={state.warehouse.name} disabled={true} />
              )}
            </Grid>

            <Grid item xs={3}>
              {state.is_edit ? (
                <VBTextField value={state.Type.name} disabled={true} />
              ) : (
                <ViknAutoComplete
                  label={""}
                  placeholder={"Select Type"}
                  value={state.Type ? state.Type : null}
                  onChange={(e, v) => handleAutoComplete(v, "Type")}
                  name="Type"
                  options={state.TypeList}
                  getOptionLabel={(option) => option?.name}
                  sx={{ width: "100%", height: "40px", padding: "3px" }}
                  // disabled={state.is_edit ? true : false}
                />
              )}
            </Grid>
          </Grid>
        </Box>

        <MyGrid
          state={state}
          DataList={DataList}
          setDataLists={setDataLists}
          handleChanges={handleDetailOnChanges}
          handleCompleteChanges={handleDetailAutoChanges}
          handleInputChange={handleInputChange}
          tableSettings={tableSettings}
          setTableSettings={setTableSettings}
          focusSettings={focusSettings}
          setFocusSettings={setFocusSettings}
          ChangeTableCustomization={ChangeTableCustomization}
          RemoveLine={RemoveLine}
          AddLine={AddLine}
          PriceDecimalPoint={PriceDecimalPoint}
          EnableProductBatchWise={EnableProductBatchWise}
          // CellValue={CellValue}
          // setCellValue={setCellValue}
          // ClickColumn={ClickColumn}
        />

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span style={{ whiteSpace: "nowrap", color: "#0A9EF3", margin: 0 }}>
              + Add Line{" "}
            </span>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <span style={{ whiteSpace: "nowrap" }}>Add By Barcode : </span>
            <BarcodeTextBox
              value={state.Barcode}
              handleChanges={handleChanges}
              name={"Barcode"}
              handleKeyDown={handleKeyDown}
            />
          </div>
        </Box>




  

  



        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent:"space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >

<Grid container spacing={2}>
            <NotesTextArea value={state.Notes?state.Notes:""} onChange={handleChanges} name={"Notes"} placeholder={t("Notes (Optional)")}  />
            </Grid>


          <Grid container spacing={2}>
            
            
            
            <Grid xs={6}>
              <table
                style={{
                  width: "max-content",
                  textAlign: "left",
                }}
              >
                <tr>
                  <th></th>
                  <th>Total Adjusted Stock</th>
                  <th style={{ padding: "0px 40px 0px 40px" }}>-</th>
                  <th>
                    {Number(state.TotalAdjustedQty).toFixed(PriceDecimalPoint)}
                  </th>
                </tr>

                {/* -------------------------- */}
                {state.Type?.name === "Adjustment" ? (
                  <>
                    <tr>
                      <th></th>

                      <th>Total Excess Amount</th>
                      <th style={{ padding: "0px 40px 0px 40px" }}>-</th>
                      <th>
                        {Number(state.TotalExcessAmount).toFixed(
                          PriceDecimalPoint
                        )}
                      </th>
                    </tr>
                    <tr>
                      <th></th>

                      <th>Total Shortage Amount</th>
                      <th style={{ padding: "0px 40px 0px 40px" }}>-</th>
                      <th>
                        {Number(state.TotalShortageAmount).toFixed(
                          PriceDecimalPoint
                        )}
                      </th>
                    </tr>
                  </>
                ) : null}
                {/* -------------------------- */}

                {/* -------------------------- */}
                {state.Type?.name === "Used" ? (
                  <>
                    <tr>
                      <th></th>

                      <th>Total Used Amount</th>
                      <th style={{ padding: "0px 40px 0px 40px" }}>-</th>
                      <th>
                        {Number(state.TotalUsedAmount).toFixed(
                          PriceDecimalPoint
                        )}
                      </th>
                    </tr>
                  </>
                ) : null}
                {/* -------------------------- */}
                {/* -------------------------- */}
                {state.Type?.name === "Damage" ? (
                  <>
                    <tr>
                      <th></th>

                      <th>Total Damage Amount</th>
                      <th style={{ padding: "0px 40px 0px 40px" }}>-</th>
                      <th>
                        {Number(state.TotalDamageAmount).toFixed(
                          PriceDecimalPoint
                        )}
                      </th>
                    </tr>
                  </>
                ) : null}
                {/* -------------------------- */}

                <tr>
                  <th></th>

                  <th>Total Amount</th>
                  <th style={{ padding: "0px 40px 0px 40px" }}>-</th>
                  <th>
                    {Number(state.TotalAmount).toFixed(PriceDecimalPoint)}
                  </th>
                </tr>
              </table>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawerImport
        open={openFilter}
        handleClose={closeFilter}
        onFilter={ImportProducts}
        // setPage_no={setPage_no}
        // handleDefaultFilter={()=>handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <Divider />

            <ListItem>
              <ViknRadioGroup
                handleChange={handleChange}
                radios={filterOptions.ImportTypesList.map((i) => ({
                  label: t(i.label),
                  value: i.name,
                }))}
                value={filterOptions.ImportType}
                valueName={"ImportType"}
                name="ImportType"
              />
            </ListItem>

            <Divider />

            {filterOptions.ImportType === "product_group" ? (
              <ListItem>
                <ViknAutoComplete
                  name="name"
                  multiple
                  value={filterOptions.SelectedProductGroups}
                  placeholder={"Select Product Groups"}
                  options={state.ProductGroupsList}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) =>
                    handleAutoComplete(newValue, "SelectedProductGroups")
                  }
                />
              </ListItem>
            ) : null}

            <Divider />
            
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    label: "Stock Level",
                    checked: filterOptions.stockLevelActive,
                    onChange: () => productCheckChange("stockLevelActive"),
                  },
                ]}
              />
            </ListItem>

            <Collapse
              in={filterOptions.stockLevelActive}
              timeout="auto"
              unmountOnExit
            >
              {" "}
              <ListItem>
                <ViknRadioGroup
                  handleChange={stockRadioChange}
                  radios={filterData.stockLevelList.map((i) => ({
                    label: i,
                    value: i,
                  }))}
                  value={filterOptions.stockLevel}
                  // labelName={""}
                />
              </ListItem>
            </Collapse>
          </List>
        }
      />

      {progress > 0 ? (
        <LoaderLite message={"Please wait......."} fullscreen={true} />
      ) : null}
    </>
  );
};

export default StockManagement;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
