import React, { useEffect, useState } from 'react'
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  InputLabel,
  List,
  ListItem,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  styled,
} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import { VBSkeletonLoader, VBTableCellBody, VBTableCellFooter, VBTableCellHeader, VBTableContainer, VBTableFooter, VBTableHeader, VBTableRowNull } from '../../../../Components/ReportTable/ReportTable';
import FilterLable from '../../../../Components/Utils/FilterLable';
import SearchReport from '../../../../Components/Utils/SearchReport';
import FilterBtn from '../../../../Components/Utils/FilterBtn';
import ExportBtn from '../../../../Components/Utils/ExportBtn';
import PrintBtn from '../../../../Components/Utils/PrintBtn';
import Pagenation from '../../../../Components/Utils/Pagenation';
import { CallExpenseSummaryReport } from '../../../../Api/Reports/FinanceApi';
import moment from 'moment';
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknAutoComplete from '../../../../Components/Utils/ViknAutoComplete';
import { date_range } from '../../../../Function/comonFunction';
import { CallBranchsList, CallParties, CallProductSearch } from '../../../../Api/Reports/CommonReportApi';
import VBDatePiker from '../../../../Components/Utils/VBDatePiker';
import ViknRadioGroup from '../../../../Components/Utils/ViknRadioGroup';
import { callSupplierVsProduct } from '../../../../Api/Reports/PurchaseApi';
import ViknSearchAutoComplete from '../../../../Components/Utils/ViknSearchAutoComplete';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { openSnackbar } from '../../../../features/SnackBarslice';
import { formatNumber } from '../../../../generalFunction';
import ReportDownload from '../../../../Components/Utils/ReportDownload';
import { UserRolePermission } from '../../../../Function/Editions';

const SupplierVSProductReport = () => {
  let Export_view = UserRolePermission("Supplier Vs Product Report", "print_permission");
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [reportData, setReportData] = useState([])
  const [Loading, setLoading] = useState(false);
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [isLoadingSupplier, setIsLoadingSupplier] = useState(false);
  const [page_no, setPage_no] = useState(1);
  const [t, i18n] = useTranslation("common");
  const [isLoadingProducts, setisLoadingProducts] = useState(false);
  const dispatch = useDispatch();
  const [filterOptions, setFilterOptions] = useState({
    date: {
      name: "Date Filter",
      isActive: false,
      value: { name: "Today" },
      FromDate: moment(),
      ToDate: moment(),
    },

    partyList: [],
    partyObj: { PartyName: "", PartyID :0 },
    // PartyID :0,

    ProductList : [],
    ProductObj : {ProductName : '' , ProductID : 0  },
    // ProductID : 0,

    filterList : [
      {name : 'supplier', label:t('Supplier')},
      {name : 'products', label:t('Products')}
    ],
    filterValue : 'supplier',
    showActiveProductsOnly:true
  })

  //Handle functions
  const handlefilterList = () => {
    let list = [];
    let filterdata = filterOptions
    if (filterdata.date.FromDate && filterdata.date.ToDate){
      let date = String (filterOptions.date.FromDate.format('DD/MM/YYYY') + ' - ' + filterOptions.date.ToDate.format('DD/MM/YYYY'))
      list.push({
        name : t('Date'),
        value : date,
      })
    }
    if (filterdata?.filterValue){
      list.push({
        name : t('Report Type'),
        value : filterdata.filterValue,
      })
    }
    if (filterdata && filterdata?.partyObj?.PartyName !== undefined && filterdata?.partyObj?.PartyName){
      list.push({
        name : t('Supplier'),
        value : filterdata.partyObj?.PartyName,
        handleDelete: () => handleDeletefilter('partyObj')

      })
    }
    if (filterdata && filterdata?.ProductObj?.ProductName !== undefined && filterdata?.ProductObj?.ProductName !== '') {
      list.push({
        name : t('Product'),
        value : filterdata?.ProductObj?.ProductName,
        handleDelete : () => handleDeletefilter('ProductObj')
      })
    }
    return list;
  };
  const handlechange = (e,name) => {
    let { value } = e.target
    setFilterOptions({
      ...filterOptions,[name] : value ,
      partyObj: { PartyName: "", PartyID :0 },
      ProductObj : {ProductName : '' , ProductID : 0  },
    })
  }
  const closeFilter = () => setOpenFilter(false);

  const handleAutoComplete = function (e, value,name) {
    if(name === 'ProductObj'){
      setFilterOptions({
        ...filterOptions,
        ProductObj: {
          ...filterOptions.ProductObj,
          ProductName: value?.ProductName ? value.ProductName : '',
          ProductID: value?.ProductID ? value.ProductID : 0
        }
      });
    }else if (name === "date") {
      console.log(e, value);
      if (value.name === "Custom") {
        setFilterOptions({
          ...filterOptions,
          date: {
            ...filterOptions.date,
            isActive: true,
            value: value,
          },
        });
      } else {
        let date = date_range(value.name);
        setFilterOptions({
          ...filterOptions,
          date: {
            ...filterOptions.date,
            isActive: false,
            value: value,
            ToDate: date.todate,
            FromDate: date.fromdate,
          },
        });
      }
    }else{
      setFilterOptions({...filterOptions,[name]:value ? value : 0})
    }
  }

  const handleDefaultFilter = () => {
    setFilterOptions({
      ...filterOptions,
      date: {
        name: "Date Filter",
        isActive: false,
        value: { name: "Today" },
        FromDate: moment(),
        ToDate: moment(),
      },
      filterValue: "supplier",
      ProductObj : {ProductName : '' , ProductID : 0  },
      partyObj: { PartyName: "", PartyID :0 },
    })
    // setReportData({
    //   ...setReportData,
    //   reportData : [],
    // })
  }
  const searchReport = function () {
    console.log(filterOptions,'filterererertrereteretereteret');
    fetchReport();
  };

  const handleSearchProduct  = async (e, value = 'a') => {
    if (value) {
      setisLoadingProducts(true);
      let length = value.length;
      let ProductLists = await CallProductSearch({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        product_name : value,
        length : length,
        type : 'report',
        showActiveProductsOnly:filterOptions.showActiveProductsOnly,
      });
      let ProductList = [];
      if (ProductLists.StatusCode === 6000){
        ProductList = ProductLists.data
      }
      setFilterOptions({...filterOptions,ProductList : ProductLists.data})
      setisLoadingProducts(false)
    } else {
      setFilterOptions({...filterOptions,ProductObj : {ProductName : '' , ProductID : 0  }});
    }
  };

  // handle validation
  const handleValidation = async () => {
    let Is_Valid = true;
    if (filterOptions.filterValue === 'supplier') {
      if (filterOptions.partyObj.PartyID === 0 ){
        Is_Valid = false;
        dispatch(
          openSnackbar({
            open : true,
            message : 'Select a Supplier',
            severity : 'warning',
          })
        )
      }
    } else if (filterOptions.filterValue === 'products') {
      if (filterOptions?.ProductObj?.ProductID === 0 ) {
        Is_Valid = false;
        dispatch(
          openSnackbar({
            open : true,
            message : 'Select a Product',
            severity : 'warning',
          })
        )
      }
    } 
    return Is_Valid;
  }


  const handleSearchParty = async (e, value, name) => {
    if (value) {
      setIsLoadingSupplier(true);
      const partyResponse = await CallParties({
        CompanyID : CompanyID,
        BranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
        items_per_page: 10,
        page_no: 1,
        search: value,
        PartyType: "2",
      });
      let partyList = [];
      if (partyResponse?.StatusCode === 6000) {
        partyList = partyResponse?.data;
      }
      console.log(partyResponse,'respoennnnnnnnnnnnnnnnnnnnnnnn>>>>>>>>>>');

      setFilterOptions({ ...filterOptions, partyList :partyList });
      setIsLoadingSupplier(false);
    } else {
      setFilterOptions({ ...filterOptions, partyObj: { PartyName: "" } });
    }
  };
  console.log(filterOptions,'filterreerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr');
  // API Fetch data
  const fetchReport = async function () {
    try {
      let Is_Valid = await handleValidation();
      if (Is_Valid) {
        setLoading(true);
        const data = await callSupplierVsProduct ({
          CompanyID : CompanyID,
          BranchID : BranchID,
          PriceRounding : Number(PriceDecimalPoint),
          FromDate:filterOptions.date.FromDate.format("YYYY-MM-DD"),
          ToDate:filterOptions.date.ToDate.format("YYYY-MM-DD"),
          ProductID : filterOptions.ProductObj.ProductID ? filterOptions.ProductObj?.ProductID : 0,
          PartyID : filterOptions.partyObj.PartyID ? filterOptions.partyObj?.PartyID : 0 ,
          filterValue : filterOptions.filterValue === 'supplier' ? 1 : 2,
        },dispatch);
        console.log(data);
        if (data?.StatusCode === 6000) {
          setReportData(data.data)
        } else if (data?.StatusCode === 6001) {
          setReportData()
          dispatch(
            openSnackbar({
              open : true,
              message : data.message,
              severity : 'warning',
            })
          )
        }
      } else {
        console.log('its not valid ');
      }
      setLoading(false)
    } catch (e) {
      console.log(e,'error on fetching report');
    }
  }

  const getDataForPage = () => {
    const startIndex = (page_no -1) * appSetting.itemPerPage;
    const endIndex = startIndex + appSetting.itemPerPage;
    const alldata = reportData?.slice(0,reportData.length -1)
    return alldata?.slice(startIndex, endIndex) || [];
  }
  const handleDeletefilter = (name, index) => {
    console.log(name, index);
    if (name === "routeListValue") {
      let routeListValue = [...filterOptions.routeListValue];
      routeListValue = routeListValue.filter((item, idx) => idx !== index);
      setFilterOptions((prevState) => ({
        ...prevState,
        routeListValue: routeListValue,
      }));
    } else if (name === 'ProductObj'){
      setFilterOptions((prevState) => ({ ...prevState, ProductObj : {ProductName : '' , ProductID : 0 }}))
      setReportData()
    } else if (name === 'partyObj'){
      setFilterOptions((prevState) => ({ ...prevState, partyObj: { PartyName: "", PartyID :0 }}))
      setReportData()
    } else {
      setFilterOptions((prevState) => ({ ...prevState, [name]: '' }));
    }
  };
  
  const filterApis = async function () {
    const partyResponse = await CallParties({
      CompanyID : CompanyID,
      BranchID : BranchID,
      PriceRounding : Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      items_per_page: 10,
      page_no: 1,
      search: "",
      PartyType: "2",
    })
    let partyList = [];
    if (partyResponse.StatusCode === 6000){
      partyList = partyResponse.data
    }
    setFilterOptions(prevState => ({
      ...prevState,
      partyList: partyList
    }));
  }
  useEffect(() => {
    filterApis()
    handleSearchProduct();
  }, [])

// console.log(reportData.length,'datttttttttttttttt');
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
             {t('Supplier Vs Product Report')}
            </Typography>
            {/* <SearchReport /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
            {Export_view ? 
            <ReportDownload
              download_type={"PDF"}
              invoice_type={"supplier_vs_product_report"}
              FromDate={filterOptions.date.FromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.date.ToDate.format("YYYY-MM-DD")}
              PartyID={filterOptions.partyObj.PartyID ? filterOptions.partyObj?.PartyID : 0}
              ProductID={filterOptions.ProductObj.ProductID ? filterOptions.ProductObj?.ProductID : 0}
              filterValue={filterOptions.filterValue === 'supplier' ? 1 : 2}
            />
            :null}
            {/* <PrintBtn /> */}
            <Pagenation 
              totalItem={reportData?.length-1}
              page_no={page_no}
              setPage_no = {setPage_no}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
          <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            display: "flex",
            // justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
          >
        <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
          {t("Applied Filters: ")}
        </div>
        {handlefilterList(filterOptions)?.map((val, i) => (
          <FilterLable
            key={i}
            name={val.name}
            value={val.value}
            handleDelete={val.handleDelete?val.handleDelete:null}
          />
        ))}
        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t('SI NO')} </VBTableCellHeader>
            {filterOptions.filterValue === 'supplier' ? <VBTableCellHeader>{t('Product Name')}</VBTableCellHeader> : <VBTableCellHeader>{t('Supplier')}</VBTableCellHeader>} 
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Last Purchase Price')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Last Purchase Quantity')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Sales')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Sales Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Average Sales Rate')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Purchase')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Purchase Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Average Purchase Rate')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Sales Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Sales Return Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Average Sales Return Rate')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Purchase Return')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Total Purchase Return Amount')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Average Purchase Return Rate')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Current Stock')}</VBTableCellHeader>
            <VBTableCellHeader sx={{textAlign:"right"}}>{t('Unit')}</VBTableCellHeader>
          </VBTableHeader>
          {Loading ? 
            <VBSkeletonLoader/> :
          <TableBody>
            {getDataForPage().map((data, i) => (<TableRow>
              <VBTableCellBody>{data.index}</VBTableCellBody>
              {filterOptions.filterValue === 'supplier' ? <VBTableCellBody>{data.ProductName}</VBTableCellBody> :  <VBTableCellBody>{data.Supplier}</VBTableCellBody>}
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.LastPurchasePrice)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.LastPurchaseQty)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalSales)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalSalesAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.AvgSalesRate)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalPurchase)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalPurchaseAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.AvgPurchaseRate)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalSalesReturn)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalSalesReturnAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.AvgSalesReturnRate)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalPurchaseReturn)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.TotalPurchaseReturnAmount)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.AvgPurchaseReturnRate)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{formatNumber(data.CurrentStock)}</VBTableCellBody>
              <VBTableCellBody sx={{textAlign:"right"}}>{data.Unit}</VBTableCellBody>
            </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>
          }
          <VBTableFooter >
            <TableRow>
              <VBTableCellFooter >{t('Total')}</VBTableCellFooter>
              <VBTableCellFooter ></VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.LastPurchasePrice ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.LastPurchaseQty ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalSales ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalSalesAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.AvgSalesRate ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalPurchase ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalPurchaseAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.AvgPurchaseRate ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalSalesReturn ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalSalesReturnAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.AvgSalesReturnRate ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalPurchaseReturn ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.TotalPurchaseReturnAmount ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{formatNumber(reportData?.[reportData?.length - 1]?.AvgPurchaseReturnRate ?? 0)}</VBTableCellFooter>
              <VBTableCellFooter sx={{textAlign:"right"}}>{reportData?.[reportData?.length - 1]?.CurrentStock || '-'}</VBTableCellFooter>
              <VBTableCellFooter align="right">{reportData?.[reportData.length - 1]?.Unit || '-'}</VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </VBTableContainer>
      </Paper>


      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        handleClose={closeFilter}
        onFilter={searchReport}
        setPage_no = {setPage_no}
        handleDefaultFilter={()=> handleDefaultFilter()}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            
            <ListItem>
              <VBDatePiker
                handleAutoComplete={handleAutoComplete}
                dateValue={filterOptions.date.value}
                isDateRange={true}
                fromDate={filterOptions.date.FromDate}
                toDate={filterOptions.date.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      FromDate: value,
                      value:{ name: "Custom" },
                    },
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    date: {
                      ...filterOptions.date,
                      ToDate: value,
                      value:{ name: "Custom" },
                    },
                  })
                }
              />
            </ListItem>
            <Divider />
            <ListItem>
                <ViknRadioGroup 
                  labelName = {t('Report Type')}
                  radios = {
                    filterOptions.filterList.map((i) => (
                      {label: i.label, value: i.name}
                    ))
                  }
                  handleChange = {handlechange}
                  value = {filterOptions.filterValue}
                  valueName = {"filterValue"}
                />
            </ListItem>
            <ListItem>
            {filterOptions.filterValue === 'supplier' && 

            <ViknSearchAutoComplete 
            freeSolo
            placeholder = {t("Supplier")}
            filterName = {t("Supplier")}
            loading = {isLoadingSupplier}
            options = {filterOptions.partyList ? filterOptions.partyList : []}
            getOptionLabel={(option) => option.PartyName || ""}
            name = "partyObj"
            filterOptions={(options, { inputValue }) =>
              options.filter((option) =>
                (option.PartyName && option.PartyName.toLowerCase().includes(inputValue.toLowerCase())) ||
                (option.PartyCode && option.PartyCode.toLowerCase().includes(inputValue.toLowerCase()))
              )
            }
            renderOption={(props, option) => (
              <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.PartyCode}</Box>
                <Box sx={{fontSize: "14px",}}>{option.PartyName}</Box>
              </Box>
            )}
            onInputChange={(event, value, reason) => { 
              if (reason === "input") {
                handleSearchParty(event,value);
              }
              if(reason === "clear"){
                setFilterOptions({ ...filterOptions, partyObj: {} });
              }
            }}
            onChange={(e, v) => handleAutoComplete(e, v, "partyObj",)}
            value={filterOptions.partyObj}

            />
              // <ViknAutoComplete 
              //   placeholder={t('Supplier')}
              //   options = {filterOptions.partyList ? filterOptions.partyList : []}
              //   getOptionLabel = {(option) => option.PartyName || ''}
              //   value = {filterOptions.partyObj}
              //   onChange = {(e,v) => handleAutoComplete(e,v,'partyObj')}
              //   onInputChange={(event, value, reason) => { 
              //     if(reason === "clear"){
              //       setFilterOptions({ ...filterOptions, partyObj : {PartyName : '' , PartyID : 0}});
              //       setReportData();
              //     }
              //     console.log(reason,'dddddddddddddddddddddddddddd');
              //   }}
              // />
            }
             </ListItem>
             {filterOptions.filterValue === 'products' && (
            <ListItem>
          <FormControlLabel sx={{ px: 1 }} control={<Checkbox checked={!filterOptions.showActiveProductsOnly} onChange={() => {
                setFilterOptions((prev) => ({
                  ...prev,
                  showActiveProductsOnly: !filterOptions.showActiveProductsOnly,
                }));
                // handleSearchProduct();
              }} />} label={t("Show Inactive Products")} />
            
            </ListItem>)
            }
             <ListItem>

            {filterOptions.filterValue === 'products' && 
              <ViknSearchAutoComplete 
                freeSolo
                placeholder = {t("Product")}
                filterName = {t("Product")}
                loading = {isLoadingProducts}
                options = {filterOptions.ProductList ? filterOptions.ProductList : []}
                getOptionLabel={(option) => option.ProductName || "" }
                name = "ProductObj"
                filterOptions={(options, { inputValue }) =>
                  options.filter((option) =>
                    (option.ProductName && option.ProductName.toLowerCase().includes(inputValue.toLowerCase())) ||
                    (option.ProductCode && option.ProductCode.toLowerCase().includes(inputValue.toLowerCase()))
                  )
                }
                renderOption={(props, option) => (
                  <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.ProductCode}</Box>
                    <Box sx={{fontSize: "14px",}}>{option.ProductName}</Box>
                  </Box>
                )}
                onInputChange={(event, value, reason) => { 
                  if (reason === "input") {
                    handleSearchProduct(event,value);
                  }
                  if(reason === "clear"){
                    setFilterOptions({ ...filterOptions, ProductObj : {ProductName : '' , ProductID : 0}});
                    setReportData();
                  }
                  console.log(reason,'dddddddddddddddddddddddddddd');
                }}
                onChange={(e, v) => handleAutoComplete(e, v, "ProductObj")}
                value={filterOptions.ProductObj}
              />
              }
             </ListItem>
          </List>
        }
      />
    </>
  )
}

export default SupplierVSProductReport

const FlexBox = styled(Box)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap:"22px",
  },
}));