import {
  Box,
  List,
  ListItem,
  Paper,
  TableBody,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
// import Slide from '@material-ui/core/Slide';
import moment from "moment";
import React, { useEffect, useState } from "react";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import ViknSearchAutoComplete from "../../../../Components/Utils/ViknSearchAutoComplete";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import FilterLable from "../../../../Components/Utils/FilterLable";
import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBSkeletonLoader,
} from "../../../../Components/ReportTable/ReportTable";
import {
  CallProductSerialisation,
} from "../../../../Api/Reports/InventoryApi";
import VBSelect from "../../../../Components/Utils/VBSelect";
import ViknCheckboxGroup from "../../../../Components/Utils/ViknCheckboxGroup";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import VBInputField from "../../../../Components/Utils/VBInputField";
import VBZoomAnime from "../../../../Components/Utils/VBZoomAnime";
import {
  CallBrands,
  CallParties,
  CallProductGroups,
  CallProductSearch,
  callBatchList,
} from "../../../../Api/Reports/CommonReportApi";
import { goToInvoice } from "../../../../Function/comonFunction";
import ReportDownload from "../../../../Components/Utils/ReportDownload";
import { formatNumber } from "../../../../generalFunction";
import LoaderLite from "../../../../Components/Utils/LoaderLite";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";

const ProductSerialisationReport = () => {
  let Export_view = UserRolePermission("Product Serialisation Report", "print_permission");
  const [t] = useTranslation("common");
  const appSettings = useSelector((state) => state.appSettingsSclice);
  const {PriceDecimalPoint,} = useSelector((state) => state.generalSettingsSclice);
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [progress, setProgress] = useState(0);
  const [progressMessage, setProgressMessage] = useState("Generating Report...");
  const [reportData, setReportData] = useState({data: [], count: 0});
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {EnableProductBatchWise} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  console.log(EnableProductBatchWise);
  const { user_id, } = useSelector((state) => state.user);
  const [openFilter, setOpenFilter] = useState(false);
  const [clearFilter, setClearFilter] = useState(false)
  const [filterOptions, setFilterOptions] = useState({
    productGroup: null,
    productWise: null,
    batch: null,
    brand: null,
    customer: null,
    supplier: null,
    serilalItem: null,
    select: "Both",
    isProductGroup: false,
    isProductWise: false,
    isBatch: true,
    isAsofDate: false,
    isSerial: false,
    isItem: false,
    fromDate: moment(),
    toDate: moment(),
    showActiveProductsOnly:true
  });

  const [filterData, setFilterData] = useState({
    productGroupList: [],
    productWiseList: [],
    batchList: [],
    brandList: [],
    customerList: [],
    supplierList: [],
    serialItemList: [],
    selectList: ["Sales", "Purchase", "Both"],
  });
  console.log(filterData);
  
  const [search, setSearch] = useState({
    batch: "",
    product: "",
    customer: "",
    supplier: "",
  });

  const [isLoading, setIsLoading] = useState({
    isLoadingBatch: false,
    isLoadingProduct: false,
    isLoadingCustomer: false,
    isLoadingSupplier: false,
    report: false
  });

  const [filterArray, setFilterArray] = useState([]);

  //Handle functions
  const closeFilter = () => setOpenFilter(false);

  const radiosValue = [t("Batch"), t("Product Group"), t("Product Wise")].map(
    (option) => {
      const isChecked = filterOptions[`is${option.replace(" ", "")}`];

      return {
        checked: isChecked !== undefined ? isChecked : false,
        onChange: () =>
          setFilterOptions({
            ...filterOptions,

            isProductWise: false,
            isProductGroup: false,
            isBatch: false,
            [`is${option.replace(" ", "")}`]: true,
          }),
        label: option,
      };
    }
  );

  const searchReport = function () {
    setPage(1)
    fetchReport();
  };

  const handleDefaultFilter = function () {
    setFilterOptions({
      productGroup: null,
      productWise: null,
      batch: null,
      brand: null,
      customer: null,
      supplier: null,
      serilalItem: null,
      select: "Sales",
      isProductGroup: false,
      isProductWise: false,
      isBatch: true,
      isAsofDate: false,
      isSerial: false,
      isItem: false,
      fromDate: moment(),
      toDate: moment(),
    })
    setClearFilter(!clearFilter)
    setPage(1)
  }

  // API Fetch data
  const fetchReport = async function () {
    setIsLoading({
      ...isLoading,
      report: true,
    });
    let payload = {
      ReportType: filterOptions.select.toLocaleLowerCase(),
      ToDate: filterOptions.toDate.format("YYYY-MM-DD"),
      FromDate: filterOptions.fromDate.format("YYYY-MM-DD"),
      AsOfDate: filterOptions.isAsofDate,
      BrandID: 0,
      SerialNo: null,
      ItemCode: null,
      GroupID: 0,
      ProductID: 0,
      BatchCode: "0",
      CustomerID: 0,
      SupplierID: 0,
      items_per_page: appSettings.itemPerPage,
      page_no: page,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
    };
    if (filterOptions.productGroup) payload.GroupID = filterOptions.productGroup.ProductGroupID;
    if (filterOptions.productWise) payload.ProductID = filterOptions.productWise.ProductID;
    if (filterOptions.batch) payload.BatchCode = filterOptions.batch.BatchCode;
    if (filterOptions.customer) payload.CustomerID = filterOptions.customer.LedgerID;
    if (filterOptions.supplier) payload.SupplierID = filterOptions.supplier.LedgerID;
    if (filterOptions.brand) payload.BrandID = filterOptions.brand.BrandID;
    if (filterOptions.isSerial) payload.SerialNo = filterOptions.serilalItem
    if (filterOptions.isItem) payload.ItemCode = filterOptions.serilalItem

    // if (filterOptions.brand) payload.GroupID = filterOptions.brand.BrandID

    const data = await CallProductSerialisation(payload);
    // console.log(data);
    if (data?.StatusCode === 6000) {
      setReportData({data: data.data, count: data.count});
    } else {
      dispatch(
        openSnackbar({
          open : true,
          message : data?.message,
          severity : 'warning',
        })
      )
      setReportData({data: [], count: 0});
    }
    setIsLoading({
      ...isLoading,
      report: false,
    });
  };

  const searchBatch = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingBatch: true,
    });
    let res = await callBatchList({
      BatchCode: search.batch,
      length: search.batch.length,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    });
    // console.log(res);
    setFilterData(prev =>({
      ...prev,
      batchList: res.data,
    }));
    setIsLoading({
      ...isLoading,
      isLoadingBatch: false,
    });
  };

  const searchProduct = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingProduct: true,
    });
    let res = await CallProductSearch({
      product_name: search.product,
      length: search.product.length,
      type: "report",
      is_product_image: false,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      showActiveProductsOnly:filterOptions.showActiveProductsOnly
    });
    if(res.StatusCode === 6000) {
      setFilterData(prev =>({
        ...prev,
        productWiseList: res.data,
      }));
      setIsLoading({
        ...isLoading,
        isLoadingProduct: false,
      });
    }
  };

  const searchCustomer = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingCustomer: true,
    });
    let res = await CallParties({
      // RouteID: null,
      search: search.customer,
      PartyType: "1",
      page_no: 1,
      items_per_page: 10,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      List: "Active"
    });
    if (res.StatusCode === 6000) {

      setFilterData(prev => ({...prev, customerList: res.data}))
    }
    setIsLoading({
      ...isLoading,
      isLoadingCustomer: false,
    });
  };

  const searchSupplier = async function () {
    setIsLoading({
      ...isLoading,
      isLoadingSupplier: true,
    });
    let res = await CallParties({
      PartyType: 2,
      // RouteID: 1,
      search: search.supplier,
      page_no: 1,
      items_per_page: 10,
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id,
      List: "Active"
    });
    if (res.StatusCode === 6000) {
      setFilterData(prev => ({...prev, supplierList: res.data}))
      
    }
    setIsLoading({
      ...isLoading,
      isLoadingSupplier: false,
    });
  };

  const filterApi = async function () {
    const payload = { 
      CompanyID: CompanyID,
      BranchID: BranchID,
      PriceRounding: Number(PriceDecimalPoint),
      CreatedUserID: user_id
    }
    try {
      const apiNames = ["productGroupList", "brandList"];
      const responses = await Promise.all([CallProductGroups(payload), CallBrands(payload)]);

      responses.forEach((response, index) => {
        if (response.StatusCode === 6000) {
          setFilterData((prevState) => ({
            ...prevState,
            [apiNames[index]]: response.data,
            // [`${apiNames[index].toLowerCase()}List`]: response.data
          }));
        } else {
          console.log(
            `API ${apiNames[index]} returned StatusCode ${response.StatusCode}`
          );
        }
      });
    } catch (error) {
      console.error("An error occurred while making the API calls", error);
    }
  };

  useEffect(() => {
    filterApi();
    // searchBatch()
  }, []);

  useEffect(() => {
    searchBatch();
  }, [search.batch]);

  useEffect(() => {
    searchProduct();
  }, [search.product]);

  useEffect(() => {
    searchCustomer();
  }, [search.customer]);

  useEffect(() => {
    searchSupplier();
  }, [search.supplier]);

  useEffect(() => {
    fetchReport()
  }, [page, clearFilter])
  

  return (
    <>
    {progress > 0 ?
      <LoaderLite message={((progress<=100)?("Generating Report... " + progress + "%"):"Creating File... ")} progress={progress} fullscreen={true} />
      :null}
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500", mr: 2 }}
              variant="h2"
            >
              {t("Product Serialisation Report")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </Box>

          <FlexBox>
            {/* <ExportBtn /> */}
            {/* <PrintBtn 
              
            /> */}
            {Export_view ? 
            <ReportDownload
              setProgress={setProgress}
              setProgressMessage={setProgressMessage}
              download_type={"PDF"}
              is_celery={true}
              invoice_type={"product_serialisation"}
              FromDate={filterOptions.fromDate.format("YYYY-MM-DD")}
              ToDate={filterOptions.toDate.format("YYYY-MM-DD")}
              VoucherType={filterOptions.select.toLocaleLowerCase()}
              AsOfDate={filterOptions.isAsofDate}
              SerialNo={filterOptions.isSerial ? filterOptions.serilalItem :null}
              ItemCode={filterOptions.isItem? filterOptions.serilalItem:null}
              BrandID={filterOptions.brand ? filterOptions.brand.BrandID : 0}
              GroupID={filterOptions.productGroup ? filterOptions.productGroup.ProductGroupID : 0}
              BatchCode={filterOptions.batch ? filterOptions.batch.BatchCode : 0}
              CustomerID={filterOptions.customer ? filterOptions.customer.LedgerID : 0}
              SupplierID={filterOptions.supplier ? filterOptions.supplier.LedgerID : 0}
              ProductID={filterOptions.productWise ? filterOptions.productWise.ProductID : 0}
              // WarehouseID={state.WareHouseID}
            />
            : null}
            <Pagenation 
              totalItem={reportData.count}
              page_no={page}
              setPage_no = {setPage}
            />
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          // This also change into custom component
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FilterLable
            name={t("Voucher Type")}
            value={filterOptions.select }
          />
          <FilterLable
            name={t("Date")}
            value={(!filterOptions.isAsofDate ? filterOptions.fromDate.format("DD-MM-YYYY") + " to " : " ") + filterOptions.toDate.format("DD-MM-YYYY") }
          />
           {filterOptions.batch && filterOptions.isBatch && (<FilterLable
              name={t("Batch")}
              value={filterOptions.batch.BatchCode}
              handleDelete={() => setFilterOptions({...filterOptions, batch: null})}
            />)
          }
          {filterOptions.productWise && filterOptions.isProductWise && (<FilterLable
              name={t("Product Wise")}
              value={filterOptions.productWise.ProductName}
              handleDelete={() => setFilterOptions({...filterOptions, productWise: null})}
            />)
          }
          {filterOptions.productGroup && filterOptions.isProductGroup && (<FilterLable
              name={t("Product Group")}
              value={filterOptions.productGroup.GroupName}
              handleDelete={() => setFilterOptions({...filterOptions, productGroup: null})}
            />)
          }

          {filterOptions.brand && (<FilterLable
              name={t("Brands ")}
              value={filterOptions.brand.BrandName}
              handleDelete={() => setFilterOptions({...filterOptions, brand: null})}
            />)
          }

          {filterOptions.customer && (<FilterLable
              name={t("Customer ")}
              value={filterOptions.customer.DisplayName}
              handleDelete={() => setFilterOptions({...filterOptions, customer: null})}
            />)
          }

          {filterOptions.supplier && (<FilterLable
              name={t("Supplier ")}
              value={filterOptions.supplier.DisplayName}
              handleDelete={() => setFilterOptions({...filterOptions, supplier: null})}
            />)
          }

          {filterOptions.serilalItem && (filterOptions.isSerial || filterOptions.isItem) && (<FilterLable
              name={filterOptions.isSerial ? t("Serial No") : t("Item Code")}
              value={filterOptions.serilalItem}
              handleDelete={() => setFilterOptions({...filterOptions, serilalItem: "", isSerial: false, isItem: false})}
            />)
          }

        </Box>

        {/* Report Table */}
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader>{t("SI NO")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Batch Code")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Date")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Product")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Product Group")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Brand")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Serial No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Item Code")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher Type")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Party")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Purchase Price")}</VBTableCellHeader>
            <VBTableCellHeader>{t("Sales Price")}</VBTableCellHeader>
          </VBTableHeader>
          {isLoading.report ? (
            <VBSkeletonLoader />
          ) : <TableBody>
            {reportData.data.map((data, i) => (
              <TableRow 
                key={data.id}
                sx={{ cursor: "pointer" }} 
                onClick={() => goToInvoice(data.id, data.VoucherType, data.VoucherNo,)} 
              >                
                <VBTableCellBody>{(page - 1) * appSettings.itemPerPage + i + 1}</VBTableCellBody>
                <VBTableCellBody>{data.BatchCode}</VBTableCellBody>
                <VBTableCellBody>{data.Date}</VBTableCellBody>
                <VBTableCellBody>{data.Product}</VBTableCellBody>
                <VBTableCellBody>{data.ProductGroup}</VBTableCellBody>
                <VBTableCellBody>{data.Brand}</VBTableCellBody>
                <VBTableCellBody>{data.SerialNo}</VBTableCellBody>

                <VBTableCellBody>{data.ItemCode}</VBTableCellBody>
                <VBTableCellBody>{data.VoucherType}</VBTableCellBody>
                <VBTableCellBody>{data.VoucherNo}</VBTableCellBody>
                <VBTableCellBody>{data.PartyName}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.PurchasePrice)}</VBTableCellBody>
                <VBTableCellBody>{formatNumber(data.SalesPrice)}</VBTableCellBody>

                {/* <VBTableCellBody>{data.UnitPrice}</VBTableCellBody>
                <VBTableCellBody>{data.Qty}</VBTableCellBody>
                <VBTableCellBody>{data.FreeQty}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
                {/* <VBTableCellBody>{data.QtyOut}</VBTableCellBody>
                <VBTableCellBody>{data.QtyIn}</VBTableCellBody> */}
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>}
          {/* <VBTableFooter>
            <TableRow>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter>
                {reportData[reportData.length - 1]?.index}
              </VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
            </TableRow>
          </VBTableFooter> */}
        </VBTableContainer>
      </Paper>

      {/* Filter Drawer Section */}
      <ViknDrawer
        open={openFilter}
        onFilter={searchReport}
        handleClose={closeFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            {/* <Divider /> */}
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.isAsofDate ? null : filterOptions.fromDate}
                toDate={filterOptions.toDate}
                fromOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, fromDate: value })
                }
                toOnChange={(value) =>
                  setFilterOptions({ ...filterOptions, toDate: value })
                }
              />
            </ListItem>
            
            <ListItem>
              <ViknCheckboxGroup radios={radiosValue} />
            </ListItem>
            { 
            filterOptions.isProductWise && 
            <ListItem>
                <ViknCheckboxGroup
                radios={[
                  {
                    label: t("Show Inactive Products"),
                    checked: filterOptions.showActiveProductsOnly
                      ? false
                      : true,
                    onChange: () => {
                      setFilterOptions((prev) => ({
                        ...prev,
                        showActiveProductsOnly: !prev.showActiveProductsOnly,
                      }));
                      searchProduct();
                    },
                  },
                ]}
              />
            </ListItem>
            }
            <ListItem>
              {/* <div> */}
              <VBZoomAnime in={filterOptions.isBatch}>
                {/* <ViknAutoComplete
                  placeholder={"Batch"}
                  value={filterOptions.product}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, product: newValue }))
                  }
                  options={filterData.productList}
                  getOptionLabel={(option) => option.label}
                /> */}
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Batch")}
                  loading={isLoading.isLoadingBatch}
                  options={filterData.batchList}
                  getOptionLabel={(option) => option.BatchCode || ""}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setSearch({ ...search, batch: value });
                    }
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, batch: newValue }))
                  }
                  value={filterOptions.batch}
                />
              </VBZoomAnime>

              <VBZoomAnime in={filterOptions.isProductWise}>
                {/* <ViknAutoComplete
                  value={filterOptions.productWise}
                  onChange={(e, newValue) => {
                    console.log(e.target);
                    setFilterOptions((prev) => ({
                      ...prev,
                      productCategory: newValue,
                    }));
                  }}
                  options={filterData.productWiseList}
                  getOptionLabel={(option) => option?.name}
                  // isOptionEqualToValue={(option, value) => option?.name === value?.name}
                /> */}
                <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Product Wise")}
                  loading={isLoading.isLoadingProduct}
                  options={filterData.productWiseList}
                  getOptionLabel={(option) => option.ProductName || ""}
                  filterOptions={(options, { inputValue }) =>
                    options.filter((option) =>
                      (option.ProductName && option.ProductName.toLowerCase().includes(inputValue.toLowerCase())) ||
                      (option.ProductCode && option.ProductCode.toLowerCase().includes(inputValue.toLowerCase()))
                    )
                  }
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                      <Box sx={{fontSize: "14px",color:"#797979",mr: 2}}>{option.ProductCode}</Box>
                      <Box sx={{fontSize: "14px",}}>{option.ProductName}</Box>
                    </Box>
                  )}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setSearch({ ...search, product: value });
                    }
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, productWise: newValue }))
                  }
                  value={filterOptions.productWise}
                />
              </VBZoomAnime>

              <VBZoomAnime in={filterOptions.isProductGroup}>
                <ViknAutoComplete
                  placeholder={t("Product Group")}
                  value={filterOptions.productGroup}
                  onChange={(e, newValue) => {
                    console.log(e);
                    setFilterOptions((prev) => ({
                      ...prev,
                      productGroup: newValue,
                    }));
                  }}
                  options={filterData.productGroupList}
                  getOptionLabel={(option) => option.GroupName}
                  // isOptionEqualToValue={(option, value) => option?.GroupName === value?.GroupName}
                />
              </VBZoomAnime>
              {/* </div> */}
            </ListItem>
            <ListItem>
              {/* Product Search AutoComplete */}
              {/* <ViknSearchAutoComplete placeholder={"Warehouse Name"} /> */}
              <VBSelect
                label ={t("Voucher Type")}
                value={filterOptions.select}
                options={filterData.selectList}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, select: e.target.value })
                }
              />
            </ListItem>
            <ListItem>
              <ViknAutoComplete
                placeholder={t("Search for Brands")}
                label={t("Brand")}
                value={filterOptions.brand}
                options={filterData.brandList}
                getOptionLabel={(option) => option.BrandName}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({ ...prev, brand: newValue }))
                }
              />
            </ListItem>
            <ListItem>
              <ViknCheckboxGroup
                radios={[
                  {
                    checked: filterOptions.isAsofDate,
                    onChange: () =>
                      setFilterOptions((prev) => ({
                        ...prev,
                        isAsofDate: !prev.isAsofDate,
                      })),
                    label: t("As of Date"),
                  },
                ]}
              />
            </ListItem>
            <ListItem>
              {/* <ViknAutoComplete
                placeholder={"Search for Customer"}
                label={"Customer"}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({
                    ...prev,
                    customer: newValue,
                  }))
                }
                options={filterData.customerList}
                getOptionLabel={(option) => option?.name}
              /> */}
              <ViknSearchAutoComplete
                  freeSolo
                  placeholder={t("Search Customer")}
                  filterName={"Customer"}
                  loading={isLoading.isLoadingCustomer}
                  options={filterData.customerList}
                  getOptionLabel={(option) => option.DisplayName || ""}
                  onInputChange={(event, value, reason) => {
                    if (reason === "input") {
                      setSearch({ ...search, customer: value });
                    }
                  }}
                  onChange={(e, newValue) =>
                    setFilterOptions((prev) => ({ ...prev, customer: newValue }))
                  }
                  value={filterOptions.customer}
                />
            </ListItem>
            <ListItem>
              <ViknSearchAutoComplete
                freeSolo
                loading={isLoading.isLoadingSupplier}
                placeholder={t("Search for Supplier")}
                filterName={t("Supplier")}
                onChange={(e, newValue) =>
                  setFilterOptions((prev) => ({...prev, supplier: newValue,}))
                }
                onInputChange={(event, value, reason) => {
                  console.log(event, reason);
                  if (reason === "input") {
                    setSearch({ ...search, supplier: value });
                  }
                }}
                options={filterData.supplierList}
                getOptionLabel={(option) => option?.DisplayName}
                value={filterOptions.supplier}
              />
            </ListItem>
            <ListItem>
              <Box minWidth={"100%"}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <ViknCheckboxGroup
                    isBorder={false}
                    radios={[
                      {
                        checked: filterOptions.isSerial,
                        onChange: () =>
                          setFilterOptions((prev) => ({
                            ...prev,
                            isSerial: !prev.isSerial,
                            isItem: false,
                          })),
                        label: t("Serial No"),
                      },
                    ]}
                  />
                  <ViknCheckboxGroup
                    isBorder={false}
                    radios={[
                      {
                        checked: filterOptions.isItem,
                        onChange: () =>
                          setFilterOptions((prev) => ({
                            ...prev,
                            isItem: !prev.isItem,
                            isSerial: false,
                            serilalItem: ""
                          })),
                        label: t("Item Code"),
                      },
                    ]}
                  />
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <VBZoomAnime in={filterOptions.isSerial}>
                    <VBInputField
                      value={filterOptions.serilalItem}
                      onChange={(e) =>
                        setFilterOptions((prev) => ({
                          ...prev,
                          serilalItem: e.target.value,
                        }))
                      }
                      placeholder={t("Serial Number")}
                    />
                  </VBZoomAnime>
                  <VBZoomAnime in={filterOptions.isItem}>
                    <VBInputField
                      value={filterOptions.serilalItem}
                      onChange={(e) =>
                        setFilterOptions((prev) => ({
                          ...prev,
                          serilalItem: e.target.value,
                        }))
                      }
                      placeholder={t("Item Code")}
                    />
                  </VBZoomAnime>
                </Box>
              </Box>
            </ListItem>
          </List>
        }
      />
    </>
  );
};

export default ProductSerialisationReport;

const FlexBox = styled(Box)(() => ({
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  // width: "27%",
}));
