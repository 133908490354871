import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

function AlertWindow({ open, handleClose, cnfFunction, title, description, cnfmName }) {
  const conformFunction = function () {
    cnfFunction();
    handleClose();
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      sx={{
        ".MuiPaper-root" : {
          minWidth: 350
        }
      }}
    >
      <DialogTitle id="alert-dialog-title">
        {title || "Are you sure ?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description || "Once deleted, you not be able to recover this data"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={handleClose}>Cancel</Button>
        <Button variant="outlined" color="error" onClick={conformFunction} autoFocus>
          {cnfmName || "Delete"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AlertWindow;
