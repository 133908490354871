import Bank from "../../Pages/Accounts/Bank";
import TaxCategory from "../../Pages/Accounts/TaxCategory";

const AccountsRoutes = [
  {
    path: "banks",
    element: <Bank/>,
    isAuth: true,
  },
  {
    path: "tax-category",
    element: <TaxCategory/>,
    isAuth: true,
  },
];

export default AccountsRoutes;
