import { useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import React from "react";
import Pagenation from "../../Components/Utils/Pagenation";
import { MenuOptions } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
import ConfirmBox from "../../Components/Utils/ConfirmBox";
import VBInputField from "../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../Components/Utils/ViknAutoComplete";

import {
  Box,
  Paper,
  TableBody,
  TableRow,
  Typography,
  Checkbox,
  Modal,
  Button,
  Grid,
  FormControlLabel,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
} from "@mui/material";

import {
  CreateNewButton,
  DeleteButton,
} from "../../Components/Utils/StyledButtons";

import {
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../Components/ReportTable/ReportTable";

import {
  setDefultTaxCatogeryAPI,
  taxCatogeryCreateAPI,
  taxCatogeryDeleteAPI,
  taxCatogeryListAPI,
  taxCatogeryUpdateAPI,
  taxCatogeryViewAPI,
  taxConversionAPI,
  taxSearchQureyAPI,
} from "../../Api/Accounts/Accounts";

import { ProductGroupAPI } from "../../Api/Products/ProductApis";
import { CallProductSearch } from "../../Api/Reports/CommonReportApi";
import BottomNavigationMenu from "../../Components/Utils/BottomNavigationMenu";
import { product_search_new_api } from "../../Api/CommonApi/CommonApis";
import Search from "@mui/icons-material/Search";
import SearchTextField from "../../Components/Utils/SearchTextField";

function TaxCategory() {
  // ============================== Data =============================
  const taxTypeList = [{ name: "General" }, { name: "Excise" }];

  const taxCategoryList = [
    {
      id: 1,
      value: "S",
      name: "Standard rate",
      TaxExceptionCode: "",
      TaxExceptionReason: "",
      TaxExceptionReasonSecondLanguage: "",
    },
    {
      id: 2,
      value: "E",
      name: "Exempt from Tax",
      TaxExceptionCode: "VATEX-SA-29",
      TaxExceptionReason:
        "Financial services mentioned in Article 29 of the VAT Regulations.",
      TaxExceptionReasonSecondLanguage: "الخدمات المالية",
    },
    {
      id: 3,
      value: "E",
      name: "Exempt from Tax",
      TaxExceptionCode: "VATEX-SA-29-7",
      TaxExceptionReason:
        "Life insurance services mentioned in Article 29 of the VAT Regulations.",
      TaxExceptionReasonSecondLanguage: "عقد تأمين على الحياة",
    },
    {
      id: 4,
      value: "E",
      name: "Exempt from Tax",
      TaxExceptionCode: "VATEX-SA-30",
      TaxExceptionReason:
        "Real estate transactions mentioned in Article 30 of the VAT Regulations.",
      TaxExceptionReasonSecondLanguage: "الضريبةالتوريدات العقارية المعفاة من",
    },
    {
      id: 5,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-32",
      TaxExceptionReason: "Export of goods.",
      TaxExceptionReasonSecondLanguage: "صادرات السلع من المملكة",
    },
    {
      id: 6,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-33",
      TaxExceptionReason: "Export of services.",
      TaxExceptionReasonSecondLanguage: "صادرات الخدمات من المملكة",
    },
    {
      id: 7,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-34-1",
      TaxExceptionReason: "The international transport of Goods.",
      TaxExceptionReasonSecondLanguage: "النقل الدولي للسلع",
    },
    {
      id: 8,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-34-2",
      TaxExceptionReason: "International transport of passengers.",
      TaxExceptionReasonSecondLanguage: "النقل الدولي للركاب",
    },
    {
      id: 9,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-34-3",
      TaxExceptionReason:
        "Services directly connected and incidental to a Supply of international passenger transport.",
      TaxExceptionReasonSecondLanguage:
        "الخدمات المرتبطة مباشرة أو عرضي ًا بتوريد النقل الدولي للركاب",
    },
    {
      id: 10,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-34-4",
      TaxExceptionReason: "Supply of a qualifying means of transport.",
      TaxExceptionReasonSecondLanguage: "توريد وسائل النقل المؤهلة",
    },
    {
      id: 11,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-34-5",
      TaxExceptionReason:
        "Any services relating to Goods or passenger transportation, as defined in article twenty five of these Regulations.",
      TaxExceptionReasonSecondLanguage:
        "الخدمات ذات الصلة بنقل السلع أو الركاب، وفق ًا للتعريف الوارد بالمادة الخامسة والعشرين من الالئحة التنفيذية لنظام ضريبة القيامة المضافة",
    },
    {
      id: 12,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-35",
      TaxExceptionReason: "Medicines and medical equipment.",
      TaxExceptionReasonSecondLanguage: "الأدوية والمعدات الطبية",
    },
    {
      id: 13,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-36",
      TaxExceptionReason: "Qualifying metals.",
      TaxExceptionReasonSecondLanguage: "المعادن المؤهلة",
    },
    {
      id: 14,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-EDU",
      TaxExceptionReason: "Private education to citizen.",
      TaxExceptionReasonSecondLanguage: "الخدمات التعليمية الخاصة للمواطنين",
    },
    {
      id: 15,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-HEA",
      TaxExceptionReason: "Private healthcare to citizen.",
      TaxExceptionReasonSecondLanguage: "الخدمات الصحية الخاصة للمواطنين",
    },
    {
      id: 16,
      value: "Z",
      name: "Zero rated goods",
      TaxExceptionCode: "VATEX-SA-MLTRY",
      TaxExceptionReason: "Supply of qualified military goods",
      TaxExceptionReasonSecondLanguage: "توريد السلع العسكرية المؤهلة",
    },
    {
      id: 17,
      value: "O",
      name: "Services outside scope of tax / Not subject to VAT",
      TaxExceptionCode: "VATEX-SA-OOS",
      TaxExceptionReason: "",
      TaxExceptionReasonSecondLanguage: "",
    },
  ];

  const SaudiCountryID = "94e4ce66-26cc-4851-af1e-ecc068e80224";
  // ====================== Global states =================================

  const { CompanyID, BranchID, LedgerID } = useSelector(
    (state) => state.companyDetails
  );

  const { Country, State } = useSelector((state) => state.companyDetails);

  const { user_id } = useSelector((state) => state.user);
  const { EnableEinvoiceKSA } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );

  const dispatch = useDispatch();

  const [t] = useTranslation("common");
  const appSetting = useSelector((state) => state.appSettingsSclice);

  // ============================= States ================================

  const [pageNo, setPageNo] = useState(1);
  const [confirmBox, setConfirmBox] = useState(false);
  const [createModal, setCreateModal] = useState(false);
  const [productWiseModal, setProductWiseModal] = useState(false);
  const [taxCatogeryWiseModal, setTaxCatogeryWiseModal] = useState(false);

  const [isEditModal, setIsEditModal] = useState({
    state: false,
    uniq_id: null,
  });

  const [response, setResponse] = useState({
    data: [],
    count: null,
    selectedItems: [],
    isSelectedAll: false,
    countryList: [],
  });

  const [uploadData, setUploadData] = useState({
    name: "",
    taxTypeName: EnableEinvoiceKSA ? { name: "General" } : null,
    taxException: null,
    generalTax: 0,
    exciseBrakepointValue: 0,
    isExciseLessThanBPPercentage: true,
    isExciseLessThanBPAmount: false,
    exciseLessThanBPTax: 0,
    exciseGreaterOREqualToBPTax: 0,
    isExciseGreaterOREqualToBPPercentage: true,
    isExciseGreaterOREqualToBPAmout: false,
  });

  const [productWiseUploadData, setProductWiseUploadData] = useState({
    TaxTypeFilter: "no_change",
    TaxID: "",
    ProductGroupID: "",
    ProductID: "",
    all_product: true,
    product_wise: false,
    group_wise: false,
    dropDwn_type: "0",
    FromTaxID: "",
    ToTaxID: "",
    Search: "",
  });

  const [listData, setListData] = useState({
    productGroupList: [],
    productList: [],
    taxCatogeryList: [],
  });

  const [searchText, setSearchText] = useState("");
  
  const [productWiseChecked, setProductWiseChecked] = useState("All Product");

  // =========================== Convert Button ==============================
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (value) => {
    setAnchorEl(null);
    if (value === 1) {
      setProductWiseModal(true);
    } else if (value === 2) {
      setTaxCatogeryWiseModal(true);
    }
  };
  // ============================= User Role Settings ==============================

  let userRoleSettings = useSelector((state) => state.userRollSettings);
  let permissions = userRoleSettings.filter((i) => i.name === "Tax Category");

  let createPermission = permissions[0].save_permission;
  let editPermission = permissions[0].edit_permission;
  let deletePermission = permissions[0].delete_permission;

  const optionList = [
    { name: "Edit", permission: editPermission, is_disabled: false },
    { name: "Delete", permission: deletePermission, is_disabled: true },
  ];

  // ============================== Functions =========================================

  // Item edit and delete button click

  const optionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = optionList.find((i) => i.name === name)?.permission;
      if (name === "Edit" && permission) {
        setCreateModal(true);
        setIsEditModal((prevState) => ({
          ...prevState,
          state: true,
          uniq_id: uniq_id,
        }));
      } else if (name === "Delete" && permission) {
        setResponse({ ...response, selectedItems: [uniq_id] });
        setConfirmBox(true);
      }
    }
  };

  // Select all Item By Click

  const selectItems = (type, id) => {
    let data = [...response.selectedItems];
    let isSelectedAll = response.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (response.isSelectedAll) {
        data = [];
      } else {
        response.data.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }
    setResponse({
      ...response,
      selectedItems: data,
      isSelectedAll: isSelectedAll,
    });
  };

  // Fetch Response Data

  const fetchData = async () => {
    let payload = {
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      page_no: pageNo,
      items_per_page: 15,
      PriceRounding: 4,
      search:searchText,
    };
    if (isEditModal.state && isEditModal.uniq_id) {
      const responseData = await taxCatogeryViewAPI({
        CreatedUserID: user_id,
        BranchID: BranchID,
        CompanyID: CompanyID,
        PriceRounding: 4,
        pk: isEditModal.uniq_id,
      });
      if (responseData.StatusCode === 6000) {
        const taxType = taxTypeList.find(
          (tax) => tax.name === responseData.data.TaxType.TaxTypeName
        );

        const taxCatogeryCode = taxCategoryList.find(
          (tax) => tax.TaxExceptionReason === responseData.data.TaxException.TaxExceptionReason
        );

        setUploadData((prevState) => ({
          ...prevState,
          name: responseData.data.TaxName,
          taxTypeName: taxType,
          taxException: taxCatogeryCode,
          generalTax: responseData.data.Tax,
          exciseBrakepointValue: responseData.data.BPValue,
          isExciseLessThanBPPercentage: responseData.data.IsAmountTaxBefore
            ? false
            : true,
          isExciseLessThanBPAmount: responseData.data.IsAmountTaxBefore,
          exciseLessThanBPTax: responseData.data.TaxBefore,
          exciseGreaterOREqualToBPTax: responseData.data.TaxAfter,
          isExciseGreaterOREqualToBPPercentage: responseData.data
            .IsAmountTaxAfter
            ? false
            : true,
          isExciseGreaterOREqualToBPAmout: responseData.data.IsAmountTaxAfter,
        }));
      }
    } else {
      const taxCatogerySearchResponse = await taxSearchQureyAPI({
        CreatedUserID: user_id,
        BranchID: BranchID,
        CompanyID: CompanyID,
        // page_no: pageNo,
        // items_per_page: 15,
        PriceRounding: 4,
      });

      if (taxCatogerySearchResponse.StatusCode === 6000) {
        setListData((prevState) => ({
          ...prevState,
          taxCatogeryList: taxCatogerySearchResponse.data,
        }));
      }
      const productGroupResponse = await ProductGroupAPI({
        CreatedUserID: user_id,
        BranchID: BranchID,
        CompanyID: CompanyID,
      });

      if (productGroupResponse.StatusCode === 6000) {
        setListData((prevState) => ({
          ...prevState,
          productGroupList: productGroupResponse.data,
        }));
      }

      const productResponse = await product_search_new_api({
        CompanyID: CompanyID,
        CreatedUserID: user_id,
        BranchID: BranchID,
        PriceRounding: 2,
        product_name: productWiseUploadData.Search,
        length: 1,
        type: "taxcategory",
        is_product_image: false,
      });

      if (productResponse.StatusCode === 6000) {
        setListData((prevState) => ({
          ...prevState,
          productList: productResponse.data,
        }));
      }

      const taxCatogeryListResponse = await taxCatogeryListAPI(payload);

      if (taxCatogeryListResponse.StatusCode === 6000) {
        setResponse((prevState) => ({
          ...prevState,
          data: taxCatogeryListResponse.data,
          count: taxCatogeryListResponse.count,
        }));
      }
    }
  };

  // Delete Data

  const deleteData = async () => {
    let payload = {
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      selecte_ids: response.selectedItems,
    };
    let responseData = await taxCatogeryDeleteAPI(
      { ...payload },
      ...response.selectedItems
    );

    const defaultItem = response.data.find((item) => item.IsDefault === true);

    const defaultId = defaultItem ? defaultItem.id : null;

    const primaryItem = response.data.find((item) => item.TaxID === 1);
    const primaryItemId = primaryItem ? primaryItem.id : null;

    if (responseData.StatusCode === 6000) {
      if (response.selectedItems.includes(defaultId)) {
        selectDefultTaxCatogery(primaryItemId);
      }

      fetchData();

      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message,
          severity: "success",
        })
      );
    } else {
      fetchData();
      dispatch(
        openSnackbar({
          open: true,
          message: responseData?.message ?? "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  // Create Tax Catogery

  const submitCreate = async (e) => {
    e.preventDefault();
    let payload = {
      Inclusive: false,
      Tax: uploadData.generalTax,
      TaxName: uploadData.name,
      TaxType:
        uploadData.taxTypeName?.name === "General" ||
        uploadData.taxTypeName?.name === undefined
          ? 1
          : 8,
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      BPValue: uploadData.exciseBrakepointValue,
      TaxBefore: uploadData.exciseLessThanBPTax,
      TaxAfter: uploadData.exciseGreaterOREqualToBPTax,
      IsAmountTaxBefore: uploadData.isExciseLessThanBPAmount,
      IsAmountTaxAfter: uploadData.isExciseGreaterOREqualToBPAmout,
      TaxCategoryCode: uploadData.taxException?.value,
      TaxException: uploadData.taxException,
      pk: isEditModal.uniq_id,
    };

    if (isEditModal.state) {
      const responseData = await taxCatogeryUpdateAPI(payload);
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message:
              responseData?.message || "Tax Category edited successfully",
            severity: "success",
          })
        );
        setCreateModal(false);
        setIsEditModal((prevState) => ({ ...prevState, state: false }));
        setUploadData((prevState) => ({
          ...prevState,
          name: "",
          taxTypeName: EnableEinvoiceKSA ? { name: "General" } : null,
          taxException: null,
          generalTax: 0,
          exciseBrakepointValue: 0,
          isExciseLessThanBPPercentage: true,
          isExciseLessThanBPAmount: false,
          exciseLessThanBPTax: 0,
          exciseGreaterOREqualToBPTax: 0,
          isExciseGreaterOREqualToBPPercentage: true,
          isExciseGreaterOREqualToBPAmout: false,
        }));
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    } else {
      const responseData = await taxCatogeryCreateAPI(payload, dispatch);
      setIsEditModal((prevState) => ({
        ...prevState,
        state: false,
      }));

      if (responseData.data.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message:
              responseData.data?.message || "Tax Category Created Successfully",
            severity: "success",
          })
        );
        setCreateModal(false);
        setUploadData((prevState) => ({
          ...prevState,
          name: "",
          taxTypeName: EnableEinvoiceKSA ? { name: "General" } : null,
          taxException: null,
          generalTax: 0,
          exciseBrakepointValue: 0,
          isExciseLessThanBPPercentage: true,
          isExciseLessThanBPAmount: false,
          exciseLessThanBPTax: 0,
          exciseGreaterOREqualToBPTax: 0,
          isExciseGreaterOREqualToBPPercentage: true,
          isExciseGreaterOREqualToBPAmout: false,
        }));
        fetchData();
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData.data?.message ?? "Something went wrong!",
            severity: "error",
          })
        );
      }
    }
  };

  // Close Create Modal

  const onClose = () => {
    setCreateModal(false);
    setIsEditModal((prevState) => ({ ...prevState, state: false }));
    setUploadData((prevState) => ({
      ...prevState,
      name: "",
      taxTypeName: EnableEinvoiceKSA ? { name: "General" } : null,
      taxException: null,
      generalTax: 0,
      exciseBrakepointValue: 0,
      isExciseLessThanBPPercentage: true,
      isExciseLessThanBPAmount: false,
      exciseLessThanBPTax: 0,
      exciseGreaterOREqualToBPTax: 0,
      isExciseGreaterOREqualToBPPercentage: true,
      isExciseGreaterOREqualToBPAmout: false,
    }));
  };

  const selectDefultTaxCatogery = async (id) => {
    let payload = {
      BranchID: BranchID,
      CompanyID: CompanyID,
      id: id,
      value: true,
    };
    const responseData = await setDefultTaxCatogeryAPI(payload);
    if (responseData.StatusCode === 6000) {
      fetchData();
    }
  };

  const handleProductWiseConvert = async (e) => {
    e.preventDefault();
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      TaxTypeFilter: productWiseUploadData.TaxTypeFilter,
      TaxID: productWiseUploadData.TaxID,
      ProductGroupID: productWiseUploadData.ProductGroupID,
      ProductID: productWiseUploadData.ProductID,
      all_product: productWiseUploadData.all_product,
      product_wise: productWiseUploadData.product_wise,
      group_wise: productWiseUploadData.group_wise,
      dropDwn_type: productWiseUploadData.dropDwn_type,
      FromTaxID: productWiseUploadData.FromTaxID,
      ToTaxID: productWiseUploadData.ToTaxID,
    };

    const productWiseConvertResponse = await taxConversionAPI(payload);

    if (productWiseConvertResponse.StatusCode === 6000) {
      dispatch(
        openSnackbar({
          open: true,
          message:
            productWiseConvertResponse.data?.message ||
            "Product Tax Converted Successfully",
          severity: "success",
        })
      );
      setProductWiseModal(false);
      setTaxCatogeryWiseModal(false);
      setProductWiseUploadData((prevState) => ({
        ...prevState,
        TaxTypeFilter: "no_change",
        TaxID: "",
        ProductGroupID: "",
        ProductID: "",
        all_product: true,
        product_wise: false,
        group_wise: false,
        dropDwn_type: "0",
        FromTaxID: "",
        ToTaxID: "",
      }));
    }
  };

  const closeConvertModal = (value) => {
    if (value === 1) {
      setProductWiseModal(false);
    }
    if (value === 2) {
      setTaxCatogeryWiseModal(false);
    }
    setProductWiseUploadData((prevState) => ({
      ...prevState,
      TaxTypeFilter: "no_change",
      TaxID: "",
      ProductGroupID: "",
      ProductID: "",
      all_product: true,
      product_wise: false,
      group_wise: false,
      dropDwn_type: "0",
      FromTaxID: "",
      ToTaxID: "",
    }));
  };

  useEffect(() => {
    fetchData();
  }, [pageNo, isEditModal.state, productWiseUploadData.Search, searchText]);
  return (
    <>
      <Paper sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            Tax Category
          </Typography>
          <SearchTextField
            value={searchText}
            escClearState={setSearchText}
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
              pageNo !== 1 && setPageNo(1)
            }}
            />
          </FlexBox>
       
          <Box sx={{ display: "flex" }}>
            <DeleteButton t={t} onClick={() => setConfirmBox(true)} />

            {/* ===================== Convert button ======================== */}

            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
              startIcon={<img src="../images/convert_button_icon.svg" alt="" />}
            >
              Convert
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem onClick={() => handleClose(1)}>Product Wise</MenuItem>
              <MenuItem onClick={() => handleClose(2)}>
                Tax Catogery Wise
              </MenuItem>
            </Menu>
            {/* ==================================================================== */}

            <CreateNewButton
              t={t}
              onClick={() => {
                if (createPermission) {
                  setCreateModal(true);
                } else {
                  dispatch(
                    openSnackbar({
                      open: true,
                      message: "You don't have permission for create",
                      severity: "warning",
                    })
                  );
                }
              }}
            />
            <Pagenation
              totalItem={response.count}
              page_no={pageNo}
              setPage_no={setPageNo}
            />
          </Box>
        </Box>
        <VBTableContainer>
          <VBTableHeader>
            <VBTableCellHeader
              sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
            >
              <Checkbox
                checked={response.isSelectedAll}
                onChange={() => {
                  selectItems("all");
                }}
                sx={{
                  padding: "2px",
                  margin: 0,
                  color: blue[400],
                  "&.Mui-checked": {
                    color: blue[400],
                  },
                  "& .MuiSvgIcon-root": { fontSize: "20px" },
                }}
              />
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Serial No")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Tax Name")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Tax Type")}
            </VBTableCellHeader>{" "}
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Tax")}
            </VBTableCellHeader>{" "}
            <VBTableCellHeader sx={{ textAlign: "left" }}>
              {t("Default")}
            </VBTableCellHeader>
            <VBTableCellHeader> </VBTableCellHeader>
          </VBTableHeader>
          <TableBody>
            {response.data.map((item, index) => (
              <TableRow key={index}>
                <VBTableCellBody
                  sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
                >
                  <Checkbox
                    checked={
                      response.selectedItems.includes(item.id) ? true : false
                    }
                    onChange={() => {
                      selectItems("not", item.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {(pageNo - 1) * appSetting.itemPerPage + index + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {item.TaxName}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {item.TaxType}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  {item.Tax == 0 ? 0 : item.Tax}
                </VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "left" }}>
                  <Checkbox
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                    onClick={() => selectDefultTaxCatogery(item.id)}
                    checked={item.IsDefault}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer", textAlign: "right" }}>
                  <MenuOptions
                    OptionList={optionList}
                    OptionOnClick={optionOnClick}
                    uniq_id={item.id}
                    disable={item.TaxName === "None" ? true : false}
                    Taxcategory={true}
                  />
                </VBTableCellBody>
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>
        </VBTableContainer>
        <ConfirmBox
          heading={"Confirm to delete?"}
          message={"Once you delete, you will not get it back!"}
          open={confirmBox}
          setOpen={setConfirmBox}
          confirmFunction={deleteData}
        />
      </Paper>

      {/* ==================================== Create and Edit Modal============================== */}

      <Modal
        open={createModal}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={submitCreate}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "2px solid #F5F5F5",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                {isEditModal.state ? "Edit Tax Category" : "Add Tax Category"}
              </Typography>
              <CloseIcon sx={{ cursor: "pointer" }} onClick={onClose} />
            </Box>
            <Grid container spacing={2} sx={{ marginBottom: 2 }}>
              <Grid item xs={6}>
                <VBInputField
                  label="Name"
                  required
                  disabled={uploadData.name === "None"}
                  value={uploadData.name}
                  onChange={(e) => {
                    const trimmedValue = e.target.value.trimStart();
                    setUploadData((prevState) => ({
                      ...prevState,
                      name: trimmedValue,
                    }));
                  }}
                />
              </Grid>
              <>
                <Grid item xs={6}>
                  <ViknAutoComplete
                    disabled={EnableEinvoiceKSA}
                    label="Tax Type"
                    required
                    placeholder={"Type Here"}
                    options={taxTypeList}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) =>
                      option.name === value?.name
                    }
                    onChange={(event, newValue) =>
                      setUploadData((prevState) => ({
                        ...prevState,
                        taxTypeName: newValue,
                      }))
                    }
                    value={uploadData.taxTypeName}
                  />
                </Grid>
                {EnableEinvoiceKSA && (
                  <Grid item xs={6}>
                    <ViknAutoComplete
                      required={EnableEinvoiceKSA}
                      placeholder="Type Here"
                      label="Tax Category Code"
                      disabled={
                        (isEditModal.state && EnableEinvoiceKSA) && uploadData.taxException?.value === "S"
                      }
                      componentsProps={{
                        popper: { style: { width: "600px" } },
                      }}
                      options={
                        isEditModal.state && EnableEinvoiceKSA
                          ? uploadData?.taxException?.value === "Z"
                            ? taxCategoryList.filter(
                                (item) => item.value === "Z"
                              )
                            : uploadData.taxException?.value === "E"
                            ? taxCategoryList.filter(
                                (item) => item.value === "E"
                              )
                            : taxCategoryList
                          : taxCategoryList
                      }
                      getOptionLabel={(option) => option.name}
                      value={uploadData.taxException}
                      renderOption={(props, option) => {
                        const { key, ...optionProps } = props;
                        return (
                          <Box
                            key={key}
                            {...optionProps}
                            component="li"
                            sx={{ display: "flex" }}
                            gap="16px"
                          >
                            <Box sx={{ display: "flex" }}>
                              <Box
                                sx={{
                                  fontSize: "14px",
                                  color: "#797979",
                                  mr: 2,
                                }}
                              >
                                {option.value}
                              </Box>
                              <Box sx={{ fontSize: "14px" }}>{option.name}</Box>
                            </Box>

                            <Box sx={{ fontSize: "14px" }}>
                              {option.TaxExceptionReason}
                            </Box>
                            <Box sx={{ fontSize: "14px" }}>
                              {option.TaxExceptionReasonSecondLanguage}
                            </Box>
                          </Box>
                        );
                      }}
                      onChange={(event, newValue) => {
                        if (newValue){
                          const { value } = newValue;
                          let tax = 0;
                          if (value === "S") {
                            tax = 15;
                          }
                          setUploadData((prevState) => ({
                            ...prevState,
                            taxException: newValue,
                            generalTax: tax,
                          }));
                        }
                      }}
                      // isOptionEqualToValue={(option, value) =>
                      //   option.id === value.id
                      // }
                    />
                  </Grid>
                )}
              </>
              {uploadData.taxTypeName === null ||
              uploadData.taxTypeName?.name === "General" ? (
                <Grid item xs={6}>
                  <VBInputField
                    type="number"
                    label="Tax"
                    disabled={
                      EnableEinvoiceKSA &&
                      (uploadData.taxException?.value === "S" ||
                        uploadData.taxException?.value === "Z")
                    }
                    value={uploadData.generalTax}
                    onChange={(e) => {
                      const value = e.target.value;

                      if (value >= 0) {
                        setUploadData((prevState) => ({
                          ...prevState,
                          generalTax: e.target.value,
                        }));
                      }
                    }}
                  />
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <VBInputField
                    type="number"
                    label="Breakpoint (BP) Value"
                    value={uploadData.exciseBrakepointValue}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value >= 0) {
                        setUploadData((prevState) => ({
                          ...prevState,
                          exciseBrakepointValue: e.target.value,
                        }));
                      }
                    }}
                  />
                </Grid>
              )}
              {!EnableEinvoiceKSA && <Grid xs={6} />}
              {uploadData.taxException &&
                uploadData.taxTypeName?.name === "General" &&
                uploadData.taxException.value !== "S" &&
                Country === SaudiCountryID && (
                  <>
                    <Grid item xs={6}>
                      <VBInputField
                        disabled={uploadData.taxException.value !== "O"}
                        label="Tax exemption reason"
                        required
                        value={uploadData.taxException.TaxExceptionReason}
                        onChange={(e) =>
                          setUploadData((prevState) => ({
                            ...prevState,
                            taxException: {
                              ...prevState.taxException,
                              TaxExceptionReason: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <VBInputField
                        disabled={uploadData.taxException.value !== "O"}
                        label="Tax exemption reason Arabic"
                        required
                        value={
                          uploadData.taxException
                            .TaxExceptionReasonSecondLanguage
                        }
                        onChange={(e) =>
                          setUploadData((prevState) => ({
                            ...prevState,
                            taxException: {
                              ...prevState.taxException,
                              TaxExceptionReason: e.target.value,
                            },
                          }))
                        }
                      />
                    </Grid>
                  </>
                )}

              {uploadData.taxTypeName?.name === "Excise" && (
                <>
                  <Grid item xs={6}>
                    <Box>
                      <Typography sx={{ fontSize: "15px" }}>
                        Less than BP
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                          label="Percentage"
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                color: blue[400],
                                "&.Mui-checked": {
                                  color: blue[400],
                                },
                                "& .MuiSvgIcon-root": { fontSize: "20px" },
                              }}
                              checked={uploadData.isExciseLessThanBPPercentage}
                              onChange={() =>
                                setUploadData((prevState) => ({
                                  ...prevState,
                                  isExciseLessThanBPPercentage: true,
                                  isExciseLessThanBPAmount: false,
                                }))
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                          label="Amount"
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                color: blue[400],
                                "&.Mui-checked": {
                                  color: blue[400],
                                },
                                "& .MuiSvgIcon-root": { fontSize: "20px" },
                              }}
                              checked={uploadData.isExciseLessThanBPAmount}
                              onChange={() =>
                                setUploadData((prevState) => ({
                                  ...prevState,
                                  isExciseLessThanBPPercentage: false,
                                  isExciseLessThanBPAmount: true,
                                }))
                              }
                            />
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <VBInputField
                      type="number"
                      label="Tax"
                      value={uploadData.exciseLessThanBPTax}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setUploadData((prevState) => ({
                            ...prevState,
                            exciseLessThanBPTax: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box>
                      <Typography sx={{ fontSize: "15px" }}>
                        Greater/equal to BP
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <FormControlLabel
                          sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                          label="Percentage"
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                color: blue[400],
                                "&.Mui-checked": {
                                  color: blue[400],
                                },
                                "& .MuiSvgIcon-root": { fontSize: "20px" },
                              }}
                              checked={
                                uploadData.isExciseGreaterOREqualToBPPercentage
                              }
                              onClick={() =>
                                setUploadData((prevState) => ({
                                  ...prevState,
                                  isExciseGreaterOREqualToBPPercentage: true,
                                  isExciseGreaterOREqualToBPAmout: false,
                                }))
                              }
                            />
                          }
                        />
                        <FormControlLabel
                          sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                          label="Amount"
                          control={
                            <Checkbox
                              size="small"
                              sx={{
                                color: blue[400],
                                "&.Mui-checked": {
                                  color: blue[400],
                                },
                                "& .MuiSvgIcon-root": { fontSize: "20px" },
                              }}
                              checked={
                                uploadData.isExciseGreaterOREqualToBPAmout
                              }
                              onClick={() =>
                                setUploadData((prevState) => ({
                                  ...prevState,
                                  isExciseGreaterOREqualToBPPercentage: false,
                                  isExciseGreaterOREqualToBPAmout: true,
                                }))
                              }
                            />
                          }
                        />
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <VBInputField
                      type="number"
                      label="Tax"
                      value={uploadData.exciseGreaterOREqualToBPTax}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value >= 0) {
                          setUploadData((prevState) => ({
                            ...prevState,
                            exciseGreaterOREqualToBPTax: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Grid>
                </>
              )}
            </Grid>
            <BlueButton sx={{ px: 3, width: "100%" }} type="submit">
              Save
            </BlueButton>
          </form>
        </Box>
      </Modal>

      {/* ======================= Product Wise Modal ======================= */}

      <Modal
        open={productWiseModal}
        onClose={() => closeConvertModal(1)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={convertModalStyle}>
          <form onSubmit={handleProductWiseConvert}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "2px solid #F5F5F5",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                Product Wise
              </Typography>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => closeConvertModal(1)}
              />
            </Box>

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="All Product"
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                    checked={
                      productWiseChecked === "All Product" ? true : false
                    }
                    onClick={() => {
                      setProductWiseChecked("All Product");
                      setProductWiseUploadData((prevState) => ({
                        ...prevState,
                        all_product: true,
                        product_wise: false,
                        group_wise: false,
                      }));
                    }}
                  />
                }
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Product"
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                    checked={productWiseChecked === "Product" ? true : false}
                    onClick={() => {
                      setProductWiseChecked("Product");
                      setProductWiseUploadData((prevState) => ({
                        ...prevState,
                        all_product: false,
                        product_wise: true,
                        group_wise: false,
                      }));
                    }}
                  />
                }
              />
              <FormControlLabel
                sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                label="Product Group"
                control={
                  <Checkbox
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                    checked={
                      productWiseChecked === "Product Group" ? true : false
                    }
                    onClick={() => {
                      setProductWiseChecked("Product Group");
                      setProductWiseUploadData((prevState) => ({
                        ...prevState,
                        all_product: false,
                        product_wise: false,
                        group_wise: true,
                      }));
                    }}
                  />
                }
              />
              {productWiseChecked === "Product" && (
                <ViknAutoComplete
                  label="Product"
                  placeholder="Type Here"
                  required
                  options={listData.productList}
                  getOptionLabel={(option) => option.ProductName}
                  onChange={(event, newValue) =>
                    setProductWiseUploadData((prevState) => ({
                      ...prevState,
                      ProductID: newValue?.ProductID,
                    }))
                  }
                  onInputChange={(event, newValue) =>
                    setProductWiseUploadData((prevState) => ({
                      ...prevState,
                      Search: newValue,
                    }))
                  }
                />
              )}

              {productWiseChecked === "Product Group" && (
                <ViknAutoComplete
                  label="Product Group"
                  placeholder="Type Here"
                  required
                  options={listData.productGroupList}
                  getOptionLabel={(option) => option.GroupName}
                  onChange={(event, newValue) =>
                    setProductWiseUploadData((prevState) => ({
                      ...prevState,
                      ProductGroupID: newValue?.ProductGroupID,
                    }))
                  }
                />
              )}

              <ViknAutoComplete
                label="Tax Category"
                placeholder="Type Here"
                required
                options={listData.taxCatogeryList}
                getOptionLabel={(option) => option.TaxName}
                onChange={(event, newValue) =>
                  setProductWiseUploadData((prevState) => ({
                    ...prevState,
                    TaxID: newValue?.TaxID,
                  }))
                }
              />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                defaultValue="no_change"
                name="radio-buttons-group"
                onChange={(e) =>
                  setProductWiseUploadData((prevState) => ({
                    ...prevState,
                    TaxTypeFilter: e.target.value,
                  }))
                }
              >
                <FormControlLabel
                  value="no_change"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                      }}
                    />
                  }
                  label="No Change"
                />
                <FormControlLabel
                  value="inclusive"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                      }}
                    />
                  }
                  label="Inclusive"
                />
                <FormControlLabel
                  value="exclusive"
                  control={
                    <Radio
                      size="small"
                      sx={{
                        color: blue[400],
                        "&.Mui-checked": {
                          color: blue[400],
                        },
                      }}
                    />
                  }
                  label="Exclusive"
                />
              </RadioGroup>
            </Box>

            <BlueButton sx={{ px: 3, width: "100%" }} type="submit">
              Convert
            </BlueButton>
          </form>
        </Box>
      </Modal>

      {/* ===================== Tax Catogery Wise Modal ======================== */}

      <Modal
        open={taxCatogeryWiseModal}
        onClose={() => closeConvertModal(2)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={convertModalStyle}>
          <form onSubmit={handleProductWiseConvert}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
                borderBottom: "2px solid #F5F5F5",
              }}
            >
              <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
                Tax Category Wise
              </Typography>
              <CloseIcon
                sx={{ cursor: "pointer" }}
                onClick={() => closeConvertModal(2)}
              />
            </Box>
            <ViknAutoComplete
              label="From"
              placeholder="Type Here"
              required
              options={listData.taxCatogeryList}
              getOptionLabel={(option) => option.TaxName}
              onChange={(event, newValue) =>
                setProductWiseUploadData((prevState) => ({
                  ...prevState,
                  FromTaxID: newValue?.TaxID,
                }))
              }
            />
            <ViknAutoComplete
              label="To"
              placeholder="Type Here"
              required
              options={listData.taxCatogeryList}
              getOptionLabel={(option) => option.TaxName}
              onChange={(event, newValue) =>
                setProductWiseUploadData((prevState) => ({
                  ...prevState,
                  ToTaxID: newValue?.TaxID,
                  dropDwn_type: 1,
                }))
              }
            />
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="no_change"
              name="radio-buttons-group"
              onChange={(e) =>
                setProductWiseUploadData((prevState) => ({
                  ...prevState,
                  TaxTypeFilter: e.target.value,
                }))
              }
            >
              <FormControlLabel
                value="no_change"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                    }}
                  />
                }
                label="No Change"
              />
              <FormControlLabel
                value="inclusive"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                    }}
                  />
                }
                label="Inclusive"
              />
              <FormControlLabel
                value="exclusive"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                    }}
                  />
                }
                label="Exclusive"
              />
            </RadioGroup>
            <BlueButton sx={{ px: 3, width: "100%" }} type="submit">
              Convert
            </BlueButton>
          </form>
        </Box>
      </Modal>
    </>
  );
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

const convertModalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 350,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

export default TaxCategory;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
