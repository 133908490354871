import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
// import LoaderLite from "./LoaderLite";
import Lottie from "react-lottie";
import loader from "../lotties/loader.json";

function PaperLoading({ isLoading, children, message }) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: loader,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getLottieStyle = () => ({
    width: "100px",
    // maxHeight: "100px",
    // margin: "0px",
    height: "100px",
    cursor: "unset",
    margin: "auto",
  });

  return isLoading ? (
    <Box sx={{ width: "100%", height: "80%", alignContent: "center" }}>
      <Lottie
        options={defaultOptions}
        isClickToPauseDisabled={true}
        style={getLottieStyle()}
      />
      <Typography
        sx={{ fontSize: "16px", fontWeight: "600", textAlign: "center" }}
      >
        {message}
      </Typography>
    </Box>
  ) : (
    children
  );
}

export default PaperLoading;

PaperLoading.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.element,
  message: PropTypes.string,
};

PaperLoading.defaultProps = {
  isLoading: false,
  message: "Fetching data, Please wait...",
};
