import {
  Box,
  Chip,
  Divider,
  List,
  ListItem,
  Paper,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import FilterBtn from "../../../../Components/Utils/FilterBtn";
import ExportBtn from "../../../../Components/Utils/ExportBtn";
import Pagenation from "../../../../Components/Utils/Pagenation";
import ViknDrawer from "../../../../Components/ViknDrawer/ViknDrawer";
import VBDatePiker from "../../../../Components/Utils/VBDatePiker";
import VBInputField from "../../../../Components/Utils/VBInputField";
import VBSelect from "../../../../Components/Utils/VBSelect";
import ViknRadioGroup from "../../../../Components/Utils/ViknRadioGroup";
import ConsolidateTable from "./Components/ConsolidateTable";
import DebitorTable from "./Components/DebitorTable";
import moment from "moment";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { date_range } from "../../../../Function/comonFunction";
import FilterLable from "../../../../Components/Utils/FilterLable";
import { CallBalanceSheet } from "../../../../Api/Reports/FinanceApi";
import { useSelector } from "react-redux";
import ExportToExcelButton from "../../../../Components/Utils/ExportToExcelButton";
import { VerticalTable } from "./Components/VerticalTable";
import { useTranslation } from "react-i18next";
import { UserRolePermission } from "../../../../Function/Editions";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { CallBranchsList, CallBrands } from "../../../../Api/Reports/CommonReportApi";

const BalanceSheet = () => {
  let Export_view = UserRolePermission("Balance Sheet", "print_permission");
  //Set Variables
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const [t] = useTranslation("common");
  const {CompanyID,BranchID,} = useSelector((state) => state.companyDetails);
  const {PriceDecimalPoint,EnableBranch} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { user_id, } = useSelector((state) => state.user);
  const [disableArray, setDisableArray] = useState([]);
  const [isFilterApisComplete, setIsFilterApisComplete] = useState(false);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState({
    FromDate: moment(),
    ToDate: moment(),
    select: "AVERAGE",
    position: "Horizontal",
    dateRange: "Today",
    tableType: ["Horizontal", "Vertical"],
    Branches:[]
  });
  const [filterData, setFilterData] = useState({
    total_rows: 0,
    switchReport: true,
    consolidateList: [],
    debitorList: [],
    BranchList:[],
    IsSpecificBranchID :0,
    IsSpecific : false,
  });
  const [isLoading, setIsLoading] = useState(false)

  //Handle Functions
  const onFilter = function () {
    fetchReport();
  };

  const handleDefaultFilter = function() {
    setFilterOptions({
      FromDate: moment(),
      ToDate: moment(),
      select: "AVERAGE",
      position: "Horizontal",
      dateRange: "Today",
      tableType: ["Horizontal", "Vertical"],
    });
  }

  const handleAutoComplete = function (e, value, name) {
    if (value) {
      if (name === "Branches") {
        let BranchIds = value.map((e) => e.BranchID);
        setFilterOptions({
          ...filterOptions,
          Branches: value,
          BranchIds: BranchIds,
        });
      } else {
        setFilterOptions({
          ...filterOptions,
          [name]: value,
        });
      }
    }
  };

  const handleBranchList = async function () {
    const BranchList = await CallBranchsList({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      show_all: true,
    });
    if (BranchList.StatusCode === 6000) {
      let Branches = [];
      let BranchIdList = BranchList.data.filter((i, e) => {
        if (i.BranchID === BranchID) {
          return i;
        }
      });
      Branches.push(BranchIdList[0]);
      setFilterOptions({
        ...filterOptions,
        Branches,
      });
      setFilterData((prev) => ({
        ...prev,
        BranchList: BranchList.data,
      }));
    }
  }

  const DisabledBranches = function (newValue) {
    let indices = filterData?.BranchList.reduce((acc, obj, i) => {
      if (
        newValue[newValue?.length - 1]?.IsProductSpecific ||
        (newValue?.length !== 0 &&
          !newValue[newValue?.length - 1]?.IsProductSpecific ===
            obj.IsProductSpecific)
      ) {
        acc.push(i);
      }
      return acc;
    }, []);
    let BranchIdS = newValue[newValue?.length - 1]?.IsProductSpecific
    if (BranchIdS){
      setFilterData((prev) => ({
        ...prev,
        IsSpecificBranchID:newValue[0].BranchID,
        IsSpecific:true,
      }));
    } else {
      setFilterData((prev) => ({
        ...prev,
        IsSpecific:false,
      }));
    }
    // let indices = filterData.BranchList
    setDisableArray(indices);
  };

  const handleValidation = function () {
    let isValid = true;
    if (filterOptions.Branches.length === 0) {
      isValid = false;
    }
    return isValid;
  };

  // Call API
  const fetchReport = async function () {
    let valid = handleValidation()
    if(valid){
      setIsLoading(true)
      const res = await CallBalanceSheet({
        FromDate: filterOptions.FromDate.format("YYYY-MM-DD"),
        ToDate: filterOptions.ToDate.format("YYYY-MM-DD"),
        ReportTpe: filterData.switchReport ? 1 : 2,
        filterMethod: filterOptions.select,
        balancePageType: filterOptions.position,
        CompanyID: CompanyID,
        BranchID:filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
        PriceRounding : Number(PriceDecimalPoint),
        CreatedUserID: user_id,
      });
      if (res.StatusCode === 6000) {
        setFilterData({
          ...filterData,
          consolidateList: res.data[0],
          debitorList: res.data[1],
        });
      }
      const brands = await CallBrands({
        CompanyID: CompanyID,
        BranchID: filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID,
        PriceRounding: Number(PriceDecimalPoint),
        CreatedUserID: user_id
      });
      if (brands.StatusCode === 6000) {
        setFilterData((prev) => ({ ...prev, brandList: brands.data }));
      }
      setIsLoading(false)
    }
  };

  useEffect(() => {
    fetchReport();
  }, [filterOptions.position]);

  useEffect(() => {
    const runFilterApis = async () => {
      await handleBranchList();
      setIsFilterApisComplete(true);
    };
    onFilter();
    runFilterApis();
  }, []);

  useEffect(() => {
    DisabledBranches(filterOptions.Branches)
    onFilter()
  }, [filterOptions.Branches])
  
  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{ color:"#001746",  fontSize: "23px", fontWeight: "500" ,mr:2}}
              variant="h2"
            >
              {t("Balance Sheet")}
            </Typography>
            {/* <SearchReport onClick={() => searchReport()} /> */}
            <FilterBtn onClick={() => setOpenFilter(true)} />
          </FlexBox>

          <FlexBox className="right">
          {Export_view ? 
          <ExportToExcelButton
            FromDate={filterOptions.FromDate.format("YYYY-MM-DD")}
            ToDate={filterOptions.ToDate.format("YYYY-MM-DD")}
            // UserID={user_id}
            report_type={"balance_sheet"}
            download_type={"PDF"}
            tab={filterData.switchReport ? 1 : 2}
            VoucherType={filterOptions.select}
            PageType={filterOptions.position}
            BranchID={filterData.IsSpecific ? filterData.IsSpecificBranchID : BranchID}
            // new_data={tabSwitch === 1?state.data:tabSwitch==2?state.data1:null}
          />
          : null}
            {/* <ExportBtn /> */}
            {/* <PrintBtn /> */}
            {/* <Pagenation
              totalItem={filterData.total_rows}
              page_no={page_no}
              setPage_no={setPage_no}
            /> */}
          </FlexBox>
        </Box>
        {/* Filter Area  */}
        <Box
          sx={{
            px: "26px",
            py: "10px" ,overflowX:"scroll",
            // height: "54px",
            display: "flex",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{color:"#001746", fontSize:"15px",fontWeight:"400",marginRight:"5px", display:'flex' , alignItems:'center',whiteSpace:"nowrap"}}>
            {t("Applied Filters: ")}
          </div>
          <FilterLable
            name={t("Date Range")}
            value={
              filterOptions.dateRange === "Custom"
                ? filterOptions.FromDate.format("DD/MM/YYYY") +
                  " - " +
                  filterOptions.ToDate.format("DD/MM/YYYY")
                : filterOptions.dateRange
            }
          />
          <FilterLable name={t("Filter By")} value={filterOptions.select} />
          <FilterLable name={t("Table Type")} value={filterOptions.position} />
          {EnableBranch && BranchID === 1 && filterOptions.Branches.length !== 0 && <FilterLable
            name={t("Branch Name")}
            value={filterOptions.Branches?.map((e, i)=> 
              <Chip 
                key={i} 
                label={e.NickName} 
                variant="outlined" 
                sx={{ p: "1px", fontSize: "10px", height: "22px", mr: "4px", 
                  ".MuiChip-label": {
                    px: '8px',
                  },
                  ".MuiSvgIcon-root": {
                    fontSize: "15px",
                    color: "#ff00006b",
                  }
                }}
                onDelete={() =>  
                  setFilterOptions({
                    ...filterOptions,
                    Branches: filterOptions.Branches.filter(l => l.BranchID !== e.BranchID)
                  })
                }
              />
              )}
              handleDelete={() => setFilterOptions({...filterOptions, Branches: []})}
            />
          }
        </Box>

        {/* Report Table */}
        {filterOptions.position !== "Vertical" && <>
          {filterData.switchReport ? (
            <ConsolidateTable reportData={filterData.consolidateList} tableType={filterOptions.position} isLoading={isLoading} />
          ) : (
            <DebitorTable reportData={filterData.debitorList} tableType={filterOptions.position} isLoading={isLoading} />
          )}
        </>}
        {filterOptions.position === "Vertical" && <VerticalTable reportData1={filterData.consolidateList} reportData2={filterData.debitorList} />}
      </Paper>

      <ViknDrawer
        open={openFilter}
        handleClose={() => setOpenFilter(false)}
        onFilter={onFilter}
        handleDefaultFilter={handleDefaultFilter}
        filterCompnt={
          <List sx={{ pt: "58px" }}>
            <ListItem>
              <VBDatePiker
                fromDate={filterOptions.FromDate}
                toDate={filterOptions.ToDate}
                fromOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    FromDate: value,
                    dateRange: "Custom"
                  })
                }
                toOnChange={(value) =>
                  setFilterOptions({
                    ...filterOptions,
                    ToDate: value,
                    dateRange: "Custom"
                  })
                }
                // fromReadOnly={filterOptions.dateRange !== "Custom"}
                // toReadOnly={filterOptions.dateRange !== "Custom"}
              />
            </ListItem>

            <ListItem>
              <VBSelect
                value={filterOptions.dateRange}
                options={[
                  "Custom",
                  "Today",
                  "This Week",
                  "This Month",
                  "Last 90 Days",
                  "This Year",
                  "Last Year",
                ]}
                handleChange={(e) =>
                  setFilterOptions({
                    ...filterOptions,
                    dateRange: e.target.value,
                    FromDate: date_range(e.target.value).fromdate,
                    ToDate: date_range(e.target.value).todate,
                  })
                }
              />
            </ListItem>
            <Divider />

            <ListItem>
              <VBSelect
                label={t("Filter")}
                value={filterOptions.select}
                options={["AVERAGE", "LIFO", "FIFO"]}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, select: e.target.value })
                }
              />
            </ListItem>

            <ListItem>
              <VBSelect
                value={filterOptions.position}
                options={["Vertical", "Horizontal"]}
                handleChange={(e) =>
                  setFilterOptions({ ...filterOptions, position: e.target.value })
                }
              />
            </ListItem>
            <Divider />

              
            {filterOptions.position !== "Vertical" && <ListItem>
              <ViknRadioGroup
                value={filterData.switchReport}
                radios={[
                  {
                    value: true,
                    label: "Consolidate",
                  },
                  {
                    value: false,
                    label: "Detailed",
                  },
                ]}
                handleChange={(e) =>
                  setFilterData((prev) => ({
                    ...prev,
                    switchReport: "true" === e.target.value,
                  }))
                }
              />
            </ListItem>}
            {EnableBranch && BranchID === 1 && (
              <>
              <ListItem sx={{display : 'flex',flexDirection:'column'}}>
                <span style={{ display: "flex", alignItems: "center",justifyContent:'end',width:'100%',padding:'2px'}}>
                  <Tooltip title="Select branches for your search. Specific branches allow one selection; non-specific branches allow multiple selections.">
                    <InfoOutlinedIcon style={{ color: "#066385", fontSize: "18px "}} />
                  </Tooltip>
                </span>
                <ViknAutoComplete
                  multiple
                  value={filterOptions.Branches}
                  placeholder={"Select a Branch"}
                  options={filterData.BranchList}
                  getOptionLabel={(option) => option.NickName}
                  onChange={(e, newValue) => {
                    handleAutoComplete(e, newValue, "Branches");
                    DisabledBranches(newValue);
                  }}
                  getOptionDisabled={(option) =>
                    disableArray.includes(filterData.BranchList.indexOf(option))
                  }
                  error={filterOptions?.Branches.length === 0 ? true : false}
                />
              </ListItem>
              </>
            )}
          </List>
        }
      />
    </>
  );
};

export default BalanceSheet;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "42%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
