import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { colors, TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

const ConsolidateTable = (props) => {
  const [t, i18n] = useTranslation("common");

  return (
    <VBTableContainer>
    <VBTableHeader>
      {/* <VBTableCellHeader colSpan={2}>{t("Expense")} </VBTableCellHeader>
      <VBTableCellHeader colSpan={2}>{t("Income")} </VBTableCellHeader> */}
    </VBTableHeader>
    {props.isLoading ? <VBSkeletonLoader /> 
    : props?.filterOptions?.filterType === 'horizontal' ? 

    <TableBody>
      <TableRow>

        <VBTableCellBody sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> TOTAL</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}}>PARTICULAR</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right"}}>SUB TOTAL</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right" }}> TOTAL</VBTableCellBody>

       
      </TableRow>
      {(props?.filterOptions?.filterValue === 'All' || props?.filterOptions?.filterValue === 'TradingAccount') && props.data?.Tradingaccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence?.name === "Total" ? 'red': e.expence?.name === "Gross Profit" ? "green" :"auto", fontWeight: e.expence?.name === "Total" ? "600" : "400" }} > {e.expence?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
          <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right",color:e.expence?.name === "Gross Profit" ? "green" :"auto" }}> {formatNumber(e.expence?.balance)}</VBTableCellBody>
          <VBTableCellBody sx={{ color: e.expence?.name === "Total" ? 'red': "auto", fontWeight: e.expence?.name === "Total"? "600" : "400" }}> {e.income?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right", color : e.expence?.name === "Gross Profit" ? "green" :"auto"}}>{formatNumber(e.income?.balance)}</VBTableCellBody>
        </TableRow>
      ))}
      {(props?.filterOptions?.filterValue === 'All' || props?.filterOptions?.filterValue === 'ProfitAndLossAccount') && props.data?.ProfitAndLossAccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence?.name === "Total" ? 'red': e.expence?.name === "Net Profit" ? "green" :"auto", fontWeight: e.expence?.name === "Total" ? "600" : "400" }} > {e.expence?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}> </VBTableCellBody>
          <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right",color:e.expence?.name === "Net Profit" ? "green" :"auto" }}> {formatNumber(e.expence?.balance)}</VBTableCellBody>
          <VBTableCellBody sx={{ color: e.expence?.name === "Total" ? 'red': "auto", fontWeight: e.expence?.name === "Total"? "600" : "400" }}> {e.income?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right", color : e.expence?.name === "Net Profit" ? "green" :"auto"}}>{formatNumber(e.income?.balance)}</VBTableCellBody>
        </TableRow>
      ))}
      <TableRow></TableRow>
    </TableBody>
    : 
    <TableBody>
      <TableRow>

        <VBTableCellBody sx={{ fontWeight: "bold"}} >PARTICULAR</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold" ,textAlign: "right"}} >SUB TOTAL</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right"}}> TOTAL</VBTableCellBody>      
        <VBTableCellBody > </VBTableCellBody>      
        <VBTableCellBody > </VBTableCellBody>      
        <VBTableCellBody > </VBTableCellBody>      
      </TableRow>

      {/* looping Trading Account incomes */}
      {(props?.filterOptions?.filterValue === 'All' || props?.filterOptions?.filterValue === 'TradingAccount') && props.data?.Tradingaccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.income?.name === "Total" ? 'red': "auto", fontWeight: e.income?.name === "Total" || e.income?.name === "Cost Of Goods Sold" || e.income?.name === "Less COS" || e.income?.name === "Add Other Income" || e.income?.name === "Less Operating Expenses" ? "600" : "400" }}> {e.income?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.income?.sub_total)}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right", color : e.expence?.name === "Gross Profit" ? "green" :"auto"}}>{formatNumber(e.income?.balance)}</VBTableCellBody>
          <VBTableCellBody > </VBTableCellBody>  
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>   
        </TableRow>
      ))}

      {/* looping Trading Account expences */}
      {(props?.filterOptions?.filterValue === 'All' || props?.filterOptions?.filterValue === 'TradingAccount') && props.data?.Tradingaccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence?.name === "Total" ? 'red': e.expence?.name === "Gross Profit" ? "green" :"auto", fontWeight: e.expence?.name === "Total" || e.expence?.name === "Cost Of Goods Sold" || e.expence?.name === "Less COS" || e.expence?.name === "Add Other Income" || e.expence?.name === "Less Operating Expenses"  ? "600" : "400" }} > {e.expence?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}>  {formatNumber(e.expence?.sub_total)}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right",color:e.expence?.name === "Gross Profit" ? "green" :"auto" }}> {formatNumber(e.expence?.balance)}</VBTableCellBody>
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>   
        </TableRow>
      ))}

      {/* looping ProfitAndLossAccount incomes */}
      {(props?.filterOptions?.filterValue === 'All' || props?.filterOptions?.filterValue === 'ProfitAndLossAccount') && props.data?.ProfitAndLossAccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.income?.name === "Total" ? 'red': e.income?.name === "Gross Profit" ? "green" :  "auto", fontWeight: e.income?.name === "Total" || e.income?.name === "Cost Of Goods Sold" || e.income?.name === "Less COS" || e.income?.name === "Add Other Income" || e.income?.name === "Less Operating Expenses" ? "600" : "400" }}> {e.income?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}>  {formatNumber(e.income?.sub_total)}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right",color:e.income?.name === "Net Profit" || e.income?.name === "Gross Profit" ? "green" :"auto" }}>{formatNumber(e.income?.balance)}</VBTableCellBody>
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>   
        </TableRow>
      ))}


      {/* looping ProfitAndLossAccount expense */}
      {(props?.filterOptions?.filterValue === 'All' || props?.filterOptions?.filterValue === 'ProfitAndLossAccount') && props.data?.ProfitAndLossAccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence?.name === "Total" ? 'red': e.expence?.name === "Net Profit" ? "green" :"auto", fontWeight: e.expence?.name === "Cost Of Goods Sold" || e.expence?.name === "Total" || e.expence?.name === "Less COS" || e.expence?.name === "Add Other Income" || e.expence?.name === "Less Operating Expenses"  ? "600" : "400" }} > {e.expence?.name} </VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right" }}>  {formatNumber(e.expence?.sub_total)}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right",color:e.expence?.name === "Net Profit" ? "green" :"auto"  }}> {formatNumber(e.expence?.balance)}</VBTableCellBody>
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>      
          <VBTableCellBody > </VBTableCellBody>   
        </TableRow>
      ))}
      <TableRow></TableRow>
  </TableBody>
  }
  </VBTableContainer>
  )
}

export default ConsolidateTable