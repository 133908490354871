import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Paper,
  Typography,
  styled,
  Chip,
  Divider,
  TableRow,
  TableBody,
  Button,
  Checkbox,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import Pagenation from "../../../Components/Utils/Pagenation";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../../Components/ReportTable/ReportTable";
import { formatNumber } from "../../../generalFunction";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  delete_stock_management,
  list_stock_management,
} from "../../../Api/CommonApi/InvoiceApis/StockManagementApi";
import { json, useNavigate } from "react-router-dom";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditIcon from "@mui/icons-material/Edit";
import { blue } from "@mui/material/colors";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  CreateNewButton,
  DeleteButton,
} from "../../../Components/Utils/StyledButtons";
import ConfirmBox from "../../../Components/Utils/ConfirmBox";
import { ListStockTransferAPI, StockTransferDeleteAPI } from "../../../Api/Inventory/StockTransferAPI";
import { MenuOptions } from "../../../Components/CommonComponents";
import { openSnackbar } from "../../../features/SnackBarslice";
import { goToInvoice } from "../../../Function/comonFunction";
import SearchTextField from "../../../Components/Utils/SearchTextField";

const ListStockTransfer = () => {
  const navigate = useNavigate();
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { PriceDecimalPoint, EnableBranch } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const [state, setState] = useState({
    dataList: [],
    data_count: null,
    search: null,
    selectedItems: [],
    isSelectedAll: false,
  });
  let user_role_settings = useSelector((state) => state.userRollSettings);

  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Stock Transfer"
  );
  let sales_delete = sales_permission[0].delete_permission;
  let sales_edit = sales_permission[0].edit_permission;
  let sales_view = sales_permission[0].view_permission;
  let sales_save = sales_permission[0].save_permission;

  const OptionList = [
    { name: "Edit", permission: sales_edit },
    { name: "Delete", permission: sales_delete },
  ];

  
  const [IsConfirmBox, setConfirmBox] = useState(false);
  const [searchText, setSearchText] = useState("");
  
  const noOfItems = appSetting.itemPerPage;

  const [page_no, setPage_no] = useState(1);
  const [loading, setLoading] = useState(false);

  const [t, i18n] = useTranslation("common");

  const fetchData = async () => {
    let payload = {
      CreatedUserID: user_id,
      BranchID: BranchID,
      PriceRounding: 2,
      QtyRounding: 5,
      CompanyID: CompanyID,
      page_no: page_no,
      items_per_page: 15,
      search:searchText,
    };
    let responseData = await ListStockTransferAPI({ ...payload });
    if (responseData.StatusCode === 6000) {
      let data_count = responseData.count;
      setState({
        ...state,
        dataList: responseData.data,
        isSelectedAll: false,
        selectedItems: [],
        data_count: data_count,
      });
    }
  };

  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit") {
        if (permission) {
          navigate("/create-stock-transfer", {
            state: { is_edit: true, uniq_id: uniq_id },
          });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for edit",
              severity: "warning",
            })
          );
        }
      } else if (name === "Delete") {
        if (permission) {
          setState({ ...state, selectedItems: [uniq_id] });
          setConfirmBox(true);
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for delete",
              severity: "warning",
            })
          );
        }
      }
    }
  };


  const deleteData = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      selecte_ids: state.selectedItems,
    };

    let responseData = await StockTransferDeleteAPI({ ...payload });

    if (responseData.StatusCode === 6000) {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData.message || "Data deleted successfully",
          severity: "success",
        })
      );
      fetchData();
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: responseData.message || "Something went wrong",
          severity: "error",
        })
      );
    }
  };

  const confirmDelete = () => {
    setConfirmBox(true);
  };

  const selectItems = (type, id) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (state.isSelectedAll) {
        data = [];
      } else {
        state.dataList.map((i) => {
          data.push(i.id);
        });
      }
    } else {
      let index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1);
      } else {
        data.push(id);
      }
    }

    setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  };

  const navigateEditPage = (id) => {
    navigate("/create-stock-management", {
      state: { is_edit: true, uniq_id: id },
    });
  };

  useEffect(() => {
    fetchData();
  }, [state.search, page_no, searchText]);

  return (
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <FlexBox className="left">
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Stock Transfer")}
          </Typography>
          <SearchTextField
            value={searchText}
            escClearState={setSearchText}
            placeholder="Search"
            onChange={(e) => {
              setSearchText(e.target.value);
              page_no !== 1 && setPage_no(1)
            }}
          />
        </FlexBox>

        <FlexBox className="right">
          <DeleteButton onClick={confirmDelete} t={t} />

          <CreateNewButton
            onClick={() => {
              navigate("/create-stock-transfer");
            }}
            t={t}
          />

          <Pagenation
            totalItem={state.data_count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
        </FlexBox>
      </Box>

      {/* ==============TABLE=============== */}

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
            <Checkbox
              checked={state.isSelectedAll} // The checked prop determines whether this checkbox is checked.
              onChange={() => {
                selectItems("all");
              }}
              sx={{
                padding: "2px",
                margin: 0,
                color: blue[400],
                "&.Mui-checked": {
                  color: blue[400],
                },
                "& .MuiSvgIcon-root": { fontSize: "20px" },
              }}
            />
          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("Voucher No")}</VBTableCellHeader>
          <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
          <VBTableCellHeader>{t("From Warehouse")}</VBTableCellHeader>
          <VBTableCellHeader>{t("To Warehouse")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Date")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Total Qty")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Grand Total")} </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "right" }}>
            {" "}
            {""}
          </VBTableCellHeader>
        </VBTableHeader>

        {loading ? (
          <VBSkeletonLoader />
        ) : (
          <TableBody>
            {state.dataList.map((data, i) => (
              <TableRow
              // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
              //   onClick={()=>{handleTableRowClick(data)}}
              >
                <VBTableCellBody
                  sx={{
                    cursor: "pointer",
                    textAlign: "left",
                    padding: "0px",
                    paddingLeft: "5px",
                  }}
                >
                  <Checkbox
                    checked={
                      state.selectedItems.includes(data.id) ? true : false
                    } // The checked prop determines whether this checkbox is checked.
                    onChange={() => {
                      selectItems("not", data.id);
                    }}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer", textAlign: "left" }}>
                  {(page_no - 1) * appSetting.itemPerPage + i + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data.VoucherNo}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }} onClick={() => goToInvoice(data.id,"Stock Transfer")}>
                  {moment(data.Date).format("DD-MM-YYYY")}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }} onClick={() => goToInvoice(data.id,"Stock Transfer")}>
                  {data.WarehouseFromName}
                </VBTableCellBody>
                <VBTableCellBody onClick={() => goToInvoice(data.id,"Stock Transfer")}>
                  {formatNumber(data.WarehouseToName)}
                </VBTableCellBody >
                <VBTableCellBody onClick={() => goToInvoice(data.id,"Stock Transfer")}>
                  {formatNumber(data.Date)}
                </VBTableCellBody>
                <VBTableCellBody onClick={() => goToInvoice(data.id,"Stock Transfer")}>
                  {formatNumber(data.TotalQty)}
                </VBTableCellBody >
                <VBTableCellBody onClick={() => goToInvoice(data.id,"Stock Transfer")}>
                  {formatNumber(data.GrandTotal)}
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{ textAlign: "right", cursor: "pointer" }}
                >
                  <MenuOptions
                    OptionList={OptionList}
                    OptionOnClick={OptionOnClick}
                    uniq_id={data.id}
                    disable={
                      data.is_billwised || data.IsNewSale === false
                        ? true
                        : false
                    }
                  />
                </VBTableCellBody>
              </TableRow>
            ))}
            <VBTableRowNull
              length={
                state.data_count ? state.data_count : state.dataList.length
              }
            />
          </TableBody>
        )}
      </VBTableContainer>
      <ConfirmBox
        heading={"Confirm to delete?"}
        message={"Once you delete, you will not get it back!"}
        open={IsConfirmBox}
        setOpen={setConfirmBox}
        confirmFunction={deleteData}
      />
    </Paper>
  );
};

export default ListStockTransfer;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
