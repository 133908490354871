import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { openSnackbar } from "../../../features/SnackBarslice";
import MyGrid from "./Details";
import { debounce } from "../../../Function/comonFunction";
import SummaryDetails from "./SummaryDetails";
import NotesTextArea from "../../../Components/Utils/TextArea";
import { useLocation, useNavigate } from "react-router-dom";
import { LedgerDetails } from "../../../Api/Sales/SalesEstimateApis";
import { SalesLineCalc, SalesInvoiceCalc } from "./functions";
import { CallEmployees } from "../../../Api/Reports/CommonReportApi";
import { blue } from "@mui/material/colors";
import moment from "moment";

import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  LinearProgress,
  Paper,
  Typography,
  styled,
} from "@mui/material";

import {
  AddButton,
  AddLineButton,
  ClearButton,
  ListButton,
  LoadingButton,
  SaveButton,
} from "../../../Components/Utils/StyledButtons";

import {
  CustomVoucher,
  InvoiceAutoComplete,
  InvoiceBarCodeTextBox,
  InvoiceDateBox,
  InvoiceTextBox,
  InvoiceVoucherNoTextBox,
} from "../../../Components/CommonComponents";

import {
  product_search_new_api,
  get_pricelist_list_new_api,
  get_single_pricelist_new_api,
  get_product_by_barcode_api,
} from "../../../Api/CommonApi/CommonApis";

import {
  callCreateStockTransferAPI,
  callGetProductByBarcode,
  callProductGroupAPI,
  callStockTransferFilterAPI,
  callStockTransferPageDetailsAPI,
  GetStockOrderAPI,
  StockTransferSinglePageAPI,
  UpdateStockTransferAPI,
} from "../../../Api/Inventory/StockTransferAPI";
import { Description } from "@mui/icons-material";
import ProductList from "../../Product/ProductList/ProductList";

const CreateStockTransfer = () => {
  // ============================== Declaration ====================================

  const [t] = useTranslation("common");
  let navigate = useNavigate();

  // ============================= global states ==================================

  const {
    PriceDecimalPoint,
    PriceCategory,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    EnableEinvoiceKSA,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const location = useLocation();
  const params = new URLSearchParams(window.location.search);

  const is_edit = location.state?.is_edit ?? params.get("is_edit");
  const uniq_id = location.state?.uniq_id ?? params.get("unq_id");
  // =============================== variables =====================================

  // let today = new Date();
  // today.setDate(today.getDate());

  let DataListItem = {
    unq_id: "",
    detailID: "",
    MaXRate: 0,
    MaxAmount: 0,
    StockTransferDetailsID: "",
    StockTransferMasterID: "",
    Amount: "",
    Barcode: "", //BarCode
    Product: null,
    ProductName: "",
    ProductCode: null,
    ProductID: null,
    PriceListID: null,
    Qty: "",
    Rate: "",
    UnitList: [],
    Unit: null,
    BranchID: null,
    Description: "",
    HSNCode: "",
    Stock: "",
    UnitName: "",
    BranchFromID: BranchID,
    BranchToID: BranchID,
  };
  
  const MasterCalcFields = [
    "BillDiscPercent",
    "BillDiscAmt",
    "CashAmount",
    "BankAmount",
    "RoundOff",
  ];

  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    PriceCategory,
  };
  // ================================ states =======================================

  const [IsButtonClick, setIsButtonClick] = useState(false);
  const [isManualRoundOff, setIsManualRoundOff] = useState(false);
  const [openCustomVoucher, setOpenCustomVoucher] = useState(false);
  const [IsLoading, setIsLoading] = useState(false);
  const [DataList, setDataLists] = useState([DataListItem]);
  console.log(DataList);
  
  const [allProductOrProductGroup, setAllProductOrProductGroup] =
    useState("all-product");
  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    WarehouseResponse: [],
    ProductGroupResponse: [],
    ProductList: [],
  });

  const [state, setState] = useState({
    StockTransferMasterID: "",
    id: "",
    Date: moment().format("YYYY-MM-DD"),
    FromStockOrderNo: "",
    FromWarehouse: null,
    ToWarehouse: null,
    ProductGroup: null,
    TotalQty: 0,
    TotalAmount: 0,
    Notes: "",
    deletedData: [],
  });
  const [tableSettings, setTableSettings] = useState({
    index: true,
    ProductCode: true,
    Barcode: false,
    Product: true,
    Unit: true,
    Qty: true,
    Rate: true,
    Amount: true,
    Activity: true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    ProductCode: true,
    Barcode: false,
    Product: true,
    Unit: true,
    Qty: true,
    Rate: true,
    Amount: true,
    Activity: true,
  });

  const [Errors, setErrors] = useState({
    field: null,
    message: null,
    error: false,
    indexList: [],
  });

  // ============================== functions ====================================

  function customRound(x) {
    if (EnableEinvoiceKSA) {
      const rounded = Math.round(x * 100) / 100;

      if (rounded - x === 0.5) {
        return rounded;
      }

      return rounded;
    } else {
      return Number(x);
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDataState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const CustomVoucherSet = (e) => {
    let PreFix = dataState.PreFix;
    let Seperator = dataState.Seperator;
    let InvoiceNo = dataState.InvoiceNo;
    let SuffixSeparator = dataState.SuffixSeparator
      ? dataState.SuffixSeparator
      : "";
    let Suffix = dataState.Suffix ? dataState.Suffix : "";
    let VoucherNo =
      String(PreFix) +
      String(Seperator) +
      String(InvoiceNo) +
      String(SuffixSeparator) +
      String(Suffix);
    setDataState({ ...dataState, VoucherNo: VoucherNo });
    setOpenCustomVoucher(false);
  };

  const ChangeTableCustomization = (type, name) => {
    let value = null;
    if (type === "tableSettings") {
      value = !tableSettings[name];
      setTableSettings({
        ...tableSettings,
        [name]: value,
      });
    } else {
      value = !focusSettings[name];
      setFocusSettings({
        ...focusSettings,
        [name]: value,
      });
    }
  };

  const onMasterChange = (e) => {
    if (true) {
      const { name, value } = e.target;
      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);

      if (isCalcField) {
        state[name] = value;

        //handling bill disc
        if (name === "BillDiscAmt") {
          state.BillDiscPercent =
            100 * (Number(value) / Number(state.NetTotal));
        } else if (name === "BillDiscPercent") {
          state.BillDiscAmt = (Number(value) / 100) * Number(state.NetTotal);
        }

        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          isManualRoundOff
        );
        setState({ ...newState });
      } else {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Product" || name === "ProductCode") && value) {
        const ProductList = await Call_ProductSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state?.FromWarehouse.WarehouseID,
          is_product_image: false,
          length: value?.length,
          product_name: value,
          type: "StockTransfer",
        });
        setDataState({...dataState, Product: ProductList});

        ProductList.map((item) => ({
          ...item,
          ProductName:
            (item.ProductName = `${item.ProductName} (${item.Stock})`),
        }));
        setDataState({ ...dataState, ProductList: ProductList});
      }
    }
  };

  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  const addByBarCode = async (text) => {
    let Data = DataList;

    let lineItem = DataListItem;

    let Product = await callGetProductByBarcode({
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
      BarCode: text,
      PriceRounding: 2,
      WarehouseID: state.FromWarehouse.WarehouseID,
      finishedProductsOnly: false,
      rawProductsOnly: false,
      type: "Purchase",
    });

    if (Product.StatusCode === 6000) {
      let UnitList = await Call_PriceList_list_Function({
        CompanyID: CompanyID,
        BranchID: BranchID,
        product_id: Product.data.id,
        type_data: "purchase",
      });

      let isProductExists = DataList.find(
        (i) => i.ProductID === Product.data.ProductID
      );

      if (!isProductExists) {
        let Unit = UnitList.find((i) => i.DefaultUnit === true);
        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.FromWarehouse.WarehouseID,
        });

        let Rate = 0;
        let Qty = 1;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);
          lineItem.Rate = Rate;
          lineItem.Barcode = UnitDetails.BarCode;
        }

        lineItem.ProductID = Product.data.ProductID;
        lineItem.Product = Product.data;
        lineItem.ProductCode = Product.data;
        lineItem.Qty = Qty;
        lineItem.UnitList = UnitList;
        lineItem.Unit = Unit;
        lineItem.PriceListID = PriceListID;
        lineItem.Stock = UnitDetails.Stock;

        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        if (DataList.length === 1 && DataList[0].ProductCode === null) {
          DataList.pop();
        }

        Data.push(calculated_lineItem);

        setDataLists([...Data]);
      } else {
        // update quantity only if the product already exists in the list
        const updateQuantity = DataList.map((item) =>
          item.ProductID === Product.data.ProductID
            ? {
                ...item,
                Qty: (item.Qty += 1),
                Amount: item.Rate * item.Qty,
              }
            : item
        );
        setDataLists(updateQuantity);
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Product?.message || "Api Error",
          severity: "error",
        })
      );
    }
  };

  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      let lineItem = Data[index];
      lineItem[name] = value;

      let calculated_lineItem = await SalesLineCalc(
        dataState,
        state,
        lineItem,
        customRound
      );
      // setState((prevState) => ({
      //   ...prevState,
      //   TotalQty: (prevState.TotalQty += 1),
      //   TotalAmount: (prevState.TotalAmount += Data[index].Rate),
      // }));
      Data[index] = calculated_lineItem;
      setDataLists([...DataList]);
    }
  };

  const onDetailsAutoChange = async (v, index, name) => {
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Product" || name === "ProductCode") {
        let Product = v;
        
        let UnitList = await Call_PriceList_list_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          product_id: Product.id,
          type_data: "SI", 
        });

        let Unit = UnitList.find((i) => i.DefaultUnit === true);

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.FromWarehouse.WarehouseID,
        });

        let Rate = 0;
        let Qty = 1;
        let DiscountPerc = 0;
        let DiscountAmount = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.Barcode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
          lineItem.is_inclusive = UnitDetails.is_inclusive;
          lineItem.Stock = UnitDetails.Stock;

          lineItem.SalesPrice = UnitDetails.SalesPrice;
          lineItem.SalesPrice1 = UnitDetails.SalesPrice1;
          lineItem.SalesPrice2 = UnitDetails.SalesPrice2;
          lineItem.SalesPrice3 = UnitDetails.SalesPrice3;

          if (state?.PriceCategory?.PriceCategoryID === 1) {
            lineItem.Rate = UnitDetails.SalesPrice;
          } else if (state?.PriceCategory?.PriceCategoryID === 2) {
            lineItem.Rate = UnitDetails.SalesPrice1;
          } else if (state?.PriceCategory?.PriceCategoryID === 3) {
            lineItem.Rate = UnitDetails.SalesPrice2;
          } else if (state?.PriceCategory?.PriceCategoryID === 4) {
            lineItem.Rate = UnitDetails.SalesPrice3;
          }
        }
        lineItem.ProductID = Product.ProductID;
        lineItem.Product = Product;
        lineItem.ProductCode = Product;
        lineItem.Description = Product.Description;
        lineItem.Qty = Qty;
        lineItem.DiscountPerc = DiscountPerc;
        lineItem.DiscountAmount = DiscountAmount;
        lineItem.PriceListID = PriceListID;
        lineItem.UnitList = UnitList;
        lineItem.Unit = Unit;
        lineItem.unq_id = Product.id;
        if (is_edit) lineItem.detailID = 1;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      } else if (name === "Unit") {
        let Unit = v;

        let PriceListID = Unit.PriceListID;

        let UnitDetails = await Call_Single_PriceList_Function({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceListID: PriceListID,
          PriceRounding: Number(PriceDecimalPoint || 2),
          WarehouseID: state.FromWarehouse.WarehouseID,
        });
        let Rate = 0;

        if (UnitDetails) {
          Rate = Number(UnitDetails.Rate);

          lineItem.MRP = UnitDetails.MRP;
          lineItem.Rate = Rate;
          lineItem.PurchasePrice = UnitDetails.PurchasePrice;
          lineItem.Barcode = UnitDetails.BarCode;
          lineItem.BatchCode = UnitDetails.BatchCode;
          lineItem.MinimumSalesPrice = UnitDetails.MinimumSalesPrice;
          lineItem.AverageCost = UnitDetails.AverageCost;
          lineItem.PriceListID = PriceListID;
          lineItem.Unit = Unit;
          lineItem.Stock = UnitDetails.Stock;
        }
        lineItem.Unit = Unit; //Changed
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;
        setDataLists([...Data]);
      } else if (name === "Tax") {
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
  };

  const AddLine = () => {
    let Data = DataList;
    let newData = DataListItem;
    Data.push(newData);
    setDataLists([...Data]);
  };

  const RemoveLine = (index) => {
    let Data = DataList;
    let deletedData = state.deletedData;
    if (index || index === 0) {
      if (index > -1 && index < Data.length) {
        if (is_edit) {
          deletedData.push(Data[index]);
        }
        Data.splice(index, 1);
      }
    }
    let newState = SalesInvoiceCalc(dataState, state, Data);
    setState({ ...newState, deletedData: deletedData });

    setDataLists([...Data]);
  };

  const clearData = async () => {
    setDataLists([DataListItem]);
    fetchData();
    setAllProductOrProductGroup("all-product");
    setState((prevState) => ({
      ...prevState,
      StockTransferMasterID: "",
      id: "",
      Date: moment().format("YYYY-MM-DD"),
      FromStockOrderNo: "",
      FromWarehouse: null,
      ToWarehouse: null,
      ProductGroup: null,
      TotalQty: 0,
      TotalAmount: 0,
      Notes: "",
      deletedData: [],
    }));
  };

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "SalesInvoice",
        });
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }
      } else if (name === "Employee") {
        const EmployeeList = await Call_Employees_api({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          search: search,
          list_type: "sales",
        });
        const uniqueSalesmansData = EmployeeList.filter((salesman) => {
          // Check if the salesman id is not already in EmployeesList
          return !dataState.EmployeeData.some(
            (employee) => employee.id === salesman.id
          );
        });
        let EmployeeData = [...dataState.EmployeeData, ...uniqueSalesmansData];
        setDataState({ ...dataState, EmployeeData: EmployeeData });
      }
    }
  };

  const onMasterAutoChange = async (e, v, name) => {
    if (v && name) {
      const isCalcField = MasterCalcFields.includes(name);

      let value = v;
      if (name === "FromWarehouse") {
        const fromWarehouseID = value.WarehouseID;
        const toWarehouse = dataState.WarehouseResponse.find(
          (item) => item.WarehouseID !== fromWarehouseID
        );

        setState((prevState) => ({
          ...prevState,
          FromWarehouse: value,
          ToWarehouse:
            state.ToWarehouse.WarehouseID === fromWarehouseID
              ? toWarehouse
              : prevState.ToWarehouse,
        }));
      } else if (name === "ToWarehouse") {
        const toWarehouseID = value.WarehouseID;
        const fromWarehouse = dataState.WarehouseResponse.find(
          (item) => item.WarehouseID !== toWarehouseID
        );

        setState((prevState) => ({
          ...prevState,
          ToWarehouse: value,
          FromWarehouse:
            state.FromWarehouse.WarehouseID === toWarehouseID
              ? fromWarehouse
              : prevState.FromWarehouse,
        }));
      } else if (isCalcField) {
        state[name] = value;
        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          isManualRoundOff
        );
        setState({ ...newState });
      } else {
        setState({ ...state, [name]: value });
      }
    }
  };

  // ============================ api fetching ===================================

  const fetchData = async () => {
    setIsLoading(true);
    if (is_edit && uniq_id) {
      const StockTransferSinglePageAPIResponse =
        await StockTransferSinglePageAPI(
          {
            CompanyID: CompanyID,
            CreatedUserID: user_id,
            BranchID: BranchID,
            PriceRounding: 2,
            QtyRounding: 2,
          },
          uniq_id
        );
      if (StockTransferSinglePageAPIResponse.StatusCode === 6000) {
        setState((prevState) => ({
          ...prevState,
          id: StockTransferSinglePageAPIResponse.data.id,
          StockTransferMasterID:
            StockTransferSinglePageAPIResponse.data.StockTransferMasterID,
          Notes: StockTransferSinglePageAPIResponse.data.Notes,
          Date: StockTransferSinglePageAPIResponse.data.Date,
        }));
        const updatedDataList =
          StockTransferSinglePageAPIResponse.data.Details.map((item) => ({
            StockTransferDetailsID: item.StockTransferDetailsID,
            StockTransferMasterID: item.StockTransferMasterID,
            ProductID: item.ProductID,
            ProductCode: item,
            Barcode: item.Barcode,
            Product: {
              ...item,
              ProductName: `${item.ProductName} (${item.Stock})`,
            },
            ProductName: item.ProductName,
            UnitList: item.UnitList,
            Unit: item.UnitList.find(
              (unitItem) => unitItem.PriceListID === item.PriceListID
            ),
            UnitName: item.UnitList.find(
              (unitItem) => unitItem.UnitName === item.UnitName
            ).UnitName,
            Qty: item.Qty,
            Rate: item.Rate,
            Amount: item.Amount,
            PriceListID: item.PriceListID,
            unq_id: item.id,
            id: item.id,
            detailID: item.detailID,
            MaXRate: item.MaXRate,
            MaxAmount: item.MaxAmount,
            BranchID: item.BranchID,
            Description: item.Description,
            HSNCode: item.HSNCode,
            Stock: item.Stock,
            BranchFromID: BranchID,
            BranchToID: BranchID,
          }));
        setDataLists(updatedDataList);
        setDataState((prevState) => ({
          ...prevState,
          Seperator: StockTransferSinglePageAPIResponse.data.Seperater,
          VoucherNo: StockTransferSinglePageAPIResponse.data.VoucherNo,
          Suffix: StockTransferSinglePageAPIResponse.data.Suffix,
          SuffixSeparator:
            StockTransferSinglePageAPIResponse.data.SuffixSeparator,
          PreFix: StockTransferSinglePageAPIResponse.data.PreFix,
          InvoiceNo: StockTransferSinglePageAPIResponse.data.InvoiceNo,
          WarehouseResponse: [],
          ProductGroupResponse: [],
          ProductList: [],
        }));
      }
      const CreateStockTransferAPiResponse =
        await callStockTransferPageDetailsAPI({
          CompanyID: CompanyID,
          CreatedUserID: user_id,
          BranchID: BranchID,
        });

      if (CreateStockTransferAPiResponse?.StatusCode === 6000) {
        const fromWarehouse =
          CreateStockTransferAPiResponse.data.Warehouse.data.find(
            (item) =>
              item.WarehouseID ===
              StockTransferSinglePageAPIResponse.data.WarehouseFromID
          );
        const toWarehouse =
          CreateStockTransferAPiResponse.data.Warehouse.data.find(
            (item) =>
              item.WarehouseID ===
              StockTransferSinglePageAPIResponse.data.WarehouseToID
          );
        setDataState((prevState) => ({
          ...prevState,
          WarehouseResponse: CreateStockTransferAPiResponse.data.Warehouse.data,
        }));
        setState((prevState) => ({
          ...prevState,
          FromWarehouse: fromWarehouse,
          ToWarehouse: toWarehouse,
        }));
      }
      const ProductGroupAPiResponse = await callProductGroupAPI({
        BranchID: BranchID,
        CompanyID: CompanyID,
        CreatedUserID: user_id,
      });

      if (ProductGroupAPiResponse.StatusCode === 6000) {
        setDataState((prevState) => ({
          ...prevState,
          ProductGroupResponse: ProductGroupAPiResponse.data,
        }));
      }
    } else {
      const CreateStockTransferAPiResponse =
        await callStockTransferPageDetailsAPI({
          CompanyID: CompanyID,
          CreatedUserID: user_id,
          BranchID: BranchID,
        });
      if (CreateStockTransferAPiResponse?.StatusCode === 6000) {
        const fromWarehouse =
          CreateStockTransferAPiResponse.data.Warehouse.data.find(
            (item) => item.IsDefaultWarehouse
          );
        const toWarehouse =
          CreateStockTransferAPiResponse.data.Warehouse.data.find(
            (item) => !item.IsDefaultWarehouse
          );
        setDataState((prevState) => ({
          ...prevState,
          Seperator: CreateStockTransferAPiResponse.data.voucher.Seperater,
          Suffix: CreateStockTransferAPiResponse.data.voucher.Suffix,
          SuffixSeparator:
            CreateStockTransferAPiResponse.data.voucher.SuffixSeparator,
          PreFix: CreateStockTransferAPiResponse.data.voucher.PreFix,
          InvoiceNo: CreateStockTransferAPiResponse.data.voucher.InvoiceNo,
          VoucherNo: CreateStockTransferAPiResponse.data.voucher.VoucherNo,
          WarehouseResponse: CreateStockTransferAPiResponse.data.Warehouse.data,
        }));
        setState((prevState) => ({
          ...prevState,
          FromWarehouse: fromWarehouse,
          ToWarehouse: toWarehouse,
        }));
      }
      const ProductGroupAPiResponse = await callProductGroupAPI({
        BranchID: BranchID,
        CompanyID: CompanyID,
        CreatedUserID: user_id,
      });

      if (ProductGroupAPiResponse.StatusCode === 6000) {
        setDataState((prevState) => ({
          ...prevState,
          ProductGroupResponse: ProductGroupAPiResponse.data,
        }));
      }
    }
    setIsLoading(false);
  };

  const Call_Employees_api = async (payload) => {
    let data = [];
    const Response = await CallEmployees(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Seles Man Search Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const Call_ProductSearchFunction = async (payload) => {
    let data = [];
    const Response = await product_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };

  const Call_PriceList_list_Function = async (payload) => {
    let data = [];
    const Response = await get_pricelist_list_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  //Detials of selected unit(pricelist) api
  const Call_Single_PriceList_Function = async (payload) => {
    let data = null;
    const Response = await get_single_pricelist_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = null;
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }

    return data;
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const updatedDataList = DataList.map((item) => ({
      ...item,
      ProductName: item?.ProductCode?.ProductName,
      ProductCode: item?.ProductCode?.ProductCode,
    }));
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      VoucherNo: dataState.VoucherNo,
      Notes: state.Notes,
      TransferredByID: null,
      Date: state.Date,
      BranchFromID: BranchID,
      BranchToID: BranchID,
      WarehouseFromID: state.FromWarehouse.WarehouseID,
      WarehouseToID: state.ToWarehouse.WarehouseID,
      TotalQty: state.TotalQty,
      GrandTotal: state.TotalAmount,
      MaxGrandTotal: 0,
      BatchID: 1,
      IsActive: true,
      StockTransferDetails: updatedDataList,
      PreFix: dataState.PreFix,
      Seperator: dataState.Seperator,
      InvoiceNo: dataState.InvoiceNo,
      VoucherType: "ST",
      StockOrderNo: state.FromStockOrderNo,
      SuffixSeparator: dataState.SuffixSeparator,
      Suffix: dataState.Suffix,
    };
    if (is_edit) {
      payload.deleted_data = state.deletedData;
    }
    let isProductOrQuantityNotNull = true;

    for (let item of DataList) {
      if (
        item.Rate === "" ||
        item.Rate === 0 ||
        item.Qty === 0 ||
        item.Qty === ""
      ) {
        isProductOrQuantityNotNull = false;
        break;
      }
    }

    let productIsNotNull = true;
    for (let item of DataList) {
      if (item.Product === null || item.Product === "") {
        productIsNotNull = false;
        break;
      }
    }

    let QuantityNotLessThanStock = true;
    let ProductName = ""
    let Stock = ""
    for (let item of DataList) {
      if (item.Stock < item.Qty) {
        console.log(item);
        
        QuantityNotLessThanStock = false;
        ProductName = item.Product.ProductName;
        Stock = item.Stock;
        break;
      }
    }

    if (productIsNotNull) {
      if (isProductOrQuantityNotNull) {
        if (QuantityNotLessThanStock) {
          if (is_edit && uniq_id) {
            const Response = await UpdateStockTransferAPI(payload, uniq_id);
            if (Response.StatusCode === 6000) {
              dispatch(
                openSnackbar({
                  open: true,
                  message:
                    Response.message || "Stock Transfer Edited Successfully",
                  severity: "success",
                })
              );
              clearData();
              navigate("/list-stock-transfer");
            } else {
              dispatch(
                openSnackbar({
                  open: true,
                  message: Response.message || "Something went wrong!",
                  severity: "error",
                })
              );
            }
          } else {
            const createStockTransferAPiResponse =
              await callCreateStockTransferAPI(payload);

            if (createStockTransferAPiResponse?.StatusCode === 6000) {
              dispatch(
                openSnackbar({
                  open: true,
                  message: createStockTransferAPiResponse.message,
                  severity: "success",
                })
              );
              clearData();
              setIsLoading(false);
              setIsButtonClick(false);
            } else {
              dispatch(
                openSnackbar({
                  open: true,
                  message: createStockTransferAPiResponse.message,
                  severity: "error",
                })
              );
            }
          }
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: `${ProductName} have only ${Stock} stock `,
              severity: "error",
            })
          );
        }
      } else {
        dispatch(
          openSnackbar({
            open: true,
            message: "Please enter quantity and rate",
            severity: "error",
          })
        );
      }
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: "Select Product",
          severity: "error",
        })
      );
    }
    setIsLoading(false);
  };

  const handleProductAddButtonClick = async () => {
    setIsLoading(true);
    const stockTransferFilterAPiResponse = await callStockTransferFilterAPI({
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
      PriceRounding: 2,
      IsAllProduct: allProductOrProductGroup === "all-product" ? true : false,
      GroupID:
        allProductOrProductGroup === "all-product"
          ? null
          : state.ProductGroup?.ProductGroupID,
      FromWarehouseID: state.FromWarehouse.WarehouseID,
    });

    if (stockTransferFilterAPiResponse?.StatusCode === 6000) {
      const updatedDataList = stockTransferFilterAPiResponse.data.map(
        (item) => ({
          ProductID: item.ProductID,
          ProductCode: item,
          BarCode:
            item.Barcode === 0 || item.Barcode === "0"
              ? item.AutoBarcode
              : item.Barcode,
          Product: {
            ...item,
            ProductName: `${item.ProductName} (${item.Stock})`,
          },
          UnitList: item.UnitList,
          Unit: item.UnitList.find((item) => item.DefaultUnit),
          Qty: item.Qty,
          Rate: item.Rate,
          Amount: item.Amount,
          PriceListID: item.PriceListID,
        })
      );
      setDataLists(updatedDataList);
      setState((prevState) => ({
        ...prevState,
        TotalQty: stockTransferFilterAPiResponse.TotalQuantity,
        TotalAmount: stockTransferFilterAPiResponse.TotalAmount,
      }));

      setIsLoading(false);
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message:
            stockTransferFilterAPiResponse?.message || "Something went wrong!",
          severity: "error",
        })
      );
    }
  };

  const handleFromStockOrder = async (e) => {
    const response = await GetStockOrderAPI({
      CompanyID: CompanyID,
      BranchID: BranchID,
      StockOrderNo: state.FromStockOrderNo,
      PriceRounding: 2,
    });
    if (response.StatusCode === 6000) {
      const updatedDataList = response.data.Details.map((item) => ({
        StockTransferDetailsID: item.StockTransferDetailsID,
        StockTransferMasterID: item.StockTransferMasterID,
        ProductID: item.ProductID,
        ProductCode: item,
        Barcode: item.Barcode,
        Product: {
          ...item,
          ProductName: `${item.ProductName} (${item.Stock})`,
        },
        ProductName: item.ProductName,
        UnitList: item.UnitList,
        Unit: item.UnitList.find(
          (unitItem) => unitItem.UnitName === item.UnitName
        ),
        UnitName: item.UnitList.find(
          (unitItem) => unitItem.UnitName === item.UnitName
        ).UnitName,
        Qty: item.Qty,
        Rate: item.Rate,
        Amount: item.Amount,
        PriceListID: item.PriceListID,
        unq_id: item.id,
        id: item.id,
        detailID: item.detailID,
        MaXRate: item.MaXRate,
        MaxAmount: item.MaxAmount,
        BranchID: item.BranchID,
        Description: item.Description,
        HSNCode: item.HSNCode,
        Stock: item.Stock,
        BranchFromID: BranchID,
        BranchToID: BranchID,
      }));
      setDataLists(updatedDataList);
      console.log(dataState.WarehouseResponse);
      
      let fromWarehouse = dataState.WarehouseResponse.find((item)=> item.WarehouseID === response.data.WarehouseFromID);
      let toWarehouse = dataState.WarehouseResponse.find((item) => item.WarehouseID === response.data.WarehouseToID);
      console.log(fromWarehouse, toWarehouse);
      
      setState((prevState) => ({
       ...prevState,
        FromWarehouse:  fromWarehouse,
        ToWarehouse: toWarehouse,
      }));
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: response.message || "Something went wrong!",
          severity: "warning",
        })
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState, state, DataList);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll", minWidth:"1080px"}}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                cmr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit
                ? t("Update Stock Transfer")
                : t("Create Stock Transfer")}
            </Typography>
          </FlexBox>
          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            <ListButton
              t={t}
              onClick={() => navigate("/list-stock-transfer")}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              <SaveButton onClick={handleSubmit} t={t} />
            )}
          </FlexBox>
        </Box>
        {IsLoading && <LinearProgress color="secondary" />}
        <Box
          sx={{
            px: "26px",
            py: "15px",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Grid container spacing={2} sx={12}>
            
          <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "left",
              }}
            >
              <InvoiceVoucherNoTextBox
                name={"VoucherNo"}
                placeholder={t("Voucher No")}
                label={"Type a VoucherNo"}
                Value={dataState.VoucherNo}
                openCustomVoucher={openCustomVoucher}
                setCustomVoucher={setOpenCustomVoucher}
                is_edit={is_edit}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography>{t("Date")}</Typography>
              <InvoiceDateBox
                name={"Date"}
                label={"Select a Date"}
                Value={state.Date}
                OnChange={onMasterChange}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography>{t("From")}</Typography>
              <InvoiceAutoComplete
                name={"FromWarehouse"}
                label={"Select a Warehouse"}
                optionLabel={"WarehouseName"}
                List={dataState.WarehouseResponse}
                Value={state.FromWarehouse}
                OnChange={onMasterAutoChange}
                OnInputChange={onMasterInputChange}
                placeholder={"Warehouse"}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              <Typography>{t("To")}</Typography>
              <InvoiceAutoComplete
                name={"ToWarehouse"}
                label={"Select a Warehouse"}
                optionLabel={"WarehouseName"}
                List={dataState.WarehouseResponse}
                Value={state.ToWarehouse}
                OnChange={onMasterAutoChange}
                OnInputChange={onMasterInputChange}
                placeholder={"Warehouse"}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "left",
              }}
            >
              
              <InvoiceTextBox
                placeholder={t("From Stock Order No")}
                name={"FromStockOrderNo"}
                label={"Type a Stock Order No"}
                Value={state.FromStockOrderNo}
                onEnter={handleFromStockOrder}
                OnChange={onMasterChange}
              />
            </Grid>
            
            <Grid
              item
              xs={3}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px",
                justifyContent: "left",
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                }
                label={t("All Product")}
                onClick={() => {
                  setAllProductOrProductGroup("all-product");
                  setState((prevState) => ({
                    ...prevState,
                    ProductGroup: null,
                  }));
                }}
                checked={allProductOrProductGroup === "all-product"}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  />
                }
                label={"Product Group"}
                onClick={() => {
                  const productGroup = dataState.ProductGroupResponse.find(
                    (item) => item.IsDefault
                  );
                  setAllProductOrProductGroup("product-group");
                  setState((prevState) => ({
                    ...prevState,
                    ProductGroup: productGroup,
                  }));
                }}
                checked={allProductOrProductGroup === "product-group"}
              />
            </Grid>
            <Grid
              item
              xs={3}
              sx={{ display: "flex", alignItems: "center", gap: "8px" }}
            >
              {allProductOrProductGroup === "product-group" && (
                <InvoiceAutoComplete
                  name={"ProductGroup"}
                  label={"Select a Product"}
                  optionLabel={"GroupName"}
                  List={dataState.ProductGroupResponse}
                  Value={state.ProductGroup}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                  placeholder={"ProductGroup"}
                />
              )}
              {IsLoading ? (
                <LoadingButton />
              ) : (
                <AddButton t={t} onClick={handleProductAddButtonClick} />
              )}
            </Grid>
          </Grid>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "500px",
          }}
        >
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine={RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <AddLineButton t={t} onClick={AddLine} />

          <Box
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px", // Adjust the gap between the span and BarcodeTextBox
              height: "40px",
            }}
          >
            <Typography>Add By Barcode</Typography>
            <InvoiceBarCodeTextBox
              onEnter={addByBarCode}
              placeholder={" Add By Barcode"}
            />
          </Box>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}

          <Box>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </Box>

          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails
            state={state}
            is_manual_roundoff={isManualRoundOff}
            setIs_manual_roundoff={setIsManualRoundOff}
            onChange={onMasterChange}
          />
        </Box>
      </Paper>
      <CustomVoucher
        open={openCustomVoucher}
        setCustomVoucher={setOpenCustomVoucher}
        state={dataState}
        handleChange={handleChange}
        CustomVoucherSet={CustomVoucherSet}
        left={380}
      />
    </>
  );
};

export default CreateStockTransfer;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "69%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
}));
