import KSASettings from "../../Pages/Settings/Pages/KSASettings";
import TransactionNos from "../../Pages/Settings/Pages/TransactionNos";
import UserRoles from "../../Pages/Settings/Pages/UserRoles";
import Users from "../../Pages/Settings/Pages/Users";
import WhatsNew from "../../Pages/Settings/Pages/WhatsNew";
import Settings from "../../Pages/Settings/Settings";
import General from "../../Pages/Settings/Pages/General";

const settingsRout = {
  path: "settings",
  element: <Settings />,
  children: [
    { path: "", element: <General />, index: true },
    { path: "transaction-nos", element: <TransactionNos /> },
    { path: "users", element: <Users /> },
    { path: "user-roles", element: <UserRoles /> },
    { path: "whats-new", element: <WhatsNew /> },
    { path: "e-invoice", element: <KSASettings /> },
  ],
};
export default settingsRout;
