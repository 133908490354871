import { Box, Divider, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import VBInputField from "../../../../Components/Utils/VBInputField";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { CallRoutes } from "../../../../Api/Reports/CommonReportApi";
import { useSelector } from "react-redux";
import { price_category } from "../../../../Api/CommonApi/CommonApis";
import { useTranslation } from "react-i18next";



const Transactions = ({ uploadDetail, setUploadDetail ,gstTreatmentList,vatTreatmentList,is_edit,
  dataLists,setDataLists,
}) => {
  const [t] = useTranslation("common");
  const { CompanyID, BranchID, CountryCode } = useSelector((state) => state.companyDetails);
  const { Country } = useSelector((state) => state.branchSettings);
  const { EnableEinvoiceKSA } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  const {
    PriceDecimalPoint,
    VAT,
    GST,
    Tax1,
    Tax2,
    Tax3,
    EnableCreditPeriod,
    EnableCreditLimit,
    EnableRoute,
    PriceCategory,
    EnableVbassist,
    UpdationDelayCount,
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  return (
    <>
      <Typography sx={{ fontSize: "22px", fontWeight: "600", mb: 1 }}>
      {t("Transactions Details")}
      </Typography>
      <Divider sx={{ mb: 3 }} />

      <form id="FormTwoCos" >
      <Box sx={{ flexGrow: 1, minWidth: 700, width: "50%" }}>
        <Grid container spacing={{ xs: 2 }} sx={{ mb: 10 }}>
          {EnableCreditPeriod && (
            <Grid item xs={6}>
              <VBInputField
                label="Credit Period"
                type="number"
                value={uploadDetail.creditPeriod}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    creditPeriod: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {EnableCreditLimit && (
            <Grid item xs={6}>
              <VBInputField
                label="Credit Limit:"
                type="number"
                value={uploadDetail.creditLimit}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    creditLimit: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {PriceCategory && (
            <Grid item xs={6}>
              <ViknAutoComplete
                label="Price Category"
                placeholder={"  "}
                options={dataLists.categoryList}
                getOptionLabel={(e) => e.PriceCategoryName}
                value={uploadDetail.priceCategory}
                onChange={(e, n) => {
                  setUploadDetail({
                    ...uploadDetail,
                    priceCategory: n,
                  });
                }}
              />
            </Grid>
          )}
          {Country === '94e4ce66-26cc-4851-af1e-ecc068e80224' && EnableEinvoiceKSA && (
          <Grid item xs={6}>
            <ViknAutoComplete
              label="Party Identification Code"
              placeholder={"  "}
              options={dataLists?.partyIdentificationCodeList}
              getOptionLabel={(e) => (e?.code || '') + ' - ' +(e?.description||"")}
              value={uploadDetail?.partyIdentificationCode}
              onChange={(e, n) => {
                setUploadDetail({
                  ...uploadDetail,
                  partyIdentificationCode: n,
                });
              }}
            />
          </Grid>
          )}
          {EnableRoute && (
            <Grid item xs={6}>
              <ViknAutoComplete
                label="Routes"
                placeholder={"  "}
                options={dataLists.routList}
                getOptionLabel={(e) => e.RouteName}
                value={uploadDetail.routes}
                onChange={(e, n) => {
                  setUploadDetail({
                    ...uploadDetail,
                    routes: n,
                  });
                }}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <VBInputField
              label={"ID No"}
              value={uploadDetail.PanNumber}
              onChange={(e) => {
                setUploadDetail({
                  ...uploadDetail,
                  PanNumber: e.target.value,
                });
              }}
            />
          </Grid>
          
          <Grid item xs={6}>
            <VBInputField
              label="Registration"
              value={uploadDetail.CRNo}
              onChange={(e) => {
                setUploadDetail({
                  ...uploadDetail,
                  CRNo: e.target.value,
                });
              }}
            />
          </Grid>
          {(VAT || GST) && (
            <Grid item xs={6}>
              <ViknAutoComplete
                label={GST ? "Tax Treatment" : "VAT Treatment"}
                placeholder={" "}
                required={true}
                options={GST ? gstTreatmentList : vatTreatmentList}
                getOptionLabel={(e) => e.name}
                value={uploadDetail.treatment}
                onChange={(e, n) => {
                  setUploadDetail({
                    ...uploadDetail,
                    [GST ? "GST_Treatment" : "VAT_Treatment"]: n?.value,treatment: n,
                  });
                  setDataLists({
                    ...dataLists,
                    treatment: n,
                  });
                }}
              />
            </Grid>
          )}
          {VAT && uploadDetail.treatment?.value === "0" && (
            <Grid item xs={6}>
              <VBInputField
                label="VAT No:"
                required
                value={uploadDetail.VATNumber}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    VATNumber: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
          {GST && !["2", "3", "4"].includes(uploadDetail.treatment?.value) && (
            <Grid item xs={6}>
              <VBInputField
                label="GST No:"
                required
                value={uploadDetail.GSTNumber}
                onChange={(e) => {
                  setUploadDetail({
                    ...uploadDetail,
                    GSTNumber: e.target.value,
                  });
                }}
              />
            </Grid>
          )}
        </Grid>
      </Box>
      </form>
    </>
  );
};

export default Transactions;
