import { FormControlLabel, FormGroup, Switch, styled } from "@mui/material";
import React from "react";

const CustomSwitch = styled(Switch)(({ theme }) => ({
  padding: 8,
  "& .MuiSwitch-track": {
    backgroundColor: theme.palette.mode === "light" ? "#878787" : "#39393D",
    borderRadius: 22 / 2,
  },
  "& .MuiSwitch-switchBase": {
    "&.Mui-checked": {
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#0A9EF3",
        opacity: 1,
        border: 0,
      },
    },
  },
  "& + .MuiSwitch-track": {
    backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#9974EF",
    opacity: 1,
    border: 0,
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "none",
    width: 16,
    height: 16,
    margin: 2,
  },
}));
const VBSwitch = ({label, labelPlacement, labelColor, ...props}) => {
  return (
    <FormGroup>
      <FormControlLabel
        sx={{
          ".MuiTypography-root": {
            fontSize: "12px",
            color: labelPlacement || "gray"
          }
        }}
        control={<CustomSwitch value={true} {...props} />}
        label={label}
        labelPlacement={labelPlacement}
      />
    </FormGroup>
  );
};

export default VBSwitch;
