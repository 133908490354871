import { store } from "./app/store";
function getCookie(name) {
  let nameEQ = name + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}
export function Decrypt(word, key = "share") {
  var CryptoJS = require("crypto-js");
  let decData = CryptoJS.enc.Base64.parse(word).toString(CryptoJS.enc.Utf8);
  let bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8);
  return JSON.parse(bytes);
}
export function Encrypt(word, key = "share") {
  var CryptoJS = require("crypto-js");
  let encJson = CryptoJS.AES.encrypt(JSON.stringify(word), key).toString();
  let encData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson));
  return encData;
}
// const getCookie = (name) => {
//     const value = `; ${document.cookie}`;
//     const parts = value.split(`; ${name}=`);
//     if (parts.length === 2) return parts.pop().split(";").shift();
//   };

// function formatNumber(num) {
//   const state = store.getState();
//   const { isPriceRounding, lang, currencyFormat } = state.appSettingsSclice;
//   const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;
//   if (isPriceRounding) {
//     let value = Number(num).toLocaleString(`${lang}-${currencyFormat}`, {
//       minimumFractionDigits: Number(PriceDecimalPoint),
//     });
//     // console.log(isNaN(value));
//     return isNaN(value) ? Number(num).toFixed( Number(PriceDecimalPoint)): value === "NaN" ?Number(num).toFixed( Number(PriceDecimalPoint)): value
//   }
//   if (num===undefined){
//     num = 0
//   }
//   console.log(num);
//   return num
// }

function formatNumber(num) {
  if (num === undefined || num === null || num === "" || isNaN(num)) {
    return num;
  }

  const state = store.getState();
  const { isPriceRounding, lang, currencyFormat } = state.appSettingsSclice;
  const { PriceDecimalPoint } = state.generalSettingsSclice.generalSettings;

  if (isPriceRounding) {
    const parsedNum = parseFloat(num);
    if (isNaN(parsedNum)) {
      return num.toFixed(Number(PriceDecimalPoint));
    } else {
      return parsedNum.toLocaleString(`${"en"}-${currencyFormat}`, {
        minimumFractionDigits: Number(PriceDecimalPoint),
        maximumFractionDigits: Number(PriceDecimalPoint),
      });
    }
  }

  // console.log(num);
  return num;
}

// Function to convert object to query parameters
function objectToQueryString(obj) {
  const queryParams = [];
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const encodedKey = encodeURIComponent(key);
      const encodedValue = encodeURIComponent(obj[key]);
      queryParams.push(`${encodedKey}=${encodedValue}`);
    }
  }
  return queryParams.join("&");
}

// JS Objects to FormData Use this function only for the nested array and object. You do this function with axios also
function jsObjtoFormData(obj) {
  const formData = new FormData();

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      try {
        // Handle undefined values by appending an empty string
        if (obj[key] === undefined) {
          formData.append(key, "");
        } 
        // Handle File objects
        else if (obj[key] instanceof File) {
          formData.append(key, obj[key], obj[key].name);
        } 
        // Handle arrays and objects by serializing them into JSON strings
        else if (Array.isArray(obj[key]) || typeof obj[key] === "object") {
          // JSON.stringify might throw an error, so we wrap it in try-catch
          try {
            formData.append(key, JSON.stringify(obj[key]));
          } catch (jsonError) {
            console.error(`Error serializing ${key}:`, jsonError);
            formData.append(key, "");
          }
        } 
        // Handle other types (string, number, etc.)
        else {
          formData.append(key, obj[key]);
        }
      } catch (error) {
        console.error(`Error processing key "${key}":`, error);
        // You can append an empty string or handle the error differently here
        formData.append(key, "");
        
        // This is very bad code and it herts you so much
        // jsObjtoFormData(obj)
      }
    }
  }

  return formData;
}



function customRound(x) {
  const rounded = Math.round(x * 100) / 100;

  if (rounded - x === 0.5) {
    return rounded;
  }

  return rounded;
}





export const dataURLtoFile = async (dataurl) => {
  let filename = dataurl.substring(
    dataurl.lastIndexOf("/") + 1,
    dataurl.length
  );

  let response = await fetch(dataurl);
  console.log(response);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };
  let fileobj = new File([data], filename, metadata);
  let file = [
    {
      originFileObj: fileobj,
      uid: "1",
      name: filename,
      url: dataurl,
    },
  ];
  return fileobj;
};


export { getCookie, formatNumber, objectToQueryString, jsObjtoFormData };
