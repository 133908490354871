import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Tab,
  Tabs,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { paletteChangePrimary, typographyChange } from "../../features/themeSclice";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 550,
  backgroundColor: "background.paper",
  borderRadius: "15px",
  boxShadow: 24,
  //   padding: 1,
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 1, width: "100%" }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}


function AppSettings({ open, handleClose }) {
  const dispatch = useDispatch();
  
  const theme = useSelector((state) => state.themeSclice);
  console.log(theme);
  
  
  const [value, setValue] = useState(1);
  const [color, setColor] = useState(theme.palette.colorValue);
  const [font, setFont] = useState("Poppins");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handldeColor = function (event) {
    setColor(event.target.value);
    dispatch(
      paletteChangePrimary({color: event.target.value})
    )
  };

  const handleFont = function (event) {
    setFont(event.target.value);
    let font;
    switch (event.target.value) {
      case "Poppins":
        font = "Poppins";
        break;
      case "timesRoman":
        font = "Luxurious Roman";
        break;
      case "Roboto":
        font = "Roboto";
        break;

      default:
        font = "Poppins";
        break;
    }
    dispatch(
      typographyChange({fontFamily: font})
    )
  };
  

  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack sx={{ flexDirection: "row", gap: 1 }}>
          <Paper
            elevation={0}
            sx={{
              bgcolor: "background.default",
              minHeight: 300,
              borderRadius: "15px 0px 0px 15px",
              width: "30%",
            }}
          >
            <Box sx={{ height: "100%" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                variant="standard"
                orientation="vertical"
              >
                <Tab
                  sx={{ textAlign: "left" }}
                  label="General"
                  {...a11yProps(0)}
                />
                <Tab
                  sx={{ textAlign: "left" }}
                  label="Theme"
                  {...a11yProps(1)}
                />
                <Tab
                  sx={{ textAlign: "left" }}
                  label="Shortcuts"
                  {...a11yProps(2)}
                />
                <Tab
                  sx={{ textAlign: "left" }}
                  label="Abous"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Paper>
          <Box sx={{ width: "70%" }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end", pr: 2 }}>
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <CustomTabPanel value={value} index={0}>
              Item Two
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <FormControl sx={{ mb: 2 }}>
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{ fontWeight: "500", fontSize: "20px" }}
                >
                  Font Family
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={font}
                  onChange={handleFont}
                >
                  <FormControlLabel
                    value="Poppins"
                    control={<Radio />}
                    label="Poppins"
                  />
                  <FormControlLabel
                    value="timesRoman"
                    control={<Radio />}
                    label="Times New Romans"
                  />
                  <FormControlLabel
                    value="Roboto"
                    control={<Radio />}
                    label="Roboto"
                  />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel
                  id="demo-row-radio-buttons-group-label"
                  sx={{ fontWeight: "500", fontSize: "20px" }}
                >
                  Color
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={color}
                  onChange={handldeColor}
                >
                  <FormControlLabel
                    value="blue"
                    control={
                      <Radio
                        sx={{
                          color: "#0A9EF3",
                          "&.Mui-checked": {
                            color: "#0A9EF3",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value="gree"
                    control={
                      <Radio
                        sx={{
                          color: "#2e7d32",
                          "&.Mui-checked": {
                            color: "#2e7d32",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value="red"
                    control={
                      <Radio
                        sx={{
                          color: "#FF0000",
                          "&.Mui-checked": {
                            color: "#FF0000",
                          },
                        }}
                      />
                    }
                  />
                  <FormControlLabel
                    value="yellow"
                    control={
                      <Radio
                        sx={{
                          color: "#ed6c02",
                          "&.Mui-checked": {
                            color: "#ed6c02",
                          },
                        }}
                      />
                    }
                  />
                </RadioGroup>
              </FormControl>
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              Item Three
            </CustomTabPanel>
          </Box>
        </Stack>
      </Box>
    </Modal>
  );
}

export default AppSettings;
