import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  VBTableCellBody,
  VBTableCellFooter,
  VBTableCellHeader,
  VBTableContainer,
  VBTableFooter,
  VBTableHeader,
} from "../../../../Components/ReportTable/ReportTable";
import {
  Autocomplete,
  Button,
  IconButton,
  InputBase,
  Paper,
  styled,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import { formatNumber } from "../../../../generalFunction";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";

const Bom = ({
  dataDetail,
  setDataDetail,
  dataList,
  AddNewBomList,
  callBomProdUnit,
  DefaultBomList,
}) => {
  const [t] = useTranslation("common");
  const [inputDisable, setInputDisable] = useState(false);

  // const testState = useState(null)
  // console.log(testState, "this is one");

  const handleProductGroup = async function (v, ind) {
    let updatedList;
    let bomTotal;

    if (v?.id) {
      setInputDisable(true);
      // console.log(inputDisable," how os this working in here");

      try {
        const res = await callBomProdUnit(v.id);

        if (res) {
          updatedList = dataDetail.BomList.map((item, i) => {
            if (i === ind) {
              return {
                ...item,
                ...res,
              };
            }
            return item;
          });
          bomTotal = {
            TotalQty: 1 + dataDetail.BomTotal.TotalQty,
            TotalUnitPrice: dataDetail.BomTotal.TotalUnitPrice + res.UnitPrice,
            TotalAmountPrice:
              dataDetail.BomTotal.TotalAmountPrice + +res.UnitPrice,
          };
          // console.log(bomTotal, "123456789074185296", res.UnitPrice, dataDetail.BomTotal.TotalUnitPrice);
        }
      } catch (error) {
        console.error("Error calling BomProdUnit:", error);
      }
    } else {
      // No ID case
      updatedList = dataDetail.BomList.map((item, i) =>
        i === ind ? DefaultBomList : item
      );
    }

    // Always set state after processing
    setDataDetail((prev) => ({
      ...prev,
      BomList: updatedList || prev.BomList,
      BomTotal: bomTotal || prev.BomTotal,
      searchBomText: "",
    }));
    setInputDisable(false);
  };

  const handleUnitName = function (val, ind) {
    let updatedList = dataDetail.BomList;
    updatedList = dataDetail.BomList.map((e, i) => ({
      ...e,
      UnitName: i === ind ? val.UnitName : e.UnitName,
    }));
    setDataDetail({
      ...dataDetail,
      BomList: updatedList,
    });
  };

  return (
    <Paper
      sx={{ width: "100%", height: "100%", position: "relative" }}
      elevation={0}
    >
      <TableContainer sx={{ height: "100%" }}>
        <Table stickyHeader aria-label="sticky table" sx={{ height: "100%" }}>
          <VBTableHeader>
            <VBTableCellHeader sx={{ textTransform: "uppercase", width: "5%" }}>
              {t("SI NO")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "30%" }}
            >
              {t("Product Name")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "20%" }}
            >
              {t("Quantity")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "20%" }}
            >
              {t("Unit")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "10%" }}
            >
              {t("Rate")}
            </VBTableCellHeader>
            <VBTableCellHeader
              sx={{ textTransform: "uppercase", width: "10%" }}
            >
              {t("Amount")}
            </VBTableCellHeader>
            <VBTableCellHeader sx={{ width: "5%" }}> </VBTableCellHeader>
          </VBTableHeader>
          <TableBody>
            {dataDetail.BomList.map((ele, ind) => (
              <TableRow key={ind + 1}>
                <VBTableCellBody>{ind + 1}</VBTableCellBody>

                <VBTableCellBody>
                  <Autocomplete
                    // disablePortal
                    // clearOnEscape
                    options={dataList.productList}
                    getOptionLabel={(option) => option.ProductName}
                    value={ele.ProductName}
                    filterOptions={(options, state) => options}
                    onInputChange={(e, value) => {
                      if (!inputDisable) {
                        setDataDetail((prev) => ({
                          ...prev,
                          searchBomText: value,
                        }));
                      }
                    }}
                    onChange={(e, v) => {
                      setInputDisable(true);
                      handleProductGroup(v, ind);
                    }}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                    PaperComponent={CustomPaper}
                  />
                </VBTableCellBody>
                <VBTableCellBody>
                  <StyledInputBase
                    type="number"
                    value={ele.Qty}
                    onChange={(e) => {
                      // e.preventDefault();
                      if (ele.id !== "") {
                        const newQty = Number(e.target.value);
                        const newBomList = dataDetail.BomList.map((obj, i) => ({
                          ...obj,
                          Qty: i === ind ? newQty : obj.Qty,
                        }));
                        const newTotalQty = dataDetail.BomTotal.TotalQty - ele.Qty + newQty;

                        const newBomTotal = newBomList.reduce(
                          (total, item) => ({
                            TotalUnitPrice: total.TotalUnitPrice + Number(item.UnitPrice),
                            TotalAmountPrice: total.TotalAmountPrice + Number(item.UnitPrice) * item.Qty ,
                          }),
                          { TotalUnitPrice: 0, TotalAmountPrice: 0 }
                        );

                        setDataDetail((prev) => ({
                          ...prev,
                          BomList: newBomList,
                          BomTotal: {
                            TotalQty: newTotalQty,
                            TotalUnitPrice: newBomTotal.TotalUnitPrice,
                            TotalAmountPrice: newBomTotal.TotalAmountPrice
                          },
                        }));
                      }
                    }}
                  />
                </VBTableCellBody>
                <VBTableCellBody>
                  <Autocomplete
                    disablePortal
                    value={ele.UnitName && { UnitName: ele.UnitName }}
                    options={ele.UnitList}
                    getOptionLabel={(option) => option.UnitName}
                    // renderInput={(params) => <StyledInputBase {...params} />}
                    renderInput={(params) => (
                      <StyledTextField
                        {...params}
                        variant="standard"
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true,
                        }}
                      />
                    )}
                    onChange={(evt, val) => {
                      handleUnitName(val, ind);
                    }}
                    PaperComponent={CustomPaper}
                  />
                </VBTableCellBody>
                <VBTableCellBody>{ele.UnitPrice}</VBTableCellBody>
                <VBTableCellBody>{ele.UnitPrice * ele.Qty}</VBTableCellBody>
                <VBTableCellBody sx={{ textAlign: "right" }}>
                  {ind !== 0 && (
                    <IconButton
                      sx={{ p: 0 }}
                      onClick={(event) => {
                        const delitedList = dataDetail.BomList.filter(
                          (e, i) => i !== ind
                        );
                        setDataDetail({
                          ...dataDetail,
                          BomList: delitedList,
                          searchBomText: "",
                          BomTotal: {
                            TotalQty: dataDetail.BomTotal.TotalQty - ele.Qty,
                            TotalUnitPrice:
                              dataDetail.BomTotal.TotalUnitPrice -
                              ele.UnitPrice,
                            TotalAmountPrice: dataDetail.BomTotal.TotalAmountPrice - ele.UnitPrice * ele.Qty
                          },
                        });
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </VBTableCellBody>
              </TableRow>
            ))}
            <Button
              color="primary"
              sx={{ width: "110px" }}
              onClick={() => {
                AddNewBomList();
              }}
            >
              <span style={{ fontSize: "18px", marginRight: "6px" }}>+</span>{" "}
              Add a line
            </Button>
            <TableRow></TableRow>
          </TableBody>

          <VBTableFooter>
            <TableRow>
              <VBTableCellFooter>{t("Total")}</VBTableCellFooter>
              <VBTableCellFooter> </VBTableCellFooter>
              <VBTableCellFooter>
                {formatNumber(dataDetail.BomTotal.TotalQty)}
              </VBTableCellFooter>
              <VBTableCellFooter> </VBTableCellFooter>
              <VBTableCellFooter>
                {formatNumber(dataDetail.BomTotal.TotalUnitPrice)}
              </VBTableCellFooter>
              <VBTableCellFooter>
                {formatNumber(
                  dataDetail.BomTotal.TotalAmountPrice
                )}
              </VBTableCellFooter>
              <VBTableCellFooter></VBTableCellFooter>
            </TableRow>
          </VBTableFooter>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default Bom;
const StyledInputBase = styled(InputBase)(() => ({
  fontSize: "12px",
  width: "100%",
}));

const StyledTextField = styled(TextField)(() => ({
  width: "100%",
  "& .MuiInputBase-input": {
    fontSize: "12px",
  },
}));

const CustomPaper = styled(Paper)(() => ({
  width: "100%", // Set your desired width
}));
