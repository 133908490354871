import React from "react";
import { Box, Typography, Link as MuiLink } from "@mui/material";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VIKNBOOKS_FRONT_URL } from "../../generalVeriable";
const BottomNavigationMenu = ({ data }) => {
  const [t] = useTranslation("common");

  return (
    <Box sx={{ position: "fixed", bottom: 0, left: { xs: "-130px" }, transform: { xs: "scale(0.9)", md: "scale(1)" } }}>
      <Box>
        {data.map((item, key) => (
          <Box
            key={key + 1}
            title={item.name}
            sx={{
              border: "1px solid #d8d8d8",
              backgroundColor: "#fff",
              margin: "7px 0 7px 5px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderRadius: "5px",
              transition: "transform 0.3s ease-in-out",
              "&:hover": {
                backgroundColor: "#212121",
                transform: "translateX(65%)",
                cursor: "pointer",
                color: "#fff",
              },
            }}
          >
            {item.domain === "books" ? (
              <MuiLink
                href={`${VIKNBOOKS_FRONT_URL}${item.link}`}
                // target="_blank"
                rel="noopener noreferrer"
                sx={{
                  display: "flex",
                  padding: "10px",
                  paddingRight: "15px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  "&.selected": {
                    backgroundColor: "#212121",
                    color: "#fff",
                  },
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "#fff",
                    padding: "10px 10px",
                    width: "130px",
                    height: "33px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {t(item.name)}
                </Typography>
                <Box sx={{ width: "30px", height: "30px" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: item.rotate180 ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    src={item.image}
                    alt=""
                  />
                </Box>
              </MuiLink>
            ) : (
              <NavLink
                to={item.link}
                style={({ isActive }) => ({
                  display: "flex",
                  padding: "10px",
                  paddingRight: "15px",
                  borderRadius: "5px",
                  textDecoration: "none",
                  backgroundColor: isActive ? "#212121" : "#fff",
                  color: isActive ? "#fff" : "#000",
                })}
              >
                <Typography
                  variant="body2"
                  sx={{
                    color: "inherit", // Use inherited color to reflect the selected state
                    padding: "10px 10px",
                    width: "130px",
                    height: "33px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {t(item.name)}
                </Typography>
                <Box sx={{ width: "30px", height: "30px" }}>
                  <img
                    style={{
                      width: "100%",
                      height: "100%",
                      transform: item.rotate180 ? "rotate(180deg)" : "rotate(0deg)",
                    }}
                    src={item.image}
                    alt=""
                  />
                </Box>
              </NavLink>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default BottomNavigationMenu;
