import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Popover,
  styled,
  TableBody,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";
import UnfoldMoreIcon from "@mui/icons-material/UnfoldMore";
import AppsIcon from "@mui/icons-material/Apps";
import ListAltIcon from "@mui/icons-material/ListAlt";
import InfoIcon from "@mui/icons-material/Info";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import AddIcon from "@mui/icons-material/Add";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";

import React, { useEffect, useState } from "react";
import Pagenation from "../../../Components/Utils/Pagenation";
import { useTranslation } from "react-i18next";
import {
  download_excel,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
} from "../../../Components/ReportTable/ReportTable";
import SearchReport from "../../../Components/Utils/SearchReport";
import { Link, useNavigate } from "react-router-dom";
import VBSelect from "../../../Components/Utils/VBSelect";
import ViknAutoComplete from "../../../Components/Utils/ViknAutoComplete";
import {
  ProductDeleteURL,
  ProductExportURL,
  ProductListUrl,
  ProductUploadURL,
} from "../../../Api/Products/ProductApis";
import { useDispatch, useSelector } from "react-redux";
import {
  CallBrands,
  CallProductCategories,
  CallProductGroups,
  CallTaxListByType,
} from "../../../Api/Reports/CommonReportApi";
// import { MenuOptions } from "../../../Components/CommonComponents";
import { openSnackbar } from "../../../features/SnackBarslice";
import PaperLoading from "../../../Components/Utils/PaperLoading";
import SearchTextField from "../../../Components/Utils/SearchTextField";
import AlertWindow from "../../../Components/Utils/AlertWindow";
import { downloadFile, serializeParams } from "../../../Function/comonFunction";
import { URL } from "../../../generalVeriable";

const PER_PAGE = 15;

function ProductList() {
  const [t] = useTranslation("common");

  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { user_id } = useSelector((state) => state.user);
  const { productsForAllBranches } = useSelector(
    (state) => state.branchSettings
  );
  const { PriceDecimalPoint, QtyDecimalPoint, GST, VAT } = useSelector(
    (state) => state.generalSettingsSclice.generalSettings
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //==============================##-- Tests --##==============================//

  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Invoice"
  );
  let delete_premession = sales_permission[0].delete_permission;
  let edit_premession = sales_permission[0].edit_permission;

  const OptionList = [
    // { name: "Edit", permission: sales_edit },
    // { name: "Delete", permission: sales_delete },
  ];

  const OptionOnClick = (e, name, uniq_id) => {
    if (e) {
      let permission = OptionList.find((i) => i.name === name)?.permission;
      if (name === "Edit") {
        if (permission) {
          navigate("/create-products", {
            state: { is_edit: true, uniq_id: uniq_id },
          });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for edit",
              severity: "warning",
            })
          );
        }
      } else if (name === "Delete") {
        if (permission) {
          // setState({ ...state, selectedItems: [uniq_id] });
          // setConfirmBox(true);
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for delete",
              severity: "warning",
            })
          );
        }
      }
    }
  };

  //==============================##-- Tests --##==============================//

  const [isLoading, setIsLoading] = useState(false);
  const [gridTable, setgridTable] = useState(true);
  const [page_no, setPage_no] = useState(1);
  const [filterEl, setFilterEl] = useState(null);
  const [isOpenIndex, setIsOpenIndex] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAlert, setisAlert] = useState(false);
  const [searchText, setSearchText] = useState("");

  const handleCloseAlert = function () {
    setisAlert(false);
  };

  const handleOpenFilter = (e) => setFilterEl(e.currentTarget);
  const handleCloseFilter = () => setFilterEl(null);

  const [exportEl, setExportEl] = useState(null);
  const handleOpenExport = (e) => setExportEl(e.currentTarget);
  const handleCloseExport = () => setExportEl(null);

  const [filterState, setFilterState] = useState({
    type: "Product",
    productCategory: null,
    productGroup: null,
    brand: null,
    taxCategory: null,
    active: null,

    isProdCat: false,
    isProdGroup: false,
    isBrand: false,
    isTaxCat: false,
    isActiv: false,
    isExact: false,
    isString: false,

    SortBy: 0,
    TaxType: 0,
    tax_type: "",
    count: 0,

    ShowDescription: false,
    ShowProductImage: false,
    ShowDisplayName: false,
    ShowGroupName: false,
    ShowBrandName: false,
    ShowTaxName: false,
  });

  const [dataLists, setDataLists] = useState({
    productLists: [],
    categoryList: [],
    groupList: [],
    brandList: [],
    taxCategorylist: [],
    activeList: [
      { name: "Active", activeId: 1 },
      { name: "Inactive", activeId: 2 },
    ],
  });

  const [delProid, setDelProid] = useState([]);

  const menuLists = (obj) => [
    {
      name: "Edit",
      disabled: edit_premession,
      id: obj.id,
      func: function () {
        // console.log(this.id);
        navigate("/create-products", {
          state: { is_edit: true, uniq_id: obj.id },
        });
      },
    },
    {
      name: "Delete",
      disabled: delete_premession,
      func: function () {
        setDelProid([obj.id]);
        // deleteProduct();
        setisAlert(true);
      },
    },
  ];

  // const [fileDocs, setfileDocs] = useState(null)

  const handleFile = async function (event) {
    let ing = event.target?.files;
    if (ing) {
      const res = await ProductUploadURL({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        Type: filterState.type,
        file: ing[0],
      });

      if (res.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: `Document uploading`,
            severity: "info",
          })
        );
      }
    }
  };

  const exportExel = function () {
    // Temperoary function Remove this function and make its better version with status checking and error handling
    let params = serializeParams({
      CompanyID: CompanyID,
      BranchID: BranchID,
      UserID: user_id,
      PriceRounding: Number(PriceDecimalPoint),
    });

    console.log(params);

    let url = URL + "api/v10/products/products-export-excel?" + params;
    var element = window.document.createElement("a");
    element.href = url;
    element.target = "_blank";
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

    // Temperoary Commented Function use this as main export api request
    // ProductExportURL({
    //   CompanyID: CompanyID,
    //   BranchID: BranchID,
    //   UserID: user_id,
    //   PriceRounding: Number(PriceDecimalPoint),
    // }).then((res) => {
    //   if (res && res.status === 200) {
    //       // Use the utility function to download the file
    //       downloadFile(res.data, res.headers["content-type"]);
    //     }
    //   }
    // });
  };

  const importProduct = function () {};

  const handleMenuClick = (event, index) => {
    setAnchorEl(event.currentTarget); // Set the clicked element as anchorEl
    setIsOpenIndex(index); // Track the index of the open menu
  };

  const handleClose = () => {
    setAnchorEl(null); // Reset anchorEl to close the menu
    setIsOpenIndex(null); // Reset the open index
  };

  const tableFilter = function (item) {
    if (item === "code") {
      setFilterState({
        ...filterState,
        SortBy: filterState.SortBy === 2 ? -2 : 2,
      });
    } else if (item === "name") {
      setFilterState({
        ...filterState,
        SortBy: filterState.SortBy === 1 ? -1 : 1,
      });
    }
  };

  const handleKeyDown = (event) => {
    if (event.altKey && event.key === "w") {
      event.preventDefault();

      setFilterState((prevState) => {
        const updatedExact = !prevState.isExact;
        dispatch(
          openSnackbar({
            open: true,
            message: `${
              updatedExact ? "Enable" : "Disable"
            } string Match Search`,
            severity: "success",
          })
        );
        return {
          ...prevState,
          isExact: updatedExact,
        };
      });
    }

    if (event.altKey && event.key === "a") {
      event.preventDefault();

      setFilterState((prevState) => {
        const updatedString = !prevState.isString;
        dispatch(
          openSnackbar({
            open: true,
            message: `${
              updatedString ? "Enable" : "Disable"
            } string Exact Search`,
            severity: "success",
          })
        );
        return {
          ...prevState,
          isString: updatedString,
        };
      });
    }
  };

  // Main table Data
  const fetchProductList = async function () {
    setIsLoading(true);
    await ProductListUrl({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      PriceRounding: Number(PriceDecimalPoint),
      QtyRounding: Number(QtyDecimalPoint),
      page_no: page_no,
      items_per_page: PER_PAGE,
      GroupID: filterState.productGroup?.GroupID || 0,
      CategoryID: filterState.productCategory?.CategoryID || 0,
      BrandID: filterState.brand?.BrandID || 0,
      TaxID: filterState.taxCategory?.TaxID || 0,
      activeId: filterState.active?.activeId || 0,
      // tax_type: filterState.tax_type,
      tax_type: "",
      productsForAllBranches: productsForAllBranches,
      SortBy: filterState.SortBy,
      product_name: searchText,
      is_exact_search: !filterState.isExact,
      is_string_search: !filterState.isString,
      length: searchText.length,
    }).then((res) => {
      if (res.StatusCode === 6000) {
        // setPage_no(res.count);
        setDataLists((prev) => ({
          ...prev,
          productLists: res.data,
        }));
        setFilterState({
          ...filterState,
          count: res.count,
          ShowDescription: res.ShowDescription,
          ShowProductImage: res.ShowProductImage,
          ShowDisplayName: res.ShowDisplayName,
          ShowGroupName: res.ShowGroupName,
          ShowBrandName: res.ShowBrandName,
          ShowTaxName: res.ShowTaxName,
        });
      } else if (res.StatusCode === 6000) {
        setDataLists((prev) => ({
          ...prev,
          productLists: [],
        }));
      }
    });
    setIsLoading(false);
  };

  const initialFetch = async function () {
    const payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    };
    await CallProductCategories(payload).then((res) => {
      if (res.StatusCode === 6000) {
        setDataLists((prev) => ({
          ...prev,
          categoryList: res.data,
        }));
      }
    });

    await CallProductGroups(payload).then((res) => {
      if (res.StatusCode === 6000) {
        setDataLists((prev) => ({
          ...prev,
          groupList: res?.data || [],
        }));
      }
    });

    await CallBrands(payload).then((res) => {
      if (res.StatusCode === 6000) {
        setDataLists((prev) => ({
          ...prev,
          brandList: res?.data || [],
        }));
      }
    });

    await CallTaxListByType({
      ...payload,
      TaxType: filterState.TaxType,
    }).then((res) => {
      if (res.StatusCode === 6000) {
        setDataLists((prev) => ({
          ...prev,
          taxCategorylist: res?.data || [],
        }));
      }
    });
  };

  const deleteProduct = function () {
    // setisAlert(true);
    delProid.length !== 0 &&
      ProductDeleteURL({
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        selecte_ids: delProid,
      }).then((res) => {
        if (res.StatusCode === 6000) {
          fetchProductList();
          setDelProid([]);
        }
      });
    handleClose();
  };

  useEffect(() => {
    let TaxType = 0;
    let tax_type = "";
    if (VAT) {
      TaxType = 1;
      tax_type = "VAT";
    } else if (GST) {
      TaxType = 2;
      tax_type = "GST";
    }

    setFilterState({
      ...filterState,
      TaxType,
      tax_type,
    });
  }, [VAT, GST]);

  useEffect(() => {
    fetchProductList();
    initialFetch();
  }, []);

  useEffect(() => {
    fetchProductList();
  }, [page_no, filterState.SortBy]);

  useEffect(() => {
    page_no === 1 &&
      (searchText.length > 2 || searchText === "") &&
      fetchProductList();
  }, [searchText]);

  // ShortCut Area
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <>
      <Paper sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                mr: 1,
                color: "#001746",
              }}
              variant="h2"
            >
              {t("Product List")}
            </Typography>

            <Button
              variant="text"
              color="error"
              sx={{ fontSize: 12 }}
              onClick={() => setisAlert(true)}
            >
              {t("Delete Selected")}
            </Button>

            <Button
              sx={{ fontSize: 12 }}
              aria-describedby={
                Boolean(filterEl) ? "filter-popover" : undefined
              }
              startIcon={<FilterListIcon />}
              onClick={handleOpenFilter}
            >
              {t("Filter")}
            </Button>
            <Popover
              id={"filter-popover"}
              open={Boolean(filterEl)}
              anchorEl={filterEl}
              onClose={handleCloseFilter}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ top: 8 }}
            >
              <Box sx={{ p: 1, width: 300 }}>
                {/* <SearchReport /> */}
                <FormGroup
                  sx={{
                    px: 1,
                    mb: 1,
                    ".MuiFormControlLabel-root .MuiTypography-root": {
                      fontSize: "14px",
                    },
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={filterState.isProdCat}
                        onChange={(event) =>
                          setFilterState({
                            ...filterState,
                            isProdCat: event.target.checked,
                            productCategory: null,
                          })
                        }
                      />
                    }
                    label="Product Category"
                  />

                  <Collapse in={filterState.isProdCat}>
                    <ViknAutoComplete
                      disablePortal
                      value={filterState.productCategory}
                      options={dataLists.categoryList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setFilterState({
                          ...filterState,
                          productCategory: v,
                        });
                      }}
                    />
                  </Collapse>

                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={filterState.isProdGroup}
                        onChange={(event) =>
                          setFilterState({
                            ...filterState,
                            isProdGroup: event.target.checked,
                            productGroup: null,
                          })
                        }
                      />
                    }
                    label="Product Group"
                  />

                  <Collapse in={filterState.isProdGroup}>
                    <ViknAutoComplete
                      disablePortal
                      value={filterState.productGroup}
                      options={dataLists.groupList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setFilterState({
                          ...filterState,
                          productGroup: v,
                        });
                      }}
                    />
                  </Collapse>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={filterState.isBrand}
                        onChange={(event) =>
                          setFilterState({
                            ...filterState,
                            isBrand: event.target.checked,
                            brand: null,
                          })
                        }
                      />
                    }
                    label="Brand"
                  />
                  <Collapse in={filterState.isBrand}>
                    <ViknAutoComplete
                      disablePortal
                      value={filterState.brand}
                      options={dataLists.brandList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setFilterState({
                          ...filterState,
                          brand: v,
                        });
                      }}
                    />
                  </Collapse>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={filterState.isTaxCat}
                        onChange={(event) =>
                          setFilterState({
                            ...filterState,
                            isTaxCat: event.target.checked,
                            taxCategory: null,
                          })
                        }
                      />
                    }
                    label="Tax Category"
                  />
                  <Collapse in={filterState.isTaxCat}>
                    <ViknAutoComplete
                      disablePortal
                      value={filterState.taxCategory}
                      options={dataLists.taxCategorylist}
                      getOptionLabel={(option) => option.TaxName}
                      onChange={(e, v) => {
                        setFilterState({
                          ...filterState,
                          taxCategory: v,
                        });
                      }}
                    />
                  </Collapse>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={filterState.isActiv}
                        onChange={(event) =>
                          setFilterState({
                            ...filterState,
                            isActiv: event.target.checked,
                            active: null,
                          })
                        }
                      />
                    }
                    label="Active"
                  />
                  <Collapse in={filterState.isActiv}>
                    <ViknAutoComplete
                      disablePortal
                      value={filterState.active}
                      options={dataLists.activeList}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, v) => {
                        setFilterState({
                          ...filterState,
                          active: v,
                        });
                      }}
                    />
                  </Collapse>
                </FormGroup>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    variant="outlined"
                    startIcon={<FilterListIcon />}
                    onClick={() => {
                      page_no === 1 ? fetchProductList() : setPage_no(1);
                    }}
                  >
                    {t("Filter")}
                  </Button>
                </Box>
              </Box>
            </Popover>
            <SearchTextField
              value={searchText}
              escClearState={setSearchText}
              onChange={(e) => {
                setSearchText(e.target.value);
                setPage_no(1);
              }}
            />
          </Box>

          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "6px" }}>
              <IconButton
                sx={{
                  border: !gridTable && 1,
                  borderRadius: !gridTable && 1,
                  p: "2px",
                }}
                onClick={() => {
                  setgridTable(false);
                }}
              >
                <AppsIcon fontSize="small" />
              </IconButton>
              <Divider
                orientation="vertical"
                variant="inset"
                flexItem
                sx={{ ml: 0 }}
              />
              <IconButton
                sx={{
                  border: gridTable && 1,
                  borderRadius: gridTable && 1,
                  p: "2px",
                }}
                onClick={() => {
                  setgridTable(true);
                }}
              >
                <ListAltIcon fontSize="small" />
              </IconButton>
            </Box>

            <Tooltip
              title={
                <div>
                  Shortcuts : <br />
                  Enable String Match Search : alt + w <br />
                  Enable String Exact Search : alt + a
                </div>
              }
              sx={{ color: "#bdbdbd", cursor: "help" }}
            >
              <InfoIcon fontSize="small" />
            </Tooltip>

            <Button
              sx={{ fontSize: 12, color: "text.primary" }}
              startIcon={<ImportExportIcon />}
              onClick={() => {
                exportExel();
              }}
            >
              Export
            </Button>
            <Button
              sx={{ fontSize: 12, color: "text.primary" }}
              startIcon={<ImportExportIcon />}
              aria-describedby={
                Boolean(exportEl) ? "export-popover" : undefined
              }
              onClick={handleOpenExport}
            >
              Import
            </Button>
            <Popover
              id={"export-popover"}
              open={Boolean(exportEl)}
              anchorEl={exportEl}
              onClose={handleCloseExport}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{ top: 8 }}
            >
              <Box sx={{ p: 2, minWidth: 250 }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
                >
                  <DocumentScannerIcon />
                  <Typography variant="body2">Import From Excel</Typography>
                </Box>
                <Typography variant="caption">Upload File</Typography>
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    sx={{ boxShadow: "none", p: 1, py: "3px" }}
                    onClick={() =>
                      document.getElementById("uploadDocs").click()
                    }
                  >
                    Upload
                  </Button>
                  <Typography variant="caption">choose a file</Typography>
                </Box>
                <input
                  type="file"
                  accept="docs/*"
                  id="uploadDocs"
                  style={{ display: "none" }}
                  onChange={handleFile}
                />
                <VBSelect
                  value={filterState.type}
                  options={["Product", "Multi Unit"]}
                  handleChange={(e) =>
                    setFilterState({ ...filterState, type: e.target.value })
                  }
                />
                <Box sx={{ pt: 1, display: "flex", flexDirection: "column" }}>
                  <a href="../../documents/product.xlsx">Product.xlsx</a>
                  <a href="../../documents/multy_unit.xlsx">multy_unit.xlsx</a>
                </Box>
              </Box>
            </Popover>
            {/* <Button sx={{ boxShadow: "none" }} variant="contained" color="error">
            Delete
          </Button> */}
            <Button
              sx={{
                color: "white",
                boxShadow: "none",
                fontSize: 12,
                p: 1,
                py: "6px",
              }}
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              component={Link}
              to="/create-products"
            >
              Create New
            </Button>

            <Pagenation
              totalItem={filterState.count}
              page_no={page_no}
              setPage_no={setPage_no}
            />
          </Box>
        </Box>
        <PaperLoading isLoading={isLoading}>
          {gridTable ? (
            // <PaperLoading isLoading={isLoading}>
            <VBTableContainer isNoFilter={true} isLoading={isLoading}>
              <VBTableHeader>
                <VBTableCellHeader sx={{ textAlign: "left", width: "0%" }}>
                  <Checkbox
                    checked={dataLists.productLists.length === delProid.length} // The checked prop determines whether this checkbox is checked.
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDelProid(
                          dataLists.productLists.map((itm) => itm.id)
                        );
                      } else {
                        setDelProid([]);
                      }
                      // selectItems("all");
                    }}
                    size="small"
                    sx={{
                      p: "4px",
                    }}
                  />
                </VBTableCellHeader>
                <VBTableCellHeader sx={{ textAlign: "left", width: "7%" }}>
                  {t("Serial No")}
                </VBTableCellHeader>
                <VBTableCellHeader>
                  {t("Product Name")}
                  <IconButton size="small" onClick={() => tableFilter("name")}>
                    <UnfoldMoreIcon fontSize="small" />
                  </IconButton>
                </VBTableCellHeader>
                {/* <VBTableCellHeader>{t("First Name	")} </VBTableCellHeader>
          <VBTableCellHeader>{t("Last Name")}</VBTableCellHeader> */}
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Sales Price")}
                </VBTableCellHeader>
                <VBTableCellHeader sx={{ textAlign: "right" }}>
                  {t("Purchase Price")}
                </VBTableCellHeader>
                <VBTableCellHeader>
                  {t("Product Code")}
                  <IconButton size="small" onClick={() => tableFilter("code")}>
                    <UnfoldMoreIcon fontSize="small" />
                  </IconButton>
                </VBTableCellHeader>
                <VBTableCellHeader>{"Default Unit"}</VBTableCellHeader>
                <VBTableCellHeader sx={{ textAlign: "center" }}>
                  {"Is Inclusive"}
                </VBTableCellHeader>
                <VBTableCellHeader>{"  "}</VBTableCellHeader>
              </VBTableHeader>

              <TableBody>
                {dataLists.productLists.map((obj, ind) => (
                  <TableRow>
                    <VBTableCellBody>
                      <Checkbox
                        checked={delProid.includes(obj.id)}
                        onChange={(e) => {
                          if (!e.target.checked) {
                            setDelProid(
                              delProid.filter((itm) => itm !== obj.id)
                            );
                          } else {
                            setDelProid([...delProid, obj.id]);
                          }
                        }}
                        size="small"
                        sx={{
                          p: "4px",
                        }}
                      />
                    </VBTableCellBody>
                    <VBTableCellBody>
                      {(page_no - 1) * PER_PAGE + ind + 1}{" "}
                    </VBTableCellBody>
                    <VBTableCellBody>{obj.ProductName}</VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {obj.SalesPrice}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {obj.PurchasePrice}
                    </VBTableCellBody>
                    <VBTableCellBody>{obj.ProductCode}</VBTableCellBody>
                    <VBTableCellBody>{obj.DefaultUnitName}</VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "center" }}>
                      <Checkbox
                        checked={obj.is_inclusive}
                        size="small"
                        sx={{
                          p: "4px",
                          cursor: "auto"
                        }}
                      />
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ textAlign: "right" }}>
                      {/* <MenuOptions
                    OptionList={OptionList}
                    OptionOnClick={OptionOnClick}
                    uniq_id={obj.id}
                    // disable={
                    //   data.is_billwised || data.IsNewSale === false
                    //     ? true
                    //     : false
                    // }
                  /> */}
                      <IconButton
                        sx={{ p: "4px" }}
                        id={"basic-button" + ind}
                        aria-controls={
                          isOpenIndex === ind ? "basic-menu" + ind : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={isOpenIndex === ind ? "true" : undefined}
                        onClick={(e) => handleMenuClick(e, ind)} // Set anchor and index
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <MenuOptions
                        id={ind}
                        anchorEl={anchorEl}
                        open={isOpenIndex === ind}
                        handleClose={handleClose}
                        menuItems={menuLists(obj)}
                      />
                    </VBTableCellBody>
                  </TableRow>
                ))}
                <TableRow></TableRow>
              </TableBody>
            </VBTableContainer>
          ) : (
            // {/* </PaperLoading> */}
            <Box
              sx={{
                flexGrow: 1,
                p: 1,
                height: "calc(100% - 74px)",
                overflowY: "scroll",
              }}
            >
              <Grid container spacing={1}>
                {dataLists.productLists.map((obj, ind) => (
                  <Grid item xs={2}>
                    <Card variant="outlined" sx={{ p: 1, borderRadius: 2 }}>
                      <CardMedia
                        component="img"
                        height="154"
                        image={
                          obj.ProductImage ||
                          "../images/Empty Product state Card.svg"
                        }
                        alt="Product Image"
                        sx={{ borderRadius: 1 }}
                      />
                      <CardContent sx={{ px: 0 }}>
                        <Typography
                          color="text.secondary"
                          variant="body2"
                        >
                          {obj.ProductCode}
                        </Typography>
                        <Typography variant="body2">
                          {obj.ProductName}
                        </Typography>
                      </CardContent>
                      <CardActions
                        sx={{
                          p: 0,
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography variant="body2" color="primary">
                          {obj.ProductGroupName}
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Typography variant="body2" sx={{ fontSize: "18px" }}>
                            {obj.SalesPrice}
                          </Typography>
                          <IconButton
                            aria-label="share"
                            sx={{ p: "4px" }}
                            id={"basic-button" + ind}
                            aria-controls={
                              isOpenIndex === ind
                                ? "basic-menu" + ind
                                : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={
                              isOpenIndex === ind ? "true" : undefined
                            }
                            onClick={(e) => handleMenuClick(e, ind)}
                          >
                            <MoreVertIcon />
                          </IconButton>
                          <MenuOptions
                            id={ind}
                            anchorEl={anchorEl}
                            open={isOpenIndex === ind}
                            handleClose={handleClose}
                            menuItems={menuLists(obj)}
                          />
                        </Box>
                      </CardActions>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </PaperLoading>
      </Paper>
      <AlertWindow
      title={"Are you sure ?"}
      description={"Once deleted, you not be able to recover this data"}
        open={isAlert}
        handleClose={handleCloseAlert}
        cnfFunction={deleteProduct}
      />
    </>
  );
}

export default ProductList;

function MenuOptions({ anchorEl, open, handleClose, id, menuItems }) {
  return (
    <Menu
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      elevation={1}
      id={"basic-menu" + id}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        "aria-labelledby": "basic-button" + id,
      }}
    >
      {menuItems.map((obj, ind) => (
        <MenuItem onClick={() => obj.func()}>{obj.name}</MenuItem>
      ))}
      {/* <MenuItem onClick={handleClose}>My account</MenuItem>
      <MenuItem onClick={handleClose}>Logout</MenuItem> */}
    </Menu>
  );
}
