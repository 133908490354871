import axios from "axios";

export const get_details = async function (body) {
  
    try {
      const { data } = await axios.post("api/v11/expense/get-details/", {
     ...body,
      });
      return data;
    } catch (error) {
      console.log("error on  API", error);
      return error
    }
  };

export const CreateExpenseApi = async function (body) {
  try {
      const { data } = await axios.post("api/v11/expense/create-expense/", {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on Expense Api",error);
      return error
  }
}

export const LedgerListApi = async function (body) {
  try {
      const { data } = await axios.post("api/v11/accountLedgers/ledgerListByGroupUnderExpence/", {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on Expense Api",error);
      return error
  }
}

export const ListExpenceApi = async function (body) {
  try {
      const { data } = await axios.post("api/v11/expense/list-expense-new/", {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on Expense Api",error);
      return error
  }
}



export const SingleExpenseData = async function (body) {
  try {
      const { data } = await axios.post("api/v11/expense/get-expense-single-master/", {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on Expense Api",error);
      return error
  }
}


export const ExpenseDataDetails = async function (body) {
  try {
      const { data } = await axios.post("api/v11/expense/get-expense-single-details/", {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on Expense Api",error);
      return error
  }
}


export const EditExpenseApi = async function (body) {
  try {
      const { data } = await axios.post(`api/v11/expense/edit/expense/${body.uniq_id}/`, {
          ...body,
      })
      return data;
  } catch (error) {
      console.log("Error on Expense Api",error);
      return error
  }
}




export const DeleteExpenseApi = async function (body) {
    try {
        const { data } = await axios.post(`api/v11/expense/delete-expense/`, {
            ...body,
        })
        return data;
    } catch (error) {
        console.log("Error on Expense Api",error);
        return error
    }
  }
