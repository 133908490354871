import { Navigate } from "react-router-dom";
import { activateFunction } from "../../../Function/Editions";
import { store } from "../../../app/store";
// import { Suspense, lazy } from "react";
import Dayreport from "../../../Pages/Reports/Accounts/DayReport/Dayreport";
import CashBook from "../../../Pages/Reports/Accounts/CashBook/CashBook";
import BankBook from "../../../Pages/Reports/Accounts/BankBook/BankBook";
import ExpensesReport from "../../../Pages/Reports/Accounts/ExpensesReport/ExpensesReport";
import ReciptReport from "../../../Pages/Reports/Accounts/ReciptReport/ReciptReport";
import PaymentReport from "../../../Pages/Reports/Accounts/PaymentReport/PaymentReport";
import LedgerReport from "../../../Pages/Reports/Accounts/LedgerReport/LedgerReport";
import DailyReport from "../../../Pages/Reports/Accounts/DailyReport/DailyReport";
import BillWiseProfitReport from "../../../Pages/Reports/Accounts/BillWiseProfitReport/BillWiseProfitReport";
import BankReconcilation from "../../../Pages/Reports/Accounts/BankReconcilation/BankReconcilation";
import BillWiseReport from "../../../Pages/Reports/Accounts/BillWiseReport/BillWiseReport";
import FearaReport from "../../../Pages/Reports/Accounts/FaeraReport/FearaReport";
import QuickReport from "../../../Pages/Reports/Accounts/QuickReport/QuickReport";
import DebitorAgeingReport from "../../../Pages/Reports/Accounts/DebtorAgeingReport/DebitorAgeingReport";
import CreditorAgeingReport from "../../../Pages/Reports/Accounts/CreditorAgeingReport/CreditorAgeingReport";
import BankStatment from "../../../Pages/Reports/Accounts/BankStatment/BankStatment";
import VBAssist from "../../../Pages/Reports/Accounts/VBAssist/VBAssist";
import DebitNoteReport from "../../../Pages/Reports/Accounts/DebitNoteReport/DebitNoteReport";
import CreditNoteReport from "../../../Pages/Reports/Accounts/CreditNoteReport/CreditNoteReport";
// const Dayreport = lazy(() => import("../../../Pages/Reports/Accounts/DayReport/Dayreport"));
// const CashBook = lazy(() => import("../../../Pages/Reports/Accounts/CashBook/CashBook"));
// const BankBook = lazy(() => import("../../../Pages/Reports/Accounts/BankBook/BankBook"));
// const ExpensesReport = lazy(() => import("../../../Pages/Reports/Accounts/ExpensesReport/ExpensesReport"));
// const ReciptReport = lazy(() => import("../../../Pages/Reports/Accounts/ReciptReport/ReciptReport"));
// const PaymentReport = lazy(() => import("../../../Pages/Reports/Accounts/PaymentReport/PaymentReport"));
// const LedgerReport = lazy(() => import("../../../Pages/Reports/Accounts/LedgerReport/LedgerReport"));
// const DailyReport = lazy(() => import("../../../Pages/Reports/Accounts/DailyReport/DailyReport"));
// const BillWiseProfitReport = lazy(() => import("../../../Pages/Reports/Accounts/BillWiseProfitReport/BillWiseProfitReport"));
// const BankReconcilation = lazy(() => import("../../../Pages/Reports/Accounts/BankReconcilation/BankReconcilation"));
// const BillWiseReport = lazy(() => import("../../../Pages/Reports/Accounts/BillWiseReport/BillWiseReport"));
// const FearaReport = lazy(() => import("../../../Pages/Reports/Accounts/FaeraReport/FearaReport"));
// const QuickReport = lazy(() => import("../../../Pages/Reports/Accounts/QuickReport/QuickReport"));
// const DebitorAgeingReport = lazy(() => import("../../../Pages/Reports/Accounts/DebtorAgeingReport/DebitorAgeingReport"));
// const CreditorAgeingReport = lazy(() => import("../../../Pages/Reports/Accounts/CreditorAgeingReport/CreditorAgeingReport"));
// const BankStatment = lazy(() => import("../../../Pages/Reports/Accounts/BankStatment/BankStatment"));
// const VBAssist = lazy(() => import("../../../Pages/Reports/Accounts/VBAssist/VBAssist"));
// const DebitNoteReport = lazy(() => import("../../../Pages/Reports/Accounts/DebitNoteReport/DebitNoteReport"));
// const CreditNoteReport = lazy(() => import("../../../Pages/Reports/Accounts/CreditNoteReport/CreditNoteReport"));
const state = store.getState();
const { EnableProductBatchWise,EnableFaeraSettings } = state.generalSettingsSclice.generalSettings;

function transformString(str) {
  const words = str.split('-');
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  const transformedStr = capitalizedWords.join(' ');
  return transformedStr;
}

// Higher Order Component for protected routes
const ProtectedRoute = ({ children, authCondition, path }) => {
  let isAuthCondition = authCondition;
  document.title = transformString(path) || "Vikn Books"
  return isAuthCondition ? children : <Navigate to="/dashboard" />;
};

const route = [
  { path: "day-report", element: <Dayreport />, isAuth: activateFunction("DayReport"), },
  { path: "cashbook", element: <CashBook />, isAuth: activateFunction("BalanceSheet"),},
  { path: "bankbooks", element: <BankBook />, isAuth: true, },
  { path: "bank-reconciliation-report", element: <BankReconcilation />, isAuth: activateFunction("BankReconciliationReport"), },
  { path: "bank-statment", element: <BankStatment />, isAuth: activateFunction("BankStatmentReport"), },
  { path: "expense-report", element: <ExpensesReport />, isAuth: activateFunction("ExpenseReport"), },
  { path: "receipt-report", element: <ReciptReport />, isAuth: activateFunction("ReceiptReport"), },
  { path: "payment-report", element: <PaymentReport />, isAuth: activateFunction("PaymentReport"), },
  { path: "ledger-report", element: <LedgerReport />, isAuth: activateFunction("LedgerReport"), },
  { path: "daily-report", element: <DailyReport />, isAuth: activateFunction(""), },
  { path: "billwise-profit-report", element: <BillWiseProfitReport />, isAuth: activateFunction("BillwiseProfit"), },
  { path: "billwise-report", element: <BillWiseReport />, isAuth: activateFunction("BillwiseReport"), },
  { path: "faera-report", element: <FearaReport />, isAuth: activateFunction("Faera Report")
  // EnableProductBatchWise===true && EnableFaeraSettings===true?true:false 
},
  { path: "quick-report", element: <QuickReport />, isAuth: activateFunction("QuickReport"), },
  { path: "debtor-ageing-report", element: <DebitorAgeingReport />, isAuth: activateFunction("DebtorAgeingreport"), },
  { path: "creditor-ageing-report", element: <CreditorAgeingReport />, isAuth: activateFunction("CreditorAgeingreport"), },
  { path: "debit-note-report", element: <DebitNoteReport />, isAuth: activateFunction("DebitNote"), },
  { path: "credit-note-report", element: <CreditNoteReport />, isAuth: activateFunction("CreditNote"), },
  { path: "vb-assist-report", element: <VBAssist />, isAuth: activateFunction("VbAssistReport"), },
];

const accountRoute = route.map((route) => ({
  ...route,
  element: 
  // (
    // <Suspense fallback={<div>Loading...</div>}>
      <ProtectedRoute path={route.path} authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
    // </Suspense>
    // )
}));

export default accountRoute;
