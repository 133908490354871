import VBLogo from "./icons/Logo.svg";
import SalesLogo from "./icons/Sales.svg";
import DownArrow from "./icons/down-arrow.png";
import Purchase from "./icons/Purchase.svg";
import Receipt from "./icons/Receipt.svg";
import Payment from "./icons/Payment.svg";
import Contacts from "./icons/Contacts.svg";
import Products from "./icons/Products.svg";
import More from "./icons/more.svg";
import ReportSerarch from "./icons/search-normal.svg";
import Print from "./icons/printer.svg";
import Export from "./icons/export.svg";
import Filter from "./icons/filter_list.svg";
import SkipNext from "./icons/skip_next.svg";
import SkipPrevious from "./icons/skip_previous.svg";
import NextPage from "./icons/Next Page.svg";
import PreviousPage from "./icons/previous page.svg";
import Accounts from "./icons/Accounts.svg";
import Loyality from "./icons/Loyality.png";
import Routes from "./icons/Routes.svg";
import UNITS from "./icons/UNITS.svg";
import PriceCategory from "./icons/PriceCategory.svg";
import Financial from "./icons/Financial.png";
import Warehouse from "./icons/Warehouse.png";
import Stock from "./icons/Stock.png";
import BankReconciliation from "./icons/Bank Reconciliation.svg";
import StockOrder from "./icons/Stock Order.svg";
import Manufacturing from "./icons/Manufacturing.svg";
import BranchTransfer from "./icons/Branch Transfer.svg";
import DeliveryNote from "./icons/Group 942.svg";
import CreditNote from "./icons/creditnote.svg";
import DebitNote from "./icons/debitnote.svg";
import settingsOption from "./icons/Mask Group 36.svg";
import GreenTick from "./icons/Group3270.svg";
import Sales from"./icons/Invoice.svg";
import PurchaseInvoice from"./icons/PurchaseInvoice.svg";
import Estimate from"./icons/Estimate.svg";
import Order from"./icons/Order.svg";
import Supplier from"./icons/Supplier.svg";
import Return from"./icons/Return.svg";
import Customer from"./icons/Customer.svg";
import Expense from"./icons/Expense.svg";
import Journal from"./icons/Journal.svg";
import Report from"./icons/Report.svg";
import Employee from"./icons/Employee.svg";
import OpeningStock from"./icons/OpeningStock.png";
import AccountLedger from"./icons/AccountLedger.svg";
import Bank from"./icons/Bank.svg";
import Tax from"./icons/Tax.svg";
import SalesBT from"./icons/Sales Invoice BT.svg";
import PurchaseBT from"./icons/Purchase Invoice BT.svg";
import PurchaseReturn from"./icons/PurchaseReturn.svg";
import PurchaseOrder from"./icons/PurchaseOrder.svg";
import WorkOrder from"./icons/WorkOrder.png";
import StockTransfer from"./icons/StockTransfer.png";
import StockManagement from"./icons/StockManagement.png";
import Tick from"./icons/tick.svg";
import QuickEdit from"./icons/quickedit.svg";
import Remove from"./icons/remove.svg";
import Plus from"./icons/plus.svg";
import ImportIcon from "./icons/Import.svg"
import ClearIcon from "./icons/Close.svg"
import AddIcon from "./icons/Add.svg"
import Refresh from "./icons/Refresh.svg"

const Icons = {VBLogo,DownArrow,SalesLogo,Purchase,Receipt,Payment,Contacts,Products,Report,More,ReportSerarch,Print,Export,Filter,SkipNext,SkipPrevious,NextPage,PreviousPage,Accounts,Loyality,Routes,UNITS,PriceCategory,Financial,Warehouse,Stock,BankReconciliation,StockOrder,Manufacturing,BranchTransfer,DeliveryNote,CreditNote,DebitNote,settingsOption,GreenTick,Sales,
  PurchaseInvoice,Estimate,Order,
  Supplier,
  Return,
  Customer,
  Expense,
  Journal,
  Employee,
  OpeningStock,
  AccountLedger,
  Bank,
  Tax,
  SalesBT,
  PurchaseBT,
  PurchaseReturn,
  PurchaseOrder,
  WorkOrder,
  StockTransfer,
  StockManagement,Tick,
  QuickEdit,Remove,Plus,
  ImportIcon,
  ClearIcon,
  AddIcon,
  Refresh
};

export { Icons };
