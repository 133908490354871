import CreateExpense from "../../Pages/Expense/CreateExpense";
import ListExpense from "../../Pages/Expense/ListExpense";

const ExpenseRoutes = [
    {
      path: "create-expense",
      element: <CreateExpense/>,
      isAuth: true,
    },
    {
      path: "list-expense",
      element: <ListExpense/>,
      isAuth: true,
    },

  ];
  
  export default ExpenseRoutes;