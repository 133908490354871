import { store } from "../app/store";
const state = store.getState();
const { EnableProductBatchWise,GST,VAT,EnableFaeraSettings } = state.generalSettingsSclice.generalSettings;

// console.log(EnableProductBatchWise, "Edit essentials________***________");

const getEnableProductBatchWise = () => {
  const state = store.getState();
  return state.generalSettingsSclice.generalSettings.EnableProductBatchWise;
}

export const Lite={
  "LoyaltyCustomer":false,
  "Loyalty Customer":false,
  "LoyalityProgram":false,
  "Loyalty Program":false,
  "Loyalty":false,
  "BankReconciliation":false,
  "Bank Reconciliation":false,
  "BankReconciliationReport":false,
  "BankStatmentReport":false,
  "Purchase Return Register Report":false,
  "PurchaseReturnRegisterReport":false,
  "ProductReport":false,
  "Product Report":false,
  "ShortageStockReport":false,
  "Shortage Stock Report":false,
  "ExcessStockReport":false,
  "Excess Stock Report":false,
  "VanReport":false,
  "VanStockReport":false,
  "ActivityLog":false,
  "VanSalesSettings":false,
  "Manufacture":false,

  "DashboardChart":false,
  "ProductSummary":false,
  "OutstandingReport":false,
  "Outstanding Report":false,
  "List":false,
  "ListReport":false,
  "Sales Order":false,
  "SalesSummary":false,
  "Sales Summary Report":false,
  "BillwiseProfit":false,
  "BillwiseReport":false,
  "Bill Wise Report":false,
  "Purchase Estimate":false,
  "Warehouse":false,
  "Shelf":false,
  "WorkOrder":false,
  "Work Order":false,
  "StockTransfer":false,
  "Stock Transfer":false,
  "Inventory":false,
  "StockTransferRegister":false,
  "SupplierVsProductReport":false,
  "Supplier Vs Product Report":false,
  "ProductAnalysisReport":false,
  "Product Analysis Report":false,
  "BatchWiseReport":false,
  "Batch Wise Report":false,
  "PriceChecker":false,
  "Route":false,
  "Enable route":false,
  "StockOrder":false,
  "StockManagement":false,
  "Stock Management":false,
  "PriceCategory":false,
  "Price Category":false,
  "SalesRegisterReport":false,
  "Sales Register Report":false,
  "SalesIntegratedReport":false,
  "Sales Integrated Report":false,
  "SalesReturnRegisterReport":false,
  "Sales Return Register Report":false,
  "PurchaseRegisterReport":false,
  "Purchase Register Report":false,
  "PurchaseIntegratedReport":false,
  "Purchase Integrated Report":false,
  "CreditorAgeingreport":false,
  "DebtorAgeingreport":false,
  "ExpenseSummary":false,
  "QuickReport":false,
  "BranchTransfer":false,
  "EnableForeignCurrency":false,
  "EnableAdditionalExpense":false,
  "DebitNote":false,
  "CreditNote":false,
  // "BatchReport":EnableProductBatchWise === true?true:false,
  // "Batch Report":EnableProductBatchWise === true?true:false,
  "Sales GST Report":GST === true?true:false,
  "Purchase GST Report":GST === true?true:false,
  "GSTR3B":GST === true?true:false,
  "Sales VAT Report" :VAT === true?true:false,
  "Purchase VAT Report":VAT === true?true:false,

  "OpeningStock":false,
  "Opening Stock":false,
  "AccountGroup":false,
  "Account Group":false,
  "PurchaseTaxReport":false,
  "SalesTaxReport":false,
  "TrialBalance":false,
  "Trial Balance":false,
  "TradingProfitAndLossAccount":false,
  "Profit And Loss Account":false,
  "BalanceSheet":false,
  "Balance Sheet":false,
  "SalesOrder":false,
  "SalesOrderReport":false,
  "SalesEstimate":false,
  "Sales Estimate":false,
  "ExpenseReport":false,
  "ExpenseList":false,
  "MultiUnit":false,
  "StockLedgerReport":false,
  "StockValueReport":false,
  "Stock Value Report":false,
  "DailyReport":false,
  "Stock Ledger Report":false,
  "DeliveryNote":false,
  "BOM":false,
  "ProductWarehouseReport":false,
  "faera report": EnableFaeraSettings ===true ? true:false,
  // "VATReport":VAT == true?true:false,
}

// const handleChange = () => {
//   const EnableProductBatchWise = getEnableProductBatchWise();
//   console.log('EnableProductBatchWise updated:', EnableProductBatchWise);

//   // Update the Lite object with the latest value
//   Lite["BatchReport"] = EnableProductBatchWise;
// }

// // Subscribe to the store
// const unsubscribe = store.subscribe(handleChange);

// Get the initial value and log it
// handleChange();

export const Essential={
  "ProductWarehouseReport":false,
  "LoyaltyCustomer":false,
  "Loyalty Customer":false,
  "LoyalityProgram":false,
  "Loyalty Program":false,
  "Loyalty":false,
  "BankReconciliation":false,
  "Bank Reconciliation":false,
  "BankReconciliationReport":false,
  "BankStatmentReport":false,
  "Purchase Return Register Report":false,
  "PurchaseReturnRegisterReport":false,
  "ProductReport":false,
  "Product Report":false,
  "ShortageStockReport":false,
  "Shortage Stock Report":false,
  "ExcessStockReport":false,
  "Excess Stock Report":false,
  "VanReport":false,
  "VanStockReport":false,
  "ActivityLog":false,
  "VanSalesSettings":false,
  "Manufacture":false,

  "DashboardChart":false,
  "ProductSummary":false,
  "OutstandingReport":false,
  "Outstanding Report":false,
  "List":false,
  "ListReport":false,
  "Sales Order":false,
  "SalesSummary":false,
  "Sales Summary Report":false,
  "BillwiseProfit":false,
  "BillwiseReport":false,
  "Bill Wise Report":false,
  "Purchase Estimate":false,
  "Warehouse":false,
  "Shelf":false,
  "WorkOrder":false,
  "Work Order":false,
  "StockTransfer":false,
  "Stock Transfer":false,
  "Inventory":false,
  "StockTransferRegister":false,
  "SupplierVsProductReport":false,
  "Supplier Vs Product Report":false,
  "ProductAnalysisReport":false,
  "Product Analysis Report":false,
  "BatchWiseReport":false,
  "Batch Wise Report":false,
  "PriceChecker":false,
  "Route":false,
  "Enable route":false,
  "StockOrder":false,
  "StockManagement":false,
  "Stock Management":false,
  "PriceCategory":false,
  "Price Category":false,
  "SalesRegisterReport":false,
  "Sales Register Report":false,
  "SalesIntegratedReport":false,
  "Sales Integrated Report":false,
  "SalesReturnRegisterReport":false,
  "Sales Return Register Report":false,
  "PurchaseRegisterReport":false,
  "Purchase Register Report":false,
  "PurchaseIntegratedReport":false,
  "Purchase Integrated Report":false,
  "CreditorAgeingreport":false,
  "DebtorAgeingreport":false,
  "ExpenseSummary":false,
  "QuickReport":false,
  "BranchTransfer":false,
  "EnableForeignCurrency":false,
  "EnableAdditionalExpense":false,
  "DebitNote":false,
  "CreditNote":false,
  // "BatchReport":EnableProductBatchWise == true?true:false,
  // "Batch Report":EnableProductBatchWise == true?true:false,
  "Sales GST Report":GST == true?true:false,
  "Purchase GST Report":GST == true?true:false,
  "GSTR3B":GST == true?true:false,
  "Sales VAT Report" :VAT == true?true:false,
  "Purchase VAT Report":VAT == true?true:false,

  "faera report": EnableFaeraSettings ==true ? true:false,
  // "VATReport":VAT == true?true:false,
}

export const Standard={
  "ProductWarehouseReport":false,
  "LoyaltyCustomer":false,
  "Loyalty Customer":false,
  "LoyalityProgram":false,
  "Loyalty Program":false,
  "Loyalty":false,
  "BankReconciliation":false,
  "Bank Reconciliation":false,
  "BankReconciliationReport":false,
  "BankStatmentReport":false,
  "Purchase Return Register Report":false,
  "PurchaseReturnRegisterReport":false,
  "ProductReport":false,
  "Product Report":false,
  "ShortageStockReport":false,
  "Shortage Stock Report":false,
  "ExcessStockReport":false,
  "Excess Stock Report":false,
  "VanReport":false,
  "VanStockReport":false,
  "ActivityLog":false,
  "VanSalesSettings":false,
  "Manufacture":false,
  
  "faera report":EnableFaeraSettings ===true ? true:false,
  // "VATReport":VAT == true?true:false,
}
