import React, { useState, useEffect ,useCallback} from "react";
import { Box, Button, Paper, Tab, Tabs, Typography, styled } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ClearButton, ListButton, LoadingButton, SaveButton } from '../../Components/Utils/StyledButtons';
import { InvoiceAutoComplete, InvoiceBarCodeTextBox, InvoiceDateBox, InvoiceLedgerSearch, InvoiceTextBox, InvoiceVoucherNoTextBox } from '../../Components/CommonComponents';
import MyGrid from './Details';
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { get_VoucherNo, get_pricelist_list_new_api, get_single_pricelist_new_api, get_stock_of_products_api, ledger_details_api, ledger_search_new_api, place_of_supply_api, product_search_new_api } from "../../Api/CommonApi/CommonApis";
import { openSnackbar } from "../../features/SnackBarslice";
import { debounce } from "../../Function/comonFunction";
import { LedgerDetails } from "../../Api/Purchase/PurchaseReturnApi";
import { create_user_address, list_user_address } from "../../Api/User/User";
import SummaryDetails from "../ParchaseReturn/SummaryDetails";
import NotesTextArea from "../../Components/Utils/TextArea";
import { CreateExpenseApi, EditExpenseApi, ExpenseDataDetails, SingleExpenseData, get_details } from "../../Api/Expense/ExpenceApis";
import { useNavigate, useLocation } from "react-router-dom";
import { Expense_validation, SalesInvoiceCalc, SalesLineCalc } from "./functions";
import VBExpense from "./VBExpense";


const CreateExpense = (props) => {

    const {Masterdata} = props

    const location = useLocation();
    const navigate = useNavigate();
    const [t, i18n] = useTranslation("common");
    let today = moment();
    let DefaultDate = today.format("YYYY-MM-DD");
    const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
    const { user_id } = useSelector((state) => state.user);
    const dispatch = useDispatch();
    const [is_manual_roundoff, setIs_manual_roundoff] = useState(false);
    const { Country, State } = useSelector((state) => state.companyDetails);
    const [IsBillwise, setBillwise] = useState(false);
    const [openCustomVoucher, setopenCustomVoucher] = useState(false);
    const [OpenBillWise, setOpenBillWise] = useState(false);
    const [IsBilling, setIsBilling] = useState(false);
    const params = new URLSearchParams(window.location.search);

    const uniq_id = location.state?.uniq_id ?? params.get("unq_id");
    const is_edit = location.state?.is_edit ?? params.get("is_edit");

    const [IsButtonClick, setIsButtonClick] = useState(false);


  

  const {
    PriceDecimalPoint,
    EnableBranch,
    EnableProductBatchWise,
    GST,
    VAT,
    CurrencySymbol,
    EnableSalesManInSales,
    PurchasePriceInSales,
    EnableBillwise,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    EnableEinvoiceKSA
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);

  let GeneralSettingsData = {
    EnableSalesManInSales,
    PriceDecimalPoint,
    PurchasePriceInSales,
    EnableBillwise,
    PurchasePriceInSales,
    show_brand_InsearchSale,
    show_stock_InsearchSale,
    show_purchasePrice_InsearchSale,
    show_productcode_InsearchSale,
    EnableEinvoiceKSA
  };

  const [dataState, setDataState] = useState({
    Seperator: null,
    Suffix: null,
    SuffixSeparator: null,
    PreFix: null,
    InvoiceNo: null,
    VoucherNo: "",
    deletedData: [],
    AccountLedgerData: [],
    CashList: [],
    BankList: [],
    CountryData: [],
    EmployeeData: [],
    TaxCategoryData: [],
    UserTableData: [],
    WarehouseData: [],
    PlaceOfSupplyData: [],
    PriceCategoryData: [],
    ProductList: [],
    ProductCodeList: [],
    BillWiseDatas: [],
  });

  const [state, setState] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    Employee: null,
    Warehouse: null,
    PriceCategory: null,
    Treatment: null,
    PlaceOfSupply: null,
    Date: DefaultDate,
    VenderInvoiceDate: DefaultDate,
    RefferenceBillNo: "",
    RefferenceBillDate: DefaultDate,
    BillDiscPercent: 0,
    BillDiscAmt: 0,
    CashAmount: 0,
    BankAmount: 0,
    Notes: "",
    BillingAddress:null,
    //Total
    Warehouse: null,
    TotalQty: 0,
    TotalGrossAmt: 0,
    TotalDiscount: 0,
    TotalTax: 0,
    NetTotal: 0,
    TotalVATAmount:0,
    TotalTaxableAmount: 0,
    TaxTaxableAmount: 0,
    NonTaxTaxableAmount: 0,
    GrandTotal: 0,
    LedgerValue: "",
    RoundOff: "0",
    VAT_Treatment: "",
    TreatmentDatas:null
  });
// Focous And Table Settings

  const [tableSettings, setTableSettings] = useState({
    index: true,
    Ledger: true,
    Amount: true,
    // Qty: true,
    Tax: true,
    DiscountAmount: true,
    TaxAmount: true,
    Total:true,
    Activity: true,
  });
  const [focusSettings, setFocusSettings] = useState({
    index: true,
    Ledger: true,
    Amount: true,
    // Qty: true,
    Tax: true,
    DiscountAmount: true,
    TaxAmount: true,
    Total:true,
    Activity: true,
  });


  let DataListItem = {
    BarCode: "",
    Ledger: null, 
    Qty: "",
    Tax: null,
    DiscountAmount: "",
    // ExciseTax: "",
    TaxAmount: "",
    Amount: 0,
    MRP: "",
    PurchasePrice: "",
    BatchCode: "",
    MinimumSalesPrice: "",
    AverageCost: "",
    TaxableAmount: 0,
    NonTaxableAmount: 0,
    GrossAmount: "",
    VATAmount:0,
  };


  const[BillingAddress,setBillingAddress] = useState({
    Attention : "", //Name
    PostalCode : "", //Pincode
    Address1 : "", //Building
    Address2 : "", //Area
    City : "",
    District : "",
    country : null,
    state : null,
    Mobile : "",
    CountryData:[],
    StateData:[],
    BillingAddressList:[],
  }) 

  const [DataList, setDataLists] = useState([DataListItem]);
  
  const [defaultValue, setDefaultValue] = useState({
    AccountLedger: null,
    CashAccount: null,
    BankAccount: null,
    PriceCategory: null,
    Warehouse: null,
    Treatment: null,
    PlaceOfSupply: null,
    // ShippingTax: null,
  });
  const TreatmentData = [
    { value: "0", name: "Registered Business - Regular", is_gst: true },
    { value: "1", name: "Registered Business - Composition", is_gst: true },
    { value: "2", name: "Consumer", is_gst: true, default: true },
    { value: "4", name: "Overseas", is_gst: true },
    { value: "5", name: "Special Economic Zone", is_gst: true },
    { value: "6", name: "Deemed Export", is_gst: true },
    { value: "0", name: "Business to Business(B2B)", is_vat: true },
    { value: "1", name: "Unregistered Business", is_vat: true, default: true },
    { value: "7", name: "Import", is_vat: true },
  ];

  const GST_TreatmentData = TreatmentData.filter((i) => i.is_gst === true);
  const VAT_TreatmentData = TreatmentData.filter((i) => i.is_vat === true);

  function customRound(x) {
  
    if (EnableEinvoiceKSA){    
      const rounded = Math.round(x * 100) / 100;
  
      if (rounded - x === 0.5) {
        return rounded;
      }
  
      return rounded;
    }
    else{
      return Number(x)
    }
  }
  

  const onDetailsInputChange = async (e, name) => {
    if (e && name) {
      let value = e.target.value;
      if ((name === "Ledger") && value) {
        const ProductList = await Call_LedgerSearchFunction({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
        //   WarehouseID: state?.Warehouse.WarehouseID,
          // is_product_image: false,
          length: value?.length,
          product_name: value,
          param:'name',
        
        });
        setDataState({ ...dataState, ProductList: ProductList });
      }
    }
  };
  const Call_LedgerSearchFunction = async (payload) => {
    let data = [];
    const Response = await ledger_search_new_api(payload);
    if (Response.StatusCode === 6000) {
      data = Response.data;
    } else if (Response.StatusCode === 6001) {
      data = [];
    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ? Response.message : "API error",
          severity: "error",
        })
      );
    }
    return data;
  };
  const debouncedOnDetailsInputChange = debounce(onDetailsInputChange, 300);

  const onDetailsAutoChange = async (v, index, name) => {
    console.log(v, name, "333333333");
    if (v && name && v?.id) {
      let Data = DataList;
      let lineItem = Data[index];

      if (name === "Ledger") {
        let Ledger = v;
        lineItem.Ledger = Ledger;
        let Rate = 0;
        let Qty = 1;
        let FQty = 0;
        let DiscountPerc = 0;
        let DiscountAmount = 0;
        let Amount = 0
        let Total = 0
      
        lineItem.Qty = Qty;
        lineItem.DiscountAmount = DiscountAmount;
        lineItem.Amount = Amount
        console.log(dataState.TaxCategoryData,"dataState.TaxCategoryData");
        let Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");


        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );
        console.log(calculated_lineItem,"calculated_lineItem>>");
        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      
      } 
      else if (name === "Tax"){
        let Tax = v;
        lineItem.Tax = Tax;
        let calculated_lineItem = await SalesLineCalc(
          dataState,
          state,
          lineItem,
          customRound
        );

        Data[index] = calculated_lineItem;

        setDataLists([...Data]);
      }
    }
  };

 



  const onDetailsChange = async (e, index) => {
    if (e) {
      let value = e.target.value;
      let name = e.target.name;
      let Data = DataList;
      console.log(
        name,
        value,
        "name,value:LLLLLLLLLLLLLLL:::::::::::::::::::::::::::"
      );
      let lineItem = Data[index];
      lineItem[name] = value;

  
      let Amount = lineItem.Amount

      if (name === "DiscountAmount") {
        lineItem.DiscountPerc = 100 * (Number(value) / Number(Amount));
      } else if (name === "DiscountPerc") {
        lineItem.DiscountAmount = (Number(value) / 100) * Number(Amount);
      }

      let calculated_lineItem = await SalesLineCalc(dataState, state, lineItem,customRound);

      Data[index] = calculated_lineItem;
      setDataLists([...DataList]);
    }
  };



    const AddLine = () => {
      let Data = DataList;
      let newData = DataListItem;
      Data.push(newData);
      setDataLists([...Data]);
    };

    const RemoveLine = (index) => {
      let Data = DataList;
      let deletedData = [];
      if (index || index === 0) {
        if (index > -1 && index < Data.length) {
          if (is_edit) {
            deletedData.push(Data[index]);
          }

          Data.splice(index, 1);
        }
      }
      let newState = SalesInvoiceCalc(dataState,state, Data,is_manual_roundoff,customRound);
      setState({ ...newState, deletedData: deletedData });

      setDataLists([...Data]);
    };

    const [Errors, setErrors] = useState({
      field: null,
      message: null,
      error: false,
      indexList: [],
    });

    const ChangeTableCustomization = (type, name) => {
        let value = null;
        if (type === "tableSettings") {
          value = !tableSettings[name];
          setTableSettings({
            ...tableSettings,
            [name]: value,
          });
        } else {
          value = !focusSettings[name];
          setFocusSettings({
            ...focusSettings,
            [name]: value,
          });
        }
      };
      
    const MasterCalcFields = [
      "BillDiscPercent",
      "BillDiscAmt",
      "CashAmount",
      "BankAmount",
      "RoundOff",
    ];

    const onMasterAutoChange = async (e, v, name) => {
    if (v && name) {
    const isCalcField = MasterCalcFields.includes(name);
    let value = v;
    if (name === "AccountLedger") {
      let {AccountLedger,BillingAddress_object} = await Call_LedgerDetials(value)
      let data = AccountLedger
      let Treatment = VAT_TreatmentData.find(
        (i) => i.value === data.Treatment
      );

      let PriceCategory = dataState.PriceCategoryData.find(
        (i) => i.PriceCategoryID === 1
      );
      if (data.PriceCategoryID) {
        PriceCategory = dataState.PriceCategoryData.find(
          (i) => i.PriceCategoryID === data.PriceCategoryID
        );
      }
      
      if (value.Treatment === null){

      Treatment = VAT
      ? VAT_TreatmentData.find((i) => i.default === true)
      : GST
      ? GST_TreatmentData.find((i) => i.default === true)
      : null;
        
      } 
      

      let Data = DataList;
      

      let Tax = null;
      console.log(value, dataState.TaxCategoryData, "----------->>def");
      Data.map((lineItem, index) => {
          if (lineItem.Ledger) {  // Ensure lineItem.Product is not null or undefined
              if (value.Treatment === "1" || value.Treatment === "7") {
                  Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");
              }
            
              lineItem.Tax = Tax;
          } else {
              console.warn(`Product is null or undefined for line item at index ${index}`);
          }
      });
  
      let TreatmentData=value.Treatment

     
      setDataLists([...Data]);
      setState({
        ...state,
        [name]: data,
        Treatment,
        TreatmentData,
        ShippingAddress: null,
        BillingAddress: BillingAddress_object,
        PriceCategory,
      });
    }

    else if (name === "Warehouse"){
      let Data = DataList
      let payload = DataList.map(object => object?.Unit?.PriceListID);
      let StockData = await Call_Stock_api({
        CompanyID: CompanyID,
        BranchID: BranchID,
        PriceListIDList : payload ,
        WarehouseID:value.WarehouseID
      })

      Data.map((lineItem,index)=>{
        if (lineItem.Product){
          lineItem.Stock =  StockData.find((i)=> i.PriceListID === lineItem.Unit.PriceListID)?.Stock ?? 0
          Data[index] = lineItem
        }
      })

      setDataLists([...Data]);
      setState({...state,[name]:value})
      
    }
    else if (name === "Treatment") {
      let Data = DataList;

      let Tax = null;
      console.log(value, Data, "----------->>def");
      Data.map((lineItem, index) => {
          if (lineItem.Product) {  // Ensure lineItem.Product is not null or undefined
              if (value.value === "0") {
                  Tax = dataState.TaxCategoryData.find((i) => i.TaxID === lineItem.Product.ProductTaxID);
              } else {
                  Tax = dataState.TaxCategoryData.find((i) => i.TaxName === "None");
              }
              lineItem.Tax = Tax;
              
          } else {
              console.warn(`Product is null or undefined for line item at index ${index}`);
          }
      });

      setDataLists([...Data]);
      setState({ ...state, [name]: value });
    }

    else if (isCalcField) {
      state[name] = value;
      
      let newState = SalesInvoiceCalc(
        dataState,
        state,
        DataList,
        is_manual_roundoff,
        customRound
      );
      setState({ ...newState });
    } else {
      setState({ ...state, [name]: value });
    }

    }

    };
      //Ledger Searching api 
  const Call_LedgerDetials = async (data) =>{
    
    
    const LedgerDetails = await ledger_details_api({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
      ledger_id:data.id
    })
    
    if (LedgerDetails.StatusCode === 6000){
      
      let AccountLedger = data//state.AccountLedger      
      AccountLedger["Treatment"] = LedgerDetails.data.Treatment
      AccountLedger["TaxNo"] = LedgerDetails.data.TaxNo
      AccountLedger["Mobile"] = LedgerDetails.data.Mobile
      AccountLedger["BillingAddress"] = LedgerDetails.data.BillingAddress
      AccountLedger["RouteName"] = LedgerDetails.data.RouteName
      AccountLedger["Balance"] = LedgerDetails.data.Balance
      AccountLedger["PriceCategoryID"] = LedgerDetails.data.PriceCategoryID
      // AccountLedger["BillingAddress_object"]:LedgerDetails
      
      let BillingAddress_object = LedgerDetails.data.BillingAddress_object
      // setState({...state,AccountLedger:AccountLedger})

      
      return {AccountLedger,BillingAddress_object}
      
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: LedgerDetails?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data;
  };

  const Call_Stock_api = async(payload) =>{
    let data = null
    const Response = await get_stock_of_products_api(payload)
    console.log(Response,"Response");
    if (Response.StatusCode === 6000){
      data = Response.data
    }
    else if (Response.StatusCode === 6001){
      data = null
    }
    else{
      dispatch(
        openSnackbar({
          open: true,
          message: Response?.message ?? "Api Error",
          severity: "error",
        })
      );
    }
    
    return data
  }

  const onMasterInputChange = async (search, name) => {
    if (search && name) {
      let length = search.length;
      let lineItem = DataListItem;

      if (name === "AccountLedger") {
        const LedgerList = await LedgerDetails({
          CompanyID: CompanyID,
          BranchID: BranchID,
          CreatedUserID: user_id,
          PriceRounding: Number(PriceDecimalPoint || 2),
          ledger_name: search,
          length: length,
          load_data: false,
          type_invoice: "PurchaseInvoice",
        });
        console.log(LedgerList);
        if (LedgerList.StatusCode === 6000) {
          let AccountLedgerData = [...LedgerList.data];
          if (state.Treatment.value = "1" || state.Treatment.value === "7"){
          console.log("hello this is a new data~");   
          }


          setDataState({ ...dataState, AccountLedgerData: AccountLedgerData });
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: LedgerList?.message ?? "Api Error",
              severity: "error",
            })
          );
        }}

        else if (name == "Treatment"){
         const value  =  state.Treatment.value;
         if ( value == "1" || value == "7"){
          lineItem.Tax = 30
         }
        }
        
    }
  };


  const is_customer =
  state.AccountLedger?.AccountGroupUnder !== 9 &&
  state.AccountLedger?.AccountGroupUnder !== 8;


  const openBillingAddressModal  = async() =>{
    setIsBilling(true)
    let res = await Create_BillingAddress(BillingAddress,"list")
    if (res){
        setBillingAddress({...BillingAddress,BillingAddressList:res})
    }
  }

  //==============CREATE/UPDATE BILLING ADDRESS==================
  const Create_BillingAddress = async(payload,method) =>{
    
    if (method === "list"){
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.Type = "BillingAddress"	
      const list_resposne = await list_user_address({
      CompanyID : CompanyID,
      BranchID:BranchID,
      LedgerID:state.AccountLedger.LedgerID,
      Type : "BillingAddress"	,
      })
      if (list_resposne.StatusCode===6000){
        return list_resposne.data
      }
    }
    else if(
      !payload.Attention||
      !payload.PostalCode||
      !payload.Address1||
      !payload.Address2||
      !payload.City||
      !payload.District||
      !payload.country||
      !payload.state||
      !payload.Mobile
    ){
      dispatch(
        openSnackbar({
          open: true,
          message: "Please fill all mandatory fields",
          severity: "error",
        })
      );
    }
    else if (method==="create"){
      
      payload.CompanyID = CompanyID
      payload.BranchID=BranchID
      payload.LedgerID=state.AccountLedger.LedgerID
      payload.type_data = "create"
      payload.AddressType = "BillingAddress"
      
      payload.country = payload.country.id
      payload.state = payload.state.id

    
      const responseData = await create_user_address(payload)
        
      if (responseData.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Created Successfully",
            severity: "success",
          })
        );
        
        setBillingAddress({...BillingAddress,BillingAddressList:responseData.data})
        return true
      } else if (responseData.StatusCode === 6001) {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message ?? "Something went wrong!",
            severity: "warning",
          })
        );
        return false
      } 
      else if (responseData.StatusCode === 6002) {
     
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      } 
      else {
  
        dispatch(
          openSnackbar({
            open: true,
            message: responseData?.message + " : " +responseData?.error ?? "Something went wrong!",
            severity: "error",
          })
        );
        return false
      }
    }
    
  }

  const fetchData = async () => {

    let ResponseView = null;
    let ResponseViewData = null;
    let AccountLedgerData = [];
    let CashList = [];
    let BankList = [];
    let CountryData = [];
    let EmployeeData = [];
    let PriceCategoryData = [];
    let TaxCategoryData = [];
    let UserTableData = [];
    let WarehouseData = [];
    let Seperator = "";
    let Suffix = "";
    let SuffixSeparator = "";
    let PreFix = "";
    let InvoiceNo = "";
    let VoucherNo = "";
    let PlaceOfSupplyData = [];
    let BillWiseDatas = [];
    let Employee = null;
    let RefferenceBillNo = "";
    let BillDiscPercent = 0;
    let BillDiscAmt = 0;
    let Notes = "";
    let PriceCategory = null;
    let Date = "";
    let VenderInvoiceDate = "";
    let RoundOff = "";
    let TotalGrossAmt = 0;


    const Data = await get_details({
      CompanyID: CompanyID,
      BranchID: BranchID,
      CreatedUserID: user_id,
    });

  
    if (Data.StatusCode === 6000) {
      const PlaceOfSupplyResponse = await place_of_supply_api(Country);
      if (PlaceOfSupplyResponse.StatusCode === 6000) {
        PlaceOfSupplyData = PlaceOfSupplyResponse.data;
      }

      // AccountLedgerData = Data.AccountLedgerData;
      let CashAndBankData = Data.CashAndBankData;
      if (CashAndBankData) {
        CashList = CashAndBankData.filter((i) => i.AccountGroupUnder === 9);
        BankList = CashAndBankData.filter((i) => i.AccountGroupUnder === 8);
      }

      CountryData = Data.CountryData;
      EmployeeData = null;
      PriceCategoryData = Data.PriceCategoryData;
      TaxCategoryData = Data.TaxCategoryData;
      UserTableData = Data.UserTableData;
      WarehouseData = Data.WarehouseData;
      
      Date =  DefaultDate;
      VenderInvoiceDate = DefaultDate;

      let VoucherNoGenerateTableData = Data.VoucherNoGenerateTableData;
      Seperator = VoucherNoGenerateTableData.Seperater;
      Suffix = VoucherNoGenerateTableData.Suffix;
      SuffixSeparator = VoucherNoGenerateTableData.SuffixSeparator;
      PreFix = VoucherNoGenerateTableData.PreFix;
      InvoiceNo = VoucherNoGenerateTableData.InvoiceNo;
      VoucherNo = VoucherNoGenerateTableData.VoucherNo;

      //Default Values
      let AccountLedgerDefault = AccountLedgerData.find((i) => i.is_default === true);

      let Warehouse = WarehouseData.find(
        (i) => i.WarehouseID === UserTableData.DefaultWarehouse
      );
      let Treatment = VAT
        ? VAT_TreatmentData.find((i) => i.default === true)
        : GST
        ? GST_TreatmentData.find((i) => i.default === true)
        : null;
      let PlaceOfSupply = PlaceOfSupplyData.find((i) => i.id === State);
      PriceCategory = PriceCategoryData.find((i) => (i.PriceCategoryID = 1));
      let CashAccount = CashList[0];
      let BankAccount = BankList[0];
      setDefaultValue((prev) => {
        return {
          ...prev,
          AccountLedger: AccountLedgerDefault,
          CashAccount: CashAccount,
          BankAccount: BankAccount,
          PriceCategory: PriceCategory,
          Warehouse: Warehouse,
          Treatment: Treatment,
          PlaceOfSupply: PlaceOfSupply,
          // ShippingTax:ShippingTax,
        };
      });

      if (is_edit && uniq_id) {
        ResponseView = await SingleExpenseData({
          BranchID: BranchID,
          CompanyID: CompanyID,
          uniq_id: uniq_id,
        });
        if (ResponseView) {
          let {AccountLedger} = await Call_LedgerDetials(ResponseView.data.AccountLedger)

          AccountLedgerDefault=AccountLedger          
          Treatment = ResponseView.data.Treatment;
          let PlaceOfSupplyName = ResponseView.data.Place_of_Supply;
          PlaceOfSupply = PlaceOfSupplyData.find(
            (i) => i.Name === PlaceOfSupplyName
          );
          RefferenceBillNo = ResponseView.data.RefferenceBillNo;
          // CashAccount = ResponseView.data.CashAccount;
          // BankAccount = ResponseView.data.BankAccount;
          BillWiseDatas = ResponseView.data.BillwiseDetails;
          Employee = null;
          VoucherNo = ResponseView.data.VoucherNo;
          BillDiscPercent = ResponseView.data.BillDiscPercent;
          BillDiscAmt = ResponseView.data.BillDiscAmt;
          Notes = ResponseView.data.Notes;
          Date = ResponseView.data.Date;
          VenderInvoiceDate = ResponseView.data.VenderInvoiceDate
          TotalGrossAmt = ResponseView.data.TotalGrossAmt
           
          PriceCategory = PriceCategoryData.find(
            (i) => i.PriceCategoryID === ResponseView.data.PriceCategoryID
          );
          Warehouse = ResponseView.data.Warehouse;
          RoundOff = ResponseView.data.RoundOff;

          setIs_manual_roundoff(ResponseView.data.is_manual_roundoff);

          let TotalQty = Number(ResponseView.data.TotalQty);
          let noOfPages = Math.ceil(TotalQty / 100);
          let data = [];

          ResponseViewData = ResponseView.data

          for (let i = 1; i <= noOfPages; i++) {
            let DetailsReponse = await ExpenseDataDetails({
              BranchID: BranchID,
              CompanyID: CompanyID,
              uniq_id: uniq_id,
              page: i,
            });
            if (DetailsReponse) {
              data = [...data, ...DetailsReponse.data];
            }
          }
          setDataLists([...data]);
        }
      }

      setState((prev) => {
        return {
          ...prev,
          ...ResponseViewData,
          AccountLedger:AccountLedgerDefault,
          Warehouse,
          Treatment,
          PlaceOfSupply,
          CashAccount,
          BankAccount,
          PriceCategory,
          RefferenceBillNo,
          Employee,
          BillDiscPercent,
          BillDiscAmt,
          Notes,
          Date,
          VenderInvoiceDate,
          RoundOff,
          TotalGrossAmt,          
        };
      });
    
      setDataState((prev) => {
        return {
          ...prev,
          AccountLedgerData,
          CashList,
          BankList,
          CountryData,
          EmployeeData,
          PriceCategoryData,
          TaxCategoryData,
          UserTableData,
          WarehouseData,
          Seperator,
          Suffix,
          SuffixSeparator,
          PreFix,
          InvoiceNo,
          VoucherNo,
          PlaceOfSupplyData,
          BillWiseDatas,
        };
      });


      setBillingAddress({...BillingAddress,CountryData,StateData:PlaceOfSupplyData})

    } else {
      dispatch(
        openSnackbar({
          open: true,
          message: Data?.message ?? "get salesreturn Api Error",
          severity: "error",
        })
      );
    }

  };
  useEffect(() => {
    fetchData();
  }, []);



  useEffect(() => {
    const CalculateData = () => {
      let newState = SalesInvoiceCalc(dataState,state, DataList,is_manual_roundoff,customRound);
      setState({ ...newState });
    };
    CalculateData();
  }, [DataList]);


  const onMasterChange = (e) => {
    if (e) {
      const { name, value } = e.target;

      //checking the field is included in MasterCalcFields
      const isCalcField = MasterCalcFields.includes(name);

      if (isCalcField) {
        state[name] = value;

        //handling bill disc
        if (name === "BillDiscAmt") {
          state.BillDiscPercent =
            100 * (Number(value) / Number(state.NetTotal));
        } else if (name === "BillDiscPercent") {
          state.BillDiscAmt = (Number(value) / 100) * Number(state.NetTotal);
        }

        let newState = SalesInvoiceCalc(
          dataState,
          state,
          DataList,
          is_manual_roundoff,
          customRound
        );
        setState({ ...newState });
      } else {
        setState((prevState) => ({ ...prevState, [name]: value }));
      }
    }
  };









  const handleSubmit = async (e, IsBillWise = true) => {
    let { error, message, field, indexList } = await Expense_validation(
      dataState,
      state,
      DataList,
      GeneralSettingsData
    );

    if (error) {
      let errors = {
        error,
        message,
        field,
        indexList,
      };
      dispatch(
        openSnackbar({
          open: true,
          message: message,
          severity: "warning",
        })
      );
      setErrors({ ...state, errors });
      console.log("this is error");
    } else {
      let Details = [];

      console.log(DataList,"DataList>>>>");
      DataList.map((i) => {
        if (i.Ledger) {
          let item = {
            id: i.id,
            LedgerID: i.Ledger.id,
            Amount : i.Amount,
            TaxID: i.Tax.TaxID,
            DiscountAmount:i.DiscountAmount,
            TaxableAmount:i.TaxableAmount,
            TaxPerc:0,
            TaxAmount:i.TaxAmount,
            Total:i.Total,
            VATPerc:0,
            VATAmount:i.TaxAmount,
            IGSTPerc:0,
            IGSTAmount:0,
            SGSTPerc:0,
            SGSTAmount:0,
            CGSTPerc:0,
            CGSTAmount:0,

          };
          Details.push(item);
        }
      });

      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        CreatedUserID: user_id,
        PriceRounding: PriceDecimalPoint,
        Details: Details,
        is_manual_roundoff: is_manual_roundoff,
        uniq_id: uniq_id, //uniq_id

        ReffNo: state.ReffNo,
        DeliveryDate: state.RefferenceBillDate,
        // ------
        Date: state.Date,
        VenderInvoiceDate: state.VenderInvoiceDate,
        Seperator: dataState.Seperator,
        Suffix: dataState.Suffix,
        SuffixSeparator: dataState.SuffixSeparator,
        PreFix: dataState.PreFix,
        InvoiceNo: dataState.InvoiceNo,
        VoucherNo: dataState.VoucherNo,
        // ---------
        BillwiseDetails: dataState.BillWiseDatas,
        Supplier: state.AccountLedger.id,
        // PriceCategoryID: state.PriceCategory.PriceCategoryID,
        SalesAccount: 86,
        EmployeeID: state.Employee,
        CustomerName: state.AccountLedger.LedgerName,
        // Treatment: state.Treatment.value,
        PlaceOfSupply: state.PlaceOfSupply.id,
        Country_of_Supply: state.PlaceOfSupply.Country,
        BillDiscPercent: state.BillDiscPercent,
        BillDiscAmt: state.BillDiscAmt,
        CashAmount: state.CashAmount,
        BankAmount: state.BankAmount,
        Notes: state.Notes,
        RoundOff: state.RoundOff,
        VAT_Treatment: state.VAT_Treatment,
        // WarehouseID: state.Warehouse.WarehouseID,
        // Total
        TotalQty: state.TotalQty,
        TotalGrossAmt: state.TotalGrossAmt,
        TotalDiscount: state.TotalDiscount,
        TotalTaxAmount: state.TotalTax,
        TotalNetAmount: state.NetTotal,
        TotalTaxableAmount: state.TotalTaxableAmount,
        TaxTaxableAmount: state.TaxTaxableAmount,
        NonTaxTaxableAmount: state.NonTaxTaxableAmount,
        GrandTotal: state.GrandTotal,
        RefferenceBillDate: state.Date,
        // PurchaseAccount: PurchaseAccount,
        MasterInvoiceNo: state.RefferenceBillNo,
        BillingAddress:state.BillingAddress?.id,
        GST_Treatment: "",
        MasterInvoiceNo : null,
        PaymentMode: 0,
        PaymentID : null,
        Amount: 0,
        BillDiscountPerc:0,
        BillDiscountAmt:0,
        TaxInclusive:0,
        TaxType:"VAT",
        TaxTypeID:32,
        TotalVATAmount:state.TotalVATAmount,
        TotalIGSTAmount:0,
        TotalSGSTAmount:0,
        TotalCGSTAmount:0,
        TaxNo:""


      };

      let responseData = null;
      if (IsBillwise === true && IsBillWise === true) {
        setOpenBillWise(true);
      } else {
        if (is_edit && uniq_id) {
          setIsButtonClick(true);
          responseData = await EditExpenseApi(payload);
        } else {
          setIsButtonClick(true);
          responseData = await CreateExpenseApi(payload);
        }
        if (responseData?.StatusCode === 6000) {
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Created Successfully",
              severity: "success",
            })
          );
          clearData();
        } else if (responseData?.StatusCode === 6001) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "warning",
            })
          );
        } else if (responseData?.StatusCode === 6002) {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message: responseData?.message ?? "Something went wrong!",
              severity: "error",
            })
          );
        } else {
          setIsButtonClick(false);
          dispatch(
            openSnackbar({
              open: true,
              message:
                responseData?.message + " : " + responseData?.error ??
                "Something went wrong!",
              severity: "error",
            })
          );
        }
      }
    }
  };



    
  const clearData = async () => {
    if (is_edit) {
      navigate("/create-expense");
    }
    setIsButtonClick(false);
    // props.handleExpenseClose()
    //new voucher number
    let voucher_datas = await get_VoucherNo({
      CompanyID: CompanyID,
      UserID: user_id,
      BranchID: BranchID,
      VoucherType: "EX",
    });
    
    setDataState({
      ...dataState,
      ...voucher_datas,
    });

    setState({
      ...state,
      AccountLedger: defaultValue.AccountLedger,
      CashAccount: defaultValue.CashAccount,
      BankAccount: defaultValue.BankAccount,
      // Employee :defaultValue.Employee,
      PriceCategory: defaultValue.PriceCategory,
      Warehouse: defaultValue.Warehouse,
      Treatment: defaultValue.Treatment,
      PlaceOfSupply: defaultValue.PlaceOfSupply,
      Date: DefaultDate,
      VenderInvoiceDate:DefaultDate,
      RefferenceBillNo: "",
      ShippingCharge: 0,
      ShippingTax: defaultValue.ShippingTax,
      shipping_tax_amount: 0,
      BillDiscPercent: 0,
      BillDiscAmt: 0,
      CashAmount: 0,
      BankAmount: 0,
      Notes: "",
      DueDate: DefaultDate,
      DeliveryDate: DefaultDate,
      //Total
      TotalQty: 0,
      TotalGrossAmt: 0,
      TotalDiscount: 0,
      TotalTax: 0,
      NetTotal: 0,
      TotalTaxableAmount: 0,
      TaxTaxableAmount: 0,
      NonTaxTaxableAmount: 0,
      GrandTotal: 0,
      BillingAddress:null

    });

    setDataLists([DataListItem]);
  };


const splitToline =()=>[

]



  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
   
  };
  const handleOpen = () =>{
    setOpen(true)
  }
const [isCreateExpense,setIsCreateExpense] =useState(false);
const handleExpenseClose = () => {
  setIsCreateExpense(false);
 
};
const handleExpenseOpen = () =>{
  setIsCreateExpense(true)
}



const [expenseData, setExpenseData] = useState([]); 

const getData = useCallback(async () => {
  console.log("clicked");

  if (!Array.isArray(DataList) || DataList.length === 0) {
    console.log("DataList is empty or not an array");
    return; 
  }

  const Details = DataList
    .filter(item => item.Ledger) 
    .map(i => ({
      id: i.id,
      LedgerID: i.Ledger?.id,
      Amount: i.Amount || 0,
      TaxID: 1,
      DiscountAmount: i.DiscountAmount || 0,
      TaxableAmount: i.TaxableAmount || 0,
      TaxPerc: 0,
      TaxAmount: i.TaxAmount || 0,
      Total: i.Total || 0,
      VATPerc: 0,
      VATAmount: 0,
      IGSTPerc: 0,
      IGSTAmount: 0,
      SGSTPerc: 0,
      SGSTAmount: 0,
      CGSTPerc: 0,
      CGSTAmount: 0,
    }));

  if (Details.length === 0) {
    console.log("No valid details found to push.");
    return; 
  }

  const payload = {
    CompanyID: CompanyID, 
    BranchID: BranchID, 
    CreatedUserID: user_id,
    PriceRounding: PriceDecimalPoint,
    Details: Details,
    is_manual_roundoff: is_manual_roundoff,
    uniq_id: uniq_id, 
    ReffNo: state.ReffNo,
    DeliveryDate: state.RefferenceBillDate,
    Date: state.Date,
    VenderInvoiceDate: state.VenderInvoiceDate,
    Seperator: dataState.Seperator,
    Suffix: dataState.Suffix,
    SuffixSeparator: dataState.SuffixSeparator,
    PreFix: dataState.PreFix,
    InvoiceNo: dataState.InvoiceNo,
    VoucherNo: dataState.VoucherNo,
    BillwiseDetails: dataState.BillWiseDatas,
    Supplier: state.AccountLedger?.id,
    SalesAccount: 86,
    EmployeeID: state.Employee,
    CustomerName: state.AccountLedger?.LedgerName,
    PlaceOfSupply: state.PlaceOfSupply?.id,
    Country_of_Supply: state.PlaceOfSupply?.Country,
    BillDiscPercent: state.BillDiscPercent,
    BillDiscAmt: state.BillDiscAmt,
    CashAmount: state.CashAmount,
    BankAmount: state.BankAmount,
    Notes: state.Notes,
    RoundOff: state.RoundOff,
    VAT_Treatment: state.VAT_Treatment,
    TotalQty: state.TotalQty,
    TotalGrossAmt: state.TotalGrossAmt,
    TotalDiscount: state.TotalDiscount,
    TotalTaxAmount: state.TotalTax,
    TotalNetAmount: state.NetTotal,
    TotalTaxableAmount: state.TotalTaxableAmount,
    TaxTaxableAmount: state.TaxTaxableAmount,
    NonTaxTaxableAmount: state.NonTaxTaxableAmount,
    GrandTotal: state.GrandTotal,
    RefferenceBillDate: state.Date,
    RefferenceBillNo: state.RefferenceBillNo,
    BillingAddress: state.BillingAddress?.id,
    GST_Treatment: "",
    MasterInvoiceNo: null,
    PaymentMode: 0,
    PaymentID: null,
    Amount: 0,
    BillDiscountPerc: 0,
    BillDiscountAmt: 0,
    TaxInclusive: 0,
    TaxType: "VAT",
    TaxTypeID: 32,
    TotalVATAmount: 0,
    TotalIGSTAmount: 0,
    TotalSGSTAmount: 0,
    TotalCGSTAmount: 0,
    TaxNo: "",
  };

  console.log("Generated Payload: ", payload);

  setExpenseData(prevData => {
    const newData = [...prevData, payload];
    console.log("Updated Expense Data:", newData);
    return newData;
  });

  props.handleExpenseClose();
}, [DataList, CompanyID, BranchID, user_id, state, dataState, PriceDecimalPoint, uniq_id, is_manual_roundoff]); // Add the dependencies that cause re-render

useEffect(() => {
  if (expenseData.length > 0) {
    console.log('Expense Data on close:', expenseData);
    // props.handleExpenseClose();
  }
}, [expenseData]); 

console.log(state, "TotalGrossAmtTotalGrossAmt");

  return (
   
      <Paper sx={{ height: "100%", width: "100%", overflow: "scroll",padding:"0"}}>
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <FlexBox className="left">
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "500",
                cmr: 2,
                color: "#001746",
              }}
              variant="h2"
            >
              {is_edit
                ? t("Update Expense")
                : t("Create Expense")}
            </Typography>
            <ListButton style={{
              color:"#0A9EF3 !importent"
            }}
              onClick={() => {
                navigate("/list-expense");
              }}
              t={t}
            />

          </FlexBox>
          <FlexBox className="right">
            <ClearButton
              onClick={() => {
                clearData();
              }}
              t={t}
            />

            {IsButtonClick ? (
              <LoadingButton t={t} />
            ) : (
              props.isCreateExpense ? (
                <SaveButton t={t}  onClick={getData}/>
              ) : (
                <SaveButton t={t} onClick={handleSubmit}/>

              )
            )}

              
          </FlexBox>
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
            height: "250px",
          }}
        >
          <div class="sales-grid-item ">
            {/* customer search */}
            <InvoiceLedgerSearch
              name={"AccountLedger"}
              label={"Select Supplier"}
              optionLabel={"LedgerName"}
              optionLabel2={"LedgerCode"}
              OptionList={dataState.AccountLedgerData}
              Value={state.AccountLedger}
              OnChange={onMasterAutoChange}
              OnInputChange={onMasterInputChange}
              // openBillingAddressModal={openBillingAddressModal}
              // BillingAddress={state.BillingAddress}
              // is_customer={is_customer}
            />
          </div>

          <div class="sales-grid-item no-border-and-shadow">
          <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Place Of Supply
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete
                  name={"PlaceOfSupply"}
                  label={"Select a Place Of Supply"}
                  optionLabel={"Name"}
                  List={dataState.PlaceOfSupplyData}
                  Value={state.PlaceOfSupply}
                  OnChange={onMasterAutoChange}
                  OnInputChange={onMasterInputChange}
                />
              </Typography>
            </div>

         
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Ref Bill No
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  name={"RefferenceBillNo"}
                  label={"Type a RefBillNo"}
                  placeholder={"Ref Bill No"}
                  Value={state.RefferenceBillNo}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
          </div>

          <div class="sales-grid-item no-border-and-shadow">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Invoice No
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceVoucherNoTextBox
                  name={"VoucherNo"}
                  label={"Type a VoucherNo"}
                  is_edit={is_edit}
                  Value={dataState.VoucherNo}
                  openCustomVoucher={openCustomVoucher}
                  setCustomVoucher={setopenCustomVoucher}
                />
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "350px",
                marginTop: "10px",
              }}
            >
              {/* <CustomVoucher
                open={openCustomVoucher}
                setCustomVoucher={setopenCustomVoucher}
                state={dataState}
                handleChange={handleChange}
                CustomVoucherSet={CustomVoucherSet}
              /> */}

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Date
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceDateBox
               
                  name={"Date"}
                  label={"Select a Date"}
                  Value={state.Date}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>


          </div>
        </Box>


        <Box
          sx={{
            // px: "26px",
            // py: "15px",
            display: "flex",
            justifyContent: "space-between",
            // borderBottom: "2px solid #F5F5F5",
          }}
        >
          <MyGrid
            dataState={dataState}
            state={state}
            DataList={DataList}
            setDataLists={setDataLists}
            tableSettings={tableSettings}
            setTableSettings={setTableSettings}
            focusSettings={focusSettings}
            setFocusSettings={setFocusSettings}
            handleInputChange={onDetailsInputChange}
            handleCompleteChanges={onDetailsAutoChange}
            handleChanges={onDetailsChange}
            AddLine={AddLine}
            RemoveLine={RemoveLine}
            PriceDecimalPoint={PriceDecimalPoint}
            Errors={Errors}
            debouncedOnDetailsInputChange={debouncedOnDetailsInputChange}
            ChangeTableCustomization={ChangeTableCustomization}
            GeneralSettingsData={GeneralSettingsData}
          />
        </Box>

        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <div style={{ cursor: "pointer" }} onClick={AddLine}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "13px",
              }}
            >
              + Add Line{" "}
            </span>
          </div>

          


        </Box>
        
        <Box
          sx={{
            px: "26px",
            py: "15px",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          {/* ------------Payment And Notes--------------- */}

          
          <div>
                  {/* -----------CASH PAYMENT---------------- */}
                  <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Cash Account
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"CashAccount"} label = {"Select a CashAccount"} optionLabel = {"LedgerName"} List = {dataState.CashList} Value = {state.CashAccount} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox type={"number"} name = {"CashAmount"} label = {"Type a CashAmount"} Value={state.CashAmount} OnChange={onMasterChange} />
              </Typography>
            </div>

            {/* ---------BANK PAYMENT-------------- */}
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "265px",
                marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Bank Account
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Amount
              </Typography>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceAutoComplete name = {"BankAccount"} label = {"Select a BankAccount"} optionLabel = {"LedgerName"} List = {dataState.BankList} Value = {state.BankAccount} OnChange = {onMasterAutoChange} OnInputChange = {onMasterInputChange} />
              </Typography>

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox  type={"number"} name = {"BankAmount"} label = {"Type a BankAmount"} Value={state.BankAmount} OnChange={onMasterChange} />
              </Typography>
            </div>
            <NotesTextArea
              value={state.Notes ? state.Notes : ""}
              name={"Notes"}
              placeholder={t("Notes (Optional)")}
              onChange={onMasterChange}
            />
          </div>

        <div style={{
            display:"flex"
        }}>
        <div>
        {/* <div style={{ cursor: "pointer" }} onClick={splitToline}>
            <span
              style={{
                whiteSpace: "nowrap",
                color: "#0A9EF3",
                margin: 0,
                fontSize: "15px",
                display:"block",
                margin:"0 auto"
              }}
            >
              Split{" "}
            </span>
          </div> */}

            <div
              style={{
                display:  "flex",               
                 justifyContent: "space-between",
                margin: "0",
                width: "265px",
              }}
            >
            
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "14px", margin: 0 }}
              >
                Discount
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                Discount(%)
              </Typography>
            </div>

            <div
              style={{
                display: "flex",                
                justifyContent: "space-between",
                margin: "0",
                width: "410px",
                // marginTop: "10px",
              }}
            >
            

              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscAmt"}
                  label={"Type a BillDiscAmt"}
                  Value={state.BillDiscAmt}
                  OnChange={onMasterChange}
                />
              </Typography>
              <Typography
                id="modal-modal-description"
                sx={{ fontSize: "13px", margin: 0 }}
              >
                <InvoiceTextBox
                  type={"number"}
                  name={"BillDiscPercent"}
                  label={"Type a BillDiscPercent"}
                  Value={state.BillDiscPercent}
                  OnChange={onMasterChange}
                />
              </Typography>
            </div>
            <Typography
              style={{ marginTop: "10px" }}
              variant="overline"
              display="block"
              gutterBottom
            >
              {/* <AddToItemsButton /> */}
            </Typography>
          </div>
          {/* TOTAL AMOUNTS AND VALUES */}

          <SummaryDetails 
            state={state} is_manual_roundoff={is_manual_roundoff} setIs_manual_roundoff={setIs_manual_roundoff} 
           />
          </div>

        </Box>
        {/* <Button onClick={handleOpen}>Expense</Button> */}

        <VBExpense 
          open = {open} 
          handleClose={handleClose} 
          isCreateExpense={isCreateExpense}
          handleExpenseOpen={handleExpenseOpen}
          handleExpenseClose={handleExpenseClose}
          // setIsCreateExpense={setIsCreateExpense}
          data={expenseData}
          getData={getData}
        />
      </Paper>
  )
}

export default CreateExpense

const FlexBox = styled(Box)(() => ({
    display: "flex",
    alignItems: "center",
    width: "69%",
    "&.right": {
      justifyContent: "flex-end",
    },
    "&.left": {
      justifyContent: "flex-start",
      gap: "22px",
    },
  }));
 