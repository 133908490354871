import { sum } from "lodash";

function roundOffNumber(value, decimalPlaces) {
  const roundedValue = Math.round(value, 2);

  return roundedValue;
}

export function SalesInvoiceCalc(
  dataState,
  state,
  DataList,
  is_manual_roundoff
) {

  const totalQty = DataList.reduce((sum, product) => sum + product.Qty, 0);
  const totalAmount = DataList.reduce((sum, product) => sum + product.Amount, 0);

  state.TotalQty = totalQty;
  state.TotalAmount = totalAmount;


  return state;
}

// Line Calculation
export function SalesLineCalc(dataState, state, lineItem, customRound) {
  let Qty = Number(lineItem.Qty) || 0;
  let Rate = customRound(lineItem.Rate) || 0;
  let Amount = customRound(Rate * Qty);

  lineItem.Qty = Qty;
  lineItem.Rate = customRound(lineItem.Rate) || 0;
  lineItem.Amount = customRound(Amount);

  return lineItem;
}

// validation
export const SalesEstimateValidation = (
  dataState,
  state,
  DataList,
  GeneralSettingsData
) => {
  console.log(
    "SalesReturnValidationHELLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLLL"
  );
  let field = null;
  let message = null;
  let error = null;
  let indexList = [];

  let Data = DataList;
  let length = Data.length;

  let is_non_taxable_amount = false;
  let is_taxable_amount = false;

  if (Data.length === 0) {
    error = true;
    message = "At least need one valid row";
  } else if (Data.length === 1 && !Data[0].Product) {
    message = "At least need one valid row";
  }

  //looping the dataList and checking all mandatory values are included
  Data.map((obj, i) => {
    if (!obj.Product) {
      if (
        i + 1 === length &&
        !obj.ProductCode &&
        !obj.BarCode &&
        !obj.Product &&
        // !obj.Description &&
        !obj.Unit &&
        // !obj.UnitList &&
        !obj.Stock &&
        !obj.Qty &&
        obj.Qty <= 0 &&
        // !obj.FQty &&
        !obj.Rate &&
        // !obj.AvgCost &&
        !obj.Tax &&
        !obj.InclusivePrice &&
        !obj.GrossAmount &&
        // !obj.DiscPerc &&
        !obj.DiscountAmount &&
        !obj.TaxAmount &&
        !obj.Amount &&
        !obj.MRP
        // !obj.PurchasePrice &&
        // !obj.BatchCode &&
        // !obj.MinimumSalesPrice &&
        // !obj.AverageCost
      ) {
      } else {
        indexList.push(i);
      }
    } else if (!obj.Product) {
      indexList.push(i);
    } else if (!obj.Unit) {
      indexList.push(i);
    } else if (!obj.Qty && Number(obj.Qty) <= 0) {
      indexList.push(i);
    } else if (!obj.Rate && Number(obj.Rate) <= 0) {
      indexList.push(i);
    } else if (!obj.Tax) {
      indexList.push(i);
    } else if (!obj.InclusivePrice) {
      indexList.push(i);
    } else if (!obj.GrossAmount) {
      indexList.push(i);
    } else if (!obj.TaxAmount && obj?.Tax?.TaxName !== "None") {
      indexList.push(i);
    } else if (!obj.Amount) {
      indexList.push(i);
    }

    if (obj.Product) {
      //checking is there any non tax amount and tax amount
      if (obj.Tax.TaxName === "None") {
        is_non_taxable_amount = true;
      } else {
        is_taxable_amount = true;
      }
    }
  });

  let newIndexList = indexList.map((i) => i + 1);

  if (indexList.length > 0) {
    message = "Data missing in Lines " + newIndexList;
    error = true;
  }

  //checking values of state
  if (!state.AccountLedger && !error) {
    error = true;
    message = "Provide valid AccountLedger";
  } else if (
    !state.Employee &&
    GeneralSettingsData.EnableSalesManInSales &&
    !error
  ) {
    error = true;
    message = "Provide valid Employee";
  } else if (!state.Treatment && !error) {
    error = true;
    message = "Provide valid Treatment";
  } else if (!state.PlaceOfSupply && !error) {
    error = true;
    message = "Provide valid PlaceOfSupply";
  } else if (!state.Date && !error) {
    error = true;
    message = "Provide valid Date";
  } else if (!state.TotalGrossAmt && !error) {
    error = true;
    message = "Provide valid TotalGrossAmt";
  } else if (!state.TotalTax && !error && is_taxable_amount) {
    error = true;
    message = "Provide valid TotalTax";
  } else if (!state.NetTotal && !error) {
    error = true;
    message = "Provide valid NetTotal";
  } else if (!state.TotalTaxableAmount && !error) {
    error = true;
    message = "Provide valid TotalTaxableAmount";
  } else if (!state.TaxTaxableAmount && !error && is_taxable_amount) {
    error = true;
    message = "Provide valid TaxTaxableAmount";
  } else if (!state.NonTaxTaxableAmount && !error && is_non_taxable_amount) {
    error = true;
    message = "Provide valid NonTaxTaxableAmount";
  } else if (!state.GrandTotal && !error) {
    error = true;
    message = "Provide valid GrandTotal";
  }

  console.log(error, ";ppppppppppppppppppppppppppppperrrororo");
  return { error, message, field, indexList };
};
