import {
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  styled,
} from "@mui/material";

const VBSelect = (props) => {
  return (
    // <Box>
    <FormControl fullWidth>
      {props?.label && (
        <FormLabel sx={{ fontSize: "14px", mb: 1 }}>{props.label}</FormLabel>
      )}
      <StyledSelect
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={props?.value}
        onChange={props?.handleChange}
        displayEmpty
        placeholder="Some is how"
        disabled={props?.disabled}
        // variant=""
        sx={{ fontSize: "16px"}}
      >
        {/* <MenuItem value="" disabled>
        <em>Placeholder</em>
      </MenuItem> */}
        {props.options?.map((item) => (
          <MenuItem
            sx={{
              fontStyle: item === "None" ? "italic" : "normal",
              color: item === "None" ? "gray" : "black",
              fontSize: "14px"
            }}
            value={item === "None" ? "" : item}
          >
            {item}
          </MenuItem>
        ))}
      </StyledSelect>
    </FormControl>
    // </Box>
  );
};

export default VBSelect;

const StyledSelect = styled(Select)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#333333",
  // color :'black',
  "& .MuiSelect-select": { padding: "5px" },
  // "& .MuiOutlinedInput-notchedOutline": {
  //   border: theme.palette.mode === "light" ? "1px solid #F8F8F8" : "1px solid #777777",
  //   borderRadius: "6px",
  // },
  // "&:hover .MuiOutlinedInput-notchedOutline": {
  //   border: theme.palette.mode === "light" ?  "1px solid #F8F8F8" : "1px solid #777777",
  //   borderRadius: "6px",
  // },
  // "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
  //   border: theme.palette.mode === "light" ?  "1px solid #F8F8F8" : "1px solid #777777",
  //   borderRadius: "6px",
  // },
}));

// Example

// const [state, setState] = useState("option1")
// <VBSelect
//    label="Language"
//    value={state}
//    options={[option1, option2, optioin3]}
//    handleChange={(e) => setState( e.target.value )}
// />
