import styled from '@emotion/styled';
import { Box, IconButton, Modal, Paper, Tab, Tabs } from '@mui/material'
import React from 'react'
import CloseIcon from "@mui/icons-material/Close";
import CreateExpense from './CreateExpense';


const VBExpense = (props) => {
       
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    console.log(props.data,"dataisworking>>>");
  return (
  <>
   <Modal
        open={props.open}
        //   onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      
        style={{display:"flex",justifyContent:"center",alignItems:"center"
        
        }}
      >
        <Paper sx={{width:"calc(100% - 200px)",minHeight:"30rem",borderRadius:'15px'}}>
        <div style={{ display: props.isCreateExpense  ? "block" : "none" }}>
          <CreateExpense 
            isCreateExpense={props.isCreateExpense} 
            handleExpenseClose={props.handleExpenseClose} 
          />
        </div>

    
          <>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 4,display: props.isCreateExpense  ? "none" : "flex" , justifyContent:"space-between",alignItems:"center"}}>
                <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                >
                
                <Tab
                    label="Expenses"
                    sx={{ width: "288px", textTransform: "none" }}
                    {...a11yProps(0)}
                />

                {/* Payment Section */}
                {/* <Tab
                    label="Payments"
                    sx={{ width: "288px", textTransform: "none" }}
                    {...a11yProps(1)}
                />
                  */}
                </Tabs>
                <div style={{
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"center",
                    gap:"20px",
                }}>
                    <div>
                    <span
                    style={{
                        whiteSpace: "nowrap",
                        color: "#0A9EF3",
                        margin: 0,
                        fontSize: "16px",
                        cursor:"pointer"
                    }}
                    onClick={props.handleExpenseOpen}
                    >
                    + Add New{" "}
                    </span>
                    </div>
                    <div>
                    <IconButton sx={{ color: "#0A9EF3" }} onClick={props.handleClose}>
                    <CloseIcon />
                    </IconButton>
                    </div>
                 
                </div>

            
            </Box>
            <Box sx={{ px: 8 }}>
                <CustomTabPanel value={value} index={0}>
                {/* <StyledDiv>
                    <StyledSubDiv>
                    <VoucherText>#Voucher No</VoucherText>
                    <SupplierText>Supplier Name</SupplierText>
                    </StyledSubDiv>
                    <StyledSubDiv>
                    <StyledText>Total</StyledText>
                    <StyledText>SAR <StyleSpan> 1344.00</StyleSpan></StyledText>
                    <StyledText>INR <StyleSpan>65.00</StyleSpan></StyledText>
                        
                    </StyledSubDiv>
                   
                </StyledDiv> */}
             {props.data.length > 0 ? (
                  props.data.map((item, index) => (
                    <StyledDiv key={index}>
                      <StyledSubDiv>
                        <VoucherText>#Voucher No: {item.VoucherNo}</VoucherText>
                        <SupplierText>Ledger ID: {item.LedgerID}</SupplierText>
                      </StyledSubDiv>
                      <StyledSubDiv>
                        <StyledText>Total</StyledText>
                        <StyledText>SAR <StyleSpan>{item.Total}</StyleSpan></StyledText>
                        <StyledText>Amount <StyleSpan>{item.Amount}</StyleSpan></StyledText>
                      </StyledSubDiv>
                    </StyledDiv>
                  ))
                ) : (
                  <StyledText>No Details Available</StyledText>
                )}

                </CustomTabPanel>
                {/* Payment section */}
                {/* <CustomTabPanel value={value} index={1}>
                <h1>helo</h1>
                </CustomTabPanel> */}
            </Box>
            </>
  
        </Paper>
    </Modal>
 
  </>
  )
}

export default VBExpense


 


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {<Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  

const StyledDiv = styled.div`
height:15rem;
width: 25rem;
background-color:#F7F7F7;
display:flex;
justify-content:center;
flex-direction:column;
gap:25px
`


const StyledSubDiv = styled.div`
display:flex;
justify-content:center;
flex-direction:column;
gap:3px
`
const VoucherText = styled.text`
margin-left: 20px;

`
const SupplierText = styled.text`
margin-left: 20px;
color:#0A9EF3;

`
const StyledText = styled.text`
margin-left: 20px;

`
const StyleSpan = styled.text`

`