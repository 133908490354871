import { Navigate } from "react-router-dom";
import StockManagement from "../../Pages/Inventory/StockManagement/StockManagement"
import { store } from "../../app/store";
import { activateFunction } from "../../Function/Editions";
import { elements } from "chart.js";
import { Outlet } from "react-router-dom";
import StockManagementList from "../../Pages/Inventory/StockManagement/StockManagementList";
import StockOrder from "../../Pages/Inventory/StockOrder/StockOrder";
import StockOrderList from "../../Pages/Inventory/StockOrder/StockOrderList";
import Manufacturing from "../../Pages/Reports/Manufacturing/Manufacturing";
import CreateStockTransfer from "../../Pages/Inventory/StockTransfer/CreateStockTransfer";
import ListStockTransfer from "../../Pages/Inventory/StockTransfer/ListStockTransfer";

const state = store.getState();


const ProtectedRoute = ({ children, authCondition }) => {
    let isAuthCondition = authCondition;
    return isAuthCondition ? children : <Navigate to="/dashboard" />;
  };


const route = [
    {path:"create-stock-management",element:<StockManagement/>,isAuth:true,},
    {path:"list-stock-management",element:<StockManagementList/>,isAuth:true,},
    {path:"create-stock-order",element:<StockOrder/>,isAuth:true,},
    {path:"list-stock-order",element:<StockOrderList/>,isAuth:true,},
    {path:"create-Manufacturing",element:<Manufacturing/>,isAuth:true,},
    {path:"create-stock-transfer",element:<CreateStockTransfer/>,isAuth:true,},
    {path:"list-stock-transfer",element:<ListStockTransfer/>,isAuth:true,},
]


const Routes  = route.map((route)=>({
    ...route,
    element: <ProtectedRoute authCondition={route.isAuth}>{route.element}</ProtectedRoute>,
}))

const inventoryRoutes = {
    path:"/",
    element: <Outlet />,
  children: [
    ...Routes
  ],
}


export default inventoryRoutes