import {
  Box,
  Button,
  Grid,
  Paper,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";
import TableSwitch from "../../../Components/Utils/TableSwitch";
import VBTextField from "../../../Components/Utils/VBTextField";
import VBInputField from "../../../Components/Utils/VBInputField";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ height: "92%", width: "100%" }}
      {...other}
    >
      {value === index && (
        <Paper
          elevation={0}
          sx={{ borderRadius: 2, height: "100%", width: "100%",  }}
        >
          {children}
        </Paper>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function General() {
  const [tabValue, setTabValue] = useState(0);

  let list = [
    { name: "Enable Card Net Work", checked: false, premession: false },
    { name: "Enable Card Details", checked: false, premession: false },
    { name: "Show Discount In Payments", checked: false, premession: false },
    { name: "Show Discount In Receipts", checked: false, premession: false },
    { name: "Enable Billwise", checked: false, premession: false },
    { name: "Enable Loyalty Settings", checked: false, premession: false },
    { name: "Show Inactive Users", checked: false, premession: false },
    { name: "Enable Route", checked: false, premession: false },
    { name: "Enable Foreign Currency", checked: false, premession: false },
    { name: "Enable Branch", checked: false, premession: false },
    { name: "Enable Excise Tax", checked: false, premession: false },
    { name: "VB Assist", checked: false, premession: false },
    { name: "Enable KSA E-invoice Phase 2", checked: false, premession: false },
  ];

  return (
    <Box sx={{ pl: 2, height: "100%" }}>
      <Stack direction={"row"} gap={2} sx={{ mb: 2 }}>
        <Button
          sx={{
            px: 6,
            borderRadius: 2,
            backgroundColor: tabValue !== 0 ? "white" : "black",
            color: tabValue === 0 ? "white" : "black",
            ":hover": { backgroundColor: tabValue !== 0 ? "white" : "black" },
          }}
          onClick={() => {
            setTabValue(0);
          }}
        >
          General
        </Button>
        <Button
          sx={{
            px: 6,
            borderRadius: 2,
            backgroundColor: tabValue !== 1 ? "white" : "black",
            color: tabValue === 1 ? "white" : "black",
            ":hover": { backgroundColor: tabValue !== 1 ? "white" : "black" },
          }}
          onClick={() => {
            setTabValue(1);
          }}
        >
          Inventory
        </Button>
      </Stack>

      <CustomTabPanel value={tabValue} index={0}>
        <Box sx={{ flexGrow: 1, p: 2 }}>
          <Typography sx={{ mb: 2, fontSize: "18px", fontWeight: "500" }}>General</Typography>
          <Grid container spacing={2}>
            {list.map((obj, ind) => (
            <Grid item xs={3}>
              <Paper
                elevation={0}
                sx={{
                  p: 1,
                  bgcolor: "#f8f8f8",
                  border: "1px solid #e2e2e2",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography sx={{ fontSize: "14px" }}>
                  {obj.name}
                </Typography>
                <TableSwitch />
              </Paper>
            </Grid>))}
            <Grid item xs={3} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center"}}>
              <Typography sx={{ fontSize: "12px", width: "100%"}}>Updation Delay In Days</Typography>
              <VBInputField sx={{ width: "60px"}} />
            </Grid>
          </Grid>
        </Box>
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}></CustomTabPanel>
    </Box>
  );
}

export default General;
