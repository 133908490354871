import React, { useEffect, useState } from "react";
import {
  VBSkeletonLoader,
  VBTableCellBody,
  VBTableCellHeader,
  VBTableContainer,
  VBTableHeader,
  VBTableRowNull,
} from "../../Components/ReportTable/ReportTable";
import { BASE_URL, BASE_V11_URL, MEDIA_URL, URL } from "../../generalVeriable";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Icons } from "../../Assets/AssetLog";


import {
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Paper,
  TableBody,
  TableRow,
  Typography,
  tabClasses,
} from "@mui/material";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { blue } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import Pagenation from "../../Components/Utils/Pagenation";
import styled from "@emotion/styled";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  get_e_invoice_list,
  get_e_invoice_submit_api,
  get_e_invoice_view_api,
  get_egs_list,
  get_invoice_download_api,
  get_invoice_validate_api,
  get_e_invoice_re_submit_api,
  check_progress,
  Zatca_data,
} from "../../Api/EInvoice/EInvoiceApis";
import { InvoiceAutoComplete } from "../../Components/CommonComponents";
import { openSnackbar } from "../../features/SnackBarslice";
// import ViewInvoice from "./EinvoiceComp";
import ViewInvoice from "./EInvoiceComponents";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import ViknAutoComplete from "../../Components/Utils/ViknAutoComplete";
import MultSelect from "./Components/MultSelect";

import axios from "axios";
import FilterBtn from "../../Components/Utils/FilterBtn";
import ViknDrawer from "../../Components/ViknDrawer/ViknDrawer";
import LoaderLite from "../../Components/Utils/LoaderLite";



const EinvoiceInvoiceList = () => {
  const dispatch = useDispatch();
  
  const appSetting = useSelector((state) => state.appSettingsSclice);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const noOfItems = appSetting.itemPerPage;
  const [page_no, setPage_no] = useState(1);
  const [openView,setOpenView] = useState(false)
  const [openMulipleView,setOpenMulipleView] = useState(false)
  const [SelectedInvoice,setSelectedInvoice] = useState(null)
  const [apiResult,setApiResult] = useState(null)
  const [multapiResult,setmultApiResult] = useState(null)
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const [openFilter ,setOpenFilter] = useState(false)
  const [is_celery_loader, setCelery_Loader] = useState(false);
  const [progress_id, setProgress_id] = useState(null);
  const [progress, setProgress] = useState(0);
  const [showLoader, setLoader] = useState(false);
  const [isTaskCompleted, setIsTaskCompleted] = useState(false)
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false);
  const [loadingView, setLoadingView] = useState(false);
  const [openIndex, setOpenIndex] = useState(-1);
  const [isReload, setIsReload] = useState(false);

  const [progressMessage, setProgressMessage] = useState("Processing ...");

  
  const [state, setState] = useState({
    dataList: [],
    // egsList: [],
    egs: null,
    selectedItems: [],
    type_of_egs:{name:"production",value:2},
    count:0,
    // singleView:{}
    status: [],
    egsList:[{name:"sandbox",value:0},{name:"simulation",value:1},{name:"production",value:2}]
  });
  
  const handleOpenIndex = async(index) =>{
    setOpenIndex(openIndex!==index?index:-1)
  }

  const ViewMultiSelect = async(id,InvoiceName,VoucherNumber,transaction_id) =>{
    setOpenMulipleView(true)
  }

  const ClickViewInvoice = async(id,InvoiceName,VoucherNumber,transaction_id) =>{
    console.log(state);
    let invoiceList = await get_invoice_view(transaction_id);
    
    // let dataList = invoiceList.Data;
    // setState({ ...state, egsList, egs: egs, dataList,count:invoiceList.count });
    setSelectedInvoice({id:id,InvoiceName:InvoiceName,VoucherNumber:VoucherNumber,singleView:invoiceList.Data,transaction_id:transaction_id})
    let correctedResponse = invoiceList.Data.full_response?.replace(/'/g, '"');
    console.log(correctedResponse,"correctedResponsecorrectedResponsecorrectedResponse");
    
    try {
      setApiResult(correctedResponse ? JSON.parse(correctedResponse) : "");
    } catch (error) {
      console.error("JSON parsing error:", error);
      setApiResult(String(correctedResponse)); // Handle the error case
    }
    console.log(String(correctedResponse),"ooooooooooooooooooooooooooooooooooooooo");
    
    
    setOpenView(true)
  }
  // const selectItems = (type, id) => {
  //   let data = [...state.selectedItems];
  //   let isSelectedAll = state.isSelectedAll;
  //   if (type === "all") {
  //     isSelectedAll = !isSelectedAll;
  //     if (state.isSelectedAll) {
  //       data = [];
  //     } else {
  //       state.dataList.map((i) => {
  //         data.push(i.id);
  //       });
  //     }
  //   } else {
  //     let index = data.findIndex((item) => item === id);
  //     if (index !== -1) {
  //       data.splice(index, 1);
  //     } else {
  //       data.push(id);
  //     }
  //   }

  //   setState({ ...state, selectedItems: data, isSelectedAll: isSelectedAll });
  // };

  const selectItems = (type, id, status) => {
    let data = [...state.selectedItems];
    let isSelectedAll = state.isSelectedAll;
  
    if (type === "all") {
      isSelectedAll = !isSelectedAll;
      if (isSelectedAll) {
        // Select all item ids
        data = state.dataList.map((item) => item.id);
      } else {
        // Deselect all
        data = [];
      }
    } else if (type === "status" && status) {
      // Filter items by the selected status
      const filteredItems = state.dataList.filter((item) => item.Status === status);
      data = filteredItems.map((item) => item.id);
  
      // Update isSelectedAll based on filtered items
      isSelectedAll = data.length === filteredItems.length;
    } else {
      // Select or deselect a single item
      const index = data.findIndex((item) => item === id);
      if (index !== -1) {
        data.splice(index, 1); // Deselect if already selected
      } else {
        data.push(id); // Select if not selected
      }
    }
  
    // Update the state with selected items and isSelectedAll flag
    setState({
      ...state,
      selectedItems: data,
      isSelectedAll,
    });
  };

  const onAutoChange = (e, v, name) => {
    if(v && name){
      let value = v
      setState({...state,[name]:value})
    }
  };

  const onInputChange = () => {
    //pass
  };

  const egs_list = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs:state?.type_of_egs.value
    };
    let Data = [];
    let res = await get_egs_list({ ...payload });
    if (res.StatusCode === 6000) {
      Data = res.data;
    }

    return Data;
  };

  const get_invoice_list = async (transaction_id="",IsView=false) => {
    setLoading(true)
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      // uniq_id: uniq_id,
      type_of_egs:state?.type_of_egs.value,
      page_number:page_no,
      items_per_page:15,
      IsView:IsView,
      id:transaction_id,
      Get_Status: state.status, 
    };
    let Data = [];
    let count = state.count;
    let egs_status = 2;
    
    let res = await get_e_invoice_list({ ...payload });
    if (res){
      
      if (res.StatusCode === 6000) {
        Data = res.data ?? [];
        count = res.count ?? [];
        egs_status = res.egs_status??2;
        
      }
    }
    setLoading(false)

    return {Data,count,egs_status};
  };
  
  
  
  //------------------------------ Get New Invoivce  --------------------------//

  const get_invoice_view = async (id) => {
    let payload = {
      transaction_id:id
    };
    let Data = [];
    let count = state.count;
    let res = await get_e_invoice_view_api({ ...payload });
    if (res){ 
      if (res.StatusCode === 6000) {
        Data = res.data ?? [];
        count = res.count ?? [];
      }
    }
    return {Data,count};
  };
  
  const statusOptions = [
    { value: 0, label: "Not Submitted" },
    { value: 1, label: "Success" },
    { value: 2, label: "Failed" },
    { value: 3, label: "Success With Warnings" },
    { value: 4, label: "Resubmit and success" },
    { value: 5, label: "Resubmit and success with warnings" },
    { value: 6, label: "Resubmit and failed" },
    { value: "all", label: "All" },
  ];
  
  //------------------------------  XML Read  --------------------------//

  const read__xml = async(data) => {
    let egs_id = state.egs.id
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      egs_id:egs_id,
      invoice_id:data.id,
      InvoiceName:data.InvoiceName,
      VoucherNumber:data.VoucherNumber,
      type_of_egs:state?.type_of_egs.value,
      is_download:true
      // is_read:true
    }
    
    
    let res = await get_invoice_validate_api({...payload});
  }
;
//------------------------------  Celery Progress Api  --------------------------//
const callCeleryProgressAPI = () => {
  setLoader(true);
  if (is_celery_loader){
    fetch(URL+"celery-progress/"+progress_id+'/')
  .then((response) => response.json())
  .then((responseData)=> {
    let percent = Math.floor(responseData.progress.percent)
    if (responseData.state === "SUCCESS"){
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs:state?.type_of_egs.value,
    };
    
    setProgress(0);
    setCelery_Loader(false)
    setLoader(false);
    Call_Result(payload)
    }
    else if (responseData.state === "FAILURE"){
      if (responseData.result === 'At least one sheet must be visible'){
      }
      else{
        // message.error('Some error occured, please try agian later!');
      }

      setCelery_Loader(false)
      setLoader(false);
      setProgress(0);
    }
    else{
      setProgress(percent);
      setProgressMessage?setProgressMessage(responseData?.progress?.description):console.log(responseData?.progress?.description);

    }
  }).catch((error) => {
    // Handle errors if needed
    console.error('API call error: ', error);
    // Set the loading state to false in case of an error
    setCelery_Loader(false);
    setLoader(false);
    setProgress(0);
  })
  }
};

useEffect(() => {
  const intervalId = setInterval(() => {
      if (!is_celery_loader || isTaskCompleted) { 
          clearInterval(intervalId);
          return;
      }
      callCeleryProgressAPI();
  }, 3000); // Call the API every 3 seconds
  
  return () => clearInterval(intervalId);
}, [is_celery_loader, isTaskCompleted]); 



const Call_Result = async (payload) => {
  if (is_celery_loader) {
    try {
      const res = await Zatca_data(payload); 
      if (res && res.StatusCode === 6000) {
        setmultApiResult(res.data); 
      } else {
        console.error("Unexpected response:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
};


const Call_Result_single = async (payload) => {
    try {
      const res = await Zatca_data(payload); 
      if (res && res.StatusCode === 6000) {
        let correctedResponse = res.data[0].Response?.replace(/'/g, '"');

        console.log(res.data,"===============yggsdff");
        setApiResult(correctedResponse ? JSON.parse(correctedResponse) : "");
      } else {
        console.error("Unexpected response:", res);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
};



  const download_xml = async (data) => {
    let payload = {
      CompanyID: CompanyID, // Pass the required data here
      BranchID: BranchID,
      transaction_id: data.transaction_id,
      type_of_egs: state?.type_of_egs?.value,
      is_download: true,
    };
  
    try {
      const res = await get_invoice_download_api({ ...payload });
  
      // Check if response is ok
      if (res && res.status === 200) {
        // Create a Blob from the response data
        const url = window.URL.createObjectURL(new Blob([res.data], { type: 'application/xml' }));
        const link = document.createElement('a');
        link.href = url;
  
        // Set filename (use transaction_id or any other identifier)
        const filename = `download.xml`;
        link.setAttribute('download', filename);
  
        // Trigger download
        document.body.appendChild(link);
        link.click();
        link.remove();
      } else {
        console.error("Failed to download the file. Status: ", res.status);
      }
    } catch (error) {
      console.error("Error in downloading XML file", error);
    }
  };


  // const download_xml = async (data) => {
  //   let payload = {
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     transaction_id: data.transaction_id,
  //     type_of_egs: state?.type_of_egs.value,
  //     is_download: true,
  //   };
  
  //   try {
  //     // Ensure responseType is set to blob to handle file download
  //     let res = await get_invoice_download_api({ ...payload }, { responseType: 'blob' });
  
  //     // Create filename from Content-Disposition header or default to download.xml
  //     const contentDisposition = res.headers?.['content-disposition'];
  //     let filename = 'download.xml';  // Default filename
  
  //     if (contentDisposition) {
  //       const disposition = contentDisposition.split(';');
  //       disposition.forEach(part => {
  //         if (part.trim().startsWith('filename=')) {
  //           filename = part.split('=')[1].replace(/['"]/g, '');
  //         } else if (part.trim().startsWith('filename*=')) {
  //           filename = decodeURIComponent(part.split("''")[1]);
  //         }
  //       });
  //     }
  
  //     // Create a new blob from the response data
  //     const blob = new Blob([res.data], { type: res.headers?.['content-type'] || 'application/xml' });
  
  //     // Create a download link for the blob and trigger the download
  //     const link = document.createElement('a');
  //     link.href = window.URL.createObjectURL(blob);
  //     link.download = filename;
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  
  //   } catch (error) {
  //     console.error('Error downloading file:', error);
  
  //     // Optional: Log the error response for debugging
  //     if (error.response) {
  //       console.log("Server response:", error.response);
  //     }
  //   }
  // };
  
  
  
  
  const validate_xml = async(data) =>{
    setLoadingView(true)
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      transaction_id:data.transaction_id,
    }
    
    
    let res = await get_invoice_validate_api({...payload});
    
    
    if (res.StatusCode === 6000){
      let correctedResponse = res.data?.replace(/'/g, '"');
      try {
        setApiResult(correctedResponse ? JSON.parse(correctedResponse) : "");
      } catch (error) {
        console.error("JSON parsing error:", error);
        setApiResult(correctedResponse); // Handle the error case
      }
      dispatch(
        openSnackbar({
          open: true,
          message: res?.message??"Success",
          severity: "info",
        })
      );
    }
    else{
      setApiResult(res.data)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
    
  }
  
  
  const submitInvoice = async(data,submit_type) => {
    setLoadingView(true)
    console.log(data,"<-data");
    
    
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      transaction_id:data.transaction_id || "",
      submit_type:submit_type,
      type_of_egs:state?.type_of_egs.value,
      is_multiple:true
    }
    
    let res = await get_e_invoice_submit_api({...payload});
    
    
    if  (res.StatusCode === 6000){
      let single = SelectedInvoice?.singleView
      if (single) {
        single.invoice_status = res.Status;        
      }
       console.log("-------------------- working------------------");
      setSelectedInvoice({...SelectedInvoice,singleView:single})
      // dispatch(
      //   openSnackbar({
      //     open: true,
      //     message: res?.message??"Success",
      //     severity: "info",
      //   })
      // );      
      check_progress_function(res.data.task_id);
   

    }
    else{
      setApiResult(res.validationResults)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message,
          severity: "error",
        })
      );
    }
    setLoadingView(false)
    
  }
  
//  const resubmit = async (data, submit_type) => {
//     try {
//       setLoadingView(true);
      
//       // Construct the payload for the API request
//       const payload = {
//         CompanyID,
//         BranchID,
//         transaction_id: data.transaction_id,
//         submit_type,
//         type_of_egs: state?.type_of_egs?.value
//       };
  
//       console.log(data, "<-data");
      
//       // Call the API and wait for response
//       const res = await get_e_invoice_re_submit_api(payload);
      
//       // Check response status and update state accordingly
//       if (res.StatusCode === 6000) {
//         setApiResult(res.validationResults);
  
//         if (SelectedInvoice?.singleView) {
//           SelectedInvoice.singleView.invoice_status = res.Status;
//         }

//         check_progress_function(res.data.task_id)
  
//         // Display success message
//         // dispatch(
//         //   openSnackbar({
//         //     open: true,
//         //     message: res.message ?? "Success",
//         //     severity: "info"
//         //   })
//         // );
  
//         // Finalize process
//         setProgress(0);
//         setOpenView(false);
//       } else {
//         // Handle error case
//         setApiResult(res.validationResults);
//         dispatch(
//           openSnackbar({
//             open: true,
//             message: res.message,
//             severity: "error"
//           })
//         );
//       }
//     } catch (error) {
//       // Handle unexpected errors
//       console.error("Error during resubmit:", error);
//       dispatch(
//         openSnackbar({
//           open: true,
//           message: "An unexpected error occurred.",
//           severity: "error"
//         })
//       );
//     } finally {
//       // Always stop the loading view
//       setLoadingView(false);
//     }
//   };

//   useEffect(() => {
//     const fetchData = async () => {
//       // let egsList = await egs_list();
//       // let egs = egsList[0];
//       let invoiceList = await get_invoice_list();
//       let dataList = invoiceList.Data;
//       setState({ ...state,  dataList,count:invoiceList.count });
//     };

//     fetchData();

//   }, [page_no,isReload]);
  

//  // Helper function to check progress and handle polling
// const check_progress_function = async (task_id) => {
//   try {
//     // Call the check_progress API to get the task status
//     const res = await check_progress(task_id);

//     if (res.state === "SUCCESS") {
//       // Task is complete, handle success actions
//       setProgress(100);
//       dispatch(
//         openSnackbar({
//           open: true,
//           message: res.message ?? "Task completed successfully",
//           severity: "info",
//         })
//       );
//       setOpenView(false); // Close the view or take other actions
//     } else if (res.state === "PENDING") {
//       // Task is still in progress, keep polling
//       console.log("Task is still in progress...");
//       setProgress(res.progress || 50); // Optional: update progress if available

//       // Continue polling after a delay (e.g., 3 seconds)
//       setTimeout(() => check_progress(task_id), 3000);
//     } else if (res.state === "FAILURE") {
//       // Task failed, handle error
//       dispatch(
//         openSnackbar({
//           open: true,
//           message: res.message ?? "Task failed",
//           severity: "error",
//         })
//       );
//       setProgress(0);
//       setOpenView(false); // Close the view or take other actions
//     }
//   } catch (error) {
//     // Handle any unexpected errors
//     console.error("Error while checking task progress:", error);
//     dispatch(
//       openSnackbar({
//         open: true,
//         message: "An unexpected error occurred while checking progress",
//         severity: "error",
//       })
//     );
//     setProgress(0);
//     setOpenView(false); // Close the view or take other actions
//   }
// };  
  
 // Resubmit function: Initiates the re-submit and starts checking task progress
// const resubmit = async (data, submit_type) => {
//   try {
//     setLoadingView(true);
    
//     // Construct the payload for the API request
//     const payload = {
//       CompanyID,
//       BranchID,
//       transaction_id: data.transaction_id,
//       submit_type,
//       type_of_egs: state?.type_of_egs?.value
//     };

//     console.log(data, "<-data");
    
//     // Call the API and wait for response
//     const res = await get_e_invoice_re_submit_api(payload);
//     console.log(res.data.task_id,"Respoinse Is printing")
    
//     // Check response status and update state accordingly
//     if (res.StatusCode === 6000) {
//       setApiResult(res.validationResults);

//       if (SelectedInvoice?.singleView) {
//         SelectedInvoice.singleView.invoice_status = res.Status;
//       }

//       // Start checking the progress of the task
//       check_progress_function(res.data.task_id);

//       // Reset progress bar and close the view when task is completed
//       setProgress(0);
//     } else {
//       // Handle error case: API call failed or returned non-successful status
//       setApiResult(res.validationResults);
//       dispatch(
//         openSnackbar({
//           open: true,
//           message: res.message,
//           severity: "error"
//         })
//       );
//     }
//   } catch (error) {
//     // Handle unexpected errors during the resubmit process
//     console.error("Error during resubmit:", error);
//     dispatch(
//       openSnackbar({
//         open: true,
//         message: "An unexpected error occurred.",
//         severity: "error"
//       })
//     );
//   } finally {
//     // Always stop the loading view
//     setLoadingView(false);
//   }
// };


const resubmit = async (data,submit_type) =>{
  setLoadingView(true)
  let payload = {
    CompanyID: CompanyID,
    BranchID: BranchID,
    transaction_id:data.transaction_id,
    submit_type:submit_type,
    type_of_egs:state?.type_of_egs.value
  }
  

  console.log(data,"<-data");
  

  
  let res = await get_e_invoice_re_submit_api({...payload});
  
  
  if (res.StatusCode === 6000){
    setApiResult(res.validationResults)
    let single = SelectedInvoice?.singleView
    if (single) {
      single.invoice_status = res.Status;        
    }
    // setSelectedInvoice({...SelectedInvoice,singleView:single})
    dispatch(
      openSnackbar({
        open: true,
        message: res?.message??"Success",
        severity: "info",
      })
    );   
    setOpenView(false)   
  }
  else{
    setApiResult(res.validationResults)
    dispatch(
      openSnackbar({
        open: true,
        message: res.message,
        severity: "error",
      })
    );
  }
  setLoadingView(false)
}

// Polling function to check task progress continuously
const check_progress_function = async (task_id) => {
  try {
    // Call the API to check the progress of the task
    const res = await check_progress(task_id);

    if (res.state === "SUCCESS") {
      // Task is complete, handle success actions
      setProgress(100);

      setLoader(false)
      dispatch(
        openSnackbar({
          open: true,
          message: res.message ?? "Task completed successfully",
          severity: "info",
        })


      );
      let payload = {
        CompanyID: CompanyID,
        BranchID: BranchID,
        type_of_egs:state?.type_of_egs.value,
      };
      Call_Result_single(payload)
      
   
    } else if (res.state === "PENDING") {
      // Task is still in progress, keep polling
      console.log("Task is still in progress...");
      setProgress(true)
      setProgress(res.progress || 50); // Optionally update progress bar
      setLoader(true)
      let res2 = { data: "Loading Please Wait ........" };  
      setApiResult(res2.data);
      // Continue polling after a delay (3 seconds)
      setTimeout(() => check_progress_function(task_id), 3000);

      setProgress(0);

    } else if (res.state === "FAILURE") {
      // Task failed, handle error
      dispatch(
        openSnackbar({
          open: true,
          message: res.message ?? "Task failed",
          severity: "error",
        })
      );
      setOpenView(false); 
      setProgress(0);

    }
    setProgress(0);

  } catch (error) {
    // Handle unexpected errors during the progress check
    console.error("Error while checking task progress:", error);
    dispatch(
      openSnackbar({
        open: true,
        message: "An unexpected error occurred while checking progress",
        severity: "error",
      })
    );
    setProgress(0);
    setOpenView(false); // Close the view or take other actions
  }
};

// Example useEffect for fetching invoice data
useEffect(() => {
  const fetchData = async () => {
    // Fetch data asynchronously and update the component's state
    try {
      let invoiceList = await get_invoice_list();
      let dataList = invoiceList.Data;
      let type_of_egs = state.type_of_egs
      
      if( invoiceList.egs_status < 2){
        type_of_egs = state.egsList.find(e => e.value === invoiceList.egs_status);
      }
      setState({ ...state, dataList, count: invoiceList.count ,type_of_egs});
      
    } catch (error) {
      console.error("Error fetching invoice data:", error);
    }
  };

  fetchData();
}, [page_no, isReload]); // Dependencies for re-fetching the data


console.log(state?.type_of_egs.value,"log is printing==============><>>>>>");
  return (
    <>
     {progress > 0 && loading?
      <LoaderLite progress={progress} fullscreen={true} />
      :null}
    <Paper sx={{ height: "100%", width: "100%" }}>
      <Box
        sx={{
          px: "26px",
          py: "15px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #F5F5F5",
        }}
      >
        <Box sx={{display:"flex", gap:"16px"}}>
          <Typography
            sx={{
              fontSize: "25px",
              fontWeight: "500",
              mr: 2,
              color: "#001746",
            }}
            variant="h2"
          >
            {t("Zatca E-Invoice Report")}
          </Typography>
            <FilterBtn onClick={() => setOpenFilter(true)} />
         

            </Box>
          {/* <InvoiceAutoComplete
            name={"egs"}
            label={"Select a Egs"}
            optionLabel={"egs_name"}
            List={state.egsList}
            Value={state.egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChan
            ge}
          /> */}
 
          {/* <p style={{color:"#0A9EF3"}}>Egs Name : {state?.egs?.egs_name}</p>
          
          
          <InvoiceAutoComplete
            name={"type_of_egs"}
            label={"Select a type_of_egs"}
            optionLabel={"name"}
            List={[{name:"sandbox",value:0},{name:"simulation",value:1},{name:"production",value:2}]}
            Value={state.type_of_egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChange}
          />
           */}

        
        {/* <ViknAutoComplete
            multiple
            limitTags={1}
            options={statusOptions}
            disableCloseOnSelect
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => {
              const { key, ...optionProps } = props;
              return (
                <li key={key} {...optionProps}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option.label}
                </li>
              );
            }}
            style={{
              width: 400,
              border: '1px solid lightgray',
            }}
            renderInput={(params) => (
              <TextField 
                {...params} 
                label="Select Status" 
                placeholder="Status Options" 
                variant="outlined"
                style={{ height: '100%' }}
              />
            )}
            onChange={(event, value) => {
              // Update the state with only the values of the selected status
              setState((prevState) => ({
                ...prevState,
                status: value.map(item => item.value), // Only keep the values
                selectedItems: value.map(item => item.value), // If you want to keep selected item values as well
              }));
            }}
            /> */}
            
        <FlexBox className="right" style={{gap:"25px"}}>
        <ButtonBase onClick={()=>{setIsReload(!isReload)}} style={{fontSize:"14px" ,display:"flex", gap:"6px"}}>
        <img
          src={Icons.Refresh}
          alt="vbLogo"
          style={{
            width: "100%", 
            height: "100%", 
            objectFit: "contain",
            transition: 'transform 1.5s ease',  // smooth rotation transition
            transform: isReload ? 'rotate(720deg)' : 'rotate(0deg)',  // rotate based on state
          }}        /> {t("Reload")}
          </ButtonBase>
        <Button variant="contained" sx={{background:"#0A9EF3"}} onClick={() => ViewMultiSelect()}>Submit Multiple</Button> 

          <Pagenation
            totalItem={state.count}
            page_no={page_no}
            setPage_no={setPage_no}
          />
          {/* <SettingsIcon/> */}
        </FlexBox>
      </Box>

      <VBTableContainer>
        <VBTableHeader>
          <VBTableCellHeader
            sx={{ textAlign: "left", padding: "0px", paddingLeft: "5px" }}
          >
          {/* <Checkbox
            checked={state.isSelectedAll}
            onChange={() => selectItems("all")} // This will toggle selecting/deselecting all items
            sx={{
              padding: "2px",
              margin: 0,
              color: blue[400],
              "&.Mui-checked": { color: blue[400] },
              "& .MuiSvgIcon-root": { fontSize: "20px" },
            }}
          /> */} {"   "}


          </VBTableCellHeader>
          <VBTableCellHeader sx={{ textAlign: "left" }}>
            {t("Serial No")}
          </VBTableCellHeader>
          <VBTableCellHeader>{t("VoucherNumber")}</VBTableCellHeader>
          <VBTableCellHeader>{t("CustomerName")}</VBTableCellHeader>
          <VBTableCellHeader>{t("CreatedDate")}</VBTableCellHeader>
          <VBTableCellHeader>{t("InvoiceName")}</VBTableCellHeader>
          <VBTableCellHeader >{t("Gross Amount")}</VBTableCellHeader>
          <VBTableCellHeader >{t("Tax Amount")}</VBTableCellHeader>
          <VBTableCellHeader >{t("Grand Total")}</VBTableCellHeader>
          <VBTableCellHeader>{t("xml")}</VBTableCellHeader>
          <VBTableCellHeader>
            {t("Status")} 
            </VBTableCellHeader>
          <VBTableCellHeader >
              Action
          </VBTableCellHeader>
        </VBTableHeader>

        {loading ? (
          <VBSkeletonLoader />
        ) : (
          // <TableBody>
          //   {state.dataList.map((data, i) => {
          //     <>
          //     <TableRow
          //     // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
          //     //   onClick={()=>{handleTableRowClick(data)}}
          //     >
          //       <VBTableCellBody
          //         sx={{
          //           // cursor: "pointer",
          //           textAlign: "left",
          //           padding: "0px",
          //           paddingLeft: "5px",
          //         }}
          //       >
          //         <Checkbox
          //           checked={
          //             state.selectedItems.includes(data.id) ? true : false
          //           } // The checked prop determines whether this checkbox is checked.
          //           onChange={() => {
          //             selectItems("not", data.id);
          //           }}
          //           sx={{
          //             padding: "2px",
          //             margin: 0,
          //             color: blue[400],
          //             "&.Mui-checked": {
          //               color: blue[400],
          //             },
          //             "& .MuiSvgIcon-root": { fontSize: "20px" },
          //           }}
          //         />
          //       </VBTableCellBody>
          //       <VBTableCellBody
          //         sx={{  cursor: "pointer" }}
          //       >
          //          {(page_no - 1) * 15 + i + 1}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.VoucherNumber}
          //       </VBTableCellBody>

          //       <VBTableCellBody sx={{ cursor: "pointer" }}>
          //         {data?.CustomerName}
          //       </VBTableCellBody>
          //       <VBTableCellBody
          //         className="truncated-text "
          //         sx={{ cursor: "pointer" }}
          //       >
          //         {data?.CreatedDate}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{ cursor: "pointer" }}>
          //         {data?.InvoiceName}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.GrossAmount}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.TaxAmount}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //         {data?.GrandTotal}
          //       </VBTableCellBody>
          //       <VBTableCellBody sx={{  cursor: "pointer" }}>
          //       <Button onClick={()=>{download_xml({transaction_id:data.transaction_id})}}>Download</Button>
          //       </VBTableCellBody>
          //       <VBTableCellBody
          //         sx={{  cursor: "pointer",color:data.Status===0?"blue":data.Status ===1 || data.Status ==4?"green":data.Status === 2 || data.Status ==6?"red":data.Status ===3 || data.Status ==5 ?"orange":"black" }}
          //       >
          //         {data.Status===0?"Not Submitted":data.Status ===1?"Success":data.Status === 2?"Failed":data.Status ===3 ?"Success With Warnings":data.Status ==4?"Resubmit and success":data.Status ==5?"resubmit and success with warnings":data.Status ==6?"resubmit and failed":"Unknown Status"}    
          //       </VBTableCellBody>



          //       {(data.Status===0 || data.Status===1 || data.Status===2) ?
          //           <VBTableCellBody sx={{  cursor: "pointer" }}>
          //             <Button onClick={()=> ClickViewInvoice(data.id,data?.InvoiceName,data?.VoucherNumber,data.transaction_id) }>View</Button>  
          //           </VBTableCellBody>

          //           :


          //           <VBTableCellBody sx={{  cursor: "pointer" }}>
          //             <Button onClick={()=> handleOpenIndex(i) }>Open</Button>  
          //           </VBTableCellBody>
          //       }



          //     </TableRow>
          //     {openIndex ===i?

          //       {i.ReportList.map((data, j) => (
          //        <TableRow
          //        // onClick={() => navigate(filterOptions.filterType==="return" || data.GrandTotal < 0 ? VIKNBOOKS_FRONT_URL+"/dashboard/sales-return-preview" :  VIKNBOOKS_FRONT_URL+"/dashboard/sale-invoice-single", { state: { invoice_type: "sales_invoice", id: data.id }, unq_id: data.id })}
          //        //   onClick={()=>{handleTableRowClick(data)}}
          //        >
          //          <VBTableCellBody
          //            sx={{
          //              // cursor: "pointer",
          //              textAlign: "left",
          //              padding: "0px",
          //              paddingLeft: "5px",
          //            }}
          //          >
          //            <Checkbox
          //              checked={
          //                state.selectedItems.includes(data.id) ? true : false
          //              } // The checked prop determines whether this checkbox is checked.
          //              onChange={() => {
          //                selectItems("not", data.id);
          //              }}
          //              sx={{
          //                padding: "2px",
          //                margin: 0,
          //                color: blue[400],
          //                "&.Mui-checked": {
          //                  color: blue[400],
          //                },
          //                "& .MuiSvgIcon-root": { fontSize: "20px" },
          //              }}
          //            />
          //          </VBTableCellBody>
          //          <VBTableCellBody
          //            sx={{  cursor: "pointer" }}
          //          >
          //             { j + 1}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.VoucherNumber}
          //          </VBTableCellBody>
   
          //          <VBTableCellBody sx={{ cursor: "pointer" }}>
          //            {data?.CustomerName}
          //          </VBTableCellBody>
          //          <VBTableCellBody
          //            className="truncated-text "
          //            sx={{ cursor: "pointer" }}
          //          >
          //            {data?.CreatedDate}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{ cursor: "pointer" }}>
          //            {data?.InvoiceName}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.GrossAmount}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.TaxAmount}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //            {data?.GrandTotal}
          //          </VBTableCellBody>
          //          <VBTableCellBody sx={{  cursor: "pointer" }}>
          //          <Button onClick={()=>{download_xml({transaction_id:data.transaction_id})}}>Download</Button>
          //          </VBTableCellBody>
          //          <VBTableCellBody
          //            sx={{  cursor: "pointer",color:data.Status===0?"blue":data.Status ===1 || data.Status ==4?"green":data.Status === 2 || data.Status ==6?"red":data.Status ===3 || data.Status ==5 ?"orange":"black" }}
          //          >
          //            {data.Status===0?"Not Submitted":data.Status ===1?"Success":data.Status === 2?"Failed":data.Status ===3 ?"Success With Warnings":data.Status ==4?"Resubmit and success":data.Status ==5?"resubmit and success with warnings":data.Status ==6?"resubmit and failed":"Unknown Status"}    
          //          </VBTableCellBody>
   
   
   
          //          {(data.Status===0 || data.Status===1 || data.Status===2) ?
          //              <VBTableCellBody sx={{  cursor: "pointer" }}>
          //                <Button onClick={()=> ClickViewInvoice(data.id,data?.InvoiceName,data?.VoucherNumber,data.transaction_id) }>View</Button>  
          //              </VBTableCellBody>
   
          //              :
   
   
          //              <VBTableCellBody sx={{  cursor: "pointer" }}>
          //                <Button onClick={()=> handleOpenIndex(i) }>Open</Button>  
          //              </VBTableCellBody>
          //          }
   
   
   
          //        </TableRow>
          //      ))}
          //     }
          //     </>
          //   })}
          //   <VBTableRowNull
          //     length={
          //       100
          //       // state.data_count ? state.data_count : state.dataList.length
          //     }
          //   />
          // </TableBody>
          <TableBody>
          {state.dataList.map((data, i) => (
                <React.Fragment key={i}>
                  <TableRow
                    key={i}
                    sx={{
                      backgroundColor: openIndex === i ? "#d0d0d0" : "inherit",  // Apply color when expanded
                      transition: "background-color 0.3s ease-in-out",  // Smooth transition
                    }}
                  >
                    <VBTableCellBody
                      sx={{
                        textAlign: "left",
                        padding: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                         {"   "}
                  {/* <Checkbox
                    checked={state.selectedItems.includes(data.id) ? true : false}
                    onChange={() => selectItems("not", data.id)}
                    sx={{
                      padding: "2px",
                      margin: 0,
                      color: blue[400],
                      "&.Mui-checked": {
                        color: blue[400],
                      },
                      "& .MuiSvgIcon-root": { fontSize: "20px" },
                    }}
                  /> */}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {(page_no - 1) * 15 + i + 1}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.VoucherNumber}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.CustomerName}
                </VBTableCellBody>
                <VBTableCellBody className="truncated-text" sx={{ cursor: "pointer" }}>
                  {data?.CreatedDate}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.InvoiceName}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.GrossAmount}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.TaxAmount}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  {data?.GrandTotal}
                </VBTableCellBody>
                <VBTableCellBody sx={{ cursor: "pointer" }}>
                  <Button onClick={() => download_xml({ transaction_id: data.transaction_id })}>
                    Download
                  </Button>
                </VBTableCellBody>
                <VBTableCellBody
                  sx={{
                    cursor: "pointer",
                    color:
                      data.Status === 0
                        ? "blue"
                        : data.Status === 1 || data.Status === 4
                        ? "green"
                        : data.Status === 2 || data.Status === 6
                        ? "red"
                        : data.Status === 3 || data.Status === 5
                        ? "orange"
                        : "black",
                  }}
                >
                  {data.Status === 0
                    ? "Not Submitted"
                    : data.Status === 1
                    ? "Success"
                    : data.Status === 2
                    ? "Failed"
                    : data.Status === 3
                    ? "Success With Warnings"
                    : data.Status === 4
                    ? "Resubmit and success"
                    : data.Status === 5
                    ? "Resubmit and success with warnings"
                    : data.Status === 6
                    ? "Resubmit and failed"
                    : "Unknown Status"}
                </VBTableCellBody>

                {(data.Status === 0 || data.Status === 1 || data.Status === 2 || data.Status === 3) ? (
                  <VBTableCellBody sx={{ cursor: "pointer",display:"flex",justifyContent:"center"}}>
                    <Button onClick={() => ClickViewInvoice(data.id, data?.InvoiceName, data?.VoucherNumber, data.transaction_id)}>
                      View
                    </Button>
                  </VBTableCellBody>
                ) : (
                  <VBTableCellBody sx={{ cursor: "pointer",display:"flex",justifyContent:"center",}}>
                    <Button sx={{
                      transform: openIndex === i ? "rotate(180deg)" : "rotate(0deg)",
                      transition: "transform 0.3s ease-in-out",
                      borderRadius: "50%",        
                      width: "38px",
                      height: "38px",
                      minWidth: "38px",
          
                      }} onClick={() => handleOpenIndex(i)}><ExpandMoreIcon /></Button>
                  </VBTableCellBody>
                )}
              </TableRow>

              {openIndex === i &&
                data.ReportList.map((report, j) => (
                  <TableRow key={0} sx={{
                    transition: "transform 0.3s ease-in-out",
                    "> td":{
                      backgroundColor: "#d4e6f6e0",
                    }
                  }}>
                    <VBTableCellBody
                      sx={{
                        textAlign: "right",
                        padding: "0px",
                        paddingLeft: "5px",
                      }}
                    >
                      {"   "}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {j + 1}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.VoucherNumber}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.CustomerName}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.CreatedDate}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.InvoiceName}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.GrossAmount}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.TaxAmount}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      {report?.GrandTotal}
                    </VBTableCellBody>
                    <VBTableCellBody sx={{ cursor: "pointer" }}>
                      <Button onClick={() => download_xml({ transaction_id: report.transaction_id })}>
                        Download
                      </Button>
                    </VBTableCellBody>
                    <VBTableCellBody
                      sx={{
                        cursor: "pointer",
                        color:
                          report.Status === 0
                            ? "blue"
                            : report.Status === 1 || report.Status === 4
                            ? "green"
                            : report.Status === 2 || report.Status === 6
                            ? "red"
                            : report.Status === 3 || report.Status === 5
                            ? "orange"
                            : "black",
                      }}
                    >
                      {report.Status === 0
                        ? "Not Submitted"
                        : report.Status === 1
                        ? "Success"
                        : report.Status === 2
                        ? "Failed"
                        : report.Status === 3
                        ? "Success With Warnings"
                        : report.Status === 4
                        ? "Resubmit and success"
                        : report.Status === 5
                        ? "Resubmit and success with warnings"
                        : report.Status === 6
                        ? "Resubmit and failed"
                        : "Unknown Status"}
                    </VBTableCellBody>

                      <VBTableCellBody sx={{ cursor: "pointer" }}>
                        <Button sx={{fontSize:"11px"}} onClick={() => ClickViewInvoice(report.id, report?.InvoiceName, report?.VoucherNumber, report.transaction_id)}>
                          View
                        </Button>
                      </VBTableCellBody>
                    
                  </TableRow>
                ))}
            </React.Fragment>
          ))}
          <VBTableRowNull
            length={100 /* or state.data_count ? state.data_count : state.dataList.length */}
          />
        </TableBody>

        )}
      </VBTableContainer>
      
      
      
      <ViewInvoice 
      open = {openView} 
      setOpen={setOpenView} 
      SelectedInvoice={SelectedInvoice} 
      submitInvoice={submitInvoice} 
      apiResult = {apiResult} 
      validate_xml={validate_xml} 
      invoice_status={SelectedInvoice?.singleView?.invoice_status}
      loadingView={loadingView}
      resubmit={resubmit}
      />

      <MultSelect 
      open = {openMulipleView} 
      setOpen={setOpenMulipleView} 
      submitInvoice={submitInvoice} 
      state = {state} setLoader={setLoader}  
      setCelery_Loader={setCelery_Loader} 
      setProgress_id ={setProgress_id} 
      showLoader = {showLoader} 
      multapiResult = {multapiResult}
      />
      
    </Paper>
    <ViknDrawer
        open={openFilter}
        onFilter={()=>setIsReload(!isReload)}
        handleClose={() => setOpenFilter(false)}
        filterCompnt={
          <Box sx={{p:"16px", pt:"58px" } }>
          <p style={{color:"#0A9EF3"}}>Egs Name : {state?.egs?.egs_name}</p>
          <InvoiceAutoComplete
            name={"type_of_egs"}
            label={"Select a type_of_egs"}
            optionLabel={"name"}
            List={[{name:"sandbox",value:0},{name:"simulation",value:1},{name:"production",value:2}]}
            Value={state.type_of_egs}
            OnChange={onAutoChange}
            OnInputChange={onInputChange}
          />
        
          <ViknAutoComplete
            sx={{ mt: "20px" }}
            multiple
            limitTags={1}
            options={statusOptions}
            disableCloseOnSelect
            value={state.selectedItems}
            isOptionEqualToValue={(option, value) => option.value === value.value} // Ensure comparison is done by value
            getOptionLabel={(option) => option.label}
            renderOption={(props, option, { selected }) => {
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected} // Selected option controls the checked state
                  />
                  {option.label}
                </li>
              );
            }}
            style={{
              // width: 400,
              border: '1px solid lightgray',
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select Status"
                placeholder="Status Options"
                variant="outlined"
                style={{ height: '100%' }}
              />
            )}
            onChange={(event, value) => {
              // Ensure state is updated with selected options
              setState((prevState) => ({
                ...prevState,
                status: value.map(item => item.value), // Store only values in state
                selectedItems: value, // Store full selected items if needed
              }));
            }}
          />

                   {/* <ButtonBase onClick={()=>{setIsReload(!isReload)}}>
            Reload List
          </ButtonBase> */}

        {/* <Button variant="contained" sx={{background:"#0A9EF3"}} onClick={() => ViewMultiSelect()}>Submit Multiple</Button> */}

          </Box>
        }
      />
    </>
  );
};

export default EinvoiceInvoiceList;

const FlexBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "39%",
  "&.right": {
    justifyContent: "flex-end",
  },
  "&.left": {
    justifyContent: "flex-start",
    gap: "22px",
  },
  "&.center":{
    justifyContent: "center",
    gap:"100px"
  }
}));