import { TableBody, TableRow } from '@mui/material';
import React from 'react'
import { VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable';
import lodash from "lodash"
import { useTranslation } from 'react-i18next';
import { formatNumber } from '../../../../../generalFunction';

const SummaryTable = ({filterData}) => {
    const [t] = useTranslation("common");
  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader sx={{borderRight: "1px solid #e6e6e6"}}>{t('Description')}</VBTableCellHeader>
      {filterData.reportList.map((e, i) => (
        <VBTableCellHeader sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>{e.Name}</VBTableCellHeader>
      ))}
      <VBTableCellHeader sx={{ textAlign: 'right'}}>{t('Amount')}</VBTableCellHeader>
    </VBTableHeader>
    <TableBody>
      
        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Gross Sale")}</p>
            <p>{t("Tax on Sale")}</p>
            <p style={{color:"#1d8146"}}>{t("Total Sale")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.gross_sale)}</p>
              <p>{formatNumber(e.tax_sale)}</p>
              <p>{formatNumber(e.total_sale)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.gross_sale;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.tax_sale;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.total_sale;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Gross Return")}</p>
            <p>{t("Tax on Return")}</p>
            <p style={{color:"#1d8146"}}>{t("Total Return")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.gross_return)}</p>
              <p>{formatNumber(e.tax_return)}</p>
              <p>{formatNumber(e.total_return)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.gross_return;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.tax_return;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.total_return;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Cash Sale")}</p>
            <p>{t("Bank Sale")}</p>
            <p>{t("Credit Sale")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.CashSale)}</p>
              <p>{formatNumber(e.BankSale)}</p>
              <p>{formatNumber(e.CreditSale)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CashSale;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.BankSale;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CreditSale;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Cash Sale Return")}</p>
            <p>{t("Bank Sale Return")}</p>
            <p style={{color:"#1d8146"}}>{t("Credit Sale Return")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.CashReturn) ?? "-"}</p>
              <p>{formatNumber(e.BankReturn) ?? "-"}</p>
              <p>{formatNumber(e.CreditReturn) ?? "-"}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CashReturn;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.BankReturn;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CreditReturn;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Cash Receipt")}</p>
            <p>{t("Bank Receipt")}</p>
            <p style={{color:"#1d8146"}}>{t("Total Receipts")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.CashReceipt)}</p>
              <p>{formatNumber(e.BankReceipt)}</p>
              <p>{formatNumber(e.total_receipt)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CashReceipt;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.BankReceipt;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.total_receipt;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Cash Payments")}</p>
            <p>{t("Bank Payments")}</p>
            <p style={{color:"red"}}>{t("Total Payments")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.CashPayments)}</p>
              <p>{formatNumber(e.BankPayments)}</p>
              <p>{formatNumber(e.total_payment)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CashPayments;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.BankPayments;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.total_payment;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6"}}>
            <p>{t("Cash Expense")}</p>
            <p>{t("Bank Expense")}</p>
            <p>{t("Credit Expense")}</p>
            <p style={{color:"red"}}>{t("Total Expense")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p>{formatNumber(e.CashExpense)}</p>
              <p>{formatNumber(e.BankExpense)}</p>
              <p>{formatNumber(e.CreditExpense)}</p>
              <p>{formatNumber(e.total_expense)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.CashExpense;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.BankExpense;
              })
            )}
          </p>
          <p>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.total_expense;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        <TableRow sx={{ backgroundColor: "green"}}>
          <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", }}>
            <p style={{color:"white"}}>{t("Net Cash")}</p>
            <p style={{color:"white"}}>{t("Total Bank")}</p>
            <p style={{color:"white"}}>{t("Total Credit")}</p>
            <p style={{color:"white"}}>{t("Total Amount")}</p>
          </VBTableCellBody>

          {filterData.reportList.map((e, i) => (
            <VBTableCellBody sx={{borderRight: "1px solid #e6e6e6", textAlign: 'right'}}>
              <p style={{color:"white"}}>{formatNumber(e.NetCash)}</p>
              <p style={{color:"white"}}>{formatNumber(e.NetBank)}</p>
              <p style={{color:"white"}}>{formatNumber(e.TotalCredit)}</p>
              <p style={{color:"white"}}>{formatNumber(e.TotalAmount)}</p>
            </VBTableCellBody>
          ))}

          <VBTableCellBody sx={{textAlign: 'right'}}>
          <p style={{color:"white"}}>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.NetCash;
              })
            )}
          </p>
          <p style={{color:"white"}}>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.NetBank;
              })
            )}
          </p>
          <p style={{color:"white"}}>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.TotalCredit;
              })
            )}
          </p>
          <p style={{color:"white"}}>
            {formatNumber(
              lodash.sumBy(filterData.reportList, function (o) {
                return o.TotalAmount;
              })
            )}
          </p>
          </VBTableCellBody>
        </TableRow>

        {/* <TableRow></TableRow> */}

    </TableBody>
  </VBTableContainer>
  )
}

export default SummaryTable