import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';
// import CompanyImage from '../../../../src/assets/logo512.png'
import MyTableComponent from './PrintTable';
// import { QRCodeCanvas } from 'qrcode.react';
import { useSelector } from 'react-redux';
// import * as base from "../../../../src/settings";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { capitalize } from 'lodash';
import { URL } from '../generalVeriable';


const EinvoiceTemplate = React.forwardRef((props, ref) => {
    console.log(props.data," l1m1dataCenter");
    
  const [Data, setData] = useState([])
  const [printsettings, setprintsettings] = useState([])
  console.log(printsettings,"dddddddddddaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa",Data,'LLLLLLLLL',props?.data);
  const qrValue = Data?.data?.ZatcaDetails?.qr_code || Data?.data?.QrUrl
  
  const {
    CompanyName,
    CompanyNameSec,
    CountryName,
    District,
    DistrictSec,
    PostalCode,
    PostalCodeSec,
    Address1, // BuildingNo
    Address1Sec,
    Address2, // StreetName
    Address2Sec,
    City,
    CitySec,
    VATNumber,
    CRNumber,


  } = useSelector((state) => state.companyDetails)
  const {PriceDecimalPoint} = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { EnableEinvoiceKSA } = useSelector((state) => state.generalSettingsSclice.generalSettings);


  const [imageUrl, setImageUrl] = useState('');
  const [footerImg, setfooterImg] = useState('')

  let imagefile =  props?.data?.companyheaderfile 
  let footerImgFile = props?.data?.companyfooterfile


  const getArabicInvoiceName = (InvoiceName) => {
    switch(InvoiceName){
      case "Tax Invoice":
        return "الفاتورة الضريبية";
      case "Simplified Tax Invoice":
        return "فاتورة ضريبية مبسطة";
      case "tax invoice debit note":
        return "مذكرة خصم الفاتورة الضريبية";
      case "simplified debit note":
        return "مذكرة مدين مبسطة"
      case "tax invoice credit note" :
        return "مذكرة ائتمان الفاتورة الضريبية"
      case "simplified credit note":
        return "مذكرة ائتمان مبسطة"
      case "Prepayment Tax Invoice":
        return "فاتورة ضريبة الدفع المسبق"
      case "Prepayment Simplified Tax Invoice":
        return "الدفع المسبق للفاتورة الضريبية المبسطة"
      default:
        return "";
    }
  }

  const invoiceName = Data?.data?.ZatcaDetails?.InvoiceName;
  const arabicInvoiceName = getArabicInvoiceName(invoiceName);  
  
  useEffect(() => {
    if (props.data) {
      setData(props.data.data);
      setprintsettings(props?.data)
    }
    if (imagefile) {
      const blob = new Blob([imagefile], { type: 'image/jpeg' }); // Adjust the MIME type if necessary
      const url = URL.createObjectURL(blob);
      setImageUrl(url);
    }
    if (footerImgFile) {
      const blob = new Blob([footerImgFile], { type: 'image/jpeg' }); 
      const url = URL.createObjectURL(blob);
      setfooterImg(url);
    }
  }, [props.data]);

  console.log(props.data, "RRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRRR");

  const themeTyphography = createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          variant: 'inherit', // default variant for all Typography components
          fontSize: '14px'
        },
      },
    },
  });



  return (  
    <ThemeProvider theme={themeTyphography}>
    <Paper ref={ref} sx={{ width: '900px', height: "100%", padding:'10px',boxShadow:'none'}}>
      <Box sx={{ border: "2px solid black", borderRadius:'6px',padding:'10px',boxShadow:'none'}}> 

        {/* Header Image */}
        <Box sx={{display:'flex',justifyContent:'center',padding:'2px'}}>
          {(Data?.settings?.IsUploadCompanyHeaderImage || props?.data?.IsUploadCompanyHeaderImage) ? (
            <img style={{height:'115px',objectFit:'center', width:'100%'}} src={imageUrl || `${URL}${Data?.settings?.companyheaderfile}`} alt="Company Header" />
          )
          : 
          <img style={{maxWidth:'150px',height:'100px',objectFit:'cover'}} src={Data?.company?.CompanyLogo ?Data?.company.CompanyLogo : ""} alt="Company Logo" />
          }
        </Box>


          <Box sx={{display:'flex',justifyContent:'center'}}>
            <Typography variant='h5' sx={{fontWeight:600,display: "inline-block",padding:'3px',fontSize:'22px'}} >{invoiceName ? `${capitalize(invoiceName)} - ${arabicInvoiceName}`: Data?.party_details?.VAT_Treatment === '0' ? "Tax Invoice - الفاتورة الضريبية" : "Simplified Tax Invoice - فاتورة ضريبية مبسطة"   }</Typography>
          </Box>
          {Data?.data?.ZatcaDetails?.InvoiceTime &&
          <Typography sx={{textAlign:'end' , fontSize:'12px'}}>Time - {Data?.data?.ZatcaDetails?.InvoiceTime }</Typography>
          }
          <Box sx={{ display : "flex" ,justifyContent:"space-around", marginTop:"10px", border:"1px solid black"}}>
            <Box sx={{  paddingRight: "10px",textAlign:'center' }}>
              <Typography style={{ fontFamily: 'Amiri, serif' }}>Invoice No</Typography>
              <Typography fontSize={'16px'}>{"رقم الفاتورة"}</Typography>
              <Typography>{Data?.data?.VoucherNo || "SI-00"}</Typography>
            </Box>
            <Box sx={{  paddingRight: "10px", textAlign:'center' }}>
              <Typography>Invoice Date</Typography>
              <Typography fontSize={'16px'}>{"تاريخ الفاتورة"}</Typography>
              <Typography>{Data?.data?.Date || "DD/MM/YYYY"}</Typography>
            </Box>
            {(Data?.settings?.IsDeliveryDate || props?.data?.IsDeliveryDate)&&
            <Box sx={{  paddingRight: "10px",textAlign:'center' }}>
              <Typography>Delivery Date</Typography>
              <Typography fontSize={'16px'}>تاريخ توصيل </Typography>
              <Typography>{Data?.data?.DeliveryDate || "DD/MM/YYYY"}</Typography> 
            </Box>
            }
            {(Data?.settings?.IsRefNo || props?.data?.IsRefNo) &&
            <Box sx={{  paddingRight: "10px",textAlign:'center' }}>
              <Typography>Reference No</Typography>
              <Typography  fontSize={'16px'}> {"رقم المرجع"}</Typography>
              <Typography>{Data?.data?.OrderNo || Data?.data?.RefferenceBillNo ||""}</Typography> 
            </Box>
            }
          </Box>
          <Box sx={{ display:"flex",justifyContent:"space-between",backgroundColor:'#002647', color:"white",padding:'4px',marginTop:'10px'}}>
            {(Data?.invoice_type === "purchase_return" || Data?.invoice_type === "purchase_invoice" || props?.data.InvoiceType ===  "purchase_return" || props?.data.InvoiceType === "purchase_invoice" ) ? (
              <>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Buyer</Typography>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>مشتر</Typography>
              </>
            ) : (
              <>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Seller</Typography>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>تاجر</Typography>
              </>
            )}
          </Box>
          <Box sx={{ display:'flex', justifyContent:'space-evenly', border: "1px solid black"}}>
            <Box sx={{borderRight:'1px solid black',width:'50%',padding:'4px'}}>
              {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}} >Name :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.CompanyName || CompanyName}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerStreetName || props?.data?.IsCustomerStreetName) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Street Name :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.Street ||Address2}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCity || props?.data?.IsCustomerCity) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>City :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.City || City }</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerDistrict || props?.data?.IsCustomerDistrict) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>District :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.District || District}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCountry || props?.data?.IsCustomerCountry) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Country :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.CountryName || CountryName}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerBuildingNo || props?.data?.IsCustomerBuildingNo) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Building No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.BuildingNo || Address1}</Typography>
              </Box>
              }
              {/* {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Addl No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.BuildingNo || ''}</Typography>
              </Box>
              } */}
              {(Data?.settings?.IsPostalCode || props?.data?.IsPostalCode) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>PostalCode :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.PostalCode || PostalCode}</Typography>
              </Box>
              }
              {((Data?.settings?.IsCustomerVATNo || props?.data?.IsCustomerVATNo)) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>VAT No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.VATNumber || VATNumber}</Typography>
              </Box> 
              }
              {(Data?.settings?.IsCRNO || props?.data?.IsCRNO) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>CR No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.company?.CRNo || CRNumber}</Typography>
              </Box>
              }
            </Box>
            <Box sx={{padding:'4px',width:'50%',display:'grid',justifyContent:'end'}}>
              {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}} >{Data?.company?.CompanyNameSec || CompanyNameSec}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600 , padding:'0px'}} >: اسم </Typography>
              </Box>
              }
              {((Data?.settings?.IsCustomerStreetName || props?.data?.IsCustomerStreetName)) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.StreetSec || Address2Sec}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >:  الشارع</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCity || props?.data?.IsCustomerCity) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.CitySec  || CitySec}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >:  مدينة</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerDistrict || props?.data?.IsCustomerDistrict) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.DistrictSec || DistrictSec}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >:  يصرف</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCountry || props?.data?.IsCustomerCountry) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.CountryName || CountryName}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >:  دولة</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerBuildingNo || props?.data?.IsCustomerBuildingNo) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.BuildingSec || Address1Sec}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >: رقم المبنى </Typography>
              </Box>
              }
              {/* {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.BuildingNoSec || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: رقم إضافي </Typography>
              </Box>
              } */}
              {(Data?.settings?.IsPostalCode || props?.data?.IsPostalCode) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.PostalCode || PostalCodeSec}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >:  رمز بريدي</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerVATNo || props?.data?.IsCustomerVATNo) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.VATNumber || VATNumber}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >: الرقم الضربية </Typography>
              </Box>
              }
              {(Data?.settings?.IsCRNO || props?.data?.IsCRNO) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.company?.CRNo || CRNumber}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}} >: السجل التجاري </Typography>
              </Box>
              }
            </Box>
          </Box>

          {/* Buyer Section */}

          <Box sx={{ display:"flex",justifyContent:"space-between",backgroundColor:'#002647', color:"white",padding:'4px'}}>
          {(Data?.invoice_type === "purchase_return" || Data?.invoice_type === "purchase_invoice" || props?.data.InvoiceType ===  "purchase_return" || props?.data.InvoiceType === "purchase_invoice" ) ? (
              <>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Seller</Typography>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>تاجر</Typography>

              </>
            ) : (
              <>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>Buyer</Typography>
                <Typography sx={{ fontFamily: 'sans-serif', fontSize: '14px' }}>مشتر</Typography>
              </>
            )}
          </Box>
          <Box sx={{ display:'flex', justifyContent:'space-evenly', border: "1px solid black"}}>
            <Box sx={{borderRight:'1px solid black',width:'50%',padding:'4px'}}>
              {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}} >Name :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.data?.LedgerName || ''}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerStreetName || props?.data?.IsCustomerStreetName) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Street Name :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.StreetName ||""}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCity || props?.data?.IsCustomerCity) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>City :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.City || ""}</Typography>
              </Box>
              } 
              {(Data?.settings?.IsCustomerDistrict || props?.data?.IsCustomerDistrict) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>District :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.District || ""}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCountry || props?.data?.IsCustomerCountry) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Country :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.CountryName || ""}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerBuildingNo || props?.data?.IsCustomerBuildingNo) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Building No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.BuildingNo || ''}</Typography>
              </Box>
              }
              {/* {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>Addl No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{''}</Typography>
              </Box>
              } */}
              {(Data?.settings?.IsPostalCode || props?.data?.IsPostalCode) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>PostalCode :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.PostalCode || ''}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerVATNo || props?.data?.IsCustomerVATNo) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>VAT No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.VATNumber || ''}</Typography>
              </Box>
              }
              {(Data?.settings?.IsCRNO || props?.data?.IsCRNO) &&
              <Box sx={{display:'flex'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600,marginRight:'10px'}}>CR No :</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px'}}>{Data?.party_details?.CRNo || ''}</Typography>
              </Box>
              }
            </Box>
            <Box sx={{padding:'4px',width:'50%',display:'grid',justifyContent:'end'}}>
              {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.party_details?.CustomerSecName || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: اسم </Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerStreetName || props?.data?.IsCustomerStreetName) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.SingleBillingAddress?.StreetNameSec || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: الشارع </Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCity || props?.data?.IsCustomerCity) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.SingleBillingAddress?.CitySec  || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: مدينة </Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerDistrict || props?.data?.IsCustomerDistrict) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.SingleBillingAddress?.DistrictSec || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: يصرف </Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerCountry || props?.data?.IsCustomerCountry) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.SingleBillingAddress?.CountryName || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: دولة </Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerBuildingNo || props?.data?.IsCustomerBuildingNo) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.SingleBillingAddress?.BuildingNoSec || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: رقم المبنى </Typography>
              </Box>
              }
              {/* {(Data?.settings?.IsCustomerName || props?.data?.IsCustomerName) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.BuildingNoSec || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: رقم إضافي  </Typography>
              </Box>
              } */}
              {(Data?.settings?.IsPostalCode || props?.data?.IsPostalCode) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.SingleBillingAddress?.PostalCodeSec || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: رمز بريدي</Typography>
              </Box>
              }
              {(Data?.settings?.IsCustomerVATNo || props?.data?.IsCustomerVATNo) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.data?.VATNumber || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>:  الرقم الضربية</Typography>
              </Box>
              }
              {(Data?.settings?.IsCRNO || props?.data?.IsCRNO) &&
              <Box sx={{display:'flex',justifyContent:'end'}}>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',marginRight:'10px'}}>{Data?.party_details?.CRNo || ''}</Typography>
                <Typography sx={{fontFamily:'sans-serif', fontSize:'14px',fontWeight:600}}>: السجل التجاري </Typography>
              </Box>
              }
            </Box>
          </Box>


        {/* Table Component */}
        <MyTableComponent data={Data} printsettings={printsettings} PriceRounding={PriceDecimalPoint}/>



        <Box sx={{ display: 'flex', justifyContent:'space-between',border: '2px solid black', marginTop: '6px', width: '100%' }}>
          <Box sx={{ width: '45%' }}>
              {(Data?.settings?.ISAmountInWords || props?.data?.ISAmountInWords) &&
              <Box sx={{borderBottom: '1px solid black'}}>
                <Typography sx={{display:'flex',fontSize: '14px', fontFamily: 'sans-serif',paddingLeft:'2px'}}>Amount In Words : </Typography>
                <Typography sx={{fontSize: '14px', fontFamily: 'sans-serif',display:'flex', paddingLeft:'2px'}}>{Data?.amount_in_words+ " " + Data?.currency_name ||"one hundred fifty Riyal"}</Typography>
                <Typography sx={{fontSize: '14px', fontFamily: 'sans-serif',display:'flex',paddingLeft:'2px'}}> {Data?.amount_in_words_arabic ||"مائة وخمسون ريال"}</Typography>

              </Box>
              }
              {(Data?.settings?.BankName || props?.data?.BankName) &&
              <Typography sx={{ textAlign:'start',paddingLeft:'2px'}}>{"BankName - اسم البنك "}: <bdo>{ props?.data?.BankName || Data?.settings?.BankName}</bdo></Typography>
              }
              {(Data?.settings?.AccountNumber || props?.data?.AccountNumber) &&
              <Typography sx={{ textAlign:'start',paddingLeft:'2px'}}>{"Account Number - رقم الحساب "}<bdo>: { props?.data?.AccountNumber || Data?.settings?.AccountNumber}</bdo></Typography>
              }
              {(Data?.settings?.IBANCode || props?.data?.IBANCode) &&
              <Typography sx={{ textAlign:'start',paddingLeft:'2px'}}>{"IBANCode - رمز الآيبان "}: <bdo>{ props?.data?.IBANCode || Data?.settings?.IBANCode}</bdo></Typography>  
              } 
              {(Data?.settings?.SwiftCode || props?.data?.SwiftCode) &&
              <Typography sx={{ textAlign:'start',paddingLeft:'2px'}}>{"SwiftCode - رمز السرعة "}: <bdo>{ props?.data?.SwiftCode || Data?.settings?.SwiftCode}</bdo></Typography>
              }
              {(Data?.settings?.Notes ||props?.data?.Notes) &&
                <Box sx={{ padding: '4px', textAlign:'left', wordWrap:'break-word', overflowWrap:'break-word'}}>
                <div
                  dangerouslySetInnerHTML={{ __html: props?.data?.Notes || Data?.settings?.Notes }}
                />
              </Box>
              }
              {((Data?.settings?.IsInvoiceNote && Data?.data?.Notes !== "" ) ||props?.data?.IsInvoiceNote) &&
              <Box sx={{ borderTop:'1px solid black'}}> 
                <Typography sx={{paddingLeft:'2px',textAlign:'start'}}>{props?.data?.IsInvoiceNote ? "Invoice Note" : Data?.data?.Notes}</Typography>
              </Box>
              }
          </Box>
          <Box sx={{ width: '45%',borderLeft: '1px solid black', borderRight: '1px solid black'}}>
            {(Data?.settings?.IsTotalQuantity || props?.data?.IsTotalQuantity) &&
            <Box sx={{ display: 'flex' , borderBottom:'1px solid black',justifyContent:'space-between', alignItems:'center',padding:'1px' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif', }}>Total Quantity</Typography>
              <Typography sx={{fontSize: '14px'}}>الكمية الإجمالية</Typography>
            </Box>
            }
            {(Data?.settings?.IsTotalGrossAmount || props?.data?.IsTotalGrossAmount) &&
            <Box sx={{ display: 'flex' , borderBottom:'1px solid black',justifyContent:'space-between', alignItems:'center',padding:'1px' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif', }}>Gross Amount</Typography>
              <Typography sx={{fontSize: '14px'}}>المبلغ الإجمالي</Typography>
            </Box>
            }
            {(Data?.settings?.IsTotalDiscount || props?.data?.IsTotalDiscount) &&
            <Box sx={{ display: 'flex' , borderBottom:'1px solid black',justifyContent:'space-between', alignItems:'center',padding:'1px' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif', }}>Total Discount</Typography>
              <Typography sx={{fontSize: '14px'}}>خصم الفاتورة</Typography>
            </Box>
            }
            {(Data?.settings?.IsTotalTax || props?.data?.IsTotalTax) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between',borderBottom:'1px solid black', alignItems:'center',padding:'1px' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Total VAT ({Data?.vat_perc || null}%)</Typography>
              <Typography sx={{fontSize: '14px'}}>إجمالي الضريبة</Typography>
            </Box>
            }
            {(Data?.settings?.IsTotalRoundoff || props?.data?.IsTotalRoundoff) &&
            <Box sx={{ display: 'flex' , borderBottom:'1px solid black',justifyContent:'space-between', alignItems:'center',padding:'1px'}}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Round off</Typography>
              <Typography sx={{fontSize: '14px'}}>جولة قبالة</Typography>
            </Box>
            }
            {(Data?.settings?.IsGrandTotal || props?.data?.IsGrandTotal) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between',borderBottom:'1px solid black', alignItems:'center',padding:'1px' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Grand Total</Typography>
              <Typography sx={{fontSize: '14px'}}>المجموع الكلي</Typography>
            </Box>
            }
            {(Data?.settings?.IsCustomerBalance || props?.data?.IsCustomerBalance) &&
            <Box sx={{ display: 'flex' , borderBottom:'1px solid black',justifyContent:'space-between', alignItems:'center',padding:'1px'}}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Previous Balance</Typography>
              <Typography sx={{fontSize: '14px'}}>الرصيد الحالي</Typography>
            </Box>
            }
            {(Data?.settings?.IsLedgerCurrentBalance || props?.data?.IsLedgerCurrentBalance) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between', alignItems:'center',padding:'1px', borderBottom:'1px solid black' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Current Balance</Typography>
              <Typography sx={{fontSize: '14px'}}>المجموع</Typography>
            </Box>
            }
            {(Data?.settings?.IsTenderCash || props?.data?.IsTenderCash) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between', alignItems:'center',padding:'1px', borderBottom:'1px solid black' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Tender Cash</Typography>
              <Typography sx={{fontSize: '14px'}}> النقد العطاء</Typography>
            </Box>
            }
            {(Data?.settings?.IsPaymentStatus || props?.data?.IsPaymentStatus) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between', alignItems:'center',padding:'1px', borderBottom:'1px solid black' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Paid Amount</Typography>
              <Typography  sx={{fontSize: '14px'}}>المبلغ المدفوع</Typography>
            </Box>
            }
            {(Data?.settings?.IsPaymentStatus || props?.data?.IsPaymentStatus) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between', alignItems:'center',padding:'1px', borderBottom:'1px solid black' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Balance</Typography>
              <Typography sx={{fontSize: '14px'}}>توازن</Typography>
            </Box>
            }
            {(Data?.settings?.IsTenderCash || props?.data?.IsTenderCash) &&
            <Box sx={{ display: 'flex' , justifyContent:'space-between', alignItems:'center',padding:'1px' }}>
              <Typography sx={{ paddingInlineEnd: '22px', fontSize: '14px', fontFamily: 'sans-serif' }}>Change</Typography>
              <Typography sx={{fontSize: '14px'}}>الرصيد</Typography>
            </Box>
            }
          </Box>
          <Box sx={{ width: '10%', borderBottom:'1px soloid black', fontSize:'14px'}}>
            {(Data?.settings?.IsTotalQuantity || props?.data?.IsTotalQuantity) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.total_qty ?? "0.00"}</Typography>
            }
            {(Data?.settings?.IsTotalGrossAmount || props?.data?.IsTotalGrossAmount) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.GrossAmt_print || "0.00"}</Typography>
            }
            {(Data?.settings?.IsTotalDiscount || props?.data?.IsTotalDiscount) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.TotalDiscount_print || "0.00"}</Typography>
            }
            {(Data?.settings?.IsTotalTax || props?.data?.IsTotalTax) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.TotalTax_print || "0.00"}</Typography>
            }
            {(Data?.settings?.IsTotalRoundoff || props?.data?.IsTotalRoundoff) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.RoundOff || "0.00"}</Typography>
            }
            {(Data?.settings?.IsGrandTotal || props?.data?.IsGrandTotal) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.GrandTotal_print || "0.00"}</Typography>
            }
            {(Data?.settings?.IsCustomerBalance || props?.data?.IsCustomerBalance) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.OldLedgerBalance ? Number(Data?.data?.OldLedgerBalance).toFixed(PriceDecimalPoint) : "0.00"}</Typography>
            }
            {(Data?.settings?.IsLedgerCurrentBalance || props?.data?.IsLedgerCurrentBalance) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.LedgerCurrentBalance ? Number(Data?.data?.LedgerCurrentBalance).toFixed(PriceDecimalPoint) : "0.00"}</Typography>
            }
            {(Data?.settings?.IsTenderCash || props?.data?.IsTenderCash) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px' , fontSize:'14px'}}>{Number(Data?.data?.TenderCash || 0).toFixed(PriceDecimalPoint)}</Typography>
            }
            {(Data?.settings?.IsPaymentStatus || props?.data?.IsPaymentStatus) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.paid_amount || "0.00"}</Typography>
            }
            {(Data?.settings?.IsPaymentStatus || props?.data?.IsPaymentStatus) &&
            <Typography sx={{textAlign:'end',borderBottom:'1px solid black',padding:'1px',fontSize:'14px'}}>{Data?.data?.InvoiceBalance || "0.00"}</Typography>
            }
            {(Data?.settings?.IsTenderCash || props?.data?.IsTenderCash) &&
            <Typography sx={{textAlign:'end',fontSize:'14px'}}>{Data?.data?.Balance || "0.00"}</Typography>
            }
          </Box>
        </Box>
        {(Data?.settings?.IsQrCode || props?.data?.IsQrCode || EnableEinvoiceKSA || Data?.settings?.IsUploadSeal || props?.data?.IsUploadSeal || Data?.settings?.IsReceiverSignature || props?.data?.IsReceiverSignature || Data?.settings?.IsAuthorizedSinature || props?.data?.IsAuthorizedSinature) &&
        <Box sx={{display:'flex', border:'1px solid black', borderTop: '0px solid black', padding: '4px',position:'relative',minHeight:'120px'}}>
          {(Data?.settings?.IsQrCode || props?.data?.IsQrCode || EnableEinvoiceKSA) &&
            <Box sx={{display:'flex',justifyContent:'start',alignItems:'start'}}>
              {/* <QRCodeCanvas 
                value={qrValue}
                size={ EnableEinvoiceKSA ? 170 : 120} 
                bgColor="#ffffff"
                fgColor="#000000" 
                level="L" 
              /> */}
            </Box>
          }
          {(Data?.settings?.IsReceiverSignature || props?.data?.IsReceiverSignature) &&
          <Box sx={{position:'absolute',left:'310px',top:'50px'}}>  
            <Typography >{Data?.settings?.RecieverSignature || props?.data?.RecieverSignature}</Typography>
          </Box>
          }
          {(Data?.settings?.IsAuthorizedSinature || props?.data?.IsAuthorizedSinature) &&
          <Box sx={{position:'absolute',right:'48px',top:'50px'}}>  
            <Typography >{Data?.settings?.AuthorizedSignature || props?.data?.AuthorizedSignature}</Typography>
          </Box>
          }
          {(Data?.settings?.IsUploadSeal || props?.data?.IsUploadSeal) &&
          <Box sx={{display:'flex',justifyContent:'end',alignItems:'end',marginLeft: 'auto'}}>
            {(Data?.settings?.SealFile || props?.data?.SealFile) && 
              <img style={{height:'120px',objectFit:'center', width:'100%',justifyContent:'end'}} src={`${URL}${props?.data?.UploadSealFile || Data?.settings?.SealFile }`} alt="Digital Seal" />
            }
          </Box>
          }
        </Box>
        }
        {(Data?.settings?.TermsAndConditions ||props?.data?.TermsAndConditions) &&
        <Box sx={{border:'1px solid black', borderTop: '0px solid black', padding: '4px'}}>
          <Box sx={{display:'flex',}}>
            <Typography sx={{ fontFamily:'sans-serif',fontSize:'14px', paddingInlineEnd:'20px',fontWeight:600}}>Terms And Conditions</Typography>
            <Typography sx={{ fontFamily:'sans-serif',fontSize:'14px',fontWeight:600}}>الشروط والأحكام</Typography>
          </Box>
          <Box sx={{marginTop:'2px'}}>
            <Typography sx={{ fontFamily:'sans-serif',fontSize:'14px', textAlign:'start'}}>{Data?.settings?.TermsAndConditions || props?.data?.TermsAndConditions }</Typography>
          </Box>

        </Box>
        }


        {/* Template Footer Image */}

        {(Data?.settings?.IsUploadCompanyFooterImage || props?.data?.IsUploadCompanyFooterImage) &&
        <Box sx={{display:'flex',justifyContent:'center',padding:'2px',marginTop:'4px'}}>
            <img style={{height:'100px',objectFit:'fill', width:'100%',backgroundPosition:'center'}} src={footerImg || `${URL}${Data?.settings?.companyfooterfile}`} alt="Company Header" />
        </Box>
        }
      </Box>  
    </Paper>
    </ThemeProvider>
  )
})

export default EinvoiceTemplate

