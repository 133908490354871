import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, Hidden, IconButton, Paper } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ViknCheckboxGroup from "../../../Components/Utils/ViknCheckboxGroup";
import VBTextField from "../../../Components/Utils/VBTextField";
import VBInputField from "../../../Components/Utils/VBInputField";
import { useState } from "react";
import { get_e_invoice_re_submit_api, get_e_invoice_submit_api } from "../../../Api/EInvoice/EInvoiceApis";
import { useDispatch, useSelector } from "react-redux";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  overflow:"scroll",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: 3,
  // p: 4,
};

export default function MultSelect({
  open,
  setOpen,
  state,
  showLoader,
  setLoader,
  setCelery_Loader,
  setProgress_id,
  multapiResult
}) {
  const [isActive, setisActive] = useState({
    isInvoice: false,
    isSandBox: false,
    isReSubmit:false
  })
  console.log(isActive);
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);

  
  const [inputs, setInputs] = useState('')


  // const submitInvoice = async() => {
  //   let payload = {
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     submit_type:"sandbox",
  //     type_of_egs:state?.type_of_egs.value
  //   }
  //   await get_e_invoice_submit_api({...payload});


  // }



   // Function to handle invoice submission
   const submitInvoice = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs: state?.type_of_egs.value,
      is_multiple:true
    };

    setLoader(true); // Show loader when submitting    
    try {
      const response = await get_e_invoice_submit_api(payload);
  
      if (response?.data.task_id) {
        setProgress_id(response.data.task_id);
        setCelery_Loader(true); // Start the celery loader

      } else {
        console.error('Submission error:', response);
      }
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setLoader(false);
    }
  };
  
  const failedSubmitInvoice = async () => {
    let payload = {
      CompanyID: CompanyID,
      BranchID: BranchID,
      type_of_egs: state?.type_of_egs.value,
    };

    setLoader(true); // Show loader when submitting    
    try {
      const response = await get_e_invoice_re_submit_api({...payload});
  
      if (response?.data.task_id) {
        setProgress_id(response.data.task_id);
        setCelery_Loader(true); // Start the celery loader

      } else {
        console.error('Submission error:', response);
      }
    } catch (error) {
      console.error('Submission error:', error);
    } finally {
      setLoader(false);
    }
  };
  


  // const failedSubmitInvoice = async () =>{
  //   let payload = {
  //     CompanyID: CompanyID,
  //     BranchID: BranchID,
  //     submit_type:"sandbox",
  //     type_of_egs:state?.type_of_egs.value
  //   }

  //  await get_e_invoice_re_submit_api({...payload});
  // }
  

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            p: 2,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            sx={{ fontSize: "18px", fontWeight: "500", color: "#001746" }}
          >Multiple Invoice Submit
          </Typography>

          <IconButton onClick={() => handleClose()}>
            <CloseIcon sx={{ color: "#0A9EF3" }} />
          </IconButton>
        </Box>
        <Divider />


        <Box sx={{ p: 2,maxHeight:"85vh",overflow:"scroll" }}>
        <ViknCheckboxGroup
            isBorder={false}
            isNoWidth={true}
            radios={[
              {
                label: "Submit All Non Submitted Invoice",
                checked: isActive.isNonSubmit, // should correspond to the state
                onChange: () => {
                  setisActive({
                    isNonSubmit: !isActive.isNonSubmit, // updated key to match
                    isFailed: false, // unchecking the other option
                    isReSubmit: false
                  });
                },
              },
              {
                label: "Submit All Failed",
                checked: isActive.isFailed, // should correspond to the state
                onChange: () => {
                  setisActive({
                    isNonSubmit: false, // unchecking the other option
                    isFailed: !isActive.isFailed, // updated key to match
                    isReSubmit: false
                  });
                },
              },
            ]}
          />

  
          
            {/* <ViknCheckboxGroup
              isBorder={false}
              isNoWidth={true}
              // sx={flexd}
              radios={[
                {
                  label: "ReSubmit Your Invoice",
                  checked: isActive.isReSubmit,
                  onChange: () => {
                    setisActive({
                      isReSubmit: !isActive.isReSubmit,
                      isSandBox: false,
                      isInvoice: false,
                    })
                  },
                }
             
              ]}
            /> */}
       
          
       <Button
          variant="contained"
          sx={{
            my: 2,
            textTransform: "none",
            backgroundColor: "#0A9EF3",
            py: "3px",
            ":hover": { backgroundColor: "#0A9EF3" },
          }}
          onClick={() => {
            if (isActive.isNonSubmit) {
              submitInvoice();
            } else if (isActive.isFailed) {
              failedSubmitInvoice(); 
            }
          }}
        >
          {showLoader ? "Loading..." : "Submit"}
        </Button>

        </Box>
{/* 
        <Paper
            sx={{
              backgroundColor: "#F8F8F8",
              border: "1px solid #D9D9D9",
              borderRadius: 2,
              p: 3,
              pb: 6,
              marginBottom:6
            }}
            elevation={0}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: 2 }}>
              Result
            </Typography>

            {multapiResult && multapiResult.length > 0 ? (
                multapiResult.map((result, index) => (
                  <div style={{ maxHeight: "calc(85vh - 220px)", overflow: "scroll", marginBottom: "20px" }}>
                  <pre style={{ margin: 0, whiteSpace: 'pre-wrap', wordBreak: 'break-word', color: '#333' }}>
                    {result.Response?JSON.stringify(result.Response, null, 2):"No Data"}
                  </pre>
                </div>
                )
              )
            ):(
              <Typography>No Data</Typography>
            )
          }
          </Paper> */}


        
<Paper
  sx={{
    backgroundColor: "#F8F8F8",
    border: "1px solid #D9D9D9",
    borderRadius: 2,
    overflow:"scroll",
    p: 3,
    pb: 6,
    marginBottom: 6,
    maxHeight: "calc(85vh - 220px)"
  }}
  elevation={0}
>
  {/* <Typography sx={{ fontSize: "14px", fontWeight: "600", mb: 2 }}>
    Result
  </Typography> */}

  {multapiResult && multapiResult.length > 0 ? (
    multapiResult.map((result, index) => {
      let parsedResponse;
      try {
        // Parse the response string to JSON
        parsedResponse = JSON.parse(result.Response.replace(/'/g, '"'));  // Replace single quotes with double quotes for valid JSON
      } catch (error) {
        parsedResponse = result.Response; // If parsing fails, fallback to original response
      }

      return (
        <div
          key={result.id}
          style={{
            maxHeight: "calc(85vh - 220px)",
            overflow: "scroll",
            marginBottom: "20px"
          }}
        >
          <Typography sx={{ fontWeight: 600 }}>{`Result of ${result.VoucherNo}`}</Typography>

          <pre
            style={{
              margin: 0,
              whiteSpace: "pre-wrap",
              wordBreak: "break-word",
              color: "#333"
            }}
          >
            {parsedResponse
              ? JSON.stringify(parsedResponse, null, 2)  // Pretty print JSON
              : "No Data"}
          </pre>
        </div>
      );
    })
  ) : (
    <Typography>No Data</Typography>
  )}
</Paper>
 

        
      </Box>
    </Modal>
  );
}
