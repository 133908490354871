import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Modal,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import CloseIcon from "@mui/icons-material/Close";
import VBInputField from "../../../../Components/Utils/VBInputField";
import VBSwitch from "../../../../Components/Utils/VBSwitch";
import { list_countries, list_states } from "../../../../Api/CommonApi/CommonApis";
import ViknAutoComplete from "../../../../Components/Utils/ViknAutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { openSnackbar } from "../../../../features/SnackBarslice";
import { number } from "prop-types";

const Address = ({ uploadDetail, setUploadDetail ,open,handleOpen,handleClose , setEditAddress ,setOpen,editAddress}) => {
  const [t] = useTranslation("common");


  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          pb: 2,
        }}
      >
        <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
          {t("Address")}
        </Typography>

        {uploadDetail?.AddresList.length !== 0 && (
          <BlueButton onClick={handleOpen} startIcon={<AddIcon />}>
            {t("Add Address")}
          </BlueButton>
        )}
      </Box>
      <Divider />

      {uploadDetail.AddresList.length === 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 1,
            mt: 10,
          }}
        >
          <img src="../images/Object.png" alt="dumm" />
          <Typography sx={{ fontSize: "22px", fontWeight: "600", mt: 3 }}>
            {t("No Address")}
          </Typography>
          <Typography
            sx={{ fontSize: "18px", fontWeight: "400", color: "#8A8A8A" }}
          >
            {t("No addresses found. Click below to add a new address")}.
          </Typography>
          <BlueButton onClick={handleOpen} startIcon={<AddIcon />}>
            {t("Add Address")}
          </BlueButton>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1, mt: 5 }}>
          <Grid container spacing={2}>
            {uploadDetail.AddresList.map((en, i) => (
              <Grid item xs={4}>
                <Item>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      p: 1,
                    }}
                  >
                    <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                      {t("Address")} {i + 1}
                    </Typography>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <FormControlLabel
                        sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                        label="Shipping"
                        control={
                          <Checkbox
                            checked={en.IsShipping}
                            onChange={(e) => {
                              const updatedAddressList =
                                uploadDetail.AddresList.map((item, index) => ({
                                  ...item,
                                  Type:
                                    index === i
                                      ? e.target.checked
                                        ? item.IsBilling
                                          ? "Common"
                                          : "Shipping Address"
                                        : ""
                                      : item.Type,
                                  IsShipping:
                                    index === i
                                      ? !uploadDetail.AddresList[i].IsShipping
                                      : false,
                                }));

                              setUploadDetail({
                                ...uploadDetail,
                                AddresList: updatedAddressList,
                              });
                            }}
                            size="small"
                            //  disabled={radio.disabled}
                          />
                        }
                      />
                      <FormControlLabel
                        sx={{ "& .MuiTypography-root": { fontSize: "12px" } }}
                        label="Billing"
                        control={
                          <Checkbox
                            checked={en.IsBilling}
                            onChange={(e) => {
                              const updatedAddressList =
                                uploadDetail.AddresList.map((item, index) => ({
                                  ...item,
                                  Type:
                                    index === i
                                      ? e.target.checked
                                        ? item.IsShipping
                                          ? "Common"
                                          : "Billing Address"
                                        : ""
                                      : item.Type,
                                  IsBilling:
                                    index === i
                                      ? !uploadDetail.AddresList[i].IsBilling
                                      : false,
                                }));

                              setUploadDetail({
                                ...uploadDetail,
                                AddresList: updatedAddressList,
                              });
                            }}
                            size="small"
                            //  disabled={radio.disabled}
                          />
                        }
                      />
                    </Box>
                  </Box>
                  <Divider />
                  <Box sx={{ p: 2 }}>
                    <Typography sx={{ fontWeight: "500", fontSize: "16px" }}>
                      {en.Attention}, {en.StreetName}, <br />
                      {en.City}, {en.BuildingNo} <br />
                      {en.StateName}, {en.CountryName}, <br />
                      {en.District}, {en.PostalCode}
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", justifyContent: "end", p: 1 }}>
                    <Button
                      variant="text"
                      startIcon={<DeleteOutlineIcon />}
                      sx={{
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        const updatedAddressList =
                          uploadDetail.AddresList.filter(
                            (item, index) => index !== i && item
                          );
                        setUploadDetail({
                          ...uploadDetail,
                          AddresList: updatedAddressList,
                        });
                      }}
                    >
                      {t("Remove")}
                    </Button>
                    <Button
                      variant="text"
                      startIcon={<BorderColorIcon />}
                      sx={{
                        color: "#0A9EF3",
                        fontSize: "16px",
                        fontWeight: "500",
                        textTransform: "none",
                      }}
                      onClick={() => {
                        setEditAddress({
                          position: i,
                          address: en,
                        });
                        setOpen(true);
                      }}
                    >
                      {t("Edit")}
                    </Button>
                  </Box>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <AddressModal
        open={open}
        handleClose={handleClose}
        uploadDetail={uploadDetail}
        setUploadDetail={setUploadDetail}
        editAddress={editAddress}
      />
    </>
  );
};

export default Address;

const BlueButton = styled(Button)(() => ({
  color: "white",
  backgroundColor: "#0A9EF3",
  textTransform: "none",
  fontSize: "15px",
  ":hover": {
    backgroundColor: "#0A9EF3",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#F9F9F9",
  ...theme.typography.body2,
  boxShadow: "none",
  border: "1px solid #EEEEEE",
  borderRadius: "#EEEEEE",
}));






/*************************************************************************************************/
//====================================== || Add Address Modal || ======================================//
/*************************************************************************************************/


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 650,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

const AddressModal = function ({
  open,
  handleClose,
  uploadDetail,
  setUploadDetail,
  editAddress,
}) {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const { EnableEinvoiceKSA } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  // const [checked, setChecked] = React.useState(false);
  const { Country,State } = useSelector((state) => state.branchSettings);
  let address = {
    Attention: "",
    BuildingNo: "",
    StreetName: "",
    City: "",
    District: "",
    Country: "",
    CountryName: "",
    State: State,
    StateName: "",
    PostalCode: "",
    IsSecLang: false,
    IsBilling: false,
    IsShipping: false,
    Type: "",
    AttentionSec: "",
    BuildingNoSec: "",
    StreetNameSec: "",
    CitySec: "",
    DistrictSec: "",
    CountrySec: "",
    CountryNameSec: "",
    StateSec: "",
    StateNameSec: "",
    PostalCodeSec: "",
  };

  const [dataList, setDataList] = useState({
    countriesList: [],
    stateList: [],
    countriesListSec: [],
    stateListSec: [],
    country: null,
    State: State,
    stateObj: null,
    countrySec: null,
    stateSecObj: null,
  });

  const [addressData, setaddressData] = useState(address);

  const handleChange = () => {
    setaddressData({ ...addressData, IsSecLang: !addressData.IsSecLang });
  };

  const handleCloseModal = function () {
    handleClose();
    setaddressData(address);
    
  };

  const inputStyle = {
    border: '1px solid red' ,
    borderRadius : '4px'
  };

  const handleValidation = () => {
    let error = '';
    let IsValid = true;
    const BuildingNo = /^\d{4}$/; // Regular expression for exactly 4 digits
    const PostalCode = /^\d{5}$/; // Regular expression for exactly 5 digits

    
    if(EnableEinvoiceKSA) {
      if (uploadDetail.VAT_Treatment === "0") {
        // if (!addressData.PostalCodeSec || !PostalCode.test(addressData.PostalCodeSec)){
        //   IsValid = false;
        //   error = 'PostalCode Must have 5 digit'
        // }
        // if (!addressData.DistrictSec ){
        //   IsValid = false;
        //   error = 'District Cannot be empty'
        // }
        // if (!addressData.CitySec ){
        //   IsValid = false;
        //   error = 'City Cannot be empty'
        // }
        // if (!addressData.StreetNameSec ){
        //   IsValid = false;
        //   error = 'StreetName Cannot be empty'
        // }
        // if (!addressData.BuildingNoSec || !BuildingNo.test(addressData.BuildingNoSec)){
        //   IsValid = false;
        //   error = 'Building No must have 4 digits'
        // }
        // if (!addressData.AttentionSec){
        //   IsValid = false;
        //   error = 'Attention Cannot be empty'
        // }
        // if (!addressData.IsSecLang ){
        //   IsValid = false;
        //   error = 'Turn On Second Language'
        // }
        if (!addressData.PostalCode || !PostalCode.test(addressData.PostalCode)){
          IsValid = false;
          error = 'PostalCode Must have 5 digit'
        }
        // if (!addressData.District ){
        //   IsValid = false;
        //   error = 'District Cannot be empty'
        // }
        // if (!addressData.City ){
        //   IsValid = false;
        //   error = 'City Cannot be empty'
        // }
        // if (!addressData.StreetName ){
        //   IsValid = false;
        //   error = 'StreetName Cannot be empty'
        // }
        if (!addressData.BuildingNo || !BuildingNo.test(addressData.BuildingNo)){
          IsValid = false;
          error = 'Building No must have 4 digits'
        }
        // if (!addressData.Attention){
        //   IsValid = false;
        //   error = 'Attention Cannot be empty'
        // }
      }
      
    }
    return { error , IsValid}
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const { IsValid , error } = handleValidation()

    if (IsValid ){
      if (editAddress.position || editAddress.position === 0) {
        const updatedAddress = uploadDetail.AddresList.map((ele, ind) =>
          editAddress.position === ind ? addressData : ele
        );
        setUploadDetail({
          ...uploadDetail,
          AddresList: updatedAddress,
        });
      } else {
        setUploadDetail({
          ...uploadDetail,
          AddresList: [...uploadDetail.AddresList, addressData],
        });
      }
      setaddressData(address);
      handleClose();
    } else  {
      dispatch(
        openSnackbar({
          open: true,
          message: error,
          severity: "warning",
        })
      );
    }
  };
  
  const callLocation = async function (id) {
    let country_id =id
    // let state_id = editAddress.state?editAddress.state:State
    const countries = await list_countries();
    const states = await list_states(country_id);

      const selectedCountry = countries.data.find((e) => e.id === country_id);
      // const selectedState = states.data.find((e) => e.id === state_id);
      setDataList({
        ...dataList,
        countriesList: countries.data,
        stateList: states.data || dataList.stateListSec,
        country: selectedCountry,
        stateObj: selectedCountry ? null : dataList.stateSecObj,
      });

      setaddressData({
        ...addressData,
        State: null,
        Country: selectedCountry.id,
        CountryName: selectedCountry.Country_Name,
      });
    
  };

  // useEffect(() => {
  //   if (editAddress.position === "") {
  //     open && callLocation(Country);
  //   }
  //   if (addressData.IsSecLang) {
  //     callLocation(Country);
  //   }
  // }, [open === true,addressData.IsSecLang]);

  // useEffect(() => {
  //   if (addressData.IsSecLang) {
  //     callLocation(Country);
  //   }
  // }, [addressData.IsSecLang]);


  useEffect(() => {
    const adder = async () => {
      const country_id = editAddress.address.Country || Country;
      const state_id = editAddress.address.State || State;
      
      try {
        const countries = await list_countries();
        const states = await list_states(country_id);
        
        const selectedCountry = countries.data.find(e => e.id === country_id);
        const selectedState = states.data.find(e => e.id === state_id);
  
        // Update addressData based on whether position is set or not
        let addressData_obj = (typeof editAddress.address === 'object' && editAddress.address !== null)
        ? editAddress.address
        : address;
  
        setaddressData({
          ...addressData_obj,
          StateName: selectedState?.Name,
          CountryName: selectedCountry?.Country_Name,
          Country: country_id,
          State: state_id,
        });
  
        setDataList({
          ...editAddress.address,
          country: selectedCountry,
          stateObj: selectedState,
          countriesList: countries.data,
          stateList: states.data,
        });
      } catch (error) {
        console.error("Error fetching countries or states", error);
      }
    };
  
    if (open) {
      adder();
    }
  }, [open]);
  
  return (
    <Modal
      open={open}
      //   onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <form onSubmit={onSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 1,
          }}
        >
          <Typography sx={{ fontSize: "22px", fontWeight: "600" }}>
            {t("Enter Address")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            <BlueButton sx={{ px: 3 }} type="submit">
              {t("Save")}
            </BlueButton>
            <IconButton sx={{ color: "#0A9EF3" }} onClick={handleCloseModal}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Divider sx={{ mb: 2 }} />

        <Box
          sx={{
            maxHeight: 500,
            overflowY: "scroll",
            "-ms-overflow-style": "none",
            "scrollbar-width": "none",
            "::-webkit-scrollbar": { display: "none" },
          }}
        >
          <Box sx={{ flexGrow: 1, mb: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <VBInputField
                  label="Attention"
                  type="text"
                  placeholder="Type here"
                  value={addressData.Attention}
                  onChange={(e) =>
                    setaddressData({
                      ...addressData,
                      Attention: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <VBInputField
                  label="Building No"
                  type="text"
                  placeholder="Type here"
                  value={addressData.BuildingNo}
                  onChange={(e) =>
                    setaddressData({
                      ...addressData,
                      BuildingNo: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <VBInputField
                  label="Street Name"
                  type="text"
                  placeholder="Type here"
                  value={addressData.StreetName}
                  onChange={(e) =>
                    setaddressData({
                      ...addressData,
                      StreetName: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <VBInputField
                  label="City"
                  type="text"
                  placeholder="Type here"
                  value={addressData.City}
                  onChange={(e) =>
                    setaddressData({
                      ...addressData,
                      City: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <VBInputField
                  label="District"
                  type="text"
                  placeholder="Type here"
                  value={addressData.District}
                  onChange={(e) =>
                    setaddressData({
                      ...addressData,
                      District: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={6}>
                <ViknAutoComplete
                  id="controllable-states-country"
                  type="text"
                  label="Country"
                  placeholder="Type here"
                  value={dataList.country}
                  onChange={(event, newValue) => {
                    callLocation(newValue.id);
                    // setaddressData({ ...addressData, country: newValue, stateObj: null });
                  }}
                  options={dataList.countriesList}
                  getOptionLabel={(e) => e.Country_Name||""}
                />
              </Grid>
              <Grid item xs={6}>
                <ViknAutoComplete
                  id="controllable-states-state"
                  type="text"
                  label={"State"}
                  placeholder="Type here"
                  value={dataList.stateObj}
                  onChange={(event, newValue) => {
                    setDataList({ ...dataList, stateObj: newValue });
                    setaddressData({
                      ...addressData,
                      State: newValue.id,
                      StateName: newValue.Name,
                    });
                  }}
                  options={dataList.stateList}
                  getOptionLabel={(e) => e.Name||""}
                />
              </Grid>
              <Grid item xs={6}>
                <VBInputField
                  label="Postal Code"
                  type="text"
                  placeholder="Type here"
                  value={addressData.PostalCode}
                  onChange={(e) =>
                    setaddressData({
                      ...addressData,
                      PostalCode: e.target.value,
                    })
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Paper
            elevation={0}
            sx={{
              bgcolor: "#F7F7F7",
              pl: "16px",
              mb: 1,
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
              {t("Second Language")}
            </Typography>
            <VBSwitch checked={addressData.IsSecLang} onChange={handleChange} />
          </Paper>

          <Collapse in={addressData.IsSecLang}>
            <Box sx={{ flexGrow: 1, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <VBInputField
                    label="Attention"
                    type="text"
                    placeholder="Type here"
                    value={addressData.AttentionSec}
                    onChange={(e) =>
                      setaddressData({
                        ...addressData,
                        AttentionSec: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    label="Building No"
                    type="text"
                    placeholder="Type here"
                    value={addressData.BuildingNoSec}
                    onChange={(e) =>
                      setaddressData({
                        ...addressData,
                        BuildingNoSec: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    label="Street Name"
                    type="text"
                    placeholder="Type here"
                    value={addressData.StreetNameSec}
                    onChange={(e) =>
                      setaddressData({
                        ...addressData,
                        StreetNameSec: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    label="City"
                    type="text"
                    placeholder="Type here"
                    value={addressData.CitySec}
                    onChange={(e) =>
                      setaddressData({
                        ...addressData,
                        CitySec: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    label="District"
                    type="text"
                    placeholder="Type here"
                    value={addressData.DistrictSec}
                    onChange={(e) =>
                      setaddressData({
                        ...addressData,
                        DistrictSec: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  <ViknAutoComplete
                    id="controllable-states-country"
                    label="Country"
                    placeholder="Type here"
                    value={dataList.country}
                    onChange={(event, newValue) => {
                      callLocation(newValue.id);
                      // setaddressData({ ...addressData, country: newValue, stateObj: null });
                    }}
                    options={dataList.countriesList}
                    getOptionLabel={(e) => e.Country_Name||""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ViknAutoComplete
                    id="controllable-states-state"
                    label={"State"}
                    placeholder="Type here"
                    value={dataList.stateObj}
                    onChange={(event, newValue) => {
                      setDataList({ ...dataList, stateObj: newValue });
                      setaddressData({
                        ...addressData,
                        State: newValue.id,
                        StateName: newValue.Name,
                      });
                    }}
                    options={dataList.stateList}
                    getOptionLabel={(e) => e.Name||""}
                  />
                </Grid>
                <Grid item xs={6}>
                  <VBInputField
                    label="Postal Code"
                    type="text"
                    placeholder="Type here"
                    value={addressData.PostalCodeSec}
                    onChange={(e) =>
                      setaddressData({
                        ...addressData,
                        PostalCodeSec: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </Box>
        </form>
      </Box>
    </Modal>
  );
};
