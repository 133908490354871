import React from 'react'
import { VBSkeletonLoader, VBTableCellBody, VBTableCellHeader, VBTableContainer, VBTableHeader } from '../../../../../Components/ReportTable/ReportTable'
import { TableBody, TableRow } from '@mui/material'
import { formatNumber } from '../../../../../generalFunction'
import { useTranslation } from 'react-i18next'

const DebitorTable = (props) => {
  const [t, i18n] = useTranslation("common");

  return (
    <VBTableContainer>
    <VBTableHeader>
      <VBTableCellHeader colSpan={3}>{t("Expense")} </VBTableCellHeader>
      <VBTableCellHeader colSpan={3}>{t("Income")} </VBTableCellHeader>
    </VBTableHeader>
    {props?.isLoading ? <VBSkeletonLoader /> :<TableBody>
      <TableRow>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Group Name</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold"}} > Ledger</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right", borderRight: "1px solid #e6e6e6" }}> Amount</VBTableCellBody>

        <VBTableCellBody sx={{ fontWeight: "bold"}}> Group Name</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold"}}> Ledger</VBTableCellBody>
        <VBTableCellBody sx={{ fontWeight: "bold", textAlign: "right"}}> Amount</VBTableCellBody>
      </TableRow>

      {props?.data?.ProfitAndLossAccount?.map((e, i) => (
        <TableRow>
          <VBTableCellBody sx={{ color: e.expence.group_name === "Total" ? 'red': "auto" }} > {e.expence.group_name}</VBTableCellBody>
          <VBTableCellBody > {e.expence.ledger_name}</VBTableCellBody>
          <VBTableCellBody sx={{ borderRight: "1px solid #e6e6e6", textAlign: "right" }}> {formatNumber(e.expence.balance)}</VBTableCellBody>

          <VBTableCellBody sx={{ color: e.expence.group_name === "Total" ? 'red': "auto" }}> {e.income.group_name}</VBTableCellBody>
          <VBTableCellBody > {e.income.ledger_name}</VBTableCellBody>
          <VBTableCellBody sx={{ textAlign: "right"}}> {formatNumber(e.income.balance)}</VBTableCellBody>
        </TableRow>
      ))}
      
      <TableRow></TableRow>
    </TableBody>}
  </VBTableContainer>
  )
}

export default DebitorTable