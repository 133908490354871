import { red } from "@mui/material/colors";
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  typography: {
    allVariants: {
      fontFamily: "Poppins",
      color: "black",
    },
  },
  palette: {
    colorValue: "blue",
    mode: "light",
    background: {
      default: "#ebebeb",
    },
    primary: {
      main: "#0A9EF3",
    },
    error: {
      main: "#FF0000",
    },
  },
};

const themeSlice = createSlice({
  name: "themeSclice",
  initialState,
  reducers: {
    typographyChange: (state, action) => {
      state.typography.allVariants.fontFamily = action.payload.fontFamily;
    },
    paletteChangePrimary: (state, action) => {
      state.palette.colorValue = action.payload.color;
      
      switch (action.payload.color) {
        case "blue":
          state.palette.primary.main = "#0A9EF3";
          break;
        case "gree":
          state.palette.primary.main = "#2e7d32";
          break;
        case "red":
          state.palette.primary.main = "#FF0000";
          break;
        case "yellow":
          state.palette.primary.main = "#ed6c02";
          break;

        default:
          state.palette.primary.main = "#0A9EF3";
          break;
      }
    },
  },
});

export const { typographyChange, paletteChangePrimary } = themeSlice.actions;
export default themeSlice.reducer;
