import {
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Chip,
  Collapse,
  Fade,
  IconButton,
  InputBase,
  Menu,
  MenuItem,
  Modal,
  Paper,
  Popover,
  Select,
  Skeleton,
  Slide,
  Stack,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  ArrowRightIcon,
  Convert3D,
  DollerCircle,
  EditLine,
  LinkLine,
  PrinterLine,
  SearchNormal,
  SelectIcon,
  ShareLine,
} from "../../Components/Icones/IconComponents";
import Pagenation from "../../Components/Utils/Pagenation";
import {
  delete_sales_api,
  invoice_send_mail,
  ledger_list_for_payment,
  list_sales_api,
  loyalty_customer,
  main_voucher,
  sales_invoice_search,
  sales_invoice_single_log,
  sales_invoice_single_payment,
  sales_invoice_single_print,
  sales_master,
  view_social_media,
} from "../../Api/Sales/SalesApis";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../generalFunction";
import PropTypes from "prop-types";
import html2canvas from "html2canvas";
import CloseIcon from "@mui/icons-material/Close";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { MyUrl, URL } from "../../generalVeriable";
import InfoIcon from "@mui/icons-material/Info";
import VBInputField from "../../Components/Utils/VBInputField";
import { openSnackbar } from "../../features/SnackBarslice";
import InvoicePDFLoading from "../../Components/Loadings/InvoicePDFLoading";
import { ArrowRight } from "@mui/icons-material";
import jsPDF from "jspdf";
import { MasterCalculations, viknAlert } from "../../Function/comonFunction";
import {
  CallEmployees,
  CallLedgerListById,
  CallTaxListByType,
  CallWarehouses,
} from "../../Api/Reports/CommonReportApi";
import { list_countries, price_category } from "../../Api/CommonApi/CommonApis";
import EinvoiceTemplate from "../../PrintTemplate/EinvoiceTemplate";

function SalesInvoiceSingle() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const inputRef = useRef(null);

  const Einvoiceref = useRef(null);

  const {
    PriceDecimalPoint,
    //  EnableBranch,
    //  EnableProductBatchWise,
    //  GST,
    //  VAT,
    //  CurrencySymbol,
    //  EnableBillwise,
    //  EnableWarehouse,
    EnableSalesManInSales,
    PriceCategory,
    RoundOffSales,
    //  EnableDeliveryDateInSales,
    //  EnableShippingCharge,

    //  PurchasePriceInSales,
    //  show_brand_InsearchSale,
    //  show_stock_InsearchSale,
    //  show_purchasePrice_InsearchSale,
    //  show_productcode_InsearchSale,
    //  EnableEinvoiceKSA
  } = useSelector((state) => state.generalSettingsSclice.generalSettings);
  const { access, user_id } = useSelector((state) => state.user);
  let user_role_settings = useSelector((state) => state.userRollSettings);
  let sales_permission = user_role_settings.filter(
    (i) => i.name === "Sales Invoice"
  );
  let sales_delete = sales_permission[0].delete_permission;
  const { CompanyID, BranchID } = useSelector((state) => state.companyDetails);
  const { CurrencySymbol, CompanyName, Country, CountryCode, State, VAT, GST } =
    useSelector((state) => state.companyDetails);
  // const [invoicePrev, setInvoicePrev] = useState(null);
  // const hiddenDivRef = useRef();

  const [pdfLoading, setPdfLoading] = useState(true);
  const [openSendMail, setOpenSendMail] = useState(false);
  const [openHistoryModal, setOpenHistoryModal] = useState(false);
  const [shareAnchorEl, setShareAnchorEl] = useState(null);
  const shareOpen = Boolean(shareAnchorEl);

  const [printAnchorEl, setPrintAnchorEl] = React.useState(null);
  const openPrint = Boolean(printAnchorEl);
  const id = openPrint ? "print-popover" : undefined;

  const [covertAnchorEl, setCovertAnchorEl] = useState(null);
  const covertOpen = Boolean(covertAnchorEl);

  const [IsEinvoiceTemplate, setEinvoiceTemplate] = useState(false);
  const [printInvoiceData, setPrintInvoiceData] = useState({});
  const [isSearch, setIsSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [printPage, setPrintPage] = useState(1);
  const [loadingState, setLoadingState] = useState({
    isListLoading: false,
    isInvoicePrintLoading: false,
  });
  const [page, setPage] = useState(1);
  const [dataDetails, setDataDetails] = useState({
    totalItem: 0,
    isLoadingMail: false,
    salesInvoiceList: [],
    historyInvoiceList: [],
    historyPaymentList: [],
    sendMailData: {
      to: "",
      cc: "",
      replayto: "",
      isSenderId: "",
      isExist: false,
      email: "",
    },
    invoiceLists: [
      {
        CustomerName: "",
        Date: "",
        GrandTotal: "",
        IsNewSale: false,
        LedgerName: "",
        TotalGrossAmt: "",
        TotalTax: "",
        VoucherNo: "",
        billStatus: "",
        is_billwised: false,
        id: "",
      },
    ],
  });

  // Top Functions | 🔧
  const handleClickSearch = function () {
    setIsSearch((prev) => !prev);
    if (!isSearch) {
      setTimeout(() => {
        inputRef.current?.focus(); // Focus the input field when opening
      }, 300); // Delay to allow Collapse animation. Always put transaction duration to the time
    }
  };

  // Delete Invoices 🗑️
  const deleteInvoices = function () {
    let idList = dataDetails.invoiceLists.map((item) => item.id);

    viknAlert({
      open: true,
      title: "Are you want to delete this",
      description: "This is the Description",
      cnfFunction: async () => {
        if (idList.length === 0) {
          dispatch(
            openSnackbar({
              open: true,
              message: "No Invoices selected",
              severity: "warning",
            })
          );
          return;
        }
        if (sales_delete) {
          let payload = {
            CompanyID: CompanyID,
            BranchID: BranchID,
            CreatedUserID: user_id,
            id_list: idList,
          };

          let responseData = await delete_sales_api({ ...payload });

          if (responseData.StatusCode === 6000) {
            dispatch(
              openSnackbar({
                open: true,
                message: responseData?.message ?? "Deleted Successfully",
                severity: "success",
              })
            );

            callInvoiceList();
          } else {
            dispatch(
              openSnackbar({
                open: true,
                message: responseData?.message ?? "Something went wrong!",
                severity: "error",
              })
            );
          }
        } else {
          dispatch(
            openSnackbar({
              open: true,
              message: "You don't have permission for delete",
              severity: "warning",
            })
          );
        }
      },
    });
  };

  // Search Invoice 🔍
  const searchInvoice = function () {
    setLoadingState({ ...loadingState, isListLoading: true });
    sales_invoice_search({
      CompanyID,
      BranchID,
      CreatedUserID: user_id,
      PriceRounding: PriceDecimalPoint,
      QtyRounding: 2,
      page_no: page,
      items_per_page: 15,
      Search: searchValue,
    }).then((res) => {
      if (res.StatusCode === 6000) {
        setDataDetails((prev) => ({
          ...prev,
          salesInvoiceList: res.data,
          // invoiceLists[0]: res.data[0],
          invoiceLists: page === 1 ? [res.data[0]] : prev.invoiceLists,
          totalItem: res.count,
        }));
      }
    });
    setLoadingState({ ...loadingState, isListLoading: false });
  };

  // Payments
  const payRecipt = function () {
    const url = `${MyUrl}/dashboard/create-receipt?boo=true &LedgerID=${dataDetails.invoiceLists[0].id}&VoucherNo=${dataDetails.invoiceLists[0].VoucherNo}`;
    window.open(url, "_blank");
  };

  // Single Invoice Functions | 👇 👇 👇 |
  const editInvoice = function () {
    navigate("/create-sales", {
      state: { is_edit: true, uniq_id: dataDetails.invoiceLists[0].id },
    });
  };

  // Share Invoices
  const submitMail = async function () {
    setDataDetails({ ...dataDetails, isLoadingMail: false });
    await invoice_send_mail({
      CompanyID: CompanyID,
      BranchID: BranchID,
      type: "SI",
      to_mails: dataDetails.sendMailData.to,
      cc_list: dataDetails.sendMailData.cc,
      replay_mail: dataDetails.sendMailData.replayto,
      id: dataDetails.invoiceLists[0].id,
      date: moment().format("YYYY-MM-DD"),
      link: `${MyUrl}/salesInvoiceReport/?CompanyID=${CompanyID}&no_of_copies=1&invoice_type=sales_invoice&invoice_id=${dataDetails.invoiceLists[0].id}&PriceRounding=${PriceDecimalPoint}&BranchID=${BranchID}&print_template="True"`,
    }).then((res) => {
      if (res.StatusCode === 6000) {
        dispatch(
          openSnackbar({
            open: true,
            message: "Mail sended successfully",
            severity: "success",
          })
        );
      }
    });

    setDataDetails({ ...dataDetails, isLoadingMail: false });
  };

  const shareWhatsApp = async function () {
    try {
      const getMedia = await view_social_media({
        CompanyID,
        CreatedUserID: user_id,
        BranchID,
      });
      // const sr_id = getServerID();
      const sr_id = 0;

      const link = `${window.location.origin}/salesInvoiceReport/?sr_id=${sr_id}&invoice_id=${dataDetails.invoiceLists[0].id}`;
      const phoneNumber = "";

      const messageParts = [
        `Dear *${encodeURIComponent(
          dataDetails.invoiceLists[0]?.LedgerName
        )}*,`,
        "",
        `Thank you for purchasing from *${encodeURIComponent(CompanyName)}*`,
        "Below are the details of your purchase:",
        "",
        `*Date*:  \`${moment().format("YYYY-MM-DD")}\``,
        `*VoucherNo*:  \`${encodeURIComponent(
          dataDetails.invoiceLists[0].VoucherNo
        )}\``,
        `*Total*:  \`${dataDetails.invoiceLists[0]?.GrandTotal_Rounded}\``,
        "",
        `*Click here for the invoice:*`,
        encodeURIComponent(link),
        "",
        getMedia.data || "",
      ];

      const message = messageParts.join("%0a");
      const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

      window.open(whatsappUrl);
    } catch (error) {
      console.error("Failed to send WhatsApp message:", error);
    }
  };

  const shareExportPDF = function () {
    // const handleGeneratePdf = async () => {
    //   const input = divRef.current;
    //   console.log("input====>",input);
    //   try {
    //     const highestTop = getHighestTop(data.FooterData);
    //     console.log("highestTop============>>>:>>",highestTop + tableHeight);
    //     const canvasHeight = highestTop + tableHeight + 20
    //     input.style.height = `${canvasHeight}px`;
    //     const canvas = await html2canvas(input);
    //     const imgData = canvas.toDataURL('image/png');
    //     const pdf = new jsPDF('p', 'mm', 'a4');
    //     const imgWidth = 210; // A4 width in mm
    //     const pageHeight = 295; // A4 height in mm
    //     const marginTop = 0;
    //     const marginLeftRight = 0;
    //     const imgHeight = (canvasHeight * imgWidth-marginLeftRight) / canvas.width;
    //     let heightLeft = imgHeight + marginTop * 2;
    //     let position = marginTop;
    //     pdf.addImage(imgData, 'PNG', marginLeftRight/2, position, imgWidth-marginLeftRight, imgHeight);
    //     heightLeft -= pageHeight+marginTop;
    //     while (heightLeft >= 0) {
    //       position = heightLeft - imgHeight;
    //       console.log("position--while==>",position);
    //       pdf.addPage();
    //       pdf.addImage(imgData, 'PNG', marginLeftRight/2, position, imgWidth-marginLeftRight, imgHeight);
    //       heightLeft -= pageHeight;
    //     }
    //     pdf.save(`SalesInvoice_${pdfId?.VoucherNo}.pdf`);
    //   } catch (error) {
    //     console.error('Error generating PDF:', error);
    //   }
    // };
    // const GeneratePDF = async () => {
    //   const input = Einvoiceref.current;
    //   // Convert the DOM element to canvas
    //   const canvas = await html2canvas(input, { scale: 2, useCORS: true });
    //   // Convert canvas to Base64 data URL
    //   const imgData = canvas.toDataURL('image/png');
    //   // Create a new jsPDF instance
    //   const pdf = new jsPDF('p', 'mm', 'a4');
    //   const imgWidth = 210; // A4 width in mm
    //   const pageHeight = 295; // A4 height in mm
    //   const imgHeight = (canvas.height * imgWidth) / canvas.width;
    //   let heightLeft = imgHeight;
    //   let position = 0;
    //   // Add the image to the PDF
    //   pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //   heightLeft -= pageHeight;
    //   // Add more pages if the content is longer than one page
    //   while (heightLeft >= 0) {
    //     position = heightLeft - imgHeight;
    //     pdf.addPage();
    //     pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
    //     heightLeft -= pageHeight;
    //   }
    //   // Encode the XML content to Base64
    //   // const xmlBase64 = btoa(unescape(encodeURIComponent(InvoiceXML)));
    //   // Attach the XML file to the PDF
    //   pdf.addFileToVFS('InvoiceData.xml', InvoiceXML); // Register the XML file
    //   // pdf.addFile('InvoiceData.xml', 'application/xml'); // Attach the file
    //   // Save the PDF
    //   pdf.save(`SalesInvoice_${pdfId?.VoucherNo}.pdf`);
    // };
    // if (isCustom) {
    //   handleGeneratePdf();
    // } else if (IsEinvoiceTemplate) {
    //   GeneratePDF();
    // } else {
    //   // downloadPDF();
    // }
  };

  // Generate Invoice Link
  const generateLink = async () => {
    let link = `${
      window.location.origin
    }/salesInvoiceReport/?CompanyID=${CompanyID}&no_of_copies=1&invoice_type=sales_invoice&invoice_id=${
      dataDetails.invoiceLists[0].id
    }&PriceRounding=${2}&BranchID=${BranchID}&print_template="True"`;
    try {
      await navigator.clipboard.writeText(link);
      dispatch(
        openSnackbar({
          open: true,
          message: "Link copied successfully",
          severity: "success",
        })
      );
    } catch (err) {
      dispatch(
        openSnackbar({
          open: true,
          message: "Some thing went wrong! Try again",
          severity: "warning",
        })
      );
    }
  };

  // Print Invoice 🖨️🖨️🖨️
  const printInvoice = async function () {
    const printURL = `${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=${printPage}&invoice_type=sales_invoice&invoice_id=${dataDetails.invoiceLists[0].id}&PriceRounding=3&BranchID=${BranchID}`;

    try {
      // Fetch the HTML content from the server
      const response = await fetch(printURL);
      if (!response.ok) {
        throw new Error("Failed to fetch print content");
      }

      // Get the HTML content as a string
      const htmlContent = await response.text();

      // Create a hidden iframe
      const iframe = document.createElement("iframe");
      iframe.style.display = "none"; // Keep it hidden
      document.body.appendChild(iframe);

      // Write the fetched content into the iframe
      const iframeDoc = iframe.contentDocument || iframe.contentWindow.document;
      iframeDoc.open();
      iframeDoc.write(htmlContent);
      iframeDoc.close();

      // Flag to track if printing has already started
      let hasPrinted = false;

      // Wait until iframe content is fully loaded, then print
      iframe.onload = function () {
        if (!hasPrinted) {
          hasPrinted = true; // Set the flag to prevent multiple prints

          // Trigger the print dialog
          iframe.contentWindow.print();

          // Clean up the iframe after printing
          iframe.contentWindow.onafterprint = function () {
            document.body.removeChild(iframe);
          };
        }
      };
    } catch (error) {
      console.error("Error loading print content:", error);
    }
  };

  // Invoice ConvertsC 🔄🔁🔄
  const convertFunc = async function () {
    let loyaltyCustomerResponse = await loyalty_customer({
      CreatedUserID: user_id,
      BranchID: BranchID,
      CompanyID: CompanyID,
      page_no: 1,
      items_per_page: 20,
    });
    let loyaltyCustomerDetails = [];
    if (loyaltyCustomerResponse.data) {
      loyaltyCustomerDetails = loyaltyCustomerResponse.data;
    }

    let voucher_datas = await main_voucher({
      CompanyID,
      UserID: user_id,
      BranchID,
      VoucherType: "SI",
    });

    let cash_bank_list = await ledger_list_for_payment({
      CreatedUserID: user_id,
      BranchID: BranchID,
      AccountGroupUnder: [8, 9],
      CompanyID: CompanyID,
      PriceRounding: PriceDecimalPoint,
      is_multiple: true,
    });
    let CashList = [];
    let BankList = [];
    if (cash_bank_list.data) {
      CashList = cash_bank_list.data.filter((c) => c.AccountGroupUnder === 9);
      BankList = cash_bank_list.data.filter((b) => b.AccountGroupUnder === 8);
    }

    let tax_type_param = "";
    if (VAT === true) {
      tax_type_param = 1;
    } else if (GST === true) {
      tax_type_param = 2;
    }
    let taxList = await CallTaxListByType({
      CompanyID,
      user_id,
      BranchID,
      access,
      tax_type_param,
    });

    // countries API
    let countries = await list_countries(access);

    let salesmans = [];
    if (EnableSalesManInSales === true) {
      salesmans = await CallEmployees({
        CompanyID: CompanyID,
        CreatedUserID: user_id,
        BranchID: BranchID,
        PriceRounding: PriceDecimalPoint,
        list_type: "sales",
      });
    }

    // pricecategory
    let priceCategories = [];
    if (PriceCategory === true) {
      priceCategories = await price_category({
        CompanyID: CompanyID,
        CreatedUserID: user_id,
        BranchID: BranchID,
      });
    }

    // LedgerAPI
    let ledgers = await CallLedgerListById({
      CompanyID: CompanyID,
      CreatedUserID: user_id,
      BranchID: BranchID,
      type_invoice: "SalesInvoice",
      PriceRounding: PriceDecimalPoint,
      load_data: true,
      ledger_name: "",
      length: 0,
    });

    let ledgers_data = [];
    if (ledgers.data) {
      ledgers_data = ledgers.data;
    }

    let single_datas = await sales_master({
      CompanyID,
      user_id,
      BranchID,
      access,
      PriceRounding: PriceDecimalPoint,
      unq_id: dataDetails.invoiceLists[0].id,
    });

    let convertingData = {};

    if (single_datas.StatusCode === 6000) {
      let ledgers = ledgers_data.filter(
        (i) => i.LedgerID === single_datas.data.LedgerID
      );
      if (!ledgers.length) {
        ledgers_data.push({
          LedgerID: single_datas.data.LedgerID,
          name: single_datas.data.LedgerName,
        });
      }
      let LedgerID = single_datas.data.LedgerID;
      let LedgerName = single_datas.data.LedgerName;
      let datas = ledgers_data.filter((i) => i.LedgerID === LedgerID);
      let LedgerIDVal = datas[0].name;
      if (!datas.length) {
        ledgers_data.push({
          LedgerID: LedgerID,
          name: LedgerName,
        });
        LedgerIDVal = LedgerName;
      }
      let PaymentReceived = false;
      if (
        single_datas.data.CashReceived > 0 ||
        single_datas.data.BankAmount > 0
      ) {
        PaymentReceived = true;
      }
      let LedgerBalance = ledgers_data[0].Balance;
      let shipping_address = [];
      let single_shipping = [];
      LedgerBalance = single_datas.data.LedgerBalance;
      shipping_address = single_datas.data.ShippingAddressList;
      single_shipping = shipping_address.filter(
        (i) => i.id === single_datas.data.ShippingAddress
      );

      let warehouses = await CallWarehouses({
        CompanyID,
        user_id,
        BranchID,
        access,
      });
      let warehouses_data = [];
      let DefaultAccountForUser = "";
      let Cash_Account = "";
      let Bank_Account = "";
      let Sales_Account = "";
      let EmployeesList = [];
      let EmployeeID = null;
      let PriceCategoryList = [];
      let TaxList = [];
      let show_all_warehouse = true;
      let DefaultWarehouse = 1;
      if (taxList.data) {
        TaxList = taxList.data;
      }
      if (salesmans) {
        if (salesmans.data) {
          EmployeesList = salesmans.data;
          let employee_obj = EmployeesList.filter(
            (i) => i.EmployeeID === salesmans.EmployeeID
          );
          if (employee_obj.length) {
            EmployeeID = salesmans.EmployeeID;
          }
        }
      }

      if (priceCategories) {
        if (priceCategories.data) {
          PriceCategoryList = priceCategories.data;
        }
      }

      if (warehouses.data) {
        warehouses_data = warehouses.data;
      }
      if (warehouses.DefaultAccountForUser) {
        DefaultAccountForUser = warehouses.DefaultAccountForUser;
      }
      if (warehouses.Cash_Account) {
        Cash_Account = warehouses.Cash_Account;
      }
      if (warehouses.Bank_Account) {
        Bank_Account = warehouses.Bank_Account;
      }
      if (warehouses.Sales_Account) {
        Sales_Account = warehouses.Sales_Account;
      }
      if (warehouses.show_all_warehouse === false) {
        show_all_warehouse = warehouses.show_all_warehouse;
      }
      if (warehouses.DefaultWarehouse) {
        DefaultWarehouse = warehouses.DefaultWarehouse;
      }
      if (show_all_warehouse === false) {
        warehouses_data = warehouses_data.filter(
          (w) => w.WarehouseID === DefaultWarehouse
        );
      }

      let VoucherNo = "";
      let InvoiceNo = "";
      let PreFix = "";
      let Seperator = "";
      let ActualPreFix = "";
      let ActualSeperator = "";
      let ActualInvoiceNo = "";
      if (voucher_datas.VoucherNo) {
        VoucherNo = voucher_datas.VoucherNo;
      }
      if (voucher_datas.InvoiceNo) {
        InvoiceNo = voucher_datas.InvoiceNo;
      } else {
        InvoiceNo = voucher_datas.new_num;
      }
      if (voucher_datas.PreFix) {
        PreFix = voucher_datas.PreFix;
      } else {
        PreFix = "";
      }
      if (voucher_datas.Seperator) {
        Seperator = voucher_datas.Seperator;
      }
      if (voucher_datas.ActualPreFix) {
        ActualPreFix = voucher_datas.ActualPreFix;
      } else {
        ActualPreFix = "";
      }
      if (voucher_datas.ActualSeperator) {
        ActualSeperator = voucher_datas.ActualSeperator;
      }
      if (voucher_datas.ActualInvoiceNo) {
        ActualInvoiceNo = voucher_datas.ActualInvoiceNo;
      } else {
        ActualInvoiceNo = voucher_datas.new_num;
      }

      const CompanyState = State;
      const CompanyCountry = Country;

      let master_datas = await MasterCalculations(
        single_datas.data.SalesDetails,
        State,
        PriceDecimalPoint,
        RoundOffSales
      );

      convertingData = {
        LedgerList: ledgers_data,
        ProductList: single_datas.data.ProductList,
        ProductCodeList: single_datas.data.ProductList,
        saleDetails: single_datas.data.SalesDetails,

        LedgerBalance: LedgerBalance,
        CashLedgerBalance: ledgers_data[0] ? ledgers_data[0].Balance : 0,
        countries: countries.data,
        WareHoseList: warehouses_data,
        DefaultAccountForUser: DefaultAccountForUser,
        Cash_Account: Cash_Account,
        Bank_Account: Bank_Account,
        Sales_Account: Sales_Account,
        country: CompanyCountry,
        state: CompanyState,
        State_of_Supply: CompanyState,
        EmployeesList,
        VoucherNo: VoucherNo,
        InvoiceNo: InvoiceNo,
        PreFix: PreFix,
        Seperator: Seperator,
        ActualPreFix: ActualPreFix,
        ActualSeperator: ActualSeperator,
        ActualInvoiceNo: ActualInvoiceNo,
        Date: moment().format("YYYY-MM-DD"),
        DeliveryDate: single_datas.data.DeliveryDate,
        tax_list: TaxList,
        DefaultDate: moment().format("YYYY-MM-DD"),
        GrandTotal: single_datas.data.GrandTotal,
        LedgerID: LedgerID,
        LedgerIDVal: LedgerIDVal,
        EmployeeID: single_datas.data.EmployeeID,
        SalesAccount: single_datas.data.SalesAccount,
        CustomerName: single_datas.data.CustomerName,
        Address1: single_datas.data.Address1,
        BillingAddress: single_datas.data.Address1,
        Notes: single_datas.data.Notes,
        TotalGrossAmt: single_datas.data.TotalGrossAmt,
        TotalTaxableAmount: single_datas.data.TotalTaxableAmount,
        TotalTax: single_datas.data.TotalTax,
        TotalNetTotal: single_datas.data.NetTotal,
        TotalDiscount: single_datas.data.TotalDiscount,
        RoundOff: single_datas.data.RoundOff,
        RoundOffSaveVal: single_datas.data.RoundOff,
        CashReceived: single_datas.data.CashReceived,
        BankAmount: single_datas.data.BankAmount,
        CardTypeID: single_datas.data.CardTypeID,
        CardNumber: single_datas.data.CardNumber,
        TransactionTypeID: single_datas.data.TransactionTypeID,
        WarehouseID: single_datas.data.WarehouseID,
        IsActive: single_datas.data.IsActive,
        TaxID: single_datas.data.TaxID,
        TaxType: single_datas.data.TaxType,
        TotalVATAmount: single_datas.data.VATAmount,
        TotalSGSTAmount: single_datas.data.SGSTAmount,
        TotalCGSTAmount: single_datas.data.CGSTAmount,
        TotalIGSTAmount: single_datas.data.IGSTAmount,
        BillDiscPercent: single_datas.data.BillDiscPercent,
        BillDiscAmount: single_datas.data.BillDiscAmt,
        Balance: single_datas.data.Balance,
        is_customer: single_datas.data.is_customer,
        CashID: single_datas.data.CashID,
        BankID: single_datas.data.BankID,
        PaymentReceived: PaymentReceived,
        SAC: single_datas.data.SAC,
        ShippingTaxPerc: single_datas.data.SalesTax,
        ShippingCharge: single_datas.data.ShippingCharge,
        shipping_tax_amount: single_datas.data.shipping_tax_amount,
        ShippingTaxID: single_datas.data.TaxTypeID,
        Country_of_Supply: single_datas.data.Country_of_Supply,
        State_of_Supply: single_datas.data.State_of_Supply,
        GST_Treatment: single_datas.data.GST_Treatment,
        VAT_Treatment: single_datas.data.VAT_Treatment,
        GSTNumber: single_datas.data.GSTNumber,
        is_did_mount: false,
        Mobile: single_datas.data.Mobile,
        shipping_address_list: shipping_address,
        shipping_address: single_shipping,
        unq_id: dataDetails.invoiceLists[0].id,
        is_edit: true,
        call_master: true,
        CountryCode: CountryCode,
        GST: GST,
        VAT: VAT,
        edit_did_mount: true,
        CashList: CashList,
        BankList: BankList,
        PriceCategoryList,
        PriceCategoryID: single_datas.data.PriceCategoryID,
        TaxNo: single_datas.data.Tax_no,
        OrderNo: single_datas.data.OrderNo,
        CreditPeriod: single_datas.data.CreditPeriod,
        LoyaltyCustomerID: single_datas.data.LoyaltyCustomerID_ID,
        LoyaltyCustomer_AccountLedgerID:
          single_datas.data.LoyaltyCustomer_AccountLedgerID,
        display_RadeemPoint: Math.abs(single_datas.data.LoyaltyCustomerPoint),
        display_LoyaltyValue: single_datas.data.LoyaltyCustomerValue,
        // display_RadeemPoint: 14,
        // display_LoyaltyValue: 24,

        loyaltyCustomerDetails,
        billing_address_list: single_datas.data.BillingAddressList
          ? single_datas.data.BillingAddressList
          : [],
        billing_address_single: single_datas.data.billing_address_single
          ? single_datas.data.billing_address_single
          : {},
        is_manual_roundoff: single_datas.data.is_manual_roundoff,
        EditGrandTotal: single_datas.data.GrandTotal,
        is_billwised: single_datas.data.is_billwised,
        DueDate: single_datas.data.DueDate,
        IGST_final_list: master_datas.IGST_final_list,
        SGST_final_list: master_datas.SGST_final_list,
      };
    }
  };

  const convertToEstimate = function () {};

  const convertToNote = function () {};

  const fetPrintData = async function () {
    setPdfLoading(true);
    const res = await sales_invoice_single_print({
      CompanyID,
      no_of_copies: 1,
      invoice_type: "sales_invoice",
      invoice_id: dataDetails.invoiceLists[0].id,
      PriceRounding: 2,
      BranchID: 1,
      print_template: `"True"`,
    }).then((res) => {
      if (res.data) {
        setPrintInvoiceData(res);
        setEinvoiceTemplate(true);
        setPdfLoading(false);
      }
    });
  };

  // Fetch Datas
  const callInvoiceList = async function () {
    setLoadingState({ ...loadingState, isListLoading: true });
    await list_sales_api({
      CompanyID,
      BranchID,
      page: page,
      noOfItems: 15,
    }).then((res) => {
      if (res.StatusCode === 6000) {
        setDataDetails((prev) => ({
          ...prev,
          salesInvoiceList: res.data,
          // invoiceLists[0]: res.data[0],
          invoiceLists: page === 1 ? [res.data[0]] : prev.invoiceLists,
          totalItem: res.count,
        }));
      }
    });
    setLoadingState({ ...loadingState, isListLoading: false });
  };

  const fetchViewHistory = function () {
    sales_invoice_single_log({
      CompanyID,
      BranchID,
      CreatedUserID: user_id,
      VoucherNo: dataDetails.invoiceLists[0].VoucherNo,
      VoucherType: "SI",
    }).then((res) => {
      let historyList = [];
      if (res.StatusCode === 6000) {
        historyList = res.data;
      } else {
        historyList = [];
      }

      setDataDetails((prev) => ({
        ...prev,
        historyInvoiceList: historyList,
      }));
    });

    sales_invoice_single_payment({
      CompanyID,
      BranchID,
      CreatedUserID: user_id,
      VoucherNo: dataDetails.invoiceLists[0].VoucherNo,
      VoucherType: "SI",
    }).then((res) => {
      let historyList = [];
      if (res.StatusCode === 6000) {
        historyList = res.data;
      } else {
        historyList = [];
      }

      setDataDetails((prev) => ({
        ...prev,
        historyPaymentList: historyList,
      }));
    });
  };

  const fetchInvoiceTemplate = function () {
    sales_invoice_single_print({
      CompanyID,
      no_of_copies: 1,
      invoice_type: "sales_invoice",
      invoice_id: dataDetails.invoiceLists[0].id,
      PriceRounding: 2,
      BranchID,
      print_template: "True",
    }).then((res) => {
      // captureAsCanvas(res);
    });
  };

  // const captureAsCanvas = async (htmlEle) => {
  //   const parser = new DOMParser();
  //   const dom = parser.parseFromString(htmlEle, "text/html");

  //   // Create a hidden container in the DOM to hold the parsed content
  //   const hiddenContainer = document.createElement("div");
  //   hiddenContainer.style.position = "absolute";
  //   hiddenContainer.style.top = "-9999px"; // Position it off-screen
  //   hiddenContainer.style.left = "-9999px";
  //   hiddenContainer.style.visibility = "hidden";

  //   hiddenContainer.innerHTML = dom.body.innerHTML;
  //   document.body.appendChild(hiddenContainer);

  //   try {
  //     const canvas = await html2canvas(hiddenContainer);
  //     const image = canvas.toDataURL("image/png");
  //     console.log("Base64 Image String: ", image); // Log Base64 string to confirm it is generated
  //     setInvoicePrev(image); // Set the state with the generated image
  //   } catch (error) {
  //     console.error("Error generating canvas:", error);
  //   } finally {
  //     // Clean up by removing the hidden container from the DOM
  //     document.body.removeChild(hiddenContainer);
  //   }
  // };

  useEffect(() => {
    callInvoiceList();
  }, [page]);

  useEffect(() => {
    if (dataDetails.invoiceLists[0].id) {
      fetchViewHistory();
      fetPrintData();
      // fetchInvoicePrint();
    }
  }, [dataDetails.invoiceLists[0].id]);

  useEffect(() => {
    searchInvoice();
  }, [searchValue]);

  // useEffect(() => {
  //   captureAsCanvas()
  // }, [htmlContent])

  return (
    <Paper
      elevation={0}
      sx={{ height: "100%", display: "flex", position: "relative" }}
    >
      <Stack
        sx={{ minWidth: 400, height: "100%", justifyContent: "space-between" }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent={!isSearch ? "space-between" : "flex-end"}
          p={2}
          px={0}
          height={"70px"}
        >
          {!isSearch && (
            <Stack direction="row" gap={1} alignItems={"center"}>
              <IconButton size="small" component={Link} to="/list-sales">
                <ArrowBackIcon />
              </IconButton>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "500", color: "#001746" }}
              >
                Sales Invoice
              </Typography>
            </Stack>
          )}

          {!isSearch && (
            <Stack direction="row" gap={1}>
              <IconButton
                sx={{ bgcolor: "#DFF0FF", color: "#072B4A" }}
                component={Link}
                to={"/create-sales"}
              >
                <AddIcon fontSize="small" />
              </IconButton>
              <IconButton
                sx={{ bgcolor: "#FFE2E2", color: "error.main" }}
                onClick={deleteInvoices}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
              <IconButton
                sx={{ bgcolor: "#DFF0FF", color: "#072B4A" }}
                onClick={handleClickSearch}
              >
                <SearchNormal fontSize="small" />
              </IconButton>
            </Stack>
          )}

          <Collapse orientation="horizontal" in={isSearch} unmountOnExit>
            <Box
              sx={{
                backgroundColor: "#DFF0FF",
                width: "380px",
                // transition: "width 0.4s ease",
                borderRadius: 5,
                pl: 2,
                mr: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                overflow: "hidden",
              }}
            >
              <InputBase
                sx={{ fontSize: "14px", width: "100%" }}
                inputRef={inputRef}
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                // onKeyDown={handleKeyDown}
              />
              <IconButton
                color="primary"
                size="small"
                onClick={() => setIsSearch(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Collapse>
        </Stack>

        <TableContainer
          component={Box}
          sx={{
            height: "100%",
            overflow: "scroll",
            borderRight: "2px solid #F5F5F5",
            p: 0,
          }}
        >
          <Table aria-label="simple table">
            <TableBody>
              <LoadingList isLoading={loadingState.isListLoading}>
                {dataDetails.salesInvoiceList.map((obj, ind) => (
                  <TableRow
                    key={ind + 1}
                    // sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    // component={Button}
                  >
                    <TableCell sx={{ p: 0, width: "10%", height: "100%" }}>
                      <Checkbox
                        size="small"
                        checked={dataDetails.invoiceLists.some(
                          (item) => item.id === obj.id
                        )}
                        onChange={(event) => {
                          if (!event.target.checked) {
                            let newInvoiceList =
                              dataDetails.invoiceLists.filter(
                                (e) => e.id !== obj.id
                              );
                            console.log(newInvoiceList);

                            setDataDetails({
                              ...dataDetails,
                              invoiceLists: newInvoiceList,
                            });
                          } else {
                            let newInvoiceList = [
                              ...dataDetails.invoiceLists,
                              obj,
                            ];
                            console.log(newInvoiceList);
                            setDataDetails({
                              ...dataDetails,
                              invoiceLists: newInvoiceList,
                            });
                          }
                        }}
                      />
                    </TableCell>

                    <TableCell component="th" scope="row" sx={{ p: 0 }}>
                      <Button
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          textAlign: "left",
                          py: 1,
                        }}
                        onClick={() => {
                          let newInvoiceList = [
                            obj,
                            ...dataDetails.invoiceLists.slice(1),
                          ];
                          setPdfLoading(true);
                          setDataDetails({
                            ...dataDetails,
                            // invoiceLists[0]: obj,
                            invoiceLists: newInvoiceList,
                          });
                        }}
                      >
                        <div>
                          <Typography sx={{ fontSize: "14px" }}>
                            {(obj?.CustomerName || "Cash in Hand").length > 20
                              ? (obj?.CustomerName || "Cash in Hand").slice(
                                  0,
                                  17
                                ) + "..."
                              : obj?.CustomerName || "Cash in Hand"}
                          </Typography>

                          <Typography
                            sx={{
                              display: "inline-block",
                              color: "primary.main",
                              fontSize: "12px",
                              mr: 2,
                            }}
                          >
                            {obj?.VoucherNo}
                          </Typography>
                          <CustomChip size="small" label={obj.billStatus} />
                        </div>
                        <div>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              textAlign: "right",
                              fontWeight: "500",
                            }}
                          >
                            {CurrencySymbol} {formatNumber(obj.GrandTotal)}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "12px",
                              textAlign: "right",
                              color: "#4B4B4B",
                            }}
                          >
                            {obj.Date}
                          </Typography>
                        </div>
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </LoadingList>
            </TableBody>
          </Table>
        </TableContainer>

        <Box
          sx={{
            px: 4,
            py: 1,
            borderTop: "2px solid #F5F5F5",
            borderRight: "2px solid #F5F5F5",
          }}
        >
          <Pagenation
            totalItem={dataDetails.totalItem}
            page_no={page}
            setPage_no={setPage}
          />
        </Box>
      </Stack>

      <Box sx={{ width: "100%" }}>
        <Stack
          sx={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            px: 4,
            py: 2,
            borderBottom: "2px solid #F5F5F5",
          }}
        >
          <Box>
            <Typography
              sx={{
                display: "inline-block",
                color: "#4B4B4B",
                fontSize: "15px",
                mr: 1,
              }}
            >
              INV -NO:
            </Typography>
            <Typography
              sx={{
                display: "inline-block",
                color: "#2F2F2F",
                fontSize: "15px",
                fontWeight: "bold",
                mr: 2,
              }}
            >
              {dataDetails.invoiceLists[0].VoucherNo}
            </Typography>
            <CustomChip
              size="medium"
              label={dataDetails.invoiceLists[0].billStatus}
            />
          </Box>

          <Stack sx={{ flexDirection: "row", gap: 2, alignItems: "center" }}>
            <Typography
              sx={{
                border: 1,
                fontSize: "12px",
                borderRadius: "6px",
                py: "4px",
                px: 2,
              }}
            >
              Sales Man: E4
            </Typography>
            {dataDetails.invoiceLists[0].billStatus !== "paid" && (
              <Button
                color="success"
                startIcon={<DollerCircle />}
                onClick={payRecipt}
              >
                Payment
              </Button>
            )}
            <Button
              sx={{ color: "black" }}
              onClick={() => {
                setOpenHistoryModal(true);
              }}
            >
              View History
            </Button>
          </Stack>
        </Stack>
        <ButtonGroup
          variant="text"
          aria-label="Basic button group"
          sx={{
            borderBottom: "2px solid #F5F5F5",
            width: "100%",
            ".MuiButton-root": {
              fontSize: "13px",
              color: "#001746",
              borderColor: "#CECDCD",
              px: 2,
              py: 1,
            },
          }}
        >
          <Button onClick={editInvoice} startIcon={<EditLine />}>
            Edit
          </Button>
          <Button
            startIcon={<ShareLine />}
            endIcon={<ExpandMoreIcon />}
            id="share-button"
            aria-controls={shareOpen ? "share-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={shareOpen ? "true" : undefined}
            onClick={(e) => setShareAnchorEl(e.currentTarget)}
          >
            Share
          </Button>
          <Menu
            id="share-menu"
            anchorEl={shareAnchorEl}
            open={shareOpen}
            onClose={() => setShareAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "share-button",
            }}
            sx={{ ".MuiPaper-root": { width: 121 } }}
          >
            <MenuItem onClick={() => setOpenSendMail(true)}>Mail</MenuItem>
            <MenuItem onClick={shareWhatsApp}>WhatsApp</MenuItem>
            <MenuItem onClick={shareExportPDF}>Export PDF</MenuItem>
            <MenuItem disabled>SMS</MenuItem>
          </Menu>

          <Button onClick={generateLink} startIcon={<LinkLine />}>
            Generate Link
          </Button>
          <Button
            startIcon={<PrinterLine />}
            onClick={(e) => {
              setPrintAnchorEl(e.currentTarget);
            }}
          >
            Print
          </Button>
          <Popover
            id={id}
            open={openPrint}
            anchorEl={printAnchorEl}
            onClose={() => {
              setPrintAnchorEl(null);
            }}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box sx={{ p: 1, width: 200 }}>
              <Typography sx={{ color: "#001746", fontSize: "12px", mb: 1 }}>
                Select Number of Copies.
              </Typography>
              <Stack gap={1} flexDirection={"row"}>
                <Select
                  id="print-simple-select"
                  value={printPage}
                  onChange={(event) => setPrintPage(event.target.value)}
                  sx={{
                    width: "100%",
                    ".MuiSelect-select": { py: 1, fontSize: "14px" },
                  }}
                  IconComponent={SelectIcon}
                >
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </Select>
                <Button
                  variant="contained"
                  sx={{ color: "white", width: "100%", textAlign: "center" }}
                  onClick={() => printInvoice()}
                >
                  Print
                </Button>
              </Stack>
            </Box>
          </Popover>
          <Button
            startIcon={<Convert3D />}
            endIcon={<ExpandMoreIcon />}
            id="covert-button"
            aria-controls={covertOpen ? "covert-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={covertOpen ? "true" : undefined}
            onClick={(e) => setCovertAnchorEl(e.currentTarget)}
          >
            Covert
          </Button>
          <Menu
            id="covert-menu"
            anchorEl={covertAnchorEl}
            open={covertOpen}
            onClose={() => setCovertAnchorEl(null)}
            MenuListProps={{
              "aria-labelledby": "covert-button",
            }}
          >
            <MenuItem onClick={convertToEstimate}>
              Convert to Sales Estimate
            </MenuItem>
            <MenuItem onClick={convertToNote}>
              Convert to Delivary Note
            </MenuItem>
          </Menu>
        </ButtonGroup>

        <Box sx={{ height: "calc(100vh - 182px)", overflow: "scroll" }}>
          <Stack
            sx={{
              width: "100%",
              alignItems: "center",
              justifyContent: "flex-start",
              pt: 4,
              pb: 2,
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: "500",
                color: "#A8A8A8",
                textAlign: "center",
                mb: 2,
              }}
            >
              Invoice Preview
            </Typography>
            {dataDetails.invoiceLists[0]?.id &&
              (!IsEinvoiceTemplate ? (
                <Paper
                  elevation={10}
                  sx={{
                    width: "794px",
                    height: "1123px",
                    minHeight: "1123px",
                    borderRadius: "15px",
                    iframe: {
                      borderRadius: "15px",
                      border: 0,
                      width: "100%",
                      height: !pdfLoading ? "100%" : "0px", // For loading purpos
                      // display: pdfLoading? "none" : "block"
                    },
                  }}
                >
                  {pdfLoading && <InvoicePDFLoading />}
                  <iframe
                    onLoad={(e) => {
                      setPdfLoading(false);
                    }}
                    src={`${URL}api/v10/settings/print-paper-new/?CompanyID=${CompanyID}&no_of_copies=${1}&invoice_type=sales_invoice&invoice_id=${
                      dataDetails.invoiceLists[0].id
                    }&PriceRounding=3&BranchID=${BranchID}&print_template="True"`}
                    loading="lazy"
                    title="Invoice-Preview"
                    type="application/pdf"
                    frameborder="0"
                    id="invoiceIframe"
                  />
                </Paper>
              ) : (
                <EinvoiceTemplate ref={Einvoiceref} data={printInvoiceData} />
              ))}
          </Stack>
        </Box>
      </Box>
      <ViewHistoryModal
        open={openHistoryModal}
        handleClose={() => {
          setOpenHistoryModal(false);
        }}
        dataDetails={dataDetails}
      />

      <SendMailModal
        open={openSendMail}
        handleClose={() => {
          setOpenSendMail(false);
        }}
        dataDetails={dataDetails}
        setDataDetails={setDataDetails}
        submitMail={submitMail}
      />
    </Paper>
  );
}

export default SalesInvoiceSingle;

const CustomChip = function ({ label, ...other }) {
  const style = {
    // color: "success.main",
    // backgroundColor: "#E9F4EA",
    px: 1,
    // height: "20px",
    fontSize: "12px",
  };

  switch (label) {
    case "paid":
      style.color = "success.main";
      style.backgroundColor = "#E9F4EA";
      break;

    case "warning":
      style.color = "#00B2B8";
      style.backgroundColor = "#00B2B814";
      break;

    case "unpaid":
      style.color = "error.main";
      style.backgroundColor = "#B8000012";
      break;

    default:
      break;
  }
  return <Chip sx={style} label={label} {...other} />;
};

const LoadingList = function ({ isLoading, children }) {
  return isLoading ? (
    <Stack sx={{ pl: "4px", gap: 1 }}>
      {Array.from({ length: 7 }, (_) => (
        <Skeleton
          variant="rounded"
          animation="wave"
          height={60}
          sx={{
            width: Math.floor(Math.random() * 100 + 1) + "%",
            backgroundColor: "#DFF0FF",
          }}
        />
      ))}
    </Stack>
  ) : (
    children
  );
};

LoadingList.propTypes = {
  isLoading: PropTypes.bool,
  children: PropTypes.element,
};

LoadingList.defaultProps = {
  isLoading: false,
};

const styleViewHistory = {
  position: "absolute",
  top: "24%",
  right: "0",
  // transform: 'translate(-50%, -50%)',
  width: 380,
  height: 550,
  // bgcolor: "background.paper",
  // border: '2px solid #000',
  // boxShadow: "none",
  borderRadius: "15px 0px 0px 15px",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ViewHistoryModal = function ({ open, handleClose, dataDetails }) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
      sx={{
        ".MuiBackdrop-root": {
          bgcolor: "#00000025",
        },
      }}
      // hideBackdrop={true}
    >
      <Slide direction="left" in={open} mountOnEnter unmountOnExit>
        <Paper elevation={5} sx={styleViewHistory}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              p: 1,
              pb: 0,
            }}
          >
            <IconButton color="primary" onClick={() => handleClose()}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{
                minHeight: "40px",
                ".MuiButtonBase-root": {
                  textTransform: "none",
                  py: 1,
                  minHeight: "40px",
                },
              }}
            >
              <Tab label="Invoice" {...a11yProps(0)} />
              <Tab label="Payment" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <Box sx={{ height: "100%", overflowX: "scroll" }}>
            <CustomTabPanel value={value} index={0}>
              <Table aria-label="simple table">
                <TableBody>
                  {dataDetails.historyInvoiceList.map((obj, ind) => (
                    <TableRow key={ind + 1}>
                      <TableCell>
                        <Typography sx={{ fontSize: "14px" }}>
                          Invoice Payment Details Modified By
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {obj?.UserName}
                        </Typography>
                        <Typography sx={{ fontSize: "14px", color: "#4B4B4B" }}>
                          {moment(obj.CreatedDate).format(
                            "DD/MM/YYYY, hh:mm A"
                          )}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <Table aria-label="simple table">
                <TableBody>
                  {dataDetails.historyPaymentList.map((obj, ind) => (
                    <TableRow key={ind + 1}>
                      <TableCell>
                        <Button
                          variant="outlined"
                          component={Link}
                          to={`${MyUrl}/dashboard ${
                            obj.PaymentVoucherType === "SR"
                              ? "/sales-return-preview"
                              : "/create-receipt?boo=true&is_edit=true&unq_id=" +
                                obj.id
                          }`}
                          target="_blank"
                          sx={{
                            fontWeight: 600,
                            textDecoration: "none",
                            fontSize: "14px",
                            borderRadius: 4,
                            // color: "#001746",
                            // borderColor: "#001746",
                            py: 0,
                          }}
                          endIcon={<ArrowRightIcon />}
                        >
                          {obj.PaymentInvoiceNo}
                        </Button>
                        <Typography
                          sx={{
                            color: "#232323",
                            fontWeight: 600,
                            fontSize: "14px",
                          }}
                        >
                          {obj.PaymentVoucherType === "SI"
                            ? "Initial payment Recorded "
                            : "payment Recorded "}
                          <span style={{ color: "#054185", fontWeight: 600 }}>
                            {formatNumber(Number(obj.Payments))}
                          </span>
                          {" by "}
                          <span style={{ color: "#0F8766", fontWeight: 600 }}>
                            {/* {state.billwise_details.UserName}. */}
                          </span>
                          {" Balance is "}
                          <span style={{ color: "#940808", fontWeight: 600 }}>
                            {obj.Balance}
                          </span>
                        </Typography>
                        <Typography
                          sx={{ color: "#818181", fontSize: "1.2em" }}
                        >
                          {obj?.PaymentDate}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </CustomTabPanel>
          </Box>
        </Paper>
      </Slide>
    </Modal>
  );
};

const SendMailStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  p: 3,
  borderRadius: 3,
};

const SendMailModal = function ({
  open,
  handleClose,
  dataDetails,
  setDataDetails,
  submitMail,
}) {
  const [mailObj, setMailObj] = useState({
    to: "",
    cc: "",
    replayto: "",
  });

  const submitForm = function () {
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    // if (input.match(validRegex)) {
    //   // alert("Valid email address!");
    //   return true;
    // } else {
    //   // alert("Invalid email address!");
    //   return false;
    // }
    setDataDetails({
      ...dataDetails,
      sendMailData: {
        ...dataDetails.sendMailData,
        to: mailObj.to,
        cc: mailObj.cc,
        replayto: mailObj.replayto,
      },
    });
    submitMail();
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={SendMailStyle}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            mb: 2,
          }}
        >
          <Typography
            sx={{
              color: "#003060",
              fontSize: "15px",
              fontWeight: "bold",
            }}
          >
            Send as Mail
          </Typography>
          <Tooltip
            title="You can send the selected invoice details throgh email"
            arrow
          >
            <InfoIcon sx={{ fontSize: "20px", opacity: "0.7" }} />
          </Tooltip>
        </Box>
        <Paper
          elevation={0}
          sx={{
            backgroundColor: "#F8F8F8",
            display: "flex",
            height: "39px",
            justifyContent: "space-between",
            alignItems: "center",
            border: "1px solid #E3E3E3",
            px: "14px",
            mb: "10px",
          }}
        >
          <Typography
            sx={{ fontSize: "13px", color: "#000000", fontWeight: "bold" }}
          >
            Default
          </Typography>
          {dataDetails.sendMailData?.isExist ? (
            <Typography
              sx={{
                fontSize: "12px",
                color: "#002570",
                textTransform: "none",
              }}
            >
              {dataDetails.sendMailData?.email}
            </Typography>
          ) : (
            <Tooltip
              title="If a Sender ID is set up, emails will be sent from that address instead of the default one."
              arrow
              placement="top"
            >
              <Button
                variant="text"
                // onClick={() =>
                //   history.push({ pathname: "/dashboard/setupsenderid" })
                // }
                component={Link}
                to={`${MyUrl}/dashboard/setupsenderid`}
                target="_balnk"
              >
                Set up a Sender ID
              </Button>
            </Tooltip>
          )}
        </Paper>
        <Stack gap={2}>
          <VBInputField
            label="To"
            type="text"
            value={mailObj.to}
            onChange={(e) => {
              setMailObj({
                ...mailObj,
                to: e.target.value,
              });
            }}
          />

          <VBInputField
            label="cc"
            type="text"
            value={mailObj.cc}
            onChange={(e) => {
              setMailObj({
                ...mailObj,
                cc: e.target.value,
              });
            }}
          />

          <VBInputField
            label="Replay to"
            type="text"
            value={mailObj.replayto}
            onChange={(e) => {
              setMailObj({
                ...mailObj,
                replayto: e.target.value,
              });
            }}
          />

          <Button
            disabled={dataDetails.isLoadingMail}
            sx={{ width: "100%", color: "white" }}
            variant="contained"
            onClick={submitForm}
          >
            Send
          </Button>
          <Button
            sx={{ width: "100%" }}
            variant="text"
            onClick={() => handleClose()}
            disabled={dataDetails.isLoadingMail}
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
};
